import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

const formSchema = z.object({
  family_tuberculosis_history: z.boolean().optional(),
  recent_contact_with_tb: z.boolean().optional(),
  test_date: z.date().optional(),
  test_type: z.string().optional(),
  test_result: z.string().optional(),
  action_taken: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const TuberculosisInformationForm = () => {
  const { resident }: { resident: Resident | null } = useSelector((state: RootState) => state.residents.resident);

  const tuberculosisInformation = useMemo(() => {
    const data: any = resident?.health_information || {};

    return {
      family_tuberculosis_history: !!data.family_tuberculosis_history,
      recent_contact_with_tb: !!data.recent_contact_with_tb,
      test_date: data.tuberculosis_test_date ? new Date(data.tuberculosis_test_date) : undefined,
      test_type: data.tuberculosis_test_type || '',
      test_result: data.tuberculosis_test_result || '',
      action_taken: data.action_taken || ''
    };
  }, [resident]);

  const [defaultValues, setDefaultValues] = useState<FormValues>(tuberculosisInformation);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues
  });

  const { uppsertTubercolosisInformation, status: medicalInformationFormStatus } = useMedicalInformationStore();

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      resident_id: resident?.id,
      ...values
    };

    try {
      await uppsertTubercolosisInformation(payload);

      setDefaultValues({
        ...values
      });

      toast({
        description: 'Tuberculosis information saved successfully'
      });
    } catch (error: any) {
      toast({
        description: 'An error occurred while saving the tuberculosis information',
        variant: 'destructive'
      });
    }
  };

  return (
    <div className="">
      <CustomAccordion
        titleComponent={<h1 className="text-xl font-bold">Tuberculosis Information</h1>}
        openOption={true}
        defaultOpen={true}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="grid grid-cols-2 gap-4 mt-4">
            <FormField
              control={form.control}
              name="family_tuberculosis_history"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel className="text-muted-foreground">
                    Is there any history of tuberculosis in the applicant's family?
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(value) => field.onChange(value === 'yes')}
                      defaultValue={field.value ? 'yes' : 'no'}
                      value={field.value ? 'yes' : 'no'}
                      disabled={medicalInformationFormStatus === 'loading'}
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="no" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="recent_contact_with_tb"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel className="text-muted-foreground">
                    Has the resident been in recent contact with someone who has tuberculosis?
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(value) => field.onChange(value === 'yes')}
                      defaultValue={field.value ? 'yes' : 'no'}
                      value={field.value ? 'yes' : 'no'}
                      disabled={medicalInformationFormStatus === 'loading'}
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="no" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="test_date"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    Date of the tuberculosis test <span className="text-destructive">(*)</span>
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                          disabled={medicalInformationFormStatus === 'loading'}
                        >
                          {field.value ? format(field.value, 'MM/dd/yyyy') : <div>Select a date</div>}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <div className="rounded-md border">
                        <CustomCalendar
                          onChange={field.onChange}
                          selectedDate={field.value || undefined}
                          MonthAndYearPicker
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="test_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type of test</FormLabel>
                  <FormControl>
                    <Input
                      placeholder=""
                      {...field}
                      value={field.value || ''}
                      disabled={medicalInformationFormStatus === 'loading'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="test_result"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Test result</FormLabel>
                  <FormControl>
                    <Input
                      placeholder=""
                      {...field}
                      value={field.value || ''}
                      disabled={medicalInformationFormStatus === 'loading'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="action_taken"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Action taken (if positive)</FormLabel>
                  <FormControl>
                    <Input
                      placeholder=""
                      {...field}
                      value={field.value || ''}
                      disabled={medicalInformationFormStatus === 'loading'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
              <div className="col-span-2 space-y-4">
                <Separator />

                <div className="flex items-end gap-2 col-span-full xl:col-span-1">
                  <Button
                    className="flex gap-2"
                    variant={'default'}
                    type="submit"
                    disabled={medicalInformationFormStatus === 'loading'}
                  >
                    {medicalInformationFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                    Update
                  </Button>
                </div>
              </div>
            )}
          </form>
        </Form>
      </CustomAccordion>
    </div>
  );
};
