import { ITrackerCleaningArea } from "./TrackerCleaningMockup";
import { TrackerCleaningAreaCreateDialog } from "./TrackerCleaningAreaCreateDialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { getAreas } from "../../slices/TrackerCleaningSlice";
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import useCleaningAreaColumns from "./CleaningAreaColumns";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import UtilService from "@/common/services/UtilService";

export function TrackerCleaningArea() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const { locationSelectedId } = useLocation();
    const [columns] = useState(useCleaningAreaColumns());
    let [dataSource, setDataSource] = useState<ITrackerCleaningArea[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDataSource(
            UtilService.sortObjectsAlphabetically(trackerCleaning.areas, "name")
        );
    }, [trackerCleaning]);

    const fetchData = async () => {
        await dispatch(getAreas(
            {
                location_id: locationSelectedId
            }
        ));
    }

    return (
        <div className="grid grid-cols-1 items-end gap-2 w-100">
            <TableCustomVirtuoso
                data={dataSource}
                columns={columns}
                renderCellContent={(index, column, data) => data[index][column.key]}
                additionalButtons={<TrackerCleaningAreaCreateDialog />}
                showSearchInput={false}
            />
        </div>
    );
}
