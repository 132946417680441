import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AllergiesCard, DefaultValues } from '../components/AllergiesCard';
import { EmptyIcon } from '../../EmptyIcon';

export const AllergiesForm = () => {
  const { resident }: { resident: Resident | null; status: string } = useSelector(
    (state: RootState) => state.residents.resident
  );

  const initialAllergies = useMemo(
    () =>
      (resident?.medical_information?.allergies || [])
        .map(({ id, allergy, description, is_contagious, created_at }) => ({
          id,
          allergy,
          description,
          is_contagious,
          created_at
        }))
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    [resident]
  );

  const [allergies, setAllergies] = useState<DefaultValues[]>(initialAllergies);

  const {
    uppsertAllergyInformation,
    deleteAllergyInformation,
    status: medicalInformationFormStatus
  } = useMedicalInformationStore();

  const addAlergy = useCallback(() => {
    setAllergies((prev) => [{ id: null, allergy: '', description: '', is_contagious: 'no' }, ...prev]);
  }, []);

  const createOrUpdateAlergy = useCallback(
    async (values: any) => {
      const payload = { resident_id: resident?.id, ...values };

      try {
        const response = await uppsertAllergyInformation(payload);

        setAllergies((prev) =>
          prev.map((d) => (d.id === null ? { ...values, id: response.allergy, created_at: new Date() } : d))
        );

        toast({ description: 'Allergy has been updated successfully' });
      } catch (error) {
        toast({ description: 'There was an error updating the allergy', variant: 'destructive' });
      }
    },
    [uppsertAllergyInformation, resident?.id]
  );

  const removeAlergy = useCallback(
    async (id: string | null) => {
      try {
        if (id) {
          const payload = { resident_id: resident?.id, id };

          const response = await deleteAllergyInformation(payload);
          const newAllergies = allergies?.filter((allergy) => allergy.id !== id) || [];
          setAllergies(newAllergies);

          if (response) {
            toast({
              description: 'Allergy has been removed successfully'
            });
          } else {
            setAllergies(allergies?.filter((allergy) => allergy.id !== id) || null);
          }
        }
      } catch (error) {
        toast({
          description: 'There was an error removing the allergy',
          variant: 'destructive'
        });
      }
    },
    [deleteAllergyInformation, resident?.id]
  );

  return (
    <>
      <div>
        <CustomAccordion
          titleComponent={<h1 className="text-xl font-bold">Allergies</h1>}
          openOption={true}
          defaultOpen={true}
        >
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex items-center justify-between">
              <p>Click the "New Allergy" button to add a new allergy. You can add as many allergies as you need.</p>

              <Button
                className="flex gap-2"
                variant={'default'}
                onClick={addAlergy}
                disabled={allergies?.some((allergy) => !allergy.id) || medicalInformationFormStatus === 'loading'}
              >
                <CircleFadingPlus className="h-4 w-4 flex-1" />
                New Allergy
              </Button>
            </div>

            <Separator />

            {allergies.length === 0 && (
              <div className="w-full flex flex-col items-center py-12 gap-4">
                <EmptyIcon className="h-auto w-[8vw]" />
                <p>There are no allergies for this resident.</p>
              </div>
            )}

            {allergies?.map((allergy, index) => (
              <AllergiesCard
                defaultValues={allergy}
                onSubmit={createOrUpdateAlergy}
                onRemove={removeAlergy}
                disabled={medicalInformationFormStatus === 'loading'}
                key={allergy.id || index}
              />
            ))}

            <Separator />

            <p>
              Total Allergies: <span className="font-bold">{allergies.length}</span>
            </p>
          </div>
        </CustomAccordion>
      </div>
    </>
  );
};
