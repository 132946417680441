import { AppDispatch, RootState } from '@/store/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewPharmacyModule, fetchAllPharmacies, fetchPharmacyById, updatePharmacyById } from '../slices/PharmacySlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { PharmacyI, PharmacyInfoI } from '../../domain/pharmacy.domian';
import { NewPharmacyForm } from '../components/forms/NewPharmacyForm';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import Repeater from '../components/Repeater';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { create } from 'zustand';
import { useNavigate, useParams } from 'react-router-dom';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { C } from 'node_modules/@fullcalendar/core/internal-common';

enum OperationTypeEnum {
  CREATE = 'create',
  UPDATE = 'update'
}

type OperationI = 'create' | 'update';

export const EditPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pharmaciesState = useSelector((state: RootState) => state.pharmacy);
  const { user } = useSelector((state: RootState) => state.auth);
  const [pharmacyData, setPharmacy] = useState<PharmacyI | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [pharmacyForm, setPharmacyForm] = useState<PharmacyInfoI>({
    npi: '',
    ncpdpid: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: 'us',
    status: 'active',
    phone: '',
    email: '',
    username: '',
    clients: []
  });
  const { pharmacyId } = useParams();

  const action: OperationI = useMemo(() => {
    return pharmacyId && pharmacyId.length > 0 ? 'update' : 'create';
  }, [pharmacyId]);

  useEffect(() => {
    if (action === OperationTypeEnum.UPDATE) {
      getPharmaciesById();
      
    }
  }, [action, pharmacyId]);

  // useEffect(() => {
  //   console.log("pharmaciesData actualizado:", pharmacyForm);
  // }, [pharmacyForm]);

  const getPharmaciesById = useCallback(async () => {
    try {
      const response = await dispatch(fetchPharmacyById(pharmacyId as string));
      const pharmacy = response.payload;
      setPharmacy(pharmacy);
      setPharmacyForm({
        npi: pharmacy.npi,
        ncpdpid: pharmacy.ncpdpid,
        name: pharmacy.name,
        address: pharmacy.address,
        city: pharmacy.city,
        state: pharmacy.state,
        zip: pharmacy.zip,
        country: pharmacy.country,
        status: 'active',
        phone: pharmacy.phone,
        email: pharmacy.email,
        username: pharmacy.user?.username,
        clients: pharmacy.clients
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [dispatch, pharmacyId]);

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (action === OperationTypeEnum.UPDATE) {

        const response: any = await dispatch(updatePharmacyById({ pharmacyId, formData: values }));
        if (response.meta.requestStatus === 'fulfilled') {
          toast({
            title: 'Pharmacy update',
            className: 'bg-green-500 text-white'
          });
          navigate('/pharmacies', { replace: true });
          return;
          // dispatch(fetchAllPharmacies());
        }
        
        if (response.meta.requestStatus === 'rejected') {
          const errorMessage = response?.error?.message || 'An error occurred';
          toast({
            description: errorMessage,
            variant: 'destructive'
          });
        }
        
      }else
      {
        const response: any = await dispatch(createNewPharmacyModule(values));
        if (response.meta.requestStatus === 'fulfilled') {
          toast({
            title: 'Pharmacy created',
            className: 'bg-green-500 text-white'
          });
          navigate('/pharmacies', { replace: true });
          return;
          // dispatch(fetchAllPharmacies());
        }
        
        if (response.meta.requestStatus === 'rejected') {
          const errorMessage = response?.error?.message || 'An error occurred';
          toast({
            description: errorMessage,
            variant: 'destructive'
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="pt-2 flex flex-col gap-5">
      <BackNavigationBar onlyBack={true} />
      <CustomCard title="Pharmacy" description="">
        <div className="max-w-[80vw] min-w-[450px] w-auto h-auto">
          <div key={JSON.stringify(pharmacyForm)} >
            <NewPharmacyForm
              onSubmit={onSubmit}
              disabled={pharmaciesState.status === 'loading'}
              action={action}
              isLoading={isLoading}
              defaultValues={{
                npi: pharmacyForm.npi,
                ncpdpid: pharmacyForm.ncpdpid,
                name: pharmacyForm.name,
                address: pharmacyForm.address,
                city: pharmacyForm.city,
                state: pharmacyForm.state,
                zip: pharmacyForm.zip,
                country: pharmacyForm.country,
                status: 'active',
                phone: pharmacyForm.phone,
                email: pharmacyForm.email,
                username: pharmacyForm.username,
                repeaterItems:  pharmacyForm.clients
              }}
            />
          </div>
        </div>
      </CustomCard>
    </div>
  );
};
