import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { cn } from '@/lib/utils';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Loader2 } from 'lucide-react';
import { NoteI } from '../../../domain/note.domain';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/registry/new-york/ui/scroll-area';
import { setNoteId, setNoteFilters } from '../../slices/NoteSlice';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '@/common/presentation/components/ui/infinite-scroll';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { format } from 'date-fns';


interface NoteListProps {
  items: NoteI[] | [];
  setCrudState: (crudState: CrudState) => void;
  scroll?: boolean;
  loadingNotes: boolean;
}

export function NoteStaffList({ items, setCrudState, scroll = true, loadingNotes }: NoteListProps) {

  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  // Redux
  const dispatch = useDispatch();
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const metaNote = useSelector((state: RootState) => state.notes.staffNotes?.meta);

  // Hooks
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (metaNote && metaNote.page >= metaNote.lastPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [metaNote]);

  //Behaviors
  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  const fectchMoreNotes = async () => {
    setLoading(true);
    dispatch(setNoteFilters(['nextPage', Number(metaNote.page || 0) + 1]));
    setLoading(false);
  };

  if (loadingNotes) {
    return <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>
  }

  if (metaNote.page == 1 && !items.length) {
    return <div className='w-full flex justify-center'>{t("notes.notesNotFound")}</div>
  }

  if (!items) {
    return;
  }

  return (
    <ScrollArea className='h-[25vh] md:h-[calc(100vh-310px)] pr-3'>
      <div className="flex flex-col gap-3">
        {items?.map((item) => (
          <button
            key={item.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all',
              noteSelected === item.id && 'bg-primary/10'
            )}
            onClick={() => {
              setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
              setNote(item.id);
            }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">
                    {item.staffs && item.staffs.length > 0
                      ? item.staffs.map((item) => <div key={item.idStaff}>{item.first_name + ' ' + item.last_name}</div>)
                      : t('notes.everyone')}
                  </div>
                  {!item && <span className="flex h-2 w-2 rounded-full bg-primary" />}
                </div>
                <div
                  className={cn(
                    'ml-auto text-xs',
                    noteSelected === item.id ? 'text-foreground' : 'text-muted-foreground',
                    selectedNotes.includes(item.id) && 'hidden'
                  )}
                >
                  {formatDate(item.format_created_at, false)} - {format(new Date(item.format_created_at), 'hh:mm a')}
                </div>
              </div>
              <div className="text-xs font-medium">{`${t('notes.by') + ' '}${item.author}`}</div>
            </div>
            <div className="text-xs text-muted-foreground text-ellipsis line-clamp-3" title={item.content} dangerouslySetInnerHTML={{ __html: item.html_content }}></div>
            {item.created_by == authUserId && (
              <div className="ml-auto">
                <Badge variant={'default'}>{t('notes.author')}</Badge>
              </div>
            )}
          </button>
        ))}

        {scroll && (
          <InfiniteScroll hasMore={hasMore} isLoading={loading} next={fectchMoreNotes} threshold={0.5}>
            {hasMore && <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>}
          </InfiniteScroll>
        )}
      </div>
    </ScrollArea>
  );
}
