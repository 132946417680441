import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useEditDefaultForm } from '@/modules/residents/infrastructure/hooks/forms/useEditDefaultForm';
import { useMemo } from 'react';
import { DefaultFormValuesForm } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/Forms/DefaultFormValuesForm';

export const EditDefaultFormDialog = () => {
  const { isOpen, onClose, defaultFormValues, changeDefaultFormValues } = useEditDefaultForm();

  const defaultValues = useMemo(() => {
    return {
      time_frame: defaultFormValues?.time_frame,
      person_responsible_for_implementation: defaultFormValues?.person_responsible_for_implementation,
      method_of_evaluating_progress: defaultFormValues?.method_of_evaluating_progress
    };
  }, [defaultFormValues]);

  const onSubmit = async (values: any) => {
    changeDefaultFormValues(values);
    toast({
      description: 'Default form values updated successfully',
      variant: 'default'
    });
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-auto max-h-[80vh] h-auto overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">Edit Default Form</DialogTitle>
            <DialogDescription>
              This is a form to update the default form values for all time frames, person(s) responsible for
              implementation, and method of evaluating progress.
            </DialogDescription>
          </DialogHeader>
          <DefaultFormValuesForm defaultValues={defaultValues} onSubmit={onSubmit} />
        </DialogContent>
      </Dialog>
    </>
  );
};
