import { LucideIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  Icon: LucideIcon;
  labelKey: string;
  value: string;
}

const ItemInfo: React.FC<IProps> = ({ Icon, labelKey, value }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <Icon className="text-muted-foreground" size={16} />
        <span className="font-medium text-sm text-gray-600 dark:text-gray-300">{t(labelKey)}:</span>
      </div>
      <span className="text-sm text-gray-800 dark:text-white">{value}</span>
    </div>
  );
};

export default ItemInfo;
