import { Button } from '@/common/presentation/components/ui/button';
import { Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { deleteSummary, getSummaries } from '../../slices/TrackerCleaningSlice';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';

export function TrackerCleaningSummaryDeleteDialog(props: { id: string, fetchSummary: (page: number, resetData: boolean) => void }) {
    const dispatch = useDispatch();
    const [openDelete, setOpenDelete] = useState(false);
    const { locationSelectedId } = useLocation();
    const { t } = useTranslation();

    const openDeleteDialog = () => {
        setOpenDelete(true);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = () => {
        dispatch(deleteSummary(
            {
                id: props.id
            }
        ));

        fetchData();
        setOpenDelete(false);
        
        return true;
    }

    const fetchData = async () => {
        props.fetchSummary(1, true);
    }

    return (
        <div>
            <Button onClick={() => openDeleteDialog()} variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.delete.title")} 
                description={t("cleaning.report.summary.delete.description")} 
                width="30rem"
                newDialog={true}
                isOpen={openDelete}
                onClose={closeDeleteDialog}
            >
                <div className='flex justify-end'>
                    <Button type="submit" onClick={handleDelete}>
                        Delete
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};
