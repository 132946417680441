import React, { useEffect, useMemo, useCallback } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import useOverviewRoutineColumns from './OverviewRoutineColumns';
import useOverviewExceptionColumns from './OverviewExceptionColumns';
import useOverviewPrnColumns from './OverviewPrnColumns';
import { format, isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';

const OverviewTable = () => {
  const overviewRoutineColumns = useOverviewRoutineColumns();
  const overviewPrnColumns = useOverviewPrnColumns();
  const overviewExceptionColumns = useOverviewExceptionColumns();
  const { params, setParam } = useRouteParams();
  const { medications, getMedicationsTimesByResident } = useMedicationStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (!params.s) {
      setParam('s', 'managerRoutine');
    }
  }, [params.s, setParam]);

  const handleTabChange = useCallback(
    (tab) => {
      setParam('s', tab);
    },
    [setParam]
  );

  const validateStatus = (dispensed, witnessed) => {
    if (witnessed === 1) return 'witnessed';
    if (dispensed === 1) return 'dispensed';
    return 'none';
  };

  const formatDate = (value, formatType = 'dateFormat') => {
    try {
      if (!value) {
        return '';
      }
      const parsedDate = parseISO(value);
      if (!isValid(parsedDate)) {
        throw new Error('Fecha no válida');
      }
      return format(parsedDate, t(`dateFormats.${formatType}`));
    } catch (error) {
      console.error('Error formateando la fecha:', error);
      return value;
    }
  };

  const transformData = (medication, type) => {
    switch (type) {
      case 'managerRoutine':
        return medication.emar_routine_medications.map((routine) => ({
          ...routine,
          medication_name: `${
            medication?.medication?.name?.charAt(0).toUpperCase() + medication?.medication?.name?.slice(1) || ''
          } ${
            (medication?.strength || medication?.form || '').charAt(0).toUpperCase() +
            (medication?.strength || medication?.form || '').slice(1)
          }`.trim(),
          dispenser_name: medication.responsible_of_administration,
          dispensed_at: formatDate(routine?.dispensed_at),
          staff: routine.dispensed_by.first_name + ' ' + routine.dispensed_by.last_name,
          resident: medication?.resident?.first_name + ' ' + medication?.resident?.last_name,
          status:
            validateStatus(routine.dispensed, routine.witnessed)?.charAt(0).toUpperCase() +
            validateStatus(routine.dispensed, routine.witnessed)?.slice(1)
        }));
      case 'managerPRN':
        return medication.emar_medication_prns.map((prn) => ({
          ...prn,
          medication_name: `${
            medication?.medication?.name?.charAt(0).toUpperCase() + medication?.medication?.name?.slice(1) || ''
          } ${
            (medication?.strength || medication?.form || '').charAt(0).toUpperCase() +
            (medication?.strength || medication?.form || '').slice(1)
          }`.trim(),
          strength: medication?.strength ? medication?.strength : medication?.form ? medication?.form : '',
          dispenser_name: medication?.responsible_of_administration || '',
          date: formatDate(prn?.dispensed_at) || '',
          staff: prn?.dispensed_by ? prn.dispensed_by.first_name + ' ' + prn.dispensed_by.last_name : '',
          resident: medication?.resident ? medication.resident.first_name + ' ' + medication?.resident?.last_name : '',
          status:
            validateStatus(prn?.dispensed, prn?.witnessed)?.charAt(0).toUpperCase() +
            validateStatus(prn?.dispensed, prn?.witnessed)?.slice(1),
          instruction: medication.instruction,
          special_instructions: medication.special_instructions,
          frecuency: medication.frequency,
          not_exceed: medication.not_exceed
        }));
      case 'exception':
        return medication.emar_medication_exceptions.map((exception) => {
          return {
            medication_name: `${
              medication?.medication?.name?.charAt(0).toUpperCase() + medication?.medication?.name?.slice(1) || ''
            } ${
              (medication?.strength || medication?.form || '').charAt(0).toUpperCase() +
              (medication?.strength || medication?.form || '').slice(1)
            }`.trim(),
            exception_date: formatDate(exception?.date_to_be_dispensed),
            dispenser_name: medication.responsible_of_administration,
            resident: exception?.resident ? `${exception.resident.first_name} ${exception.resident.last_name}` : '',
            reason: exception?.reason?.name || '',
            details: exception?.details || '',
            time_to_be_dispensed: exception?.time_to_be_dispensed || '',
            staff: exception?.created_by ? exception.created_by.first_name + ' ' + exception.created_by.last_name : ''
          };
        });

      default:
        return [];
    }
  };

  const filteredMedications = useMemo(() => {
    const filterMedications = () => {
      return medications.flatMap((medication) => {
        let type = '';
        if (params.s === 'managerRoutine') {
          type = 'managerRoutine';
        } else if (params.s === 'managerPRN') {
          type = 'managerPRN';
        } else if (params.s === 'exception') {
          type = 'exception';
        } else {
          return [];
        }
        return transformData(medication, type);
      });
    };
    return filterMedications();
  }, [medications, params.s]);

  useEffect(() => {
    getMedicationsTimesByResident('all-locations', 'all-residents', format(new Date(), 'yyyy-MM-dd'));
  }, [getMedicationsTimesByResident]);

  const renderTabContent = () => {
    let columns = [];
    if (params.s === 'managerRoutine') {
      columns = overviewRoutineColumns;
    } else if (params.s === 'managerPRN') {
      columns = overviewPrnColumns;
    } else if (params.s === 'exception') {
      columns = overviewExceptionColumns;
    }

    return (
      <TableCustomVirtuoso
        data={filteredMedications}
        columns={columns}
        renderCellContent={(index, column, data) => {
          const value = data[index][column.key];
          if (typeof value === 'object' && value !== null) {
            return value.name || '';
          }
          return value;
        }}
        onSort={() => {}}
        loadMore={() => {}}
        hasMore={false}
        isLoading={false}
      />
    );
  };

  return (
    <div className="w-full">
      <Tabs defaultValue="managerRoutine" value={params.s} onValueChange={handleTabChange}>
        <TabsContent value="managerRoutine">{params.s === 'managerRoutine' ? renderTabContent() : null}</TabsContent>
        <TabsContent value="managerPRN">{params.s === 'managerPRN' && renderTabContent()}</TabsContent>
        <TabsContent value="exception">{params.s === 'exception' && renderTabContent()}</TabsContent>
      </Tabs>
    </div>
  );
};

export default OverviewTable;
