import * as React from 'react';
import { Check } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Button } from '@/common/presentation/components/ui/button';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Separator } from '@/common/presentation/components/ui/separator';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import { useTranslation } from 'react-i18next';

interface Option {
  name: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
}

interface FilterTableProps {
  typeOptions: Option[];
  statusOptions: Option[];
  filters: { type?: string; status?: string };
  setFilters: (filters: { type: string; status: string }) => void;
  fetchFilteredData: (filters: { type: string; status: string }) => void;
}

const FilterTable: React.FC<FilterTableProps> = ({
  typeOptions,
  statusOptions,
  filters = { type: '', status: '' },
  setFilters,
  fetchFilteredData
}) => {
  const { t } = useTranslation();

  const selectedTypeValues = new Set((filters.type ?? '').split(',').filter(Boolean));
  const selectedStatusValues = new Set((filters.status ?? '').split(',').filter(Boolean));

  const handleSelectAll = (type: 'type' | 'status') => {
    if (type === 'type') {
      setFilters({ ...filters, type: 'ALL' });
      fetchFilteredData({ ...filters, type: 'ALL' });
    } else {
      setFilters({ ...filters, status: 'ALL' });
      fetchFilteredData({ ...filters, status: 'ALL' });
    }
  };

  const handleOptionSelect = (type: 'type' | 'status', option: Option) => {
    const newFilters = { ...filters, [type]: option.value };
    setFilters(newFilters);
    fetchFilteredData(newFilters);
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center space-x-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm" className="h-8 border-dashed">
              {t('medications.filterTable.type')}
              {selectedTypeValues.size > 0 && (
                <>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                    {selectedTypeValues.size}
                  </Badge>
                  <div className="hidden space-x-1 lg:flex">
                    {typeOptions
                      .filter((option) => selectedTypeValues.has(option.value))
                      .map((option) => (
                        <Badge key={option.value} variant="secondary" className="rounded-sm px-1 font-normal">
                          {option.name}
                        </Badge>
                      ))}
                  </div>
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <Command>
              <CommandList>
                <CommandEmpty>{t('filterTable.noTypeOptions')}</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => handleSelectAll('type')}
                    className={filters.type === 'ALL' ? 'bg-primary text-primary-foreground' : ''}
                  >
                    {t('medications.filterTable.selectAll')}
                  </CommandItem>
                  {typeOptions.map((option) => {
                    const isSelected = filters.type === option.value; // Solo un valor puede estar seleccionado
                    return (
                      <CommandItem key={option.value} onSelect={() => handleOptionSelect('type', option)}>
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <Check className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                        <span>{option.name}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                {selectedTypeValues.size > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup>
                      <CommandItem
                        onSelect={() => {
                          setFilters({ ...filters, type: '' });
                          fetchFilteredData({ ...filters, type: '' });
                        }}
                        className="justify-center text-center"
                      >
                        {t('medications.filterTable.clearTypeFilters')}
                      </CommandItem>
                    </CommandGroup>
                  </>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <div className="flex items-center space-x-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm" className="h-8 border-dashed">
              {t('medications.filterTable.status')}
              {selectedStatusValues.size > 0 && (
                <>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                    {selectedStatusValues.size}
                  </Badge>
                  <div className="hidden space-x-1 lg:flex">
                    {statusOptions
                      .filter((option) => selectedStatusValues.has(option.value))
                      .map((option) => (
                        <Badge key={option.value} variant="secondary" className="rounded-sm px-1 font-normal">
                          {option.name}
                        </Badge>
                      ))}
                  </div>
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <Command>
              <CommandList>
                <CommandEmpty>{t('filterTable.noStatusOptions')}</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => handleSelectAll('status')}
                    className={filters.status === 'ALL' ? 'bg-primary text-primary-foreground' : ''}
                  >
                    {t('medications.filterTable.selectAll')}
                  </CommandItem>
                  {statusOptions.map((option) => {
                    const isSelected = filters.status === option.value;
                    return (
                      <CommandItem key={option.value} onSelect={() => handleOptionSelect('status', option)}>
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <Check className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                        <span>{option.name}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                {selectedStatusValues.size > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup>
                      <CommandItem
                        onSelect={() => {
                          setFilters({ ...filters, status: '' });
                          fetchFilteredData({ ...filters, status: '' });
                        }}
                        className="justify-center text-center"
                      >
                        {t('medications.filterTable.clearStatusFilters')}
                      </CommandItem>
                    </CommandGroup>
                  </>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default FilterTable;
