import { QuickMenu } from "../../domain/models/QuickMenu";
import { BedroomQr } from "../../presentation/components/BedroomQr/BedroomQr";

export const generateMenuItems = (context: QuickMenu) => [
  {
    label: context.t('general.documents'),
    onClick: () => {
      context.onOpen();
      context.setTabSelected('documents');
    },
    variant: 'default',
    content: null,
  },
  {
    label: context.t('general.forms'),
    onClick: () => context.navigate(`/residents/forms?r=${context.residentId}`),
    variant: 'default',
    content: null,
  },
  {
    label: context.t('trackers.trackerBedroom.bedroomQr'),
    onClick: () => {
      context.setTabSelected('bedroom_qr')
      context.handleOpenBedroom()
    },
    variant: 'default',
    content:
      context.tabSelected === 'bedroom_qr' ? (
        <BedroomQr isOpen={context.isOpenBedroom} onClose={context.handleCloseBedroom} />
      ) : null,
  },
  {
    label: context.t('residents.alerts'),
    onClick: () => { context.setTabSelected('alerts') },
    variant: 'default',
    content: null,
  },
];
