import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';

import { Button } from '@/common/presentation/components/ui/button';
import { useDocumentsStore } from '@/modules/residents/domain/stores/documents/documentsStore';
import { useNewCategory } from '@/modules/residents/infrastructure/hooks/documents/useNewCategory';
import { CircleFadingPlus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Dialogs } from './Dialogs';

import { useDocumentsDialog } from '@/modules/residents/infrastructure/hooks/documents/useDocumentsDialog';
import { DocumentCategory, DocumentResourcesParams } from '@/modules/residents/infrastructure/types/documents.ts';
import { CategoryCard } from '../CategoryCard';
import useRouteParams from '@/common/hooks/RouteParamsHook';

const defaultResourceParamValues: DocumentResourcesParams = {
  country: 'united_states',
  state: 'california',
  city: null,
  module: 'residents'
};

export const ResidentDocumentsDialog = () => {
  const { isOpen, onClose } = useDocumentsDialog();
  const { onOpen } = useNewCategory();
  const { customCategories, defaultCategories, getCategories, getResource, status } = useDocumentsStore();
  const { params } = useRouteParams();

  const [categories, setCategories] = useState<DocumentCategory[]>([]);

  useEffect(() => {
    const fetchResource = async () => {
      await getResource({
        ...defaultResourceParamValues,
        resident_id: params.r
      });
      await getCategories();
    };

    if (isOpen) {
      fetchResource();
    }
  }, [isOpen]);

  useEffect(() => {
    setCategories([...(defaultCategories || []), ...(customCategories || [])]);
  }, [defaultCategories, customCategories]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[90vw] max-h-[90vh] h-auto overflow-y-auto">
          <DialogHeader className="flex justify-between">
            <div className="flex justify-between">
              <div className="col-span-2">
                <DialogTitle className="text-xl text-primary">Documents</DialogTitle>
                <DialogDescription>
                  Manage your resident's documentation. Upload, delete and create versions of documents here.
                </DialogDescription>
              </div>
              <div className="p-2">
                <Button className="flex w-full gap-2 bg-primary" onClick={() => onOpen()}>
                  <CircleFadingPlus className="size-4 mr-2" />
                  Add New Category
                </Button>
              </div>
            </div>
          </DialogHeader>

          {status === 'loading' && <div>Loading...</div>}

          {categories.length > 0 && (
            <div className="flex flex-col gap-4">
              {categories
                .filter(category => category.status === 'active' || !category.hasOwnProperty('status'))
                .map((category) => (
                  <CategoryCard key={category.value} category={category} />
                ))}
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialogs />
    </>
  );
};
