import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { useTranslation } from 'react-i18next';

interface Bedroom {
    id: string;
    name: string;
}


interface ISelectFieldProps {
    dataBedroom: Bedroom[];
    bedroomId: string;
    handleFieldChange: (id: string) => void;
}



export function SelectFieldBedroomQr({ bedroomId, handleFieldChange, dataBedroom }: ISelectFieldProps) {
    const { t } = useTranslation();
    const bedrooms = dataBedroom.find((bedroom) => bedroom.id === bedroomId)
    return (

        <div className="w-1/2 flex items-center justify-between">
            <Select value={bedroomId} onValueChange={handleFieldChange}>
                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                    {bedrooms ? (<SelectValue>{bedrooms.name}</SelectValue>) :
                        (bedroomId == 'all' ? 'All' : t('trackers.Field'))}
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    {dataBedroom.map((bedroom) => (
                        <SelectItem key={bedroom.id} value={bedroom.id}>
                            {bedroom.name}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}