import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SuggestionSheet } from '../SuggestionSheet';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';

const formSchema = z.object({
  time_frame: z.string().optional(),
  person_responsible_for_implementation: z.string().optional(),
  method_of_evaluating_progress: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
  defaultValues: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
}

export const DefaultFormValuesForm = ({ defaultValues, onSubmit }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const { formResource: formResourceFromStore } = useFormsStore();

  const addCompletitionToTextarea = (value: string, tree: any) => {
    const currentValue = form.getValues(tree) || '';
    const newValue = `${currentValue}\n -${value}`;
    form.setValue(tree, newValue);
  };

  const removeCompletionFromTextarea = (value: string, tree: any) => {
    const currentValue = form.getValues(tree) || '';
    const updatedValue = currentValue
      .split('\n')
      .filter((line: string) => line.trim() !== `-${value}`)
      .join('\n');
    form.setValue(tree, updatedValue);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
            <FormField
              control={form.control}
              name="time_frame"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Time Frame</FormLabel>
                  <FormControl>
                    <div className="relative cursor-pointer">
                      <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                        <SuggestionSheet
                          label={'Time Frame'}
                          defaultValue={formResourceFromStore.other_information?.default?.time_frame}
                          addCompletitionToTextarea={(value: string) => addCompletitionToTextarea(value, 'time_frame')}
                          removeCompletionFromTextarea={(value: string) =>
                            removeCompletionFromTextarea(value, 'time_frame')
                          }
                          currentValue={field.value as string}
                        />
                      </div>
                      <Textarea className="h-[150px] border-none focus-visible:ring-0 pb-14" {...field} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
            <FormField
              control={form.control}
              name="person_responsible_for_implementation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Person Responsible For Implementation</FormLabel>
                  <FormControl>
                    <div className="relative cursor-pointer">
                      <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                        <SuggestionSheet
                          label={'Person Responsible For Implementation'}
                          defaultValue={
                            formResourceFromStore.other_information?.default?.person_responsible_for_implementation
                          }
                          addCompletitionToTextarea={(value: string) =>
                            addCompletitionToTextarea(value, 'person_responsible_for_implementation')
                          }
                          removeCompletionFromTextarea={(value: string) =>
                            removeCompletionFromTextarea(value, 'person_responsible_for_implementation')
                          }
                          currentValue={field.value as string}
                        />
                      </div>
                      <Textarea
                        className="h-[150px] border-none focus-visible:ring-0 pb-14"
                        {...field}
                        // placeholder="Enter person responsible for implementation"
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
            <FormField
              control={form.control}
              name="method_of_evaluating_progress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Method Of Evaluating Progress</FormLabel>
                  <FormControl>
                    <div className="relative cursor-pointer">
                      <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                        <SuggestionSheet
                          label={'Method Of Evaluating Progress'}
                          defaultValue={formResourceFromStore.other_information?.default?.method_of_evaluating_progress}
                          addCompletitionToTextarea={(value: string) =>
                            addCompletitionToTextarea(value, 'method_of_evaluating_progress')
                          }
                          removeCompletionFromTextarea={(value: string) =>
                            removeCompletionFromTextarea(value, 'method_of_evaluating_progress')
                          }
                          currentValue={field.value as string}
                        />
                      </div>
                      <Textarea
                        className="h-[150px] border-none focus-visible:ring-0 pb-14"
                        {...field}
                        // placeholder="Enter method of evaluating progress"
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Button type="submit" className="col-span-full" disabled={false}>
            {false && <Loader className="size-4 mr-2 animate-spin" />}
            Save
          </Button>
        </form>
      </Form>
    </>
  );
};
