import {
  getFields,
  sheetFieldsLoading,
  updateEnabledFields
} from "@/modules/settings/presentation/components/menus/Residents/SheetField/slices/SheetFieldSlice";

import { Checkbox } from "@/components/ui/checkbox";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { CustomAccordion } from "@/common/presentation/components/CustomAccordion/CustomAccordion";
import { ISheetField } from '@/modules/settings/presentation/components/menus/Residents/SheetField/slices/SheetFieldSlice'
import { t } from "i18next";

type TRelatedTo = "faceSheet" | "emergencySheet";

interface ISwitchState {
  faceSheet: { [key: string]: boolean };
  emergencySheet: { [key: string]: boolean };
}

interface IStateFields {
  id: string;
  status: boolean
}

interface IFaceEmergencySheets {
  id: string;
  name: string;
}

export const EnabledFields = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [switchStates, setSwitchStatus] = useState<ISwitchState>({ faceSheet: { val: false }, emergencySheet: { val: false } });
  const sheetFields: ISheetField = useSelector((state: RootState) => state.settings.sheetFields?.sheetFields);
  const { accent_color } = useSelector((state: RootState) => state.configurations.configurations);


  const handleSwitch = (obj: IFaceEmergencySheets, relatedTo: TRelatedTo) => {
    setSwitchStatus((prevStates: ISwitchState) => {
      const prevRelatedStates = prevStates[relatedTo] || {};

      return {
        ...prevStates,
        [relatedTo]: {
          ...prevRelatedStates,
          [obj.id]: !prevRelatedStates[obj.id]
        }
      };
    });

    handleUpdateEnabledFields({
      ...switchStates,
      [relatedTo]: {
        ...switchStates[relatedTo] ?? [],
        [obj.id]: switchStates[relatedTo] && !switchStates[relatedTo][obj.id]
      }
    });
  }

  const updateStatusFields = (relatedTo: TRelatedTo, newStates: { [key: string]: boolean }) => {

    setSwitchStatus((prevStates: ISwitchState) => {
      const prevRelatedStates = prevStates[relatedTo] || {};
      return {
        ...prevStates,
        [relatedTo]: {
          ...prevRelatedStates,
          ...newStates
        }
      }
    });

    handleUpdateEnabledFields({
      ...switchStates,
      [relatedTo]: {
        ...newStates,
      }
    });

  }

  const handleSelectAll = (category: string, relatedTo: TRelatedTo) => {

    const categoryFields = sheetFields?.data[relatedTo][category] || [];
    const newStates = { ...switchStates[relatedTo] };

    const allChecked = categoryFields.every((field: IStateFields) => newStates[field.id]);

    categoryFields.forEach((field: IStateFields) => {
      newStates[field.id] = !allChecked;
    });
    
    updateStatusFields(relatedTo, newStates);
  }

  const handleClearAll = (relatedTo: TRelatedTo, category: string) => {
    const categoryFields = sheetFields?.data[relatedTo][category] || [];

    let newStates: { [key: string]: boolean } = {};
    categoryFields.forEach((field: IStateFields) => {
      newStates[field.id] = false;
    });

    updateStatusFields(relatedTo, newStates);

  }

  const handleUpdateEnabledFields = (newStates: ISwitchState) => {
    dispatch(sheetFieldsLoading());
    dispatch(updateEnabledFields(newStates));
  }

  useEffect(() => {
    dispatch(sheetFieldsLoading());
    dispatch(getFields());
  }, [dispatch]);

  useEffect(() => {
    if (sheetFields?.data?.faceSheet && sheetFields?.data?.emergencySheet) {
      const faceSheetFields = Object?.entries(sheetFields?.data?.faceSheet)?.flatMap(([_, fSheet]) => fSheet);
      const emergencySheetFields = Object?.entries(sheetFields?.data?.emergencySheet)?.flatMap(([_, eSheet]) => eSheet);

      faceSheetFields.forEach((val: IStateFields) => {
        setSwitchStatus((prev: ISwitchState) => ({
          ...prev,
          faceSheet: {
            ...prev?.faceSheet,
            [val.id]: (val.status) ? true : false
          }
        }));
      });

      emergencySheetFields.forEach((val: IStateFields) => {
        setSwitchStatus((prev: ISwitchState) => ({
          ...prev,
          emergencySheet: {
            ...prev?.emergencySheet,
            [val.id]: (val.status) ? true : false
          }
        }));
      })
    }
  }, [sheetFields]);

  return (
    <>

      <Tabs defaultValue="faceSheet">
        <TabsList className="bg-white border-b-2 p-0 rounded-none w-full place-content-start justify-start">
          <TabsTrigger
            value="faceSheet"
            className="rounded-none px-4 h-full data-[state=active]:bg-gray data-[state=active]:text-primary data-[state=active]:border-b-2 text-[14px] data-[state=active]:border-primary"
          >
            {t('settings.residents.faceSheets')}
          </TabsTrigger>
          <TabsTrigger
            value="emergencySheet"
            className="rounded-none px-4 h-full data-[state=active]:bg-gray data-[state=active]:text-primary data-[state=active]:border-b-2 text-[14px] data-[state=active]:border-primary"
          >
            {t('settings.residents.emergencySheets')}
          </TabsTrigger>
        </TabsList>

        <TabsContent value="faceSheet">
          {Object.entries(sheetFields?.data?.faceSheet).map(([index, fSheet]) => (
            <CustomAccordion
              key={index}
              title={index}
              className="mb-1 pt-4 pb-4 pl-6 pr-6 rounded-md border border-[#E4E4E7]"
              titleClassName="text-[14px] text-gray-600"
              children={
                <div className={`space-y-4 border-t-primary`} style={{ borderTop: '1px solid' + accent_color }}>
                  <div className="flex justify-end gap-4 items-center pb-1 pt-4">
                    <div className="flex items-center space-x-2 ">
                      <Checkbox
                        id={`SelectAll-${index}`}
                        checked={fSheet?.every((fs: IFaceEmergencySheets) => switchStates?.faceSheet && switchStates?.faceSheet[fs.id])}
                        onCheckedChange={() => handleSelectAll(index, "faceSheet")}
                        className="border-gray-300 rounded-sm data-[state=checked]:bg-primary data-[state=checked]:border-purple-500"
                      />
                      <label htmlFor={`SelectAll-${index}`} className="text-[12px] text-gray-500">
                        {t('common.selectAll')}
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id={`ClearAll-${index}`}
                        checked={fSheet?.every((fs: IFaceEmergencySheets) => switchStates?.faceSheet && switchStates?.faceSheet[fs.id] == false)}
                        onCheckedChange={() => handleClearAll("faceSheet", index)}
                        className="border-gray-300 rounded-sm data-[state=checked]:bg-primary data-[state=checked]:border-purple-500"
                      />
                      <label htmlFor={`ClearAll-${index}`} className="text-[12px] text-gray-500">
                        {t('common.clearAll')}
                      </label>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {fSheet?.map((fs: { id: string, name: string }) => (
                      <div
                        key={fs.id}
                        className={`flex items-center space-x-2 p-2 rounded ${switchStates?.faceSheet && switchStates?.faceSheet[fs.id]
                            ? `bg-[${accent_color}] bg-opacity-10`
                            : ''
                          } hover:bg-gray-100`}
                      >
                        <Checkbox
                          id={fs.id}
                          checked={(switchStates?.faceSheet && switchStates?.faceSheet[fs.id]) || false}
                          onCheckedChange={() => handleSwitch(fs, "faceSheet")}
                          className="border-gray-300 rounded-sm data-[state=checked]:bg-primary data-[state=checked]:border-primary"
                        />
                        <label htmlFor={fs.id} className="text-sm">
                          {fs?.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              }
            />
          ))}
        </TabsContent>

        <TabsContent value="emergencySheet">
          {Object.entries(sheetFields?.data?.emergencySheet).map(([index, fSheet]) => (
            <CustomAccordion
              key={index}
              title={index}
              className="mb-1 pt-4 pb-4 pl-6 pr-6 rounded-md border border-[#E4E4E7]"
              titleClassName="text-[14px] text-gray-600"
              children={
                <div className={`space-y-4 border-t-primary`} style={{ borderTop: '1px solid' + accent_color }}>
                  <div className="flex justify-end gap-4 items-center pb-1 pt-4">
                    <div className="flex items-center space-x-2 ">
                      <Checkbox
                        id={`SelectAll-${index}`}
                        checked={fSheet?.every((fs: IFaceEmergencySheets) => switchStates?.emergencySheet[fs.id])}
                        onCheckedChange={() => handleSelectAll(index, "emergencySheet")}
                        className="border-gray-300 rounded-sm data-[state=checked]:bg-primary data-[state=checked]:border-purple-500"
                      />
                      <label htmlFor={`SelectAll-${index}`} className="text-[12px] text-gray-500">
                        {t('common.selectAll')}
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id={`SelectAll-${index}`}
                        checked={fSheet?.every((fs: IFaceEmergencySheets) => switchStates?.emergencySheet[fs.id])}
                        onCheckedChange={() => handleClearAll("emergencySheet", index)}
                        className="border-gray-300 rounded-sm data-[state=checked]:bg-primary data-[state=checked]:border-purple-500"
                      />
                      <label htmlFor={`SelectAll-${index}`} className="text-[12px] text-gray-500">
                        {t('common.clearAll')}
                      </label>
                    </div>

                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {fSheet?.map((fs: IFaceEmergencySheets) => (
                      <div
                        key={fs.id}
                        className={`flex items-center space-x-2 p-2 rounded ${switchStates?.emergencySheet[fs.id]
                            ? `bg-[${accent_color}] bg-opacity-10`
                            : ''
                          } hover:bg-gray-100`}
                      >
                        <Checkbox
                          id={fs.id}
                          checked={switchStates?.emergencySheet[fs.id] || false}
                          onCheckedChange={() => handleSwitch(fs, "emergencySheet")}
                          className="border-gray-300 rounded-sm data-[state=checked]:bg-primary data-[state=checked]:border-purple-500"
                        />
                        <label htmlFor={fs.id} className="text-sm">{fs?.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              }
            />
          ))}
        </TabsContent>
      </Tabs>
    </>
  );
}