const UtilService = {
    sortObjectsAlphabetically: (objectsArray: any[], field: string) => {
        const arrayForSort = [...objectsArray];

        return arrayForSort.sort(
            (first, second) => {
                return first[field].localeCompare(second[field]);
            }
        );
    },
    decodeHtmlEntities(html: string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
};

export default UtilService;
