import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { CircleFadingPlus } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getDayHygiene, getTrackerHygieneType, saveTrackerHygieneType } from "../../slices/TrackerHygieneSlice";
import OptionsManager from "@/common/presentation/components/OptionsManager/OptionsManager";

const TrackerHygieneTypeCreateDialog = () => {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [options, setOptions] = useState<string[]>(["Done", "Refused"]);
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        name: ""
    });

    const fetchDayTrackerHygiene = () => {
        dispatch(
            getDayHygiene(
                {
                    location_id: locationSelectedId,
                }
            )
        );
    }

    const getTypes = () => {
        dispatch(
            getTrackerHygieneType(
                {
                    location_id: locationSelectedId
                }
            )
        );
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const setTypeOptions = (options: string[]) => {
        setOptions(options);
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(values.name) {
            dispatch(
                saveTrackerHygieneType(
                    {
                        ...values,
                        options
                    }
                )
            );
    
            fetchData();
            cleanInputs();
            closeEditDialog();

            toast(
                {
                    description: t("trackers.trackerhygiene.successTypeCreated")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.task.create.toast.required")
                }
            );
        }
    }

    const fetchData = async () => {
        getTypes();
        fetchDayTrackerHygiene();
    }

    const cleanInputs = () => {
        setOptions(
            [
                "Done",
                "Refused"
            ]
        );

        setValues({
            name: ""
        });
    };

    return (
        <div>
            <Button variant="outline" size="sm" className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end' onClick={openEditDialog}>
                <CircleFadingPlus className="h-4 w-4 mr-1" />
                <span className='mr-1'>{t("cleaning.create.area.newButton")}</span>
            </Button>

            <CustomDialog 
                title={"Create"}
                width="34rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
                description={t("trackers.trackerhygiene.editTypeDialogDescription")}
            >
                <div>
                    <Label htmlFor="name" className="text-right">
                        {t("cleaning.create.task.create.form.name")}
                    </Label>
                    
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        className="col-span-3 focus-visible:ring-0 focus-visible:ring-offset-0"
                        value={values.name}
                        onChange={handleInputChange}
                    />
                </div>

                <OptionsManager setTypeOptions={setTypeOptions} options={options} />

                <div className='flex justify-end mt-5' onClick={handleSubmit}>
                    <Button type="submit">
                        {t("cleaning.create.task.create.form.submit")}
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};

export default TrackerHygieneTypeCreateDialog;
