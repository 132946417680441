import { useCallback, useEffect, useRef } from 'react';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { AdministrationAlerts } from './components/administration/administration-alerts';
import { CalendarCard } from './components/calendar';
import { MedicationAlerts } from './components/medication-alerts';
import { NotesCard } from './components/notes/notes-card';
import { ResidentAlerts } from './components/residents/resident-alerts';
import { StaffAlerts } from './components/staff-alerts';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch } from 'react-redux';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { ForyouAlerts } from './components/foryou-alerts';
import { AppDispatch } from '@/store/store';
import { fetchDashboardAlerts, fetchNotGivenMedicationAlerts } from '../slices/DashboardSlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import MedicationAlertsModal from './components/medicationAlertModal/MedicationAlertModal';
import CurrentTime from './components/current-time';
import SystemHealth from './components/system-health/system-health';
import { useIntersectionObserver } from '../../infraestructure/hooks/insersectionObserver';

function DashboardPage() {
  const { locationSelectedId } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const abortControllerRef = useRef<AbortController | null>(null);
  const { params } = useRouteParams();
  const { isIntersecting, elementRef } = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    dispatch(
      getStaffByLocation(!locationSelectedId || locationSelectedId == 'all-locations' ? undefined : locationSelectedId)
    );
    getAlerts(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [locationSelectedId, dispatch]);

  const getAlerts = useCallback(
    async (signal: AbortSignal) => {
      await dispatch(fetchDashboardAlerts({ location: locationSelectedId, signal }));
      await getNoDispensedMedicationAlerts(signal);
    },
    [locationSelectedId, dispatch]
  );

  const getNoDispensedMedicationAlerts = useCallback(
    async (signal: AbortSignal) => {
      await dispatch(fetchNotGivenMedicationAlerts({ locationId: locationSelectedId, signal: signal }));
    },
    [locationSelectedId, dispatch]
  );

  return (
    <div className="flex min-h-screen w-full flex-col">
      <MedicationAlertsModal />
      <div className="flex flex-col gap-4 py-4">
        <main className="flex flex-col gap-4">
          <LocationSelector quickView={true} />

          <div className="mt-4">
            <div className="grid gap-6 rounded-lg border p-4">
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
                <ResidentAlerts />
                <AdministrationAlerts />
                <MedicationAlerts />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <StaffAlerts location={params.l} />
                <ForyouAlerts location={params.l} />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
                <CalendarCard location={params.l} />
                <NotesCard />
              </div>

              <div ref={elementRef} className="grid grid-cols-1 lg:grid-cols-1 gap-4">
                {isIntersecting && <SystemHealth />}
              </div>
              <div>
                <CurrentTime />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default DashboardPage;
