import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocationDocumentsCategoryService from '../infrastructure/services/LocationDocumentsCategoryService';
import { SaveCategoryPayload } from '../../../Staff/DocumentCategories/infrastructure/interfaces/DocumentsInterface';

interface CategoryI {
    category_id: string;
    label: string;
    order: number;
    status: 'active' | 'inactive';
}

export const getCategories = createAsyncThunk("locations/documents/categories",
    async (params: { search: string; per_page: number; page: number }) => {
        const response = await LocationDocumentsCategoryService.getCategory(
            params.search,
            params.per_page,
            params.page);
        return response;
    });

export const getUnpaginatedCategories = createAsyncThunk("locations/documents/get-categories",
    async () => {
        const response = await LocationDocumentsCategoryService.getCategories();
        return response;
    });

export const saveCategories = createAsyncThunk(
    "locations/documents/save-category",
    async (body: SaveCategoryPayload, { rejectWithValue }) => {
        try {
            const response = await LocationDocumentsCategoryService.saveCategories(body);

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateCategories = createAsyncThunk("locations/documents/update-category",
    async (body: SaveCategoryPayload, { rejectWithValue }) => {
        try {
            const response = await LocationDocumentsCategoryService.updateCategories(body);
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteCategory = createAsyncThunk('locations/documents/delete-category',
    async (categoryId: string) => {
        const response = await LocationDocumentsCategoryService.deleteCategory(categoryId);
        return response;
    }
);

interface DocumentsCategoryState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    documentCategories: CategoryI[];
    saveCategories?: CategoryI
}

const initialState: DocumentsCategoryState = {
    status: 'idle',
    error: null,
    documentCategories: []
};

const LocationDocumentsCategory = createSlice({
    name: 'documentsCategoryStaff',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentCategories = action.payload;
        });

        builder.addCase(getCategories.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(getUnpaginatedCategories.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getUnpaginatedCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentCategories = action.payload;
        });

        builder.addCase(getUnpaginatedCategories.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });


        builder.addCase(saveCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveCategories = action.payload;
        });

        builder.addCase(saveCategories.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(updateCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentCategories = action.payload;
        });

        builder.addCase(updateCategories.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(deleteCategory.fulfilled, (state) => {
            state.status = 'idle';
        });

    }
});

export default LocationDocumentsCategory.reducer;