import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
//import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
//import { useSelector } from 'react-redux';
import { Label } from '@/common/presentation/components/ui/label';
import { z } from 'zod';
import { cn } from '@/lib/utils';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import Repeater from '../Repeater';
import CountrySelector from '@/modules/wizard/presentation/components/CountrySelector/CountrySelector';
import { useDispatch } from 'react-redux';
import { fetchUserNameExist } from '@/modules/wizard/presentation/slices/SignUpSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  disabled?: boolean;
  action: string;
  isLoading?: boolean;
}

const formSchema = z.object({
  npi: z.string({
    required_error: 'Npi is required.'
  }),
  ncpdpid: z.string({
    required_error: 'Ncpdpid is required.'
  }),
  name: z.string({
    required_error: 'Name is required.'
  }),
  phone: z.string().optional(),
  address: z.string({
    required_error: 'Phone is required.'
  }),
  city: z.string({
    required_error: 'City is required.'
  }),
  state: z.string({
    required_error: 'State is required.'
  }),
  zip: z.string({
    required_error: 'Zip is required.'
  }),
  country: z.string({
    required_error: 'Country is required.'
  }),
  repeaterItems: z.array(
    z.object({
      client: z.string(),
      locations: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      )
    })
  ),
  email: z.string().email().optional(),
  status: z.enum(['active', 'inactive']),
  username: z.string(),
  password:  z .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        if (value.length < 8 || value.length > 30) return false;
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,30}$/.test(value);
      },
      {
        message:
          'Password must be at least 8 characters, no more than 30 characters, and contain at least one uppercase letter, one lowercase letter, and one number.'
      }
    ),
  confirmpassword: z.string().optional()
})
.refine(
  (data) => {
    if (!data.password) return true;
    return data.password === data.confirmpassword;
  },
  {
    message: "Passwords don't match",
    path: ['confirmpassword']
  }
);

export type FormValues = z.infer<typeof formSchema>;

export const NewPharmacyForm = ({ defaultValues, onSubmit, disabled, action ,isLoading = false }: Props) => {
//  const { user } = useSelector((state: RootState) => state.auth);
  const [isUserNameExist, setIsUserNameExist] = useState(false);
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { t } = useTranslation();
  const { reset, setError, clearErrors } = useForm({ defaultValues });
  
  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const userNameExists = async (userName: string) => {
    setIsUserNameExist(false);
    if (userName.trim().length === 0) {
      return;
    }
    const response = await dispatch<any>(fetchUserNameExist(userName));
    if (!response.error) {
      const { exists } = response.payload;
      setIsUserNameExist(exists);
      return exists;
    }
    return false;
  };

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false
  });

  // Configura `useFieldArray` para el campo `repeaterItems`
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'repeaterItems' // Asegúrate de que coincida con el campo en el esquema de Zod
  });

  /*
  const canEdit = useMemo(() => {
    return user?.roles.some((role: any) => role.name === 'Super Administrator');
  }, [user]);
*/

// const handleUsernameBlur = async () => {
//   const username = form.getValues('username');
//   const exists = await userNameExists(username);

//   if (exists) {
//     setError('username', {
//       type: 'manual',
//       message: 'Username already exists',
//     });
//   } else {
//     clearErrors('username');
//   }
// };


  const handleSubmit = async (values: FormValues) => {
    if (action === 'create') {
      if (await userNameExists(values.username)) {
        setError('username', {
          type: 'manual',
          message: 'Username already exists',
        });
        return;
      }
    }


    onSubmit(values);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4 px-1 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm ">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2">
              <FormField
                control={form.control}
                name="npi"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Npi<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ncpdpid"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Ncpdpid<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Name<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Phone<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Email<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className=" bg-primary" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2">
            <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Address<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      City<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      State<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="zip"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Zip<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Country<span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <CountrySelector
                        defaultCountry={'US'}
                        country={field.value}
                        name="country"
                        onChange={(e) => {
                          field.onChange(e.value.toLowerCase());
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

            </div>
            <Separator className=" bg-primary" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2">
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Username <span className="text-primary"> *</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={isLoading}
                          placeholder=""
                          className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                      {isUserNameExist && (
                        <Label className="text-sm font-medium text-destructive">
                          Username already exist
                        </Label>
                      )}
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Password <span className="text-primary"> *</span>
                      </FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input
                            {...field}
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="********"
                            autoCorrect="off"
                            autoComplete="off"
                            disabled={isLoading}
                            className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          />
                          <Eye
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(true)}
                          />
                          <EyeOff
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              !passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(false)}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="confirmpassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Confirm password <span className="text-primary"> *</span>
                      </FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input
                            {...field}
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="********"
                            autoCorrect="off"
                            disabled={isLoading}
                            autoComplete="off"
                            className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          />
                          <Eye
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(true)}
                          />
                          <EyeOff
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              !passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(false)}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Repeater fields={fields} append={append} remove={remove} form={form} />
            <div className="mt-6 flex justify-end space-x-2">
              <Button type="submit" disabled={isLoading}>
                {isLoading && <Loader2 className="size-4 animate-spin mr-2" />}
                Save Pharmacy
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
};
