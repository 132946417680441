import { useTranslation } from 'react-i18next';
import { SquareActivity } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';

interface DiagnosisCardProps {
  className?: string;
}

const rows = [
  { label: "Primary Diagnosis:", value: "Dementia" },
  { label: "Secondary Diagnosis:", value: "Pollen" },
  { label: "Allergies:", value: "Nonambulatory" },
  { label: "Ambulatory:", value: "Mild cognitive" },
  { label: "Mental Status:", value: "Mild cognitive" },
];

export const DiagnosisCard = ({ className }: DiagnosisCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('general.diagnosis')}
        headerIconLeft={SquareActivity}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[166px]">
          <div className="space-y-2">
            {rows.map((row, index) => (
              <div
                className={`grid grid-cols-2 items-center gap-4 rounded-md bg-zinc-100 dark:bg-zinc-900
                  px-4 py-1.5 border border-gray-200 dark:border-zinc-700
                  ${row.label.length || row.value.length > 15 ? 'h-auto' : 'h-6'}
                  `}
                key={index}
              >
                <span className="text-xs text-zinc-600 dark:text-zinc-50 font-semibold">
                  {row.label}
                </span>
                <span className="text-xs text-zinc-600 dark:text-zinc-50 font-normal text-start break-words">
                  {row.value}
                </span>
              </div>
            ))}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};