import React, { useEffect, useState, useMemo } from 'react';
import { Search } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { fetchAlertList, setAlertStatus } from '../slices/AlertSlice';
import { Input } from '@/components/ui/input';
import AlertCard from './AlertCard';
import AlertCardSkeleton from './AlertCardSkeleton';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface AlertsListProps {
  residentId?: string;
  userId?: string;
  locationId?: string;
  relatedTo: 'user' | 'resident' | 'location';
}

const AlertsList: React.FC<AlertsListProps> = ({ residentId, userId, relatedTo }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data, status } = useSelector((state: RootState) => state.customAlerts.alerts);
  const alertStatus = useSelector((state: RootState) => state.customAlerts.alertStatus);
  const [alertStatusFilter, setAlertStatusFilter] = useState<'current' | 'expired'>('current');
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    fetchAlertsData();
  }, [residentId, alertStatus, alertStatusFilter]);

  const fetchAlertsData = async () => {
    try {
      setLoading(true);
      await dispatch(
        fetchAlertList({
          resident_id: residentId && residentId?.length > 0 ? [residentId ?? ''] : null,
          user_id: userId && userId?.length > 0 ? [userId ?? ''] : null,
          alert_validity: alertStatusFilter
        })
      ).unwrap();
    } catch (error) {
      console.error('Failed to fetch alerts:', error);
    }
    setLoading(false);
    dispatch(setAlertStatus('idle'));
  };

  const filteredAlerts = useMemo(() => {
    return data.filter((alert) => alert.title.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [data, searchTerm]);

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row items-center gap-4">
        <div className="relative w-full md:w-80">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
          <Input
            placeholder={t('customAlerts.searchAlertsByTitle')}
            className="pl-10 w-full focus:border-2 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none focus-visible:ring-0 focus-visible:ring-primary focus-visible:ring-offset-0"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto mt-1 md:mt-0">
          <Button
            onClick={() => {
              setAlertStatusFilter('current');
            }}
            className={cn(
              'border-2 border-gray-300',
              alertStatusFilter == 'current'
                ? 'bg-primary'
                : 'bg-background text-primary border-dotted hover:bg-background'
            )}
          >
            {t('customAlerts.currentAlerts')}
          </Button>
          <Button
            onClick={() => {
              setAlertStatusFilter('expired');
            }}
            className={cn(
              'border-2 border-gray-300',
              alertStatusFilter == 'expired'
                ? 'bg-primary'
                : 'bg-background text-primary border-dotted hover:bg-background'
            )}
          >
            {t('customAlerts.expiredAlerts')}
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 2lg:grid-cols-3 gap-6">
        {status === 'loading' || loading ? (
          [1, 2, 3, 4].map((item) => <AlertCardSkeleton key={item} />)
        ) : filteredAlerts.length > 0 ? (
          filteredAlerts.map((alert) => <AlertCard key={alert.id} alert={alert} relatedTo={relatedTo} />)
        ) : (
          <div className="col-span-full text-center text-muted-foreground">{t('customAlerts.noAlertsFound')}</div>
        )}
      </div>
    </div>
  );
};

export default AlertsList;
