import { DashboardProfile } from '@/modules/residents/presentation/pages/DashboardProfile';
import { ResidentFormPage } from '@/modules/residents/presentation/pages/forms/ResidentFormPage';
import { ResidentFormsPage } from '@/modules/residents/presentation/pages/forms/ResidentFormsPage';
import { ResidentsPage } from '@/modules/residents/presentation/pages/ResidentsPage';
import { UpdateResident } from '@/modules/residents/presentation/pages/UpdateResident';
import { Route, Routes } from 'react-router-dom';

const ResidentsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ResidentsPage />} />
      <Route path="/update" element={<UpdateResident />} />
      <Route path="/profile" element={<DashboardProfile />} />
      <Route path="/forms" element={<ResidentFormsPage />} />
      <Route path="/forms/:formKey" element={<ResidentFormPage />} />
 
    </Routes>
  );
};

export default ResidentsRouter;
