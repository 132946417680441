import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import Layout from '@/common/presentation/components/layout/Layout';
import EssentialSuspendedPage from '@/modules/subscription/presentation/pages/EssentialSuspendedPage';
import MarketPlacePage from '@/modules/subscription/presentation/pages/MarketPlacePage';
import { useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import ContactsRouter from './ContactsRouter';
import DashboardRouter from './DashboardRouter';
import EmarRouter from './EmarRouter';
import MedicationsRouter from './MedicationsRouter';
import NotesRouter from './NotesRouter';
import { PageNotFound } from './PageNotFound';
import PaymentRouter from './PaymentRouter';
import ResidentsRouter from '@/routes/ResidentsRouter';
import SignUpRouter from './SignUpRouter';
import StaffRouter from './StaffRouter';
import TrackerRouter from './TrackerRouter';
import CalendarPage from '@/modules/calendar/presentation/pages/CalendarPage';
import TrainingRouter from './TrainingRouter';
import LoginPage from '@/auth/presentation/pages/LoginPage';
import VisitorsRouter from './VisitorsRouter';
import { AssessmentPage } from '@/modules/visitors/presentation/pages/AssessmentPage';
import SecurityRouter from './SecurityRouter';
import PharmaciesRouter from './PharmaciesRouter';
import ReportsRouter from './ReportsRouter';
import ReportByResidentPage from '@/modules/reportbyresident/presentation/pages/ReportByResidentPage';
import ReportByResidentRouter from './ReportByResidentRouter';
import { QrCode } from '@/modules/qrcode/QrCode';


const LayoutWrapper = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const AppRouter = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <LayoutWrapper />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard/*" element={<DashboardRouter />} />
        <Route path="/medications/*" element={<MedicationsRouter />} />
        <Route path="/calendar/*" element={<CalendarPage />} />
        <Route path="/emar/*" element={<EmarRouter />} />
        <Route path="/notes/*" element={<NotesRouter />} />
        <Route path="/staff/*" element={<StaffRouter />} />
        <Route path="/residents/*" element={<ResidentsRouter />} />
        <Route path="/tracker/*" element={<TrackerRouter />} />
        <Route path="/visitors/*" element={<VisitorsRouter />} />
        <Route path="/contacts/*" element={<ContactsRouter />} />
        <Route path="/payment/*" element={<PaymentRouter />} />
        <Route path="/service-suspended" element={<EssentialSuspendedPage />} />
        <Route path="/market-place" element={<MarketPlacePage />} />
        <Route path="/pharmacies/*" element={<PharmaciesRouter />} />
        <Route path="/training/*" element={<TrainingRouter />} />
        <Route path="/security/*" element={<SecurityRouter />} />
        <Route path="/reports/*" element={<ReportsRouter />} />
        <Route path="/reportbyresident/*" element={<ReportByResidentRouter />} />
      </Route>
      <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />} />
      <Route path="/assessment" element={<AssessmentPage />} />
      <Route path="/sign-up/*" element={<SignUpRouter />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/qr-code" element={<QrCode />} />
    </Routes>
  );
};

export default AppRouter;
