import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useHygineTypeColumns from '../components/hygine/HygineTypeColumns';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { getTrackerHygieneType } from '../slices/TrackerHygieneSlice';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerHygieneTypeCreateDialog from '../components/hygine/TrackerHygieneTypeCreateDialog';
import UtilService from '@/common/services/UtilService';
import { t } from 'i18next';

function TrackerHygieneType() {
    const dispatch = useDispatch();
    const trackerHygiene = useSelector((state: RootState) => state.trackerHygiene);
    const configurations = useSelector((state: RootState) => state.configurations);
    const { locationSelectedId } = useLocation();
    const [types, setTypes] = useState([]);
    const [accentColor, setAccentColor] = useState("");

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        getTypes();
    }, [locationSelectedId]);

    useEffect(() => {
        const newTypes = trackerHygiene.typesHygienes

        if(newTypes && newTypes.length) {
            if(newTypes && newTypes.length) {
                setTypes(
                    UtilService.sortObjectsAlphabetically(newTypes, "type_name")
                );
            }
        } else {
            setTypes([]);
        }
    }, [trackerHygiene.typesHygienes]);

    const getTypes = async () => {
        dispatch(
            getTrackerHygieneType(
                {
                    location_id: locationSelectedId
                }
            )
        );
    };
    
    return (
        <>
            {
                locationSelectedId && (
                    <Card className="border-t-4 border-t-primary/50">
                        <CardHeader>
                            <div className='flex justify-between'>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                    <span>
                                        {t("trackers.trackerhygiene.customTypes")}
                                    </span>
                                </CardTitle>
                            </div>
                        </CardHeader>

                        <CardContent>
                            <div className="rounded-md overflow-hidden">
                                <TableCustomVirtuoso
                                    data={types}
                                    columns={useHygineTypeColumns()}
                                    renderCellContent={(index, column, data) => data[index][column.key]}
                                    showSearchInput={false}
                                    additionalButtons={<TrackerHygieneTypeCreateDialog />}
                                />
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        </>
    )
}

export default TrackerHygieneType;
