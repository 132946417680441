import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { useTimeGiven } from '@/modules/emar/infrastructure/hooks/UseTimeGiven';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import { useState } from 'react';

interface Props {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
}

export const PrnTimeGivenDialog = ({ onChange, value }: Props) => {
  const { isOpen, onClose } = useTimeGiven();

  const [date, setDate] = useState<Date | undefined>(value);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[90vw] lg:max-w-[40vw] flex flex-col gap-6">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold"> Date & Time Given</DialogTitle>
          </DialogHeader>
          <CustomCalendar onChange={setDate} value={date} MonthAndYearPicker timePicker />
          <Button onClick={() => onChange(date)}>
            <span>Save</span>
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
