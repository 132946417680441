import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { useTranslation } from 'react-i18next';
import { DocumentsCategoryI } from '../../infrastructure/interfaces/DocumentsFolder';


interface ISelectCategoryProps {
    documentCategories: DocumentsCategoryI[];
    categoryKey: string;
    handleCategoryChange: (key: string) => void;
}

export function SelectCategoryDocument({ categoryKey, handleCategoryChange, documentCategories }: ISelectCategoryProps) {
    const { t } = useTranslation();
    const categories = documentCategories.find((category) => category.value === categoryKey)
    return (

        <div className="w-1/2 flex items-center justify-between">
            <Select value={categoryKey} onValueChange={handleCategoryChange}>
                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                    {categories ? (<SelectValue>{categories.label}</SelectValue>) :
                        (categoryKey == 'all' ? t('notes.all') : t('notes.category'))}
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">{t('notes.all')}</SelectItem>
                    {documentCategories.map((category) => (
                        <SelectItem key={category.value} value={category.value}>
                            {category.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}