import React from 'react';
import { ICourse } from '../../domain/training.domain';
import { cn } from '@/lib/utils';
import { Card, CardContent } from '@/components/ui/card';

interface IProps {
  course: ICourse;
}

const CourseCard: React.FC<IProps> = ({ course }) => {
  const classesColorStatus: { [key: string]: string } = {
    completed: 'bg-green-500',
    in_progress: 'bg-yellow-500',
    not_started: 'bg-zinc-200'
  };
  return (
    <Card className="w-full max-w-xs hover:shadow transition-all duration-300 ease-in-out cursor-pointer">
      <CardContent className="p-1 space-y-1">
        <div className="bg-primary/10 dark:bg-primary/15 p-3 rounded-md h-20 flex items-center">
          <p className="text-primary text-sm font-medium line-clamp-3">{course.label}</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className={cn('inline-block rounded-full h-2 w-2', classesColorStatus[course.status])} />
            <p className="text-sm text-zinc-600 dark:text-zinc-300">{course.status}</p>
          </div>
          <p className="text-sm text-primary hover:underline">Start</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default CourseCard;
