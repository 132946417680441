import { Button } from '@/common/presentation/components/ui/button';

interface NavItemProps {
  label: string;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({
  label,
  onClick,
}) => (
  <div className="flex items-center gap-2">
    <Button
      onClick={onClick}
      variant="default"
      size={'sm'}
      className={`flex items-center w-full px-4 py-1.5
        justify-start gap-2.5 rounded shadow-md hover:shadow-lg
        ${label.length > 8 ? 'h-auto' : 'h-8'}`}
    >
      <span className="font-semibold leading-normal whitespace-normal break-words text-start
      dark:text-white">
        {label}
      </span>
    </Button>
  </div>
);

export default NavItem;
