import { useTranslation } from 'react-i18next';
import { FileUser } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';

interface EmployerInfoProps {
  className?: string;
}

export const EmployerInfo = ({ className }: EmployerInfoProps) => {
  const { t } = useTranslation();
  const contentClass = "items-center gap-4 rounded-md bg-zinc-100 dark:bg-zinc-900 px-4 py-1.5 border border-gray-200 dark:border-zinc-700"
  const textClass = "text-xs text-zinc-600 dark:text-zinc-50 font-medium"

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('staff.profile.employerInformation')}
        headerIconLeft={FileUser}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[160px]">
          <div className="space-y-3">
            <div className={`grid ${contentClass}`}>
              <span className="text-xs text-primary font-semibold">
                Blue Horizon Homes, LLC.
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                Location:
              </span>
              <span className={`${textClass} font-normal`}>
                Mom and Dad's House - Manor
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                  Position:
              </span>
              <span className={`${textClass} font-normal`}>
                Resident Caregiver
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                  Shift:
              </span>
              <span className={`${textClass} font-normal`}>
                7:00 am - 7:00 pm
              </span>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};