import { ICourse, IFilters } from '../training.domain';

export const trainingFilters: IFilters[] = [
  {
    label: 'All Status',
    key: 'all'
  },
  {
    label: 'Not Started',
    key: 'not_started'
  },
  {
    label: 'In progress',
    key: 'in_progress'
  },
  {
    label: 'Completed',
    key: 'completed'
  }
];

export const coursesDummy: ICourse[] = [
    {
      id: '2f4a0206-3fa9-4d94-bf89-72d5f7a3f8a9',
      status: 'in_progress',
      label: 'Activities of Daily Living'
    },
    {
      id: '9d7f2cb5-d985-4575-aa66-18d9ad519dad',
      status: 'completed',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '524b2fed-95eb-4b66-a5c3-de6c9b6f851c',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '7b1a0d24-a4ca-4f33-b80d-98bb17e11aac',
      status: 'not_started',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '1a52f1eb-fa55-4a5d-b1ab-fb2e1caf2f0b',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: 'b3d9eaee-fee4-4786-9fbd-98ccc59ca580',
      status: 'not_started',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '4b9b93db-e739-47e0-971b-9d3a1d9d4a1b',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '5c1c9e36-a6b8-4b38-b8b2-83ac44a4f32e',
      status: 'completed',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '6a4effed-c116-4d85-a2a7-ff9f0cb784a0',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: 'f2dd3a13-87a1-4a6a-b856-58ccbf4d1baa',
      status: 'completed',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '19c9f85c-a76c-4f3d-8c8d-92d7fd11fcf4',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '49d55b2d-c7d1-4d78-8cd2-7a415300c785',
      status: 'not_started',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: 'a9f9f811-a7d6-4e05-9c63-6a1f9eab3d86',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '2d92ace3-a9d6-49be-9292-99b3dec5d7f6',
      status: 'not_started',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '52a9a908-0be6-4a35-9c15-2fc782d0ac7b',
      status: 'in_progress',
      label: 'Restricted and Prohibited Conditions RCFE'
    },
    {
      id: '8f4e0c99-cfc1-4cd3-90db-64bed229b876',
      status: 'completed',
      label: 'Restricted and Prohibited Conditions RCFE'
    }
  ];