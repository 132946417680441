import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { format, parseISO } from 'date-fns';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';

export const ResidentInformation = () => {
  const { t } = useTranslation();
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const residentAge = resident?.dob ? new Date().getFullYear() - new Date(resident?.dob).getFullYear() : 0;

  return (
    <Card className="w-full py-4 px-6 xl:h-[262px] border-t-4 border-t-primary/80 dark:bg-zinc-950">
      <CardContent className="grid grid-cols-1 md:grid-cols-12 gap-4 p-0">
      <div className="flex items-center justify-start col-span-full lg:col-span-4 md:col-span-full
        lg:items-center lg:justify-center">
          <Avatar className="w-[168px] h-[168px] rounded-full">
            <AvatarImage
              src={resident?.image_url || 'fallback_image_url'}
              alt={`${resident?.first_name || 'Unknown'} ${resident?.last_name || ''}`}
              className="h-full w-full object-cover rounded-full"
            />

            <AvatarFallback className={cn("bg-secondary dark:bg-zinc-900 dark:text-white text-sm rounded-full", "bg-secondary text-sm")}>
              {resident.first_name[0] + resident.last_name[0]}
            </AvatarFallback>
          </Avatar>
      </div>
      <div className="col-span-full lg:col-span-8 flex flex-col lg:items-start items-start lg:justify-center
        lg:px-2 px-0">
        <h1 className="text-primary text-2xl font-bold leading-normal mb-4">
          {resident?.first_name} {resident?.middle_name} {resident?.last_name} |{' '}
          {resident?.preferred_name}
        </h1>
        <div className='text-base text-zinc-600 space-y-1 dark:text-white'>
          <p className="flex fle-wrap">
            <span className="font-semibold mr-2">{t('general.dateOfBirth')}:</span>
              {resident?.dob
                ? `${format(parseISO(resident?.dob), 'MM/dd/yyyy')} (${residentAge} years old)`
                : t('general.not_specified')}
          </p>
          <p className="flex flex-wrap">
            <span className="font-semibold mr-2">{t('general.sex')}:</span>
            {resident?.sex[0].toUpperCase() + resident?.sex.slice(1) || t('general.not_specified')}
          </p>
          <p className="flex flex-wrap">
            <span className="font-semibold mr-2">{t('residents.profile.spokenLanguages')}:</span>
            {resident?.spoken_languages}
          </p>
          <p className="flex  flex-row items-center space-x-4 space-y-0">
            <span className="font-semibold mr-2">{t('general.weight')}:</span>
              {resident?.weight ? `${resident.weight} lbs` : t('general.not_specified')}
            <span className="font-semibold mr-4 pr-2">{t('general.height')}:</span>
              {resident?.height ? `${resident.weight} ft`: t('general.not_specified')}
          </p>
        </div>
      </div>
      <div className="col-span-full">
      <Separator className="w-full bg-primary gap-4" />
      </div>
      <div className="col-span-full grid grid-cols-2 xl:grid-cols-4 gap-2 text-start items-center h-auto 
        p-1 rounded-md bg-zinc-100 px-4 dark:bg-zinc-900">
        <p className=" grid grid-cols-2 gap-2 px-2 text-sm font-semibold">
          <span className="text-primary">DNR:</span>
          <span className="text-left text-zinc-800 dark:text-white">YES</span>
        </p>
        <p className="grid grid-cols-2 gap-2 px-2 text-sm font-semibold">
          <span className="text-primary">POLST:</span>
          <span className="text-left text-zinc-800 pl-6 dark:text-white">YES</span>
        </p>
        <p className="grid grid-cols-2 gap-2 px-2 text-sm font-semibold">
          <span className="text-primary">Hospice:</span>
          <span className="text-left text-zinc-800 dark:text-white">YES</span>
        </p>
        <p className="grid grid-cols-2 gap-2 px-2 text-sm font-semibold">
          <span className="text-primary">Conserved:</span>
          <span className="text-left text-zinc-800 pl-6 dark:text-white">YES</span>
        </p>
      </div>
      </CardContent>
    </Card>
  );
};