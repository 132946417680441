import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from "@/components/ui/card";

interface IModuleCard {
    title: string;
    id: string;
    color?: string;
    icon: React.ReactNode;
    onClick: (id: string) => void;
}

const ModuleCard: React.FC<IModuleCard> = ({ id, color, title, icon, onClick }) => {
    
    const handleCardClick = () => {
        onClick(id);
    };

    if (!color) return null;

    return (
        <Card
            className={'w-full w-md-[239px] shadow-genericCard cursor-pointer dark:bg-zinc-900 rounded-lg'}
            onClick={handleCardClick}
        >
            <CardContent className={'flex items-center gap-4 p-4 h-[72px]'}>
                 {icon && (
                    <div
                        style={{ backgroundColor: color }} 
                        className={`${color} text-white rounded-full w-10 h-10 flex justify-center items-center shrink-0`}
                    >
                        {icon}
                    </div>
                )}
                <p className="text-sm font-semibold leading-5 text-zinc-600 dark:text-white">
                    {title}
                </p>
            </CardContent>
        </Card>
    );
};

export default ModuleCard;