import CryptoJS from 'crypto-js';

// This function is used to encrypt data to be send to Laravel
// decrypt the data in Laravel using decryptJS() function
export const encryptToLaravel = (data: any) => {
  const encryptedKey = import.meta.env.VITE_WEB_LARAVEL_ENCRYPTION_KEY;
  const key = CryptoJS.enc.Utf8.parse(encryptedKey);
  const iv = CryptoJS.enc.Utf8.parse(encryptedKey);
  return CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv }).toString();
};

export const encryptToNest = (data: string) => {
  return CryptoJS.AES.encrypt(data, import.meta.env.VITE_WEB_NEST_CARING_DATA_KEY).toString();
};

export const encrypt = (data: string) => {
  return CryptoJS.AES.encrypt(data, import.meta.env.VITE_REACT_ENCRYPTION_KEY).toString();
};

export const decrypt = (data: string) => {
  if (!data) {
    console.error("No data provided for decryption");
    return "";
  }

  try {
    const bytes = CryptoJS.AES.decrypt(data, import.meta.env.VITE_REACT_ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Error during decryption:", error);
    return "";
  }
};
