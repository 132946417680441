import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from '@/utils/helpers/i18n.helper';
import { MainMenu } from '@/common/domain/MainMenu';

const useFilteredTrackers = (subMenuTrackers: MainMenu[]) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.toLocaleLowerCase() ?? '');
  };

  const filteredTrackers = useMemo(() => {
    return subMenuTrackers
      .filter((item) => {
        const translationKey = normalizeKey(item.name);
        const translatedName = t(`menu.tracker.submenus.${translationKey}`).toLowerCase();
        return translatedName.includes(search);
      })
      .sort((a, b) => {
        const translationKeyA = normalizeKey(a.name);
        const translationKeyB = normalizeKey(b.name);
        const translatedNameA = t(`menu.tracker.submenus.${translationKeyA}`).toLowerCase();
        const translatedNameB = t(`menu.tracker.submenus.${translationKeyB}`).toLowerCase();
        return translatedNameA.localeCompare(translatedNameB);
      });
  }, [subMenuTrackers, search, t]);

  return { search, handleSearch, filteredTrackers };
};

export default useFilteredTrackers;