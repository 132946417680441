import React from 'react';

import { Route, Routes } from 'react-router-dom';
import MedicationsPage from '../modules/medications/presentation/pages/MedicationsPage';
import MedicationDetails from '@/modules/medications/presentation/components/MedicationsDetails/MedicationDetails';
import DestructionsPage from '@/modules/medications/presentation/components/Destructions/DestructionsPage';
import CentralizedMedicationRecordPage from '@/modules/medications/presentation/components/CentralizedMedicationRecord/CentralizedMedicationRecordPage';

const MedicationsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MedicationsPage />} />
      <Route path="/:locationId?" element={<MedicationsPage />} />
      <Route path="/edit/:medicationId/:locationId?" element={<MedicationDetails />} />
      <Route path="/destruction/:residentId?" element={<DestructionsPage />} />
      <Route path="/centralized" element={<CentralizedMedicationRecordPage />} />
    </Routes>
  );
};

export default MedicationsRouter;
