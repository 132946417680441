import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Slider } from '@/common/presentation/components/ui/slider';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useTimeGiven } from '../../../infrastructure/hooks/UseTimeGiven';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { useState } from 'react';
import { PrnTimeGivenDialog } from '../dialogs/PrnTimeGivenDialog';

interface Props {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  disabled?: boolean;
  reasons: any[];
}

const formSchema = z.object({
  time_given: z.date({
    required_error: 'Time Given is required'
  }),
  follow_up: z.string({
    required_error: 'Please select a follow up option'
  }),
  reason_id: z.string({
    required_error: 'Reason is required'
  }),
  level_of_pain: z.number().optional(),
  result: z.string().optional(),
  frecuency: z.string().optional(),
  not_exceed: z.string().optional(),
  comments: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

const followUpOptions = [
  { value: '30', label: '30 minutes' },
  { value: '60', label: '1 hour' },
  { value: '120', label: '2 hours' },
  { value: '180', label: '3 hours' },
  { value: '240', label: '4 hours' },
  { value: '300', label: '5 hours' },
  { value: '360', label: '6 hours' },
  { value: '420', label: '7 hours' },
  { value: '480', label: '8 hours' },
  { value: '720', label: '12 hours' },
  { value: '1440', label: '24 hours' }
];

const resultOptions = [
  { value: 'helpful', label: 'Helpful' },
  { value: 'somehow_helpful', label: 'Somehow Helpful' },
  { value: 'not_helpful', label: 'Not Helpful' }
];

export const PrnDialogForm = ({ defaultValues, onSubmit, disabled, reasons }: Props) => {
  const { onOpen, onClose } = useTimeGiven();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
      reason_id: defaultValues?.reason_id || '',
      follow_up: defaultValues?.follow_up || '60'
    },
    mode: 'onChange'
  });

  const [showPainLevel, setShowPainLevel] = useState(
    form.watch('level_of_pain') !== undefined && form.watch('level_of_pain')! > 0
  );

  const handleSubmit = async (formData: FormValues) => {
    onSubmit(formData);
  };

  const painLevel = () => {
    setShowPainLevel(!showPainLevel);

    if (!showPainLevel) {
      form.setValue('level_of_pain', 0);
    }
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="grid grid-cols-12 gap-4 p-2">
          <FormField
            control={form.control}
            name="time_given"
            render={({ field }) => (
              <FormItem className="col-span-12 lg:col-span-12">
                <FormLabel>
                  Date & Time Given <span className="text-destructive">(*)</span>
                </FormLabel>
                <FormControl>
                  <Button
                    variant={'outline'}
                    className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                    disabled={disabled}
                    onClick={(e) => {
                      e.preventDefault();
                      onOpen();
                    }}
                  >
                    {field.value && format(field.value, 'MM/dd/yyyy hh:mm a')}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
                <PrnTimeGivenDialog
                  onChange={(date: Date | undefined) => {
                    field.onChange(date);
                    onClose();
                  }}
                  value={field.value || new Date()}
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="follow_up"
            render={({ field }) => (
              <FormItem className="col-span-12 lg:col-span-6">
                <FormLabel>Follow Up</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  defaultValue={field.value}
                  disabled={disabled}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a follow up option" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {followUpOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="reason_id"
            render={({ field }) => (
              <FormItem className="col-span-12 lg:col-span-6">
                <FormLabel>
                  Reason <span className="text-destructive">(*)</span>
                </FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  defaultValue={field.value}
                  disabled={disabled}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a reason" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {reasons?.map((reason) => (
                      <SelectItem key={reason.id} value={reason.id}>
                        {reason.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="col-span-12 flex items-center space-x-2">
            <Checkbox checked={showPainLevel} onCheckedChange={painLevel} />
            <label
              htmlFor="terms2"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              If resident is in pain, click here
            </label>
          </div>

          {showPainLevel && (
            <FormField
              control={form.control}
              name="level_of_pain"
              render={({ field }) => (
                <FormItem className="col-span-12">
                  <FormLabel>
                    Level of Pain <span className="font-normal">(1-10)</span>
                  </FormLabel>
                  <FormControl>
                    <div className="relative col-span-12 w-full py-4">
                      <div className="pl-[calc(5%+2px)] pr-[calc(7%+3px)]">
                        <Slider
                          value={[field.value || 0]}
                          defaultValue={[field.value || 0]}
                          max={10}
                          step={1}
                          onValueChange={(value: (number | undefined)[]) => {
                            field.onChange(value[0]);
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <img
                        src="/img/big-image2.png"
                        width={'680'}
                        height={'180'}
                        alt=""
                        className="h-full w-full select-none pointer-events-none"
                        draggable={false}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <FormField
            control={form.control}
            name="result"
            render={({ field }) => (
              <FormItem className="col-span-12">
                <FormLabel>Result</FormLabel>
                <FormControl className="flex gap-8">
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    className="flex"
                    disabled={disabled}
                  >
                    {resultOptions.map((option) => (
                      <FormItem className="flex items-center space-x-1 space-y-0" key={option.value}>
                        <FormControl>
                          <RadioGroupItem value={option.value} />
                        </FormControl>
                        <FormLabel className="font-normal">{option.label}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="comments"
            render={({ field }) => (
              <FormItem className="col-span-12">
                <FormLabel>Comments</FormLabel>
                <FormControl>
                  <Textarea className="resize-none" {...field} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormItem className="col-span-12 lg:col-span-4">
            <Button type="submit" disabled={disabled} className="w-full">
              {disabled && <Loader className="mr-2 size-4 animate-spin" />}
              Save
            </Button>
          </FormItem>
        </form>
      </Form>
    </>
  );
};
