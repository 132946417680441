import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import React from 'react';

interface IProps {
  names: string;
  image?: string;
  date?: string;
  title: string;
  Button?: JSX.Element;
}

const AlertItemCard: React.FC<IProps> = ({ names, image, date, title, Button }) => {
  const { formatDate } = useDateHelpers();
  return (
    <div className="flex flex-col gap-3 w-full">
      <Card className="w-full rounded-md p-2 hover:bg-primary/5">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <Avatar className="h-6 w-6">
                <AvatarImage src={image} alt="User Image" className="h-full w-full object-cover" />
                <AvatarFallback className="bg-secondary text-sm">
                  {names?.split(' ')[0]?.charAt(0)}
                  {names?.split(' ')[1]?.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col sm:flex-row lg:flex-row">
                <p className="text-sm font-medium">{names} - </p>
                <div>
                  {date && (
                    <p className="text-sm text-primary font-medium sm:ml-2 md:ml-0 2xl:ml-1">
                      {formatDate(date, false)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {Button}
          </div>
          <div>
            <p className="font-normal text-sm">{title}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AlertItemCard;
