import React from 'react';
import { Row } from "@tanstack/react-table";
import TrackerHygieneTypeUpdateDialog from './TrackerHygieneTypeUpdateDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
}

const RenderAreaActionsColumn: React.FC<RenderActionsColumnProps> = (rowData) => {
    return (
        <div className='flex justify-center'>
            <TrackerHygieneTypeUpdateDialog row={rowData} />
        </div>
    );
};

export default RenderAreaActionsColumn;
