import { t } from "i18next";
import RenderHygineTypeActionsColumn from "./RenderHygineTypeActionsColumn";

const useHygineTypeColumns = () => {
  const HygineTypeColumns = [
    {
      key: 'type_name',
      id: 'name',
      labelTranslationKey: 'trackerhygienesettings.typeName',
      value: 'name',
      visible: true,
      sortable: true,
      width: '70%'
    },
    {
      key: 'status',
      id: 'status',
      labelTranslationKey: 'common.status',
      render: (row) => {
        let status = "active";

        if(row.client_id) status = row.status;
        else {
          if(row.clientTrackerTypes) status = row.clientTrackerTypes.status;
          else status = row.status;
        }

        return status === "active" ? t("common.active") : t("common.inactive");
      },
      value: 'status',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'actions',
      labelTranslationKey: 'contacts.actions',
      render: RenderHygineTypeActionsColumn,
      visible: true,
      static: true,
      width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(HygineTypeColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = HygineTypeColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useHygineTypeColumns;
