import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/registry/new-york/ui/avatar';
import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { CategoryAccumulator } from '../note';
import { Chat } from '../notes/elements/chat';
import { cn } from '@/lib/utils';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/common/presentation/components/ui/dialog';
import { fetchRefreshNotes, fetchRefreshStaffNotes, fetchRemoveNote, setNoteId } from '../../slices/NoteSlice';
import { ImgageGrid } from './imageGrid';
import { NoteI } from '../../../domain/note.domain';
import { Pencil, UserPlus, ReplyAllIcon, Trash, Loader, Eye, ChevronDown, ChevronUp, MessageSquareText, ReplyIcon } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/common/presentation/components/registry/new-york/ui/use-toast';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from './confirmDialog';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format } from 'date-fns';


interface NoteDisplayProps {
  note: NoteI | null;
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
  categoriesNames: CategoryAccumulator;
}

interface formDataAddUser {
  note_id: string;
  user_id: string[];
}

interface formDataSharePrivateNote {
  note_id: string;
  user_id: string[];
  content: string;
}

interface Option {
  value: string;
  label: string;
}

export function NoteDisplay({ note, setCrudState, crudState, categoriesNames }: NoteDisplayProps) {
  // Initial
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  // Redux
  const dispatch = useDispatch();
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const auth = useSelector((state: RootState) => state.auth.user);
  const noteToOptions: Option[] = [];
  const staffAnswerPrivateNote: Option[] = [];

  // Hooks
  const [openDialogSharePrivateNote, setOpenDialogSharePrivateNote] = useState(false);
  const [openDialogAddUser, setOpenDialogAddUser] = useState(false);
  const [displayChat, setDisplayChat] = useState<boolean>(false);
  const [displayAnswer, setDisplayAnswer] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);
  const [optionSelectedConfirmDialog, setOptionSelectedConfirmDialog] = useState('');
  const [loadingRemoveNote, setLoadingRemoveNote] = useState<boolean>(false);
  const [selectedStaffAnswerPrivateNote, setSelectedStaffAnswerPrivateNote] = useState<Option>({ label: '', value: '' });

  useEffect(() => {
    let isSHowDisplatChat = !crudState.showingStaffNotes && (note?.answers.length ? true : false);
    if (isSHowDisplatChat) {
      setDisplayChat(isSHowDisplatChat)
      setDisplayAnswer(false);
    } else {
      setDisplayAnswer(true);
      setDisplayChat(false)
    }
  }, [crudState])

  useEffect(() => {
    if (note) {
      if (optionSelectedConfirmDialog == 'yes') {
        removeNote(note.id)
        setIsOpenConfirmDialog(false);
      } else {
        setIsOpenConfirmDialog(false);
      }
    }
  }, [optionSelectedConfirmDialog]);

  useEffect(() => {
    if (!displayChat) {
      setDisplayAnswer(true);
    }
  }, [displayChat]);

  useEffect(() => {
    if (note) {
      let staffCurrent = staff.find((element: any) => element.id == note.created_by);
      if (staffCurrent) {
        let item = {
          value: staffCurrent.id,
          label: staffCurrent.first_name + ' ' + staffCurrent.last_name
        };
        setSelectedStaffAnswerPrivateNote(item)
        setFormDataSharePrivateNote({
          ...formDataSharePrivateNote,
          ['user_id']: [item.value]
        });
      }
    }
  }, [note])

  const [formDataAddUser, setFormDataAddUser] = useState<formDataAddUser>({
    note_id: noteSelected,
    user_id: []
  });

  staff.forEach((element: any) => {
    if (userId != element.id) {
      let item = {
        value: element.id,
        label: element.first_name + ' ' + element.last_name
      };
      staffAnswerPrivateNote.push(item);
    }
  });

  const [formDataSharePrivateNote, setFormDataSharePrivateNote] = useState<formDataSharePrivateNote>({
    note_id: noteSelected,
    user_id: [],
    content: ''
  });

  staff.forEach((element: any) => {
    if (
      userId != element.id &&
      Array.isArray(note?.private_user_ids) &&
      note.private_user_ids.includes(element.id) &&
      !note?.created_by != element.id
    ) {
      let item = {
        value: element.id,
        label: element.first_name + ' ' + element.last_name
      };
      noteToOptions.push(item);
    }
  });

  const staffNames = staff.reduce((acc, item) => {
    acc[item.id] = item.first_name + ' ' + item.last_name;
    return acc;
  }, {});

  const staffPhotos = staff.reduce((acc, item) => {
    acc[item.id] = item.profile_url;
    return acc;
  }, {});

  const customClassNames = {
    control: () =>
      cn(
        '!bg-background border-1 !border-gray-300 dark:!border-gray-700 focus:!border-red-400 !rounded h-1 focus:!outline-none'
      ),
    menu: () => cn('!bg-background !border-2 !border-gray focus:!border-red-400 !z-[9999]'),
    option: ({ isSelected }: any) =>
      cn(
        'dark:text-white dark:hover:!bg-gray-400 hover:!bg-primary/10 before:!bg-primary/10',
        isSelected ? '!bg-primary' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white'),
    multiValueLabel: () => cn('!text-white')
  };

  const handleSelectAddUser = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    setFormDataAddUser({
      ...formDataAddUser,
      ['user_id']: recipients
    });
  };

  const handleSubmitAddUser = async (e: React.FormEvent) => {
    e.preventDefault();
    let data = formDataAddUser;
    data['note_id'] = noteSelected;
    await NoteService.addUsertoNote(data);
    setOpenDialogAddUser(false);

    toast({
      title: 'User added',
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">User added</code>
        </pre>
      )
    });
  };

  const handleSelectSharePrivateNote = (e: any) => {
    setSelectedStaffAnswerPrivateNote(e);
    setFormDataSharePrivateNote({
      ...formDataSharePrivateNote,
      ['user_id']: [e.value]
    });
  };

  const handleSubmitSharePrivateNote = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !formDataSharePrivateNote.content
      || formDataSharePrivateNote.content.trim().length < 2
      || !formDataSharePrivateNote.user_id.length
    ) {
      return;
    }

    let data = formDataSharePrivateNote;
    data['note_id'] = noteSelected;
    await NoteService.sharePrivateNote(data);
    setOpenDialogSharePrivateNote(false);
    toast({
      description: t('notes.notePrivateSave'),
    });
  };

  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  const removeNote = async (noteId: string) => {
    setLoadingRemoveNote(true);
    await dispatch<any>(fetchRemoveNote(noteId));
    if (crudState.showingStaffNotes) {
      await dispatch<any>(fetchRefreshStaffNotes());
    } else {
      await dispatch<any>(fetchRefreshNotes());
    }
    setOptionSelectedConfirmDialog('');
    setLoadingRemoveNote(false);
  }

  const editAnswer = () => {
    setDisplayAnswer(true);
    setDisplayChat(false);
  }

  const showAnswers = () => {
    setDisplayAnswer(false);
    setDisplayChat(true);
  }

  return (
    note ? (
      <div className={"col-span-12 md:col-span-7 h-[calc(100vh-340px)]"}>
        <Card className="border-t-4 border-t-primary/80 mb-3">
          <CardContent className='h-full pb-2'>
            <div className="flex flex-1 flex-col mt-1">
              <div className="flex justify-between py-2 px-0">
                <div className="flex gap-4">
                  <Avatar>
                    <AvatarImage
                      src={staffPhotos[note.created_by] ? staffPhotos[note.created_by] : undefined}
                      className="h-full w-full object-cover"
                    />
                    <AvatarFallback>
                      {note.author.split(' ').map((chunk) => chunk[0]).join('')}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex-col">
                    <div className="self-center font-semibold">{note.author}</div>
                    {note.resident_id ? (
                      <div className="flex text-xs font-thin">
                        {!crudState.showingStaffNotes && note.resident_id && (
                          <>
                            <span>{t('notes.resident') + ':'}</span>
                            <span className='text-primary ml-1'>{note.resident_name}</span>
                          </>
                        )}
                      </div>
                    ) : (
                      note.staffs && note.staffs.length && (
                        <>
                          <span>{t('notes.staff') + ':'}</span>
                          <span className='text-primary ml-1'>{note.staffs.map((item) => item.first_name + ' ' + item.last_name).join(', ')}</span>
                        </>
                      )
                    )}
                    {note.category_id && (
                      <div className="self-center text-xs font-thin">
                        {t('notes.category') + ': '} {categoriesNames[note.category_id]}
                      </div>
                    )}
                    {note.doctor_name && (
                      <div className="text-xs text-muted-foreground ">{t('notes.doctorNote') + ': '} {note.doctor_name} - {formatDate(note.medical_appoinment_date, false)} - {format(new Date(note.medical_appoinment_date), 'hh:mm a')}</div>
                    )}
                  </div>
                </div>
                <div className='flex flex-col items-end'>
                  <div className="ml-auto flex items-center justify-end gap-2 flex-wrap">
                    {((auth?.roles[0] && ['Super Administrator', 'Executive'].includes(auth?.roles[0].name))) && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            disabled={!note}
                            onClick={() =>
                              setCrudState(
                                NoteHelperService.defineState({
                                  isUpdating: crudState.showingStaffNotes ? false : true,
                                  showingStaffNotes: crudState.showingStaffNotes,
                                  isUpdatingStaffNote: crudState.showingStaffNotes ? true : false,
                                  itemId: note.id
                                })
                              )
                            }
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>{t('notes.edit')}</TooltipContent>
                      </Tooltip>
                    )}
                    {((auth?.roles[0] && ['Super Administrator', 'Executive'].includes(auth?.roles[0].name))) && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          {loadingRemoveNote ? (
                            <Loader className="mr-2 h-4 w-4 animate-spin" />
                          ) : (
                            <Button
                              variant="ghost"
                              size="icon"
                              disabled={!note}
                              onClick={() => {
                                setIsOpenConfirmDialog(true);
                              }}
                            >
                              <Trash className="h-4 w-4" />
                            </Button>
                          )}
                        </TooltipTrigger>
                        <TooltipContent>{t('notes.remove')}</TooltipContent>
                      </Tooltip>
                    )}
                    {note.visibility == 'private' && (
                      <Dialog
                        open={openDialogAddUser}
                        onOpenChange={() => setOpenDialogAddUser(openDialogAddUser ? false : true)}
                      >
                        <DialogTrigger>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant="ghost" size="icon" disabled={!note}>
                                <UserPlus className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>{t('notes.addUser')}</TooltipContent>
                          </Tooltip>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px]">
                          <DialogHeader>
                            <DialogTitle>{t('notes.addUserToThisNote')}</DialogTitle>
                          </DialogHeader>
                          <form onSubmit={handleSubmitAddUser}>
                            <div className="grid gap-4 py-4">
                              <Select
                                isMulti
                                name="colors"
                                className="basic-multi-select"
                                options={noteToOptions}
                                classNamePrefix="select"
                                placeholder="Users"
                                classNames={customClassNames}
                                onChange={handleSelectAddUser}
                              />
                            </div>
                            <DialogFooter>
                              <Button type="submit">{t('notes.Add')}</Button>
                            </DialogFooter>
                          </form>
                        </DialogContent>
                      </Dialog>
                    )}
                    {!crudState.showingStaffNotes && (
                      <Dialog
                        open={openDialogSharePrivateNote}
                        onOpenChange={() => setOpenDialogSharePrivateNote(openDialogSharePrivateNote ? false : true)}
                      >
                        <DialogTrigger>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant="ghost" size="icon" disabled={!note}>
                                <ReplyIcon className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>{t('notes.answerAsAPrivateNote')}</TooltipContent>
                          </Tooltip>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px]">
                          <DialogHeader>
                            <DialogTitle>{t('notes.answerAsAPrivateNote')}</DialogTitle>
                          </DialogHeader>
                          <form onSubmit={handleSubmitSharePrivateNote}>
                            <div className="grid gap-4 py-4">
                              <Select
                                name="colors"
                                value={selectedStaffAnswerPrivateNote}
                                className="basic-multi-select"
                                options={staffAnswerPrivateNote}
                                classNamePrefix="select"
                                placeholder="Answer To"
                                classNames={customClassNames}
                                onChange={handleSelectSharePrivateNote}
                              />
                            </div>
                            {(!formDataSharePrivateNote.user_id) && (
                              <span className='text-red-600'>{t('notes.staffAnswerPrivateNote')}</span>
                            )}
                            <Textarea className='mb-3 focus-visible:ring-primary' placeholder={t('notes.comment')} onChange={(e) => {
                              e.preventDefault();
                              setFormDataSharePrivateNote({
                                ...formDataSharePrivateNote,
                                content: e.target.value
                              });
                            }}></Textarea>
                            {(!formDataSharePrivateNote.content || formDataSharePrivateNote.content == '' || formDataSharePrivateNote.content.trim().length < 2) && (
                              <span className='text-red-600'>{t('notes.contentAnswerPrivateNoteLength')}</span>
                            )}
                            <DialogFooter>
                              <Button type="submit" className='mt-3'>{t('notes.buttonReplayDialog')}</Button>
                            </DialogFooter>
                          </form>
                        </DialogContent>
                      </Dialog>
                    )}
                    {!crudState.showingStaffNotes && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            disabled={!note}
                            onClick={() => {
                              setDisplayAnswer(true)
                              setDisplayChat(false);
                            }}
                          >
                            <ReplyAllIcon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>{t('notes.answerNote')}</TooltipContent>
                      </Tooltip>
                    )}
                    <Button className='border-gray border text-xs w-9 h-5 flex items-center justify-center border-zinc-600 rounded hover:cursor-pointer hover:bg-white-900 bg-white-900 text-dark' onClick={() => setNote('')}>
                      {t("notes.close")}
                    </Button>
                  </div>
                  <div className='text-muted-foreground text-xs mt-1'>
                    {formatDate(note.created_at, false)} - {format(new Date(note.format_created_at), 'hh:mm a')}
                  </div>
                </div>
              </div>
              <Separator className='bg-primary' />
              <div className="flex flex-col h-full py-2 text-sm">
                {!crudState.showingStaffNotes ? (
                  <ScrollArea type="always" className="flex-grow min-h-[150px] overflow-auto max-h-[250px]" style={{ scrollbarWidth: 'thin' }}>
                    <article dangerouslySetInnerHTML={{ __html: note.html_content }} />
                    {note.images && note.images.length > 0 && <ImgageGrid images={note.images} />}
                  </ScrollArea>
                ) : (
                  <ScrollArea type="always" className="flex-grow">
                    <article dangerouslySetInnerHTML={{ __html: note.html_content }} />
                    {note.images && note.images.length > 0 && <ImgageGrid images={note.images} />}
                  </ScrollArea>
                )}
              </div>
              {!crudState.showingStaffNotes && (
                <>
                  <Separator className='bg-primary' />
                  <div className='grid grid-cols-12 pt-2'>
                    <div className='max-[500px]:col-span-12 col-span-8 flex flex-wrap items-center'>
                      <Button className='text-xs h-5 shadow-none pr-2 pl-0 flex items-center justify-center text-zinc-600 rounded hover:cursor-pointer hover:bg-white-900 bg-white-900 text-dark'>
                        <Eye className="h-4 w-4 mr-2" />
                        {t("notes.whoRead")}
                      </Button>
                      {note.readers.filter(it => it.status != 'unread').slice(0, 8).map((item) => (
                        <Tooltip key={item.id}>
                          <TooltipTrigger asChild>
                            <Button key={item.id} className="text-xs my-1 py-0 px-1 bg-primary/10 text-primary shadow ml-2 h-5" variant={'secondary'}>
                              {staffNames[item.user_id]}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent className='bg-white border-t-4 border-t-primary/80 text-dark shadow'>
                            <span className='font-semibold mr-1'>{t('notes.readOn')}:</span>
                            <span className='font-regular'>{formatDate(item.format_updated_at, false)} - {format(new Date(item.format_updated_at), 'hh:mm a')}</span>
                          </TooltipContent>
                        </Tooltip>
                      ))}
                      {note.readers.filter(it => it.status != 'unread').length - 8 > 0 && (
                        <Popover>
                          <PopoverTrigger>
                            <Badge className="text-xs my-1 p-1 bg-primary/10 text-primary shadow ml-2" variant={'secondary'}>
                              {note.readers.filter(it => it.status != 'unread').length - 8}+
                            </Badge>
                          </PopoverTrigger>
                          <PopoverContent>
                            <ScrollArea className="h-[150px]">
                              <ul>
                                {note?.readers.map((item: any) => (
                                  <li key={item.id} className="mb-1">
                                    {staffNames[item.user_id]} - {formatDate(item.format_updated_at, false)} - {format(new Date(item.format_updated_at), 'hh:mm a')}
                                  </li>
                                ))}
                              </ul>
                            </ScrollArea>
                          </PopoverContent>
                        </Popover>
                      )}
                    </div>
                    <div className='max-[500px]:col-span-12 col-span-4 flex items-center justify-end'>
                      <Button onClick={() => {
                        setDisplayChat(!displayChat);
                        setDisplayAnswer(false);
                      }} className={'shadow-none px-2 flex items-center justify-center text-zinc-500 rounded hover:cursor-pointer ' + (!displayChat ? 'text-white' : 'hover:bg-primary/10 bg-white-900')}>
                        <MessageSquareText className="h-5 w-5 mr-2" />
                        {note.answers.length} {t("notes.comments")}
                        {!displayChat ? <ChevronDown className="h-6 w-6 ml-2" /> : <ChevronUp className="h-6 w-6 ml-2" />}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
        {!crudState.showingStaffNotes && <Chat note={note} displayChat={displayChat} displayAnswer={displayAnswer} editAnswer={editAnswer} showAnswers={showAnswers} />}
        {isOpenConfirmDialog && (
          <ConfirmDialog setOptionModal={setOptionSelectedConfirmDialog} optionSelected={optionSelectedConfirmDialog} isOpen={isOpenConfirmDialog} message={t('notes.titleDeleteNote')} />
        )}
      </div>
    ) : (
      <Card className="col-span-12 md:col-span-7 border-t-4 border-t-primary/80">
        <CardContent className='h-full'>
          <div className="flex items-center justify-center h-full">
            <div className="text-center font-bold">{t('notes.noNoteSelected')}</div>
          </div>
        </CardContent>
      </Card>
    )
  );
}
