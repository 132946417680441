import { useNavigate, useParams } from 'react-router-dom';
import { EmarNavigation } from '@/modules/emar/presentation/components/Navigation';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { EmarMedicationsCard } from '@/modules/emar/presentation/components/EmarMedicationsCard';
import { useEffect } from 'react';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { getLocation } from '@/modules/locations/presentation/slices/locationSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';

export const EmarDetailPage = () => {
  const { locationId } = useParams();
  const { params, setParam } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const { location } = useSelector((state: RootState) => state.locations.location);

  useEffect(() => {
    if (!params.e) {
      setParam('e', 'routine');
    }
  }, [params.e, setParam]);

  useEffect(() => {
    if (!location?.name && params.l) {
      dispatch(getLocation(params.l));
    }
  }, [dispatch, params.l, location?.name]);

  return (
    <div className="pt-4 flex flex-col gap-4">
      <LocationSelector quickView={true} />
      <EmarNavigation emar={params?.e || 'routine'} />
      <ResidentSelector
        locationId={locationId || 'all-locations'}
        residentId={params?.r || 'all-residents'}
        setLocationParams={false}
      />
      <EmarMedicationsCard />
    </div>
  );
};
