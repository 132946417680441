import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { CustomFieldsForm } from './CustomFieldsForm';
import { useUpdateCustomField } from '@/modules/settings/infrastructure/hooks/use-update-custom-field';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';

import * as residentsConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';

export const UpdateCustomFieldsDialog = () => {
  const { isOpen, onClose, name } = useUpdateCustomField();
  const { configurations, status } = useSelector((state: RootState) => state.residents.configurations);

  const dispatch = useDispatch<AppDispatch>();

  const handleUpdate = async (values: any) => {
    const updatedFields = configurations.custom_form_fields.map((field: any) => {
      if (field.name === name) {
        return {
          ...values,
          order: field.order
        };
      }
      return field;
    });

    const response = await dispatch(
      residentsConfigurationsActions.postResidentsConfigurations({
        custom_form_fields: updatedFields
      })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      onClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Update Custom Field</DialogTitle>
          </DialogHeader>
          <CustomFieldsForm
            name={name}
            onSubmit={handleUpdate}
            disabled={status === 'loading'}
            defaultValues={configurations?.custom_form_fields?.find((field: any) => field.name === name)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
