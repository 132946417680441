import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import {
  Carrier,
  useCarriersAndFinancialInformationStore
} from '@/modules/residents/domain/stores/carriers-financial-information/use-carrier-financial-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CarrierCard } from './components/CarrierCard';
import { EmptyIcon } from '../EmptyIcon';

export const CarriersInformationForm = () => {
  const { resident }: { resident: Resident | null; status: string } = useSelector(
    (state: RootState) => state.residents.resident
  );

  const { getCarriers, createCarrier, deleteCarrier, updateCarrier, status } =
    useCarriersAndFinancialInformationStore();

  const [carriersInformation, setCarriersInformation] = useState<any[]>([]);

  useEffect(() => {
    if (resident) {
      const fetchCarriers = async () => {
        try {
          const { data } = await getCarriers(resident.id);
          setCarriersInformation(data || []);
        } catch (error) {
          toast({
            description: 'Error fetching carriers information',
            variant: 'destructive'
          });
        }
      };

      fetchCarriers();
    }
  }, [resident, getCarriers]);

  const addCarrier = useCallback(() => {
    setCarriersInformation((prev) => [
      {
        id: null,
        carrier: '',
        carrier_number: '',
        type: ''
      },
      ...prev
    ]);
  }, []);

  const createOrUpdateCarrier = useCallback(
    async ({ id, ...rest }: Carrier) => {
      const payload = {
        ...rest,
        resident_id: resident?.id
      };

      try {
        let response;

        if (id) {
          response = await updateCarrier({ id, ...payload });
          toast({
            description: 'Carrier information has been updated successfully'
          });
        } else {
          response = await createCarrier(payload);
          toast({
            description: 'Carrier information has been saved successfully'
          });
        }

        setCarriersInformation((prev) =>
          prev.map((item) => (item.id === id || item.id === null ? { ...item, id: response.id || id, ...rest } : item))
        );
      } catch (error) {
        toast({
          description: 'Error saving carrier information',
          variant: 'destructive'
        });
      }
    },
    [createCarrier, updateCarrier, resident]
  );

  const removeCarrier = useCallback(
    async (id: string | null) => {
      if (id !== null) {
        try {
          await deleteCarrier({ id });

          setCarriersInformation((prev) => prev.filter((item) => item.id !== id));

          toast({
            description: 'Carrier information has been removed successfully'
          });
        } catch (error) {
          toast({
            description: 'Error removing carrier information',
            variant: 'destructive'
          });
        }
      } else {
        setCarriersInformation((prev) => prev.filter((item) => item.id !== id));
      }
    },
    [deleteCarrier]
  );

  return (
    <div>
      <CustomAccordion
        titleComponent={<h1 className="text-xl font-bold">Insurance Information</h1>}
        openOption={true}
        defaultOpen={true}
      >
        <div className="w-full flex flex-col gap-4">
          <div className="w-full flex items-center justify-between">
            <p>Add any insurance information that the resident may have</p>
            <Button className="flex gap-2" variant="default" onClick={addCarrier}>
              <CircleFadingPlus className="h-4 w-4" />
              New Insurance
            </Button>
          </div>

          {carriersInformation.length === 0 && (
            <div className="w-full flex flex-col items-center py-12 gap-4">
              <EmptyIcon className="h-auto w-[8vw]" />
              <p>There are no insurance information for this resident.</p>
            </div>
          )}

          {carriersInformation?.map((carrier, index) => (
            <CarrierCard
              key={carrier.id || index}
              defaultValues={carrier}
              onSubmit={createOrUpdateCarrier}
              onRemove={removeCarrier}
              disabled={status === 'loading'}
            />
          ))}

          <Separator />

          <p>
            Total de Seguros: <span className="font-bold">{carriersInformation.length}</span>
          </p>
        </div>
      </CustomAccordion>
    </div>
  );
};
