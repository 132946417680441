import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useState, useEffect } from 'react';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { saveFolder, deleteFolder, updateFolder } from '../../slices/DocumentsFiles';
import { DocumentsCategoryI } from '../../infrastructure/interfaces/DocumentsFolder';


interface DocumentsFolderI {
    id: string;
    name: string;
    key: string;
    category: string;
    status: 'active' | 'inactive';
    admin_only: 'yes' | 'no';
}


interface DocumentsCategoryProps {

    dataDocumentsFile: (page: number, rowsPerPage: number, resetData: boolean) => void;
    rowsPerPage: number;
    action: 'edit' | 'delete' | '';
    openModal: boolean;
    closeModal: () => void;
    selectedRow: DocumentsFolderI | null;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    documentCategories: DocumentsCategoryI[];
}


export function DialogCreateOrUpdate({ openModal, closeModal, dataDocumentsFile, selectedRow, action,
    rowsPerPage, setCurrentPage, documentCategories
}: DocumentsCategoryProps) {

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        id: undefined,
        name: '',
        category: '',
        status: false,
        admin_only: false
    });
    const dispatch = useDispatch<AppDispatch>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (action === 'edit' && selectedRow) {
            setFormData({
                id: selectedRow.id,
                name: selectedRow.name || '',
                category: selectedRow.category || '',
                status: selectedRow.status === 'active' ? true : selectedRow.status === 'inactive' ? false : true,
                admin_only: selectedRow.admin_only === 'yes' ? true : selectedRow.admin_only === 'no' ? false : true,
            });
        } else {
            setFormData({
                id: undefined,
                name: '',
                category: '',
                status: false,
                admin_only: false
            });
        }
    }, [selectedRow, action]);

    useEffect(() => {
        if (error) {
            setError('');
        }
    }, [closeModal]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleCategoryChange = (value: string) => {
        setFormData(prevState => ({
            ...prevState,
            category: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsSubmitting(true);

        if (formData.name.trim().length < 3) {
            setError('threeCharacters');
            setIsSubmitting(false);
            return;
        }

        const regex = /^[a-zA-Z0-9'_\- ]*$/;
        if (!regex.test(formData.name.trim())) {
            setError('characterValids');
            setIsSubmitting(false);
            return;
        }

        if (!formData.category) {
            setError('category');
            setIsSubmitting(false);
            return;
        }

        const payload = {
            id: action ? formData.id : undefined,
            key: formData.name.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase(),
            category: formData.category,
            name: formData.name.trim(),
            status: formData.status ? ('active' as const) : ('inactive' as const),
            admin_only: formData.admin_only ? ('yes' as const) : ('no' as const),
        };


        try {
            let response;
            if (action === 'edit') {

                response = await dispatch(updateFolder(payload))

            } else {
                response = await dispatch(saveFolder(payload))
            }

            if (String(response.payload).includes('The key already exists for this category')) {
                setError('name');
                return;
            }

            setCurrentPage(1);
            dataDocumentsFile(1, rowsPerPage, true);
            closeModal();
            setFormData({
                id: undefined,
                name: '',
                category: '',
                status: false,
                admin_only: false
            });

        } catch (error: any) {


            console.error('Error saving folder:', error);
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            await dispatch(deleteFolder(selectedRow!.id))
            setCurrentPage(1);
            dataDocumentsFile(1, rowsPerPage, true);
            closeModal();
            setIsSubmitting(false);
        } catch (error) {
            console.log(error)
        }
    }

    return (

        <CustomDialog
            newDialog={true}
            isOpen={openModal}
            title={
                action === 'edit'
                    ? t('notes.edit')
                    : action === 'delete'
                        ? t('trackers.Delete')
                        : t('medications.customCombobox.create')
            }
            onClose={closeModal}
        >
            <div>
                {action === 'delete' ? (
                    <div>
                        <div className='flex justify-center'>
                            <h2>{t('trackers.sureDelete')}</h2>
                        </div>

                        <div className="w-full flex justify-end mt-5">
                            <Button onClick={handleDelete} disabled={isSubmitting} className="bg-red-600 text-white px-4 py-2 rounded">
                                {t('trackers.Delete')}
                            </Button>
                            <Button onClick={closeModal} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                                {t('calendar.cancel')}
                            </Button>
                        </div>
                    </div>
                ) :
                    <form onSubmit={handleSubmit}>

                        <div className="grid gap-4">
                            <Label htmlFor="label" className="control-label mb-3 mt-4">
                                {t('trackers.customtype.Name')}
                            </Label>
                            <div className="input-group">
                                <Input
                                    className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleInputChange}

                                />
                            </div>
                            {error === 'name' && <p style={{ color: 'red' }}>{t("settings.nameExists")}</p>}
                            {error === 'characterValids' && <p style={{ color: 'red' }}>{t("settings.charactersValids")}</p>}
                            {error === 'threeCharacters' && <p style={{ color: 'red' }}>{t("settings.validThreeName")}</p>}
                        </div>
                        
                        <div className="grid gap-4">
                            <Label htmlFor="label" className="control-label mb-3 mt-4">
                                {t('notes.category')}
                            </Label>
                            <div className="input-group">
                                <Select
                                    value={formData.category}
                                    onValueChange={handleCategoryChange}
                                >
                                    <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                        <SelectValue>
                                            {formData.category
                                                ? documentCategories.find((cat) => cat.value === formData.category)?.label
                                                : 'Select a category'}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {documentCategories.map((category) => (
                                            <SelectItem key={category.value} value={category.value}>
                                                {category.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            {error === 'category' && <p style={{ color: 'red' }}>{t('notes.categoryNotFound')}</p>}
                        </div>

                        <div className="grid gap-4">
                            <Label htmlFor="name" className="control-label mb-3 mt-4">
                                {t('medications.filterTable.status')}

                            </Label>
                            <div className="input-group">
                                <Switch
                                    checked={formData.status}
                                    onCheckedChange={(checked: boolean) =>
                                        setFormData((prev) => ({ ...prev, status: checked }))
                                    }
                                />

                            </div>
                        </div>

                        <div className="grid gap-4">
                            <Label htmlFor="name" className="control-label mb-3 mt-4">
                                {t('settings.adminOnly')}

                            </Label>
                            <div className="input-group">
                                <Switch
                                    checked={formData.admin_only}
                                    onCheckedChange={(checked: boolean) =>
                                        setFormData((prev) => ({ ...prev, admin_only: checked }))
                                    }
                                />

                            </div>
                        </div>

                        <div className="w-full flex justify-end mt-4">
                            <Button
                                type="submit"
                                className="bg-primary text-white px-4 py-2 rounded"
                                disabled={isSubmitting}
                            >
                                {t('trackers.Save')}
                            </Button>
                        </div>
                    </form>}
            </div>
        </CustomDialog>


    )

}