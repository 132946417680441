import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { DeviceInfoI, UpdateDeviceDataI } from '../../domain/device.domain';

const SecurityService = {
  requestDeviceRegistration: async (deviceInfo: DeviceInfoI, locationId: string, name: string, description: string) => {
    let url = '/device/request-register';
    try {
      const response = await axios.post(url, {
        name: name,
        description: description,
        browser: deviceInfo.browser,
        agent: deviceInfo.agent,
        device_type: deviceInfo.device_type,
        finger_print: deviceInfo.finger_print,
        operative_system: deviceInfo.operative_system,
        location_id: locationId,
        full_data: deviceInfo.full_data
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error requesting device registration ${error}`);
    }
  },
  getDevices: async (
    locationId: string,
    status: string | null = null,
    type: string | null = null,
    name: string | null = null
  ) => {
    let url = '/device/list';
    try {
      const response = await axios.post(url, {
        location_id: locationId,
        status: status,
        type: type,
        name: name
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error getting devices ${error}`);
    }
  },
  getDevice: async (fingerPrint: string) => {
    let url = `/device/finger-print/${fingerPrint}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting the device ${error}`);
    }
  },
  getDeviceById: async (deviceId: string) => {
    let url = `/device/${deviceId}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting the device ${error}`);
    }
  },
  updateDeviceStatus: async (deviceId: string, newStatus: string) => {
    let url = '/device/update-status';
    try {
      const response = await axios.post(url, {
        device_id: deviceId,
        new_status: newStatus
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error updating device ${error}`);
    }
  },
  updateDevice: async (params: UpdateDeviceDataI) => {
    let url = '/device/update';
    try {
      const response = await axios.post(url, params);
      return response.data;
    } catch (error) {
      throw new Error(`Error updating device ${error}`);
    }
  },
  updateLocationRestrictByDevice: async (locationId: string, status: boolean) => {
    let url = '/device/update-restrict-by-device';
    try {
      const response = await axios.post(url, {
        location_id: locationId,
        status: status
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error updating location ${error}`);
    }
  },
  registerDevice: async (deviceInfo: DeviceInfoI, locationIds: string[], name: string) => {
    let url = '/device/register';
    try {
      const response = await axios.post(url, {
        browser: deviceInfo.browser,
        agent: deviceInfo.agent,
        device_type: deviceInfo.device_type,
        finger_print: deviceInfo.finger_print,
        operative_system: deviceInfo.operative_system,
        locations: locationIds,
        name: name,
        full_data: deviceInfo.full_data
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error registering device ${error}`);
    }
  }
};

export default SecurityService;
