import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { format, parseISO } from 'date-fns';
import { Card, CardContent } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';

export const StaffInformation = () => {
  const { t } = useTranslation();
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const StaffAge = staffMember?.dob ? new Date().getFullYear() - new Date(staffMember?.dob).getFullYear() : 0;

  return (
    <Card className="w-full py-4 p-7 xl:h-[228px] border-t-4 border-t-primary/80 dark:bg-zinc-95">
      <CardContent className="grid grid-cols-1 md:grid-cols-12 gap-4 p-0">
        <div className="flex items-center justify-start col-span-full lg:col-span-4 md:col-span-full
          lg:items-center lg:justify-center">
            <Avatar className="w-[168px] h-[168px] rounded-full">
              <AvatarImage
                src={staffMember?.profile_url}
                alt={`${staffMember?.first_name} ${staffMember?.last_name}`}
                className="h-full w-full object-cover rounded-full"
              />
              <AvatarFallback className={cn("bg-secondary dark:bg-zinc-900 dark:text-white text-sm rounded-full", "bg-secondary text-sm")}>
              {(staffMember?.first_name?.[0] ?? '') + (staffMember?.last_name?.[0] ?? '')}
              </AvatarFallback>
            </Avatar>
        </div>
        <div className="col-span-full lg:col-span-8 flex flex-col lg:items-start items-start lg:justify-center
          lg:px-2 px-0">
          <h1 className="text-primary text-2xl font-bold leading-normal mb-4">
            {staffMember?.first_name} {staffMember?.middle_name} {staffMember?.last_name}{' '}
            {staffMember?.preferred_name}
          </h1>
          <div className='text-base text-zinc-600 space-y-1 dark:text-white'>
            <p className="flex fle-wrap">
              <span className="font-semibold mr-2">{t('general.dateOfBirth')}:</span>
              {staffMember?.dob
                ? `${format(parseISO(staffMember?.dob), 'MM/dd/yyyy')} (${StaffAge} years old)`
                : t('general.not_specified')}
            </p>
            <p className="flex flex-wrap">
              <span className="font-semibold mr-2">{t('staff.profile.hireDate')}:</span>
              {staffMember?.hire_date
                ? `${format(parseISO(staffMember?.hire_date), 'MM/dd/yyyy')}`
                : t('general.not_specified')
              }
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};