import { Button } from '@/common/presentation/components/ui/button';
import * as locationActions from '@/modules/locations/presentation/slices/locationSlice';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch, RootState } from '@/store/store';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmarTabs } from './EmarTabs';
import useRouteParams from '@/common/hooks/RouteParamsHook';

interface Props {
  emar: string;
}

export const EmarNavigation = ({ emar }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { location: locationSelector } = useSelector((state: RootState) => state.locations.location);
  const { params } = useRouteParams();

  const onBack = () => {
    navigate('/emar');
    dispatch(locationActions.clearLocation());
    dispatch(residentActions.resetResident());
  };

  return (
    <>
      <div className="w-full flex gap-4">
        <div className="font-semibold text-2xl">
          {t('emar.navigation.label', { emar: emar === 'routine' ? 'Routine' : 'PRN' })}
          <span className="font-bold">{params.l === 'all-locations' ? 'All Locations' : locationSelector?.name}</span>
        </div>

        <EmarTabs />
      </div>
    </>
  );
};
