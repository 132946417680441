import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { RootState } from '@/store/store';
import Title from '@/common/presentation/components/Title/Title';
import { Input } from '@/common/presentation/components/ui/input';
import useFilteredTrackers from '../hook/useFilteredTrackers';
import { MainMenu } from "@/common/domain/MainMenu";
import MenuCard from "@/common/presentation/components/Card/MenuCard";

function TrackerPage() {
  const { t } = useTranslation();
  const { configurations, isLoading } = useSelector(
    (state: RootState) => state.configurations
  );
  const menus: MainMenu[] = configurations?.menus || [];

  const subMenuTrackers: MainMenu[] = menus
  .filter((menu) => menu.path === "/tracker")
      .flatMap((menu) => menu.submenus || []);

  const { search, handleSearch, filteredTrackers } = useFilteredTrackers(subMenuTrackers);

  /*
    Si no salen los iconos correr esta migración:
    UpdateMenuIconsSeeder
  */

  return (
    <>
      <div className='pb-6 px-4 md:px-8 flex flex-col h-[90vh]'>
        <div className='flex justify-between mt-10 mb-1 md:mb-0'>
          <Title className='text-primary'>
            {t('menu.tracker.name')}
          </Title>
        </div>
        <div className='flex flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4 text-left
        md:justify-between md:items-center text-zinc-600 text-sm font-medium leading-5 mb-6'>
          <p className='md:mb-0 dark:text-white'>
            {t('trackers.searchTitle')}
          </p>
          <Input
            type='text'
            className='w-full md:w-80 h-10 py-2 px-4 dark:text-white'
            placeholder={t('common.search')}
            value={search}
            onChange={handleSearch}
          />
        </div>
        <div className="flex flex-col">
          <MenuCard
            submenu={filteredTrackers}
            translationContext="menu.tracker.submenus"
            isLoading={isLoading}
            />
        </div>
      </div>
    </>
  );
}

export default TrackerPage