import { TFunction } from "i18next";

type TranslationObject = {
  name?: string;
  [key: string]: any;
};

// Normalizes a key by removing spaces and converting it to lowercase.
export const normalizeKey = (key: string): string => {
  return key.replace(/[\s-]/g, "").toLowerCase();
};


// Gets the translation of a key, handling cases where the key points to a string or an object.
export const getMenuTranslation = (key: string, t: TFunction): string => {
  const translation: TranslationObject = t(`menu.${key}`, { returnObjects: true });

  if (typeof translation === "string") {
    return translation;
  }
  
  if (typeof translation === "object" && translation.name) {
    return translation.name;
  }

  return key;
};
