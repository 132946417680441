import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { AlertCreateFormI, AlertDtoListI, AlertUpdateFormI } from '../../domain/alerts.domain';

const AlertService = {
  getCategories: async () => {
    let url = `/custom-alerts/categories`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error fetching alert categories';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  createAlert: async (formData: AlertCreateFormI) => {
    let url = `/custom-alerts`;
    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error creating alert';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getAlertList: async (data: AlertDtoListI) => {
    let url = '/custom-alerts/list';
    try {
      const response = await axios.get(url, { params: data });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error fetching alerts';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  updateAlert: async (data: AlertUpdateFormI) => {
    let url = `/custom-alerts/${data.id}`;
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error updating alert';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  markAsDone: async (id: string, relatedId: string) => {
    let url = `/custom-alerts/mark-as-done/${id}`;
    try {
      const response = await axios.post(url, { related_id: relatedId });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error marking alert as done';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
};

export default AlertService;
