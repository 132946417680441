import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/common/presentation/components/ui/accordion"
import { EnabledFields } from "./EnabledFields";
import { SummaryReport } from "./Resume/SummaryReport";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { t } from "i18next";

export const SheetField = () => {

  return <>
    <Tabs defaultValue="enabledFields" className="h-full flex flex-col">
      <div className="md:flex flex-col-reverse md:flex-row justify-between gap-4 mb-6 items-center md:items-left"> 
        <p className="text-gray text-[12px]">
          {t('settings.residents.sheetFieldsDescription')}
        </p>
        <TabsList className="bg-transparent">
          <TabsTrigger 
            value="enabledFields"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            
          </TabsTrigger>
          <TabsTrigger 
            value="summary"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            {t('common.summary')}
          </TabsTrigger>
        </TabsList>
      </div>

      <TabsContent 
        value="enabledFields" 
        className="flex-1 h-full mt-0 border-none p-0"
      >
        <div className="h-[40vh] md:h-[350px] w-full relative">
          <ScrollArea className="h-full w-full absolute inset-0">
            <EnabledFields />
          </ScrollArea>
        </div>
      </TabsContent>
      
      <TabsContent 
        value="summary"
        className="flex-1 h-full mt-0 border-none p-0"
      >
        <SummaryReport />
      </TabsContent>
    </Tabs>
  </>
}

interface IAccordion {
  title: string,
  content: React.ReactNode;
}

export const CustomAccordion: React.FC<IAccordion> = ({ title, content }) => {
  return <>
    <Accordion type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger className="w-4">
          {title}
        </AccordionTrigger>
        <AccordionContent>
          {content}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  </>;
}