import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import dateFormats from '../dateFormats';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';

export const useDateHelpers = () => {
    const { t, i18n } = useTranslation();
    const { configurations } = useConfigurationsProvider();

    const formatDate = (
        dateTime: string,
        hours: boolean = true,
        onlyHours: boolean = false,
        useTimezone: boolean = true
    ): string => {
        const currentLang = i18n.language;
    
        // Método para parsear la fecha con formato específico
        const parseDateTime = (dt: string) => {
            const format = hours
                ? dateFormats[currentLang]?.dateTimeFormat.replace('dd', 'DD').replace('yyyy', 'YYYY')
                : dateFormats[currentLang]?.dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY');
    
            // Intentar parsear con el formato específico de la configuración regional
            const parsedMoment = moment(dt, format, true);
            
            // Si el parsing con formato específico falla, intentar otros métodos
            return parsedMoment.isValid() 
                ? parsedMoment 
                : moment(new Date(dt));
        };
    
        const laTime = useTimezone
            ? parseDateTime(dateTime).tz(configurations.timezone)
            : parseDateTime(dateTime);
    
        if (onlyHours) {
            return laTime.format('hh:mm a');
        }
    
        const formatString = hours
            ? dateFormats[currentLang]?.dateTimeFormat
                .replace('dd', 'DD')
                .replace('yyyy', 'YYYY')
            : dateFormats[currentLang]?.dateFormat
                .replace('dd', 'DD')
                .replace('yyyy', 'YYYY');
    
        return laTime.format(formatString);
    };

    return { formatDate, t };
};