import { Pill } from 'lucide-react';
import { AlertCard } from './alert-card';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from './skeleton';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import AlertItemCard from '@/common/presentation/components/AlertItemCrad.tsx/AlertItemCard';
import moment from 'moment';
import { useMemo } from 'react';

export const MedicationAlerts = () => {
  const alerts = useSelector((state: RootState) => state.dashboard.medicationAlerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents ?? []);
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  const currentDate = useMemo(() => moment().format('YYYY-MM-DD'), []);

  return (
    <>
      <AlertCard title={t('dashboard.medicationAlerts')} alerts={alerts.data ?? []} icon={Pill} custom={true}>
        {alerts.status === 'loading' ? (
          <AlertsSkeleton />
        ) : !alerts.data || alerts.data.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {alerts?.data?.map((alert, index) => (
              <div key={`div_custom_${index}`}>
                <div className="flex flex-col gap-3">
                  <AlertItemCard
                    key={`item_${index}`}
                    names={UserHelper.getResidentName(alert.resident_id, residents) ?? ''}
                    title={t(`dashboard.${alert.message}`, {
                      medicationName:
                        alert.medication_info.name.charAt(0).toUpperCase() + alert.medication_info.name.slice(1),
                      expirationDate: alert.expiration_date ? formatDate(alert.expiration_date ?? '', false) : '',
                      minimunQuantity: alert.medication_info.minimum_quantity,
                      medicationType: alert.medication_info.type
                    })}
                    date={currentDate}
                    image={UserHelper.getResidentInformation(alert.resident_id, residents)?.image_url ?? ''}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </AlertCard>
    </>
  );
};
