import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/common/presentation/components/ui/accordion";
import { useDocumentsDialog } from '@/modules/residents/infrastructure/hooks/documents/useDocumentsDialog';
import { generateMenuItems } from '@/modules/residents/infrastructure/utils/generateMenuItems';
import { QuickMenu } from '@/modules/residents/domain/models/QuickMenu';
import NavItem from './NavItem';

interface QuickLinksProps {
  className?: string;
  residentId: string;
  tabSelected?: string | null;
  setTabSelected: (tab: string) => void;
};

const QuickLinks: React.FC<QuickLinksProps> = ({
  className,
  residentId,
  setTabSelected,
  tabSelected
}) => {
  const { t } = useTranslation();
  const { onOpen } = useDocumentsDialog();
  const navigate = useNavigate();
  const [isOpenBedroom, setIsOpenBedroom] = useState<boolean>(false);

  const handleOpenBedroom = () =>setIsOpenBedroom(true);
  const handleCloseBedroom = () => setIsOpenBedroom(false);

  const menuContext:QuickMenu = {
    t,
    navigate,
    onOpen,
    handleOpenBedroom,
    handleCloseBedroom,
    tabSelected,
    setTabSelected,
    residentId,
    isOpenBedroom
  }

  const menuItems = useMemo(() => generateMenuItems(menuContext), [menuContext]);

  return (
    <>
      <div className={cn(className, 'flex flex-row justify-center lg:justify-start')}>
        <Card
          className={'w-full p-4 lg:pt-5 border-t-4 border-t-primary/80 dark:bg-zinc-950 col-span-4 h-full'}
        >
          <CardContent className='p-0 flex-1'>
            <div className='block lg:hidden'>
              <Accordion type="single" collapsible>
                <AccordionItem value="quickLinks" className='border-b-0'>
                  <AccordionTrigger className='p-0 no-underline focus:outline-none focus:ring-0 hover:no-underline'>
                    <CardTitle className='text-primary leading-normal text-base font-semibold'>
                      { t('general.quickLinks')}
                    </CardTitle>
                  </AccordionTrigger>
                  <AccordionContent className="pb-0 mt-4">
                    {menuItems.map((item, index) => (
                      <div key={index} className="pb-2 last:pb-0">
                        <NavItem
                          label={item.label}
                          onClick={item.onClick}
                        />
                          {item.content && <div className="mt-2">{item.content}</div>}
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
            <div className='hidden lg:block'>
              <CardHeader className='shrink-0 p-0'>
                    <CardTitle className='text-primary leading-normal text-base font-semibold'>
                      { t('general.quickLinks')}
                    </CardTitle>
              </CardHeader>
              <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto mt-4 max-h-[180px]">
                {menuItems.map((item, index) => (
                  <div key={index} className="pb-2 last:pb-0">
                    <NavItem
                      label={item.label}
                      onClick={item.onClick}
                    />
                      {item.content && <div className="mt-2">{item.content}</div>}
                  </div>
                ))}
                <ScrollBar orientation="vertical" />
              </ScrollArea>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default QuickLinks;