import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { RootState } from '@/store/store';
import { Eye, Trash } from 'lucide-react';
import { useSelector } from 'react-redux';

interface Props {
  document_id: string;
  form_id: string;
  url: string;
  data: Record<string, unknown>;
}

export const HistoryTableActions = ({ document_id, form_id, url, data }: Props) => {
  const { deleteForm, getFormHistory } = useFormsStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const handleDelete = async () => {
    try {
      const response = await deleteForm(data !== null ? form_id : document_id, data !== null ? 'form' : 'document');

      toast({
        description: 'Form deleted successfully!',
        variant: 'default'
      });

      if (response) {
        await getFormHistory(resident?.id, 'lic625_appraisal_needs_and_service_plan');
      }
    } catch (error) {
      toast({
        description: 'Error deleting form',
        variant: 'destructive'
      });
    }
  };

  const handleView = async () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="flex gap-2">
      <Button size={'icon'} variant={'outline'} onClick={handleView}>
        <Eye className="h-4 w-4" />
      </Button>
      <Button size={'icon'} variant={'outline'} onClick={handleDelete}>
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );
};
