import { t } from "i18next";
import TrackerVisitorsRenderActionsColumn from "./TrackerVisitorsRenderActionsColumn";
import { format } from "date-fns";

const TrackerVisitorsReportColumns = () => {
  const HygineTypeColumns = [
    {
        key: 'date',
        id: 'date',
        labelTranslationKey: 'common.date',
        render: (row) => format(new Date(row.date), "dd/MM/yyyy hh:mm a"),
        value: 'date',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'regardingTo',
        id: 'regardingTo',
        labelTranslationKey: 'visitors.regardingTo',
        value: 'regardingTo',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'visitor',
        id: 'visitor',
        labelTranslationKey: 'visitors.visitor',
        value: 'visitor',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'temperature',
        id: 'temperature',
        labelTranslationKey: 'visitors.temperature',
        value: 'temperature',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: TrackerVisitorsRenderActionsColumn,
        visible: true,
        static: true,
        width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(HygineTypeColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = HygineTypeColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default TrackerVisitorsReportColumns;
