import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { useEffect, useState } from "react";
import { ITrackerCleaningArea, ITrackerCleaningTask } from "./TrackerCleaningMockup";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { Checkbox } from "@/common/presentation/components/ui/checkbox";
import { format } from "date-fns";
import { Button } from "@/common/presentation/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { cleanAreaTask, getAreas, getDaySummaries, getTasksArea } from "../../slices/TrackerCleaningSlice";
import { RootState } from "@/store/store";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import TrackerCleaningService from "@/modules/tracker/infrastructure/services/TrackerCleaningService";
import { Search } from "lucide-react";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from "react-i18next";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import UtilService from "@/common/services/UtilService";

export function TrackerCleaningQuickTracker() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const configurations = useSelector((state: RootState) => state.configurations);
    const { toast } = useToast();
    const { t } = useTranslation();
    const [selectedArea, setArea] = useState<string | null>(null);
    const [selectedAllFacilities, setSelectedAllFacilities] = useState<string>("All Facilities");
    const { locationSelectedId } = useLocation();
    const [areas, setAreas] = useState<ITrackerCleaningArea[]>([]);
    const [tasks, setTasks] = useState<ITrackerCleaningTask[]>([]);
    const [dataSource, setDataSource] = useState<ITrackerCleaningTask[]>([]);
    const [percentage, setPercentage] = useState<number>(0);
    const [accentColor, setAccentColor] = useState("");

    useEffect(() => {
        fetchDaySummmaries();
        fetchAreas();
        fetchTask(null);

        return () => {
            dispatch(cleanAreaTask());
        };
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        setPercentage((tasks.filter(value => value.complete).length / tasks.length) * 100);
    }, [tasks]);

    useEffect(() => {
        if(trackerCleaning.activeAreas.length) setAreas(trackerCleaning.activeAreas);
    }, [trackerCleaning.activeAreas]);

    useEffect(() => {
        if(trackerCleaning.daySummaries && trackerCleaning.areaTasks) {
            let summariesNames: string[] = []; 

            if(trackerCleaning.daySummaries.length) {
                summariesNames = trackerCleaning.daySummaries.map(
                    (value) => {
                        if(value && value.data && value.data.task && value.data.task.name) return value.data.task.name;
                        else "";
                    }
                );
            }

            if(trackerCleaning.areaTasks.length) {
                const verifiedTasks = trackerCleaning.areaTasks.map(value => {
                    const newValue = Object.assign({}, value);
    
                    if(summariesNames.includes(newValue.name)) {
                        newValue.complete = true;
                        newValue.doneToday = true;
                    } else {
                        newValue.complete = false;
                        newValue.doneToday = false;
                    }
    
                    return newValue;
                });
                const sortedTasks = UtilService.sortObjectsAlphabetically(verifiedTasks, "name");
    
                setTasks(
                    sortedTasks
                );
    
                setDataSource(
                    sortedTasks
                );
            }
        }
    }, [trackerCleaning.daySummaries, trackerCleaning.areaTasks]);

    const fetchDaySummmaries = () => {
        dispatch(getDaySummaries(
            {
                location_id: locationSelectedId,
                day: format(new Date(), "d")
            }
        ));
    }

    const fetchTask = (area: string | null) => {
        if(locationSelectedId != "all-locations") {
            dispatch(getTasksArea(
                {
                    location_id: locationSelectedId,
                    area
                }
            ));
        }
    }

    const fetchAreas = () => {
        if(locationSelectedId != "all-locations") {
            dispatch(getAreas(
                {
                    location_id: locationSelectedId
                }
            ));
        }
    }

    const onSelectedAllFacilities = (event: string) => {
        setArea("");
        setSelectedAllFacilities(event);
        fetchTask(null);
    }

    const onSelectedTab = (event: string) => {
        setSelectedAllFacilities("");
        setArea(event);
        fetchTask(event);
    }

    const completeTask = (id: string, checked: boolean) => {
        const newTasks = dataSource.map(value => {
            const newValue = Object.assign({}, value);

            if(value.id === id) newValue.complete = checked;

            return newValue;
        });

        setTasks(newTasks);
        setDataSource(newTasks);
    }

    const saveQuickSummary = async () => {
        const toCreate = dataSource.filter(value => value.complete && !value.doneToday);

        if(toCreate.length) {
            const summariesPromise = toCreate.map((value) => {
                return TrackerCleaningService.createSummary({
                    "location_id": locationSelectedId,
                    "data": {
                        "date": new Date().toISOString(),
                        "task": value.id,
                        "detail": "",
                        "area": value.area
                    }
                })
            });
            
            await Promise.all(summariesPromise);

            fetchDaySummmaries();
        } else {
            toast(
                {
                    description: t("cleaning.form.quick.toast.taskSelectRequired")
                }
            );
        }
    }

    return (
        <div>
            {
                (
                    areas.length ? (
                        <div className="flex">
                            <Tabs defaultValue={selectedAllFacilities} value={selectedAllFacilities} onValueChange={(event) => { onSelectedAllFacilities(event); }} className="w-auto mb-3 mr-2">
                                <TabsList>
                                    <TabsTrigger value={"All Facilities"} className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        {t("cleaning.AllFacilities")}
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        
                            <Tabs defaultValue={selectedArea} value={selectedArea} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto mb-3">
                                <TabsList>
                                    {
                                        areas.map(value => 
                                            <TabsTrigger value={value.id} className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                {value.name}
                                            </TabsTrigger>
                                        )
                                    }
                                </TabsList>
                            </Tabs>
                        </div>
                    ) : null
                )
            }

            <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 grid-row-1" style={{ rowGap: "1rem", columnGap: "2rem", alignItems: "start" }}>
                <div className="w-100" style={
                    { 
                        display: "grid", 
                        gridTemplateRows: "1fr auto", 
                        gridTemplateColumns: "1fr", 
                        columnGap: "2rem", 
                        alignItems: "center"
                    }
                }>
                    <div style={
                        { 
                            overflowY: "scroll", 
                            height: "20rem" 
                        }
                    }>
                        {
                            dataSource.length ? (
                                <Table>
                                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                                            <TableHead className="text-white">
                                                { t("cleaning.form.quick.table.task") }
                                            </TableHead>
                                            <TableHead className="text-white">{format(new Date(), "d")}</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    
                                    <TableBody>
                                        {
                                            dataSource.length ? (
                                                dataSource.map(
                                                    element =>
                                                    <TableRow key={element.id}>
                                                        <TableCell>{UtilService.decodeHtmlEntities(element.name)}</TableCell>
                                                        <TableCell>
                                                            <Checkbox checked={element.complete} disabled={element.complete && element.doneToday} onCheckedChange={(checked: boolean) => completeTask(element.id, checked)} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            ): null
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className="flex flex-col w-100 justify-center align-center mt-5">
                                    <div className="flex justify-center items-center">
                                        <Search className="text-center w-5 mb-2" />
                                    </div>

                                    <h1 className="text-center">
                                        { t("cleaning.form.quick.table.notFound") }
                                    </h1>
                                </div>
                            )
                        }
                    </div>

                    {
                        dataSource.length ? (
                            <div className="flex justify-end">
                                <Button variant="outline" size="sm" className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end mt-3' onClick={saveQuickSummary}>
                                    <span>
                                        { t("cleaning.form.quick.submitButton") }
                                    </span>
                                </Button>
                            </div>
                        ) : null
                    }
                </div>

                <div>
                        {
                            dataSource.length ? (
                                <Table>
                                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                                            <TableHead className="text-white">
                                                { t("trackers.trackercleaning.progress") }
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    
                                    <TableBody className="flex align-center justify-center" style={{ padding: "2rem" }}>
                                            <div style={{ width: "13rem" }}>
                                                <CircularProgressbar 
                                                    className="w-1" 
                                                    value={percentage} 
                                                    text={Math.floor(percentage) + '%'}
                                                    styles={buildStyles({
                                                        strokeLinecap: 'butt',
                                                        textSize: '0.8rem',
                                                        textColor: accentColor,
                                                        trailColor: '#ecf0f1',
                                                        pathColor: accentColor
                                                    })}
                                                />
                                            </div>
                                    </TableBody>
                                </Table>
                            ) : null
                        }
                </div>
            </div>
        </div>
    );
}
