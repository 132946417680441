import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import renderActionsColumn from './renderActionsColumn';
import { useSearchParams } from 'react-router-dom';

const useColumnsResidentMedications = (statusFilter) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleResidentClick = (residentId) => {
    const currentLocation = searchParams.get('l') || 'all-locations';
    setSearchParams({ r: residentId, l: currentLocation });
  };

  const columnsResidentMedications = [
    {
      key: 'medication_name',
      id: 'medication_name',
      labelTranslationKey: 'medications.columnsResidentMedications.medication',
      value: 'medication_name',
      visible: true,
      sortable: true,
      static: true,
      width: '20%',
      render: (rowData) => rowData?.medication?.name ?? ''
    },
    {
      key: 'medication_form',
      id: 'medication_form',
      labelTranslationKey: 'medications.columnsResidentMedications.form-strength',
      value: 'medication_form',
      visible: true,
      sortable: true,
      width: '5%',
      render: (rowData) => rowData?.medication_form ?? ''
    },
    {
      key: 'resident_full_name',
      id: 'resident_full_name',
      labelTranslationKey: 'medications.columnsResidentMedications.resident',
      value: 'resident_full_name',
      link: true,
      visible: true,
      sortable: true,
      width: '5%',
      render: (rowData) => {
        if (!rowData || !rowData.resident_id) return '';
        return (
          <span
            className="cursor-pointer text-primary hover:underline"
            onClick={() => handleResidentClick(rowData.resident_id)}
          >
            {rowData.resident_full_name ?? '-'}
          </span>
        );
      }
    },
    {
      key: 'time',
      id: 'time',
      labelTranslationKey: 'medications.columnsResidentMedications.time',
      value: 'time',
      visible: true,
      sortable: true,
      width: '10%',
      render: (rowData) => rowData?.time ?? ''
    },
    {
      key: 'rx',
      id: 'rx',
      labelTranslationKey: 'medications.columnsResidentMedications.rx',
      value: 'rx',
      visible: true,
      sortable: true,
      width: '10%',
      render: (rowData) => rowData?.rx ?? ''
    },
    {
      key: 'type_medication_name',
      id: 'type_medication_name',
      labelTranslationKey: 'medications.columnsResidentMedications.type',
      value: 'type_medication_name',
      visible: true,
      static: true,
      sortable: true,
      width: '5%',
      render: (rowData) => rowData?.type_medication_name ?? ''
    },
    {
      key: 'current_quantity',
      id: 'current_quantity',
      labelTranslationKey: 'medications.columnsResidentMedications.quantity',
      value: 'current_quantity',
      visible: true,
      sortable: true,
      width: '2%',
      render: (rowData) => rowData?.current_quantity ?? ''
    },
    {
      key: 'filleDate',
      id: 'filleDate',
      labelTranslationKey: 'medications.columnsResidentMedications.filleDate',
      value: 'filleDate',
      visible: false,
      sortable: true,
      width: '10%',
      render: (rowData) => rowData?.filleDate ?? ''
    }
  ];

  if (statusFilter) {
    const actionsIndex = columnsResidentMedications.findIndex((column) => column.key === 'actions');
    columnsResidentMedications.splice(actionsIndex, 0, {
      key: 'status',
      id: 'status',
      labelTranslationKey: 'medications.columnsResidentMedications.status',
      value: 'status',
      visible: true,
      sortable: true,
      width: '5%',
      render: (rowData) => {
        if (rowData?.status === undefined) return '';
        return rowData.status === true
          ? t('medications.columnsResidentMedications.active')
          : t('medications.columnsResidentMedications.inactive');
      }
    });
  }

  columnsResidentMedications.push({
    key: 'actions',
    labelTranslationKey: 'medications.columnsResidentMedications.actions',
    render: (rowData) => {
      if (!rowData) return null;
      return renderActionsColumn(rowData);
    },
    visible: true,
    static: true,
    width: '5%'
  });

  const columnsWithTranslation = columnsResidentMedications.map((column) => ({
    ...column,
    label: t(column.labelTranslationKey || column.key)
  }));

  return columnsWithTranslation;
};

export default useColumnsResidentMedications;
