import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '@/store/store';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { DiagnosisCard } from '@/modules/residents/presentation/components/ProfileInformation/DiagnosisCard';
import { FoodNutrition } from '@/modules/residents/presentation/components/ProfileInformation/FoodNutrition';
import { ResponsiblePartyContact } from '@/modules/residents/presentation/components/ProfileInformation/ResponsiblePartyContact';
import { AlertsCard } from '@/modules/residents/presentation/components/ProfileInformation/AlertsCard';
import ResidentAlerts from '../components/Alerts/ResidentAlerts';
import QuickLinks from '../components/ProfileInformation/QuickLinks/QuickLinks';
import { ResidentNavigationMenu } from '../components/ProfileInformation/ResidentNavigationMenu';
import { ResidentInformation } from '../components/ProfileInformation/ResidentInformation';
import { PrimaryPhysicianContact } from '../components/ProfileInformation/PrimaryPhysicianContact';


export const DashboardProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [tabSelected, setTabSelected] = useState<string | null>(null);

  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const residentId = new URLSearchParams(location.search).get('r');

  useEffect(() => {
    if (searchParams.get('r')) {
      dispatch(residentActions.getResidentById(searchParams.get('r')!));
    }
  }, [searchParams.get('r')]);

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  const contactDetails = [
    {
      responsiblePartyName: 'Robert Adams',
      address: '555 Main St , Seal Beach , CA , 90740',
      email: 'main@main.com',
      phone: '(321) 911-3264'
    },
    {
      primaryPhysicianName: 'Doctor Feelgood',
      address: '123 Main ST, Downey , Ca, 90876',
      email: 'test@mail.com',
      phone: '(255) 555-5554'
    }
  ];

  return (
    <>
      <div className="flex justify-center pb-8">
        <div className="w-full flex flex-col">
          {(() => {
            switch (tabSelected) {
              case 'alerts':
                return (
                  <>
                    <BackNavigationBar
                      title={t('residents.alerts')}
                      path={`/residents/profile?r=${residentId}`}
                      onBack={() => setTabSelected('')}
                    />
                    <ResidentAlerts residentId={resident.id} />
                  </>
                )
              default:
                return (
                  <>
                    <BackNavigationBar
                      title={t('residents.profile.residentInformation')}
                      path={'/residents'}
                    />
                    <div className="grid grid-cols-1 gap-4 px-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-12 lg:gap-6">
                      <div className="col-span-1 md:col-span-2 lg:col-span-9 flex flex-col">
                        <ResidentInformation />
                      </div>
                      <div className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col h-full">
                        <QuickLinks
                          className="col-span-full xl:col-span-4 h-full flex flex-col"
                          residentId={resident.id}
                          tabSelected={tabSelected}
                          setTabSelected={setTabSelected}
                        />
                      </div>
                    </div>
                    <div className="col-span-full px-4">
                      <div className="pt-4">
                        <ResidentNavigationMenu/>
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-12 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 px-4">
                      <div className="pt-4">
                        <ResponsiblePartyContact
                          contactDetails ={contactDetails[0]}
                        />
                      </div>
                      <div className="pt-4">
                        <PrimaryPhysicianContact
                          contactDetails ={contactDetails[1]}
                        />
                      </div>
                      <div className="pt-4 flex flex-col h-full">
                        <AlertsCard />
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-12 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 px-4">
                      <div className="pt-4 lg:col-span-1">
                        <DiagnosisCard />
                      </div>
                      <div className="pt-4 lg:col-span-2">
                        <FoodNutrition />
                      </div>
                    </div>
                  </>
                );
            }
          })()}
        </div>
      </div>
    </>
  );
};
