import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AlertService from '../../infraestructure/services/AlertService';
import { AlertCreateFormI, AlertDtoListI, AlertStateI, AlertUpdateFormI } from '../../domain/alerts.domain';

const initialState: AlertStateI = {
  categories: {
    status: 'loading',
    data: []
  },
  alerts: {
    status: 'loading',
    data: []
  },
  alertStatus: 'idle'
};

export const fetchAlertsCategories = createAsyncThunk('customAlerts/categories', async () => {
  try {
    const response = await AlertService.getCategories();
    return response;
  } catch (error) {
    throw error;
  }
});

export const createAlert = createAsyncThunk('customAlerts/create', async (formData: AlertCreateFormI) => {
  try {
    const response = await AlertService.createAlert(formData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchAlertList = createAsyncThunk('customAlerts/list', async (params: AlertDtoListI) => {
  try {
    const response = await AlertService.getAlertList(params);
    return response;
  } catch (error) {
    throw error;
  }
});

export const updateAlert = createAsyncThunk('customAlerts/update', async (formData: AlertUpdateFormI) => {
  try {
    const response = await AlertService.updateAlert(formData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const markAsDoneAlert = createAsyncThunk('customAlerts/markAsDone', async (params: {alertId: string; relatedId: string;}) => {
  try {
    const response = await AlertService.markAsDone(params.alertId, params.relatedId);
    return response;
  } catch (error) {
    throw error;
  }
});

const AlertSlice = createSlice({
  name: 'customAlerts',
  initialState,
  reducers: {
    setAlertStatus: (state, action) => {
      state.alertStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlertsCategories.pending, (state, _) => {
      state.categories = {
        status: 'loading',
        data: []
      };
    });
    builder.addCase(fetchAlertsCategories.fulfilled, (state, action) => {
      state.categories = {
        status: 'idle',
        data: action.payload.categories
      };
    });
    builder.addCase(fetchAlertList.pending, (state, _) => {
      state.categories = {
        status: 'loading',
        data: []
      };
    });
    builder.addCase(fetchAlertList.fulfilled, (state, action) => {
      state.alerts = {
        status: 'idle',
        data: action.payload.alerts
      };
    });
  }
});

export const { setAlertStatus } = AlertSlice.actions;
export default AlertSlice.reducer;
