import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

interface IBedroomQrForm {
    residentId: string;
    bedrooms: Array<{
        bedroomId: string;
        status: 'active' | 'inactive';
    }>;
}

const ResidentBedroomQr = {

    getBedroomQrCategory: async () => {
        try {
            const { data } = await axios.get(`residentBedroomQr/getAllBedroomQrCategory`);
            if ('data' in data) {
                return data.data;
            }
        } catch (error: any) {
            throw new Error(`Error get bedroom qr categories: ${error.message}`);
        }
    },

    getBedroomQrCategoryByResident: async (residentId: string) => {
        try {
            const response = await axios.get('residentBedroomQr/getCategoriesByResident', {
                params: { residentId },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching categories: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching Categories: ' + error.message);
        }

    },

    saveBedroomQrCategoryByResident: async (formData: IBedroomQrForm) => {
        try {
            const response = await axios.post(`residentBedroomQr/saveCategoriesByResident`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating tracker Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error creating tracker Sleep: ' + error.message);
        }
    }

}

export default ResidentBedroomQr;