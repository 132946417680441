export const MENU_COLORS = [
  "bg-cyan-500",
  "bg-blue-900",
  "bg-orange-500",
  "bg-pink-600",
  "bg-sky-600",
  "bg-fuchsia-800",
  "bg-emerald-500",
  "bg-rose-600",
  "bg-amber-400",
  "bg-indigo-500",
  "bg-amber-600",
];
