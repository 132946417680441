import TrackerSkinCheckCreate from "./TrackerSkinCheckCreate";
import { useEffect, useRef, useState } from "react";
import TrackerSkinCanvas from "./TrackerSkinCanvas";

interface TrackerSkinCheckDetailedProps {
    dataSkinReport?: (page: number, rowsPerPage: number, reset: boolean) => void;
    sexResident: string;
    orientation?: string;
    resident?: string;
    callback?: () => void;
}

const TrackerSkinCheckDetailed = ({ dataSkinReport, sexResident, orientation, resident, callback }: TrackerSkinCheckDetailedProps) => {
    const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [points, setPoints] = useState([]);

    useEffect(() => {
        const backgroundImageUrl = sexResident === null || sexResident === 'male'
        ? 'https://caringdata.com/corte/hombre.png'
        : 'https://caringdata.com/corte/mujer.png';

        setBackgroundImageUrl(backgroundImageUrl);
    }, [sexResident]);

    const getPoints = () => {
        return points;
    };

    const setCanvasPoints = (newPoint: any) => {
        setPoints(newPoint);
    };

    const deleteCoordinates = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');

        if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);
        setPoints([]);
    };

    return (
        <div className={"grid " + (orientation === "horizontal" ? "lg:grid-cols-1 xl:grid-cols-2" : "sm:grid-cols-1")}>
            <div>
                <TrackerSkinCanvas 
                    backgroundImageUrl={backgroundImageUrl} 
                    canvasRef={canvasRef} 
                    points={getPoints} 
                    setCanvasPoints={setCanvasPoints} 
                    deleteCoordinates={deleteCoordinates}
                />
            </div>

            <div>
                <TrackerSkinCheckCreate 
                    dataSkinReport={dataSkinReport} 
                    canvasRef={canvasRef} 
                    deleteCoordinates={deleteCoordinates}
                    resident={resident}
                    callback={callback}
                />
            </div>
        </div>
            
    );
};

export default TrackerSkinCheckDetailed;
