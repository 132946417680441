import { Dialog, DialogContent } from "@/common/presentation/components/ui/dialog";
import TrackerSkinCheckDetailed from "../Skin Check/TrackerSkinCheckDetailed";

interface TrackerHygieneSkinCheckDialog {
    resident: string;
    sexResident: string;
    isOpen: boolean;
    callback?: () => void;
};

const TrackerHygieneSkinCheckDialog = ({ resident, sexResident, isOpen, callback }: TrackerHygieneSkinCheckDialog) => {
    return (
        <Dialog open={isOpen}>
            <DialogContent style={{ paddingTop: "3rem", maxWidth: "min-content" }}>
                <div style={{ width: "80vw", height: "40rem", overflowY: "auto", overflowX: "hidden" }}>
                    <TrackerSkinCheckDetailed 
                        resident={resident} 
                        sexResident={sexResident} 
                        orientation={"horizontal"} 
                        callback={callback}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default TrackerHygieneSkinCheckDialog;
