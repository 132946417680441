import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerDirectService from '../../infrastructure/services/TrackerDirectService';

interface ITrackerDirectState {
  dayDirects: any[];
    directs: any[],
    directTypes: any[],
    status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: ITrackerDirectState = {
    directs: [],
    dayDirects: [],
    directTypes: [],
    status: 'idle',
    error: null
};

export const getTrackerDirect = createAsyncThunk("directTracker/getDirectTracker", async (body: any) => {
  const response = await TrackerDirectService.getTrackerDirect(body);
  return response.data;
});

export const getDayTrackerDirect = createAsyncThunk("trackerDirect/getDayTrackerDirect", async (body: any) => {
  const response = await TrackerDirectService.getDayTrackerDirect(body);
  return response.data;
});

export const saveTrackerDirect = createAsyncThunk("trackerDirect/saveTrackerDirect", async (body: any) => {
  const response = await TrackerDirectService.saveTrackerDirect(body);
  return response.data;
});

export const updateTrackerDirect = createAsyncThunk("trackerDirect/updateTrackerDirect", async (body: any) => {
  const response = await TrackerDirectService.updateTrackerDirect(body);
  return response.data;
});

export const destroyTrackerDirect = createAsyncThunk("trackerDirect/destroyTrackerDirect", async (body: any) => {
  const response = await TrackerDirectService.destroyTrackerDirect(body);
  return response.data;
});

export const getTrackerDirectTypes = createAsyncThunk("trackerDirect/getTrackerDirectType", async () => {
  const response = await TrackerDirectService.getTrackerDirectType();
  return response.data;
});

export const saveTrackerDirectTypes = createAsyncThunk("trackerDirect/saveTrackerDirectTypes", async (body: any) => {
  const response = await TrackerDirectService.saveTrackerDirectType(body);
  return response.data;
});

export const updateTrackerDirectTypes = createAsyncThunk("trackerDirect/updateTrackerDirectTypes", async (body: any) => {
  const response = await TrackerDirectService.updateTrackerDirectType(body);
  return response.data;
});

const trackerDirect = createSlice({
  name: 'trackerDirect',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackerDirect.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getTrackerDirect.fulfilled, (state, action) => {
      state.status = 'idle';
      state.directs = action.payload;
    });

    builder.addCase(getTrackerDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getDayTrackerDirect.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getDayTrackerDirect.fulfilled, (state, action) => {
      state.status = 'idle';
      state.dayDirects = action.payload;
    });

    builder.addCase(getDayTrackerDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getTrackerDirectTypes.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getTrackerDirectTypes.fulfilled, (state, action) => {
      state.status = 'idle';
      state.directTypes = JSON.parse(action.payload);
    });

    builder.addCase(getTrackerDirectTypes.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export default trackerDirect.reducer;
