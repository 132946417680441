import useRouteParams from '@/common/hooks/RouteParamsHook';
import { ImageCard } from '@/common/presentation/components/Selectors/ImageCard';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import * as locationActions from '@/modules/locations/presentation/slices/locationSlice';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { Home } from 'lucide-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../ui/use-toast';
import SimpleCard from './SimpleCard';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { useRequestRestrictByDeviceDialog } from '@/modules/security/infraestructure/hooks/useRestrictByDeviceDialog';
import { DeviceInfoI } from '@/modules/security/domain/device.domain';
import { fetchDevice, postRequestDeviceRegistration } from '@/modules/security/presentation/slices/SecuritySlice';
import { RoleHelper } from '@/utils/helpers/user.helper';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { Resident } from '@/common/domain/Residents';

interface ListImagesProps {
  onSelect?: (id: string) => void;
  className?: ReactNode;
  locationId?: string | null;
  selections?: boolean;
  setUrlParams?: boolean;
  quickView?: boolean;
  allLocations?: boolean;
  firstSelection?: boolean;
}

const rolesToValidate = ['Staff'];

export const LocationSelector = ({
  onSelect,
  className,
  locationId = null,
  selections = true,
  setUrlParams = true,
  quickView = false,
  allLocations = true,
  firstSelection = false
}: ListImagesProps) => {
  const { params, setParam } = useRouteParams();
  const { locations, status } = useSelector((state: RootState) => state.locations.allLocations);
  const { setLocationSelectedId, locationSelectedId } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { deviceInfo } = useConfigurationsProvider();
  const currentDeviceRegistered = useSelector((state: RootState) => state.security.current_device.device);
  const currentDeviceRegisteredStatus = useSelector((state: RootState) => state.security.current_device.status);
  const dataAllResidents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const { onOpen, setMessage, setJustClose, setOnSubmit, setSubmitLoading, requestDeviceInfo } = useRequestRestrictByDeviceDialog();
  const { hasAnyRole } = useRoleHelper();
  const [locationSelectedTried, setLocationSelectedTried] = useState('all-locations');

  const { t } = useTranslation();

  useEffect(() => {
    if(!allLocations && locationSelectedId === "all-locations") onSelectedLocation(locations[0].id);
  }, [allLocations]);

  useEffect(() => {
    // Check if it is pharmacy and select the first location one as default
    if (user.roles.some((role: any) => role.name === 'Pharmacy')) {
     setLocationSelectedId(locations[0].id);
    }
  }, [locations, user])

  const getLocations = async () => {
    const response: any = await dispatch(locationsActions.getLocations());
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const getLocation = async (id: string) => {
    const response: any = await dispatch(locationActions.getLocation(id));
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
      setParam('l', 'all-locations');
    }
  };

  const getResidents = async () => {
    const response: any = await dispatch(
      getResidentsByLocation(locationSelectedId == 'all-locations' ? undefined : locationSelectedId)
    );
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const onSelectedLocation = (id: string) => {
    // setSelectedLocation(id); TODO: CHECK
    if (firstSelection && mustSelectLocation) {
      setLocationSelectedTried(id);
      const restriction = checkDeviceRestriction(id);
      if (!restriction) {
        return false;
      }
    }

    setLocationSelectedId(id);
    onSelect?.(id);
    if (setUrlParams) setParam('l', id);

    if (id === 'all-locations') {
      dispatch(locationActions.removeLocation());
    } else {
      getLocation(id);
    }
  };
  

  const requestRegistration = async (deviceInformation: DeviceInfoI, locationId: string): Promise<any> => {
    try {
      setSubmitLoading(true);
      const response = await dispatch(
        postRequestDeviceRegistration({ 
          deviceInfo: deviceInformation,
          locationId: locationId, 
          name: requestDeviceInfo.deviceName , 
          description: requestDeviceInfo.deviceDescription 
        })
      );
      if (response.payload.result === 'ok') {
        dispatch(
          fetchDevice({
            fingerPrint: deviceInfo?.finger_print ?? ''
          })
        );
        setJustClose(true);
        setMessage(t('security.deviceRegistrationSubmitted'));
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    const locationInfo = locations.find((location) => location.id === locationSelectedTried) ?? null;
    if (!deviceInfo || !locationInfo) {
      return;
    }
    setOnSubmit(() => requestRegistration(deviceInfo, locationInfo.id));
  }, [locationSelectedTried, requestDeviceInfo, deviceInfo])
  

  const checkDeviceRestriction = (locationId: string): boolean => {
    const locationInfo = locations.find((location) => location.id === locationId) ?? null;

    if (!deviceInfo) {
      alert('No device detected');
      return false;
    }

    if (locationInfo.restrict_by_device === 'active') {
      if (currentDeviceRegisteredStatus == 'loading') {
        return false;
      }

      setJustClose(false);

      const locationDevice = currentDeviceRegistered?.locations.find((location) => location.id === locationId);

      if (!currentDeviceRegistered) {
        setMessage(t('security.deviceNotRegistered', {locationName: locationInfo.name}));
        setOnSubmit(() => requestRegistration(deviceInfo, locationInfo.id));
        onOpen();
        return false;
      }

      if (currentDeviceRegistered && !locationDevice) {
        setJustClose(true);
        setMessage(t('security.deviceRegisteredNoInThisLocation', {deviceName: currentDeviceRegistered.name}));
        onOpen();
        return false;
      }

      const registerUserDevice =
        currentDeviceRegistered?.users.find((userDevice: any) => userDevice.id === user.id) ?? null;

      if (!registerUserDevice && !currentDeviceRegistered.all_users) {
        setJustClose(true);
        setMessage(t('security.deviceRegisteredButYouAreNotAssigned', {deviceName: currentDeviceRegistered.name, locationName: locationInfo.name}));
        onOpen();
        return false;
      }

      if (locationDevice && currentDeviceRegistered.status === 'inactive') {
        setJustClose(true);
        setMessage(t('security.deviceRegisteredButNotActive', {deviceName: currentDeviceRegistered.name, locationName: locationInfo.name}));
        onOpen();
        return false;
      }

      if (currentDeviceRegistered && currentDeviceRegistered.status === 'pending') {
        setJustClose(true);
        setMessage(t('security.deviceRegisteredNotApproved', {deviceName: currentDeviceRegistered.name, locationName: locationInfo.name}));
        onOpen();
        return false;
      }

      if (registerUserDevice?.pivot.status !== 'active' && !currentDeviceRegistered.all_users) {
        setJustClose(true);
        setMessage(t('security.deviceRegisteredButYouAreNotAssigned', {deviceName: currentDeviceRegistered.name, locationName: locationInfo.name}));
        onOpen();
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    getResidents();
  }, [locationSelectedId]);

  useEffect(() => {
    if (params.l && params.l.length > 0) {
      setLocationSelectedId(params.l);
      // setSelectedLocation(params.l); TODO: CHECK
      if (params.l === 'all-locations') {
        dispatch(locationActions.removeLocation());
      } else {
        getLocation(params.l);
      }
    }
  }, [params.l, dispatch]);

  useEffect(() => {
    if (!locations.length) getLocations();
  }, [dispatch]);

  const mustSelectLocation = useMemo(() => {
    return hasAnyRole(['Staff', 'Client Manager']);
  }, [user]);

  const selectedLocation = useMemo(() => {
    return localStorage.getItem('location_selected');
  }, [localStorage.getItem('location_selected')]);

  const getResidentsCountByLocation = (residents: Resident[] | undefined, locationId: string): number => {
    if (!residents || residents.length === 0) {
      return 0;
    }

    return residents.filter((resident) => resident.location && resident.location.id === locationId).length;
  };

  return (
    <>
      {!mustSelectLocation && !firstSelection && (
        <div className={cn('overflow-hidden pt-3 pb-1', className)}>
          {status === 'loading' && !quickView ? (
            <div className="flex items-center justify-start">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="w-[120px] h-[168px] rounded-xl bg-primary/20" />
                <Skeleton className="h-5 w-[120px] rounded-full bg-primary/20" />
              </div>
            </div>
          ) : status === 'loading' ? (
            <div className="flex items-center justify-start mb-4">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="w-[120px] h-9 rounded-lg bg-primary/20" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="flex gap-4 mb-4">
                {locations.length > 1 &&
                  allLocations &&
                  (quickView ? (
                    <SimpleCard
                      item={{
                        id: 'all',
                        firstName: t('locationsSelector.allLocations')
                      }}
                      isSelected={selections && locationSelectedId === 'all-locations'}
                      onSelect={() => onSelectedLocation('all-locations')}
                    />
                  ) : (
                    <ImageCard
                      icon={Home}
                      item={{
                        id: 'all',
                        firstName: t('locationsSelector.allLocations')
                      }}
                      className={cn('hover:cursor-pointer')}
                      onSelect={() => onSelectedLocation('all-locations')}
                      aspectRatio={'portrait'}
                      isSelected={selections && locationSelectedId === 'all-locations'}
                    />
                  ))}

                {locations.map((location, index) => {
                   const residentsCount = getResidentsCountByLocation(dataAllResidents, location.id);

                  return quickView ? (
                    <SimpleCard
                      key={index}
                      item={{
                        id: location.id,
                        firstName: location.name,
                        description: location.location_description,
                        residentsInfo: `${residentsCount} ${t('common.residents')}`,
                        image: location.image_path
                      }}
                      isSelected={selections && locationSelectedId === location.id}
                      onSelect={() => onSelectedLocation(location.id)}
                    />
                    ) : (
                      <ImageCard
                        key={index}
                        item={{
                          id: location.id,
                          firstName: location.name,
                          description: location.location_description,
                          image: location.image_path
                        }}
                        onSelect={() => onSelectedLocation(location.id)}
                        aspectRatio={'portrait'}
                        isSelected={selections && locationSelectedId === location.id}
                      />
                    )
                })}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>
      )}

      {mustSelectLocation && firstSelection && (
        <div className={cn('overflow-hidden pt-3 pb-2', className)}>
          {status === 'loading' && !quickView ? (
            <div className="flex items-center justify-start">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="w-[120px] h-[168px] rounded-xl bg-primary/20" />
                <Skeleton className="h-5 w-[120px] rounded-full bg-primary/20" />
              </div>
            </div>
          ) : status === 'loading' ? (
            <div className="flex items-center justify-start mb-4">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="w-[120px] h-9 rounded-lg bg-primary/20" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="flex gap-4 mb-4">
                {locations.length > 1 &&
                  allLocations &&
                  (quickView ? (
                    <SimpleCard
                      item={{
                        id: 'all',
                        firstName: t('locationsSelector.allLocations')
                      }}
                      isSelected={selections && locationSelectedId === 'all-locations'}
                      onSelect={() => onSelectedLocation('all-locations')}
                    />
                  ) : (
                    <ImageCard
                      icon={Home}
                      item={{
                        id: 'all',
                        firstName: t('locationsSelector.allLocations')
                      }}
                      className={cn('hover:cursor-pointer')}
                      onSelect={() => onSelectedLocation('all-locations')}
                      aspectRatio={'portrait'}
                      isSelected={selections && locationSelectedId === 'all-locations'}
                    />
                  ))}

                {locations.map((location, index) =>{
                  const residentsCount = getResidentsCountByLocation(dataAllResidents, location.id);

                  return quickView ? (
                    <SimpleCard
                      key={index}
                      item={{
                        id: location.id,
                        firstName: location.name,
                        description: location.location_description,
                        residentsInfo: `${residentsCount} ${t('common.residents')}`,
                        image: location.image_path
                      }}
                      isSelected={selections && locationSelectedId === location.id}
                      onSelect={() => onSelectedLocation(location.id)}
                    />
                    ) : (
                      <ImageCard
                        key={index}
                        item={{
                          id: location.id,
                          firstName: location.name,
                          description: location.location_description,
                          image: location.image_path
                        }}
                        onSelect={() => onSelectedLocation(location.id)}
                        aspectRatio={'portrait'}
                        isSelected={selections && locationSelectedId === location.id}
                      />
                    )
                })}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>
      )}

      {mustSelectLocation && selectedLocation && !firstSelection && (
        <div className="text-lg py-4">
          <span className="font-bold mr-2">Selected Location:</span>
          {
            locations.find((location) => {
              return location.id === localStorage.getItem('location_selected');
            })?.name
          }
        </div>
      )}
    </>
  );
};
