import { LucideIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Item } from './item';

import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { getLocationMenusConfiguration } from '@/common/presentation/slices/menuSlice';
import * as configActions from '@/common/presentation/slices/configurationSlice';
import { setConfigurationsLoading } from '@/common/presentation/slices/configurationSlice';
import * as lucide from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { getMenuTranslation } from '@/utils/helpers/i18n.helper';
import { MainMenu } from '@/common/domain/MainMenu';

interface INavigationList {
  isMobile: boolean;
  mobileCollapse: () => void;
}

export const NavigationList = ({ isMobile, mobileCollapse }: INavigationList) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { menus } = useConfigurationsProvider();
  const menuItems = menus || [];

  const level = 0;
  const [items, setItems] = useState<MainMenu[]>([]);
  const { configurations, isLoading } = useSelector((state: any) => state.configurations);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const { locationSelectedId } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const onExpand = (id: string) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const pathOpenNewTab: string[] = [
    '/training'
  ];

  useEffect(() => {
    const userMenus = JSON.parse(JSON.stringify(menuItems));
    userMenus.forEach((menu: any) => {
      if (menu.icon === null || menu.icon === undefined) {
        menu.icon = 'LayoutDashboard';
      }
    });
    if (userMenus.length > 0) {
      userMenus.sort((a: any, b: any) => a.order - b.order);
      setItems(userMenus);
    }
  }, [menuItems]);

  const getIcon = (icon: string) => {
    const Accessibility = icon as keyof typeof lucide;
    return lucide[Accessibility] as LucideIcon;
  };

  const onItemClick = (url: string) => {
    if (pathOpenNewTab.includes(url)) {
      window.open(url, '_blank');
      return;
    }

    navigate(url);
    if (isMobile) {
      mobileCollapse?.();
    }
  };

  const freshMenu = async (locationId: string) => {
    if (isLoading) return null;

    dispatch(setConfigurationsLoading());
    const response: any = await dispatch(getLocationMenusConfiguration(locationId));
    if (response?.payload?.data) {
      dispatch(
        configActions.setConfigurations({
          ...configurations,
          menus: response.payload.data
        })
      );
    }
  };

  useEffect(() => {
    freshMenu(locationSelectedId);
  }, [locationSelectedId]);

  return (
    <>
      {items.length === 0 && <p className="pl-3">No navigation items</p>}

      {isLoading &&
        items.map((item: any) => (
          <Skeleton
            className="h-8 animate-pulse rounded-md my-2 bg-black/10 dark:bg-gray-500/20 ml-2 mr-3"
            key={item.id + '-skeleton'}
          />
        ))}

      {!isLoading &&
        items.map((item) => {
          const menuName = getMenuTranslation(item.key, t);

          return (
            <div key={item.id}>
              <Item
                id={item.id}
                label={menuName}
                icon={getIcon(item.icon)}
                active={false}
                level={level}
                hasSubmenu={item.submenus.length > 0}
                expanded={!!expandedItems[item.id]}
                onExpand={() => onExpand(item.id)}
                onClick={() => onItemClick(item.path)}
              />
              {expandedItems[item.id] &&
                item.submenus?.length > 0 &&
                item.submenus
                  .filter((submenu) => submenu.visible)
                  .sort((a, b) => a.order - b.order)
                  .map((submenu) => {
                    const submenuName = t(`menu.${item.key}.submenus.${submenu.key}`) || submenu.name;

                    return (
                      <div key={submenu.id}>
                        <Item
                          id={submenu.id}
                          label={submenuName}
                          icon={getIcon(submenu.icon)}
                          active={false}
                          level={level + 1}
                          expanded={!!expandedItems[submenu.id]}
                          onClick={() => onItemClick(submenu.path)}
                        />
                      </div>
                    );
                  })}
            </div>
          );
        })}
    </>
  );
};
