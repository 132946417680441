import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { FormField } from '@/modules/residents/domain/models/FormField';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
import { useFormFieldsManager } from '@/modules/residents/infrastructure/hooks/plan-of-care/useFormFieldsManager';
import { DocumentResourcesParams } from '@/modules/residents/infrastructure/types/documents';
import { RootState } from '@/store/store';
import { CheckedState } from '@radix-ui/react-checkbox';
import { FileCog, Loader2, MonitorDown, Save } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { PlanOfCareForm } from './PlanOfCareForm';
import { PlanOfCareTypeForm } from './PlanOfCareTypeForm';

const defaultFormFields: FormField[] = [
  {
    label: "Resident's Description ",
    value: 'resident_description',
    placeholder: null,
    isVisible: true,
    isRequired: true,
    isDefault: true,
    status: 'active',
    order: 1
  },
  {
    label: "Resident's Likes",
    value: 'resident_likes',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 2
  },
  {
    label: "Resident's Dislikes",
    value: 'resident_dislikes',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 3
  },
  {
    label: 'Hygiene Routine',
    value: 'hygiene_routine',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 4
  },
  {
    label: 'Loved Ones',
    value: 'loved_ones',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 5
  },
  {
    label: 'Career Hobbies',
    value: 'career_hobbies',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 6
  },
  {
    label: 'Food Preferences',
    value: 'food_preferences',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 7
  },
  {
    label: 'Morning Routine',
    value: 'morning_routine',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 8
  },
  {
    label: 'Night Routine',
    value: 'night_routine',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 9
  },
  {
    label: 'Additional Comments',
    value: 'additional_comments',
    placeholder: null,
    isVisible: true,
    isRequired: false,
    isDefault: true,
    status: 'active',
    order: 10
  }
];

export const PlanOfCareFormCard = () => {
  const planOfCareTypeFormRef = useRef<HTMLFormElement>();
  const planOfCareFormRef = useRef<HTMLFormElement>();

  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const [consent, setConsent] = useState(false);
  const { forms } = useFormsStore();

  const [appraisalNeedsAndServicesPlan, setAppraisalNeedsAndServicesPlan] = useState<any>({
    description: '',
    resident_likes: '',
    resident_dislikes: ''
  });

  const latestForm = useMemo(() => {
    return (
      forms
        .filter((form) => form.data && Object.keys(form.data).length > 0)
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0] || {}
    );
  }, [forms]);

  const { onOpen } = useFormFieldsManager();
  const { formFields, getFormFields, status: formsStatus, savePlanOfCare, getPlanOfCareHistory } = usePlanOfCareStore();

  const defaultFields = useMemo(() => {
    const mergedFields = [...defaultFormFields];

    formFields?.forEach((field) => {
      const index = mergedFields.findIndex((defaultField) => defaultField.value === field.value);
      if (index !== -1) {
        // Si existe en defaultFormFields, sobrescribe
        mergedFields[index] = { ...mergedFields[index], ...field };
      } else {
        // Si no existe, añade el nuevo campo
        mergedFields.push(field);
      }
    });

    return mergedFields;
  }, [defaultFormFields, formFields]);

  useEffect(() => {
    const fetchFormFields = async () => {
      await getFormFields('plan_of_care');
    };

    fetchFormFields();
  }, []);

  const handleCheck = (e: CheckedState) => {
    setConsent(e as boolean);
  };

  const handleSubmit = async ({ pdf }: { pdf: boolean }) => {
    try {
      const planOfCareTypeFormData = await planOfCareTypeFormRef.current?.handleSubmit();
      const planOfCareFormData = await planOfCareFormRef.current?.handleSubmit();

      const combinedData = {
        ...planOfCareTypeFormData,
        ...planOfCareFormData,
        consent
      };

      const defaultResourceParamValues: DocumentResourcesParams = {
        country: 'united_states',
        state: 'california',
        city: null,
        module: 'residents'
      };

      const payload = {
        documentKey: 'plan_of_care',
        residentId: resident?.id,
        formData: combinedData,
        // other_information: {
        //   defaultResourceParamValues
        // }
        defaultResourceParamValues
      };

      const { form } = await savePlanOfCare(payload, pdf);

      toast({
        description: 'Plan of care saved successfully!',
        variant: 'default'
      });

      await getPlanOfCareHistory(resident?.id);

      if (form) {
        const base64Data = form.replace(/^data:application\/pdf;base64,/, '');

        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const uintArray = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          uintArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        setTimeout(() => URL.revokeObjectURL(url), 100);
      }
    } catch (error: any) {
      console.error('Error saving plan of care:', error);
    }
  };

  const loadLatestForm = () => {
    console.log({ latestForm });

    const data = {
      description: latestForm?.data?.basic_information?.resident_description,
      resident_likes: latestForm?.data?.basic_information?.resident_likes,
      resident_dislikes: latestForm?.data?.basic_information?.resident_dislikes
    };

    setAppraisalNeedsAndServicesPlan(data);

    toast({
      description:
        'Description and likes and dislikes loaded from LIC 625 Appraisal Needs and Services Plan form successfully!',
      variant: 'default'
    });
  };

  return (
    <>
      <CustomAccordion
        title={`Plan of Care Form for ${resident?.first_name} ${resident?.last_name}`}
        components={
          <>
            <Button variant="ghost" onClick={() => onOpen(defaultFields)}>
              <FileCog className="mr-2 size-4" />
              Manage Fields
            </Button>
            <Button variant="ghost" onClick={() => loadLatestForm()}>
              <MonitorDown className="mr-2 size-4" />
              Load From LIC 625 Appraisal / Needs and Services Plan
            </Button>
          </>
        }
        defaultOpen={true}
        openOption={false}
        separator
      >
        <div className="mt-4 flex flex-col gap-4">
          <PlanOfCareTypeForm ref={planOfCareTypeFormRef} defaultValues={{}} />
          <PlanOfCareForm
            ref={planOfCareFormRef}
            defaultFormFields={defaultFields}
            defaultValues={{
              resident_description: appraisalNeedsAndServicesPlan?.description || '',
              resident_likes: appraisalNeedsAndServicesPlan?.resident_likes || '',
              resident_dislikes: appraisalNeedsAndServicesPlan?.resident_dislikes || ''
            }}
          />
          <div className="grid grid-cols-3 gap-4 bg-primary/5 p-3 rounded-lg shadow-sm">
            <div className="flex items-center space-x-2 col-span-1 xl:col-span-4">
              <Checkbox id="terms" checked={consent} onCheckedChange={handleCheck} />
              <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I have read and understand all the complete information in Samuel's Plan of Care
              </label>
            </div>
          </div>

          <div className="flex justify-center gap-4 col-span-full self-end">
            <Button
              onClick={() => handleSubmit({ pdf: false })}
              variant={'default'}
              disabled={formsStatus === 'loading' || !consent}
            >
              {formsStatus === 'loading' ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Save className="size-4 mr-2" />
              )}
              Save Plan of Care
            </Button>
            <Button
              onClick={() => handleSubmit({ pdf: true })}
              variant={'default'}
              disabled={formsStatus === 'loading' || !consent}
            >
              {formsStatus === 'loading' ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <FaRegFilePdf className="size-4 mr-2" />
              )}
              Save and View PDF
            </Button>
          </div>
        </div>
      </CustomAccordion>
    </>
  );
};
