import renderActionsColumn from './renderActionsColumn';

const columnsReportMedications: Column[] = [
  {
    key: 'month',
    labelTranslationKey: 'medications.DestructionMedications.reportDestructionMedications.reportDate',
    numeric: false,
    value: 'month',
    visible: true,
    static: true,
    sortable: true
  },
  {
    id: 'resident_name',
    key: 'resident_name',
    labelTranslationKey: 'medications.DestructionMedications.reportDestructionMedications.resident',
    type: 'resident_name',
    value: 'resident_name',
    visible: true,
    static: true,
    sortable: true,
    numeric: false,
    render: (data) => data.resident?.name || ''
  },

  {
    key: 'pdf',
    labelTranslationKey: 'Pdf',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true,
    render: renderActionsColumn
  }
];

export default columnsReportMedications;
