
import { useState, useEffect } from 'react';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Input } from '@/common/presentation/components/ui/input';
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { Button } from '@/common/presentation/components/ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { saveBedroomQr, getTrackerBedroomQrByDate } from '../../slices/TrackerBedroomQrSlice';
import { getBedroomQrCategoriesByResident } from '@/modules/residents/presentation/slices/residentBedroomQrSlice';
import { saveSpecialInformation } from '@/modules/residents/presentation/slices/residentSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card } from '@/common/presentation/components/ui/card';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { Textarea } from "@/common/presentation/components/ui/textarea";
import { Label } from '@/components/ui/label';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';

interface Bedroom {
    bedroomId: string;
    name: string;
}

interface BedroomData {
    isActive: boolean;
    details: string;
    date: string;
    trackerId?: string;
}

export function UpdateBedroomQr() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { formatDate } = useDateHelpers();
    const [residentId, setResidentId] = useState<string | undefined>();
    const dispatch = useDispatch<AppDispatch>();
    const [activeBedrooms, setActiveBedrooms] = useState<Bedroom[]>([]);
    const [bedroomData, setBedroomData] = useState<Record<string, BedroomData>>({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const formattedDate = selectedDate ? selectedDate.toISOString() : '';
    const params = new URLSearchParams(location.search)
    const id = params.get('residentId');
    const residentName = params.get('residentName');
    const auth = useSelector((state: RootState) => state.auth.user);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [specialInformation, setSpecialInformation] = useState<string>("");
    const residentSpecialInfo = useSelector((state: RootState) => {
        const resident = state.residents.allResidents.residents.find((resident) => resident.id === id);
        return resident ? resident.special_information : '';
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { hasAnyRole } = useRoleHelper();

    useEffect(() => {
        if (!id || !residentName) {
            navigate('/dashboard')
            return
        }

        const fetchBedroomData = async () => {

            try {
                setIsLoading(true);
                const response = await dispatch(getBedroomQrCategoriesByResident(id));
                const bedrooms = response.payload.bedrooms;

                if (bedrooms) {
                    const initialData = bedrooms.reduce((acc: Record<string, BedroomData>, bedroom: { bedroomId: string; status: string }) => {
                        acc[bedroom.bedroomId] = {
                            isActive: bedroom.status === 'active',
                            details: '',
                            date: ''
                        };
                        return acc;
                    }, {});

                    const bedroomsActive = bedrooms.filter((bedroom: { status: string }) => bedroom.status === 'active');

                    setResidentId(id);
                    setBedroomData(initialData);
                    setActiveBedrooms(bedroomsActive);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error fetching bedroom data:", error);
            }
        };

        fetchBedroomData();
    }, [dispatch, id]);

    useEffect(() => {
        if (!residentSpecialInfo) {
            return
        }
        setSpecialInformation(residentSpecialInfo);
    }, []);


    const handleSwitchChange = (bedroomId: string) => {
        setBedroomData((prev) => {

            const currentBedroomData = prev[bedroomId] || {};

            return {
                ...prev,
                [bedroomId]: {
                    ...currentBedroomData,
                    isActive: !currentBedroomData.isActive,
                },
            };
        });
    };

    const handleInputChange = (bedroomId: string, value: string) => {
        setBedroomData((prev) => ({
            ...prev,
            [bedroomId]: {
                ...prev[bedroomId],
                details: value,
            },
        }));
    };

    const formattDate = (dateString: string) => {
        if (!dateString) return t('trackers.trackerBedroom.selectDate');
        const date = new Date(dateString).toISOString();
        return formatDate(date);

    };

    const handleDateChange = (bedroomId: string, date: Date) => {
        setBedroomData((prev) => ({
            ...prev,
            [bedroomId]: {
                ...prev[bedroomId],
                date: date.toISOString(),
            },
        }));
    };

    const handleDateFilterChange = (date: Date) => {
        setSelectedDate(date);
    };

    const handleSpecialInformationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSpecialInformation(event.target.value);
    };


    const fetchFilteredBedroomData = async (residentId: string, date: Date) => {
        try {
            const response = await dispatch(getTrackerBedroomQrByDate({
                resident_id: residentId,
                date: date.toISOString()

            }));

            return response.payload.trackerBedroomQR;

        } catch (error) {
            console.error("Error fetching filtered bedroom data:", error);
            return null;
        }
    };

    useEffect(() => {
        if (residentId && selectedDate) {
            fetchFilteredBedroomData(residentId, selectedDate).then((filteredData) => {

                let updatedData: Record<string, BedroomData> = {}

                if (!filteredData || filteredData.length === 0) {
                    setBedroomData(updatedData)
                    return;
                }

                filteredData.forEach((item: { id: string; data: { bedroom_id: string; detail: string; date: string } }) => {
                    const bedroomId = item.data?.bedroom_id;
                    if (bedroomId) {
                        updatedData[bedroomId] = {
                            ...updatedData[bedroomId],
                            details: item.data.detail,
                            date: item.data.date,
                            trackerId: item.id
                        };
                    } else {
                        console.warn("Missing bedroom_id for item:", item);
                    }
                });
                setBedroomData(updatedData);
            });
        }
    }, [selectedDate, residentId]);

    const handleSave = async () => {
        setIsSubmit(true);
        const activeData = {
            residentId,
            bedrooms: Object.entries(bedroomData)
                .filter(([, data]) => data.isActive)
                .map(([id, data]) => ({
                    bedroomId: id,
                    detail: data.details,
                    date: hasAnyRole(['Super Administrator', 'Executive'])
                        ? data.date
                        : new Date().toISOString(),
                    trackerId: null,
                })),
        };

        try {

            if (!residentId) {
                console.error('Resident ID is undefined!');
                return;
            }


            if (['Super Administrator', 'Executive'].includes(auth?.roles[0]?.name)) {
                const specialInfo = {
                    resident_id: residentId,
                    special_information: specialInformation
                }

                await dispatch(saveSpecialInformation(specialInfo));
            }

        } catch (error) {
            console.error('Error saving special information:', error);

            toast({
                description: t('trackers.trackerBedroom.errorSaveSpecialInfo'),
                variant: 'destructive'
            });
        }

        if (activeData.bedrooms.length !== 0) {
            const response = await dispatch(saveBedroomQr(activeData));

            if (response.payload) {
                setIsSubmit(false);
                navigate('/tracker/bedroomqr');
            }
        } else {
            navigate('/tracker/bedroomqr');
        }

    };

    return (
        <div className='p-6'>
            <div className="font-semibold text-2xl text-primary">{residentName}</div>
            <div className="flex flex-col md:flex-row items-center justify-between flex-wrap">
                <div>{t('trackers.trackerBedroom.insertBedroomTracker')}</div>
                <div className="w-full lg:w-1/3 mt-2 md:mt-0">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button variant="outline" className="w-full justify-start text-left font-normal">
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formatDate(formattedDate)}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                onChange={handleDateFilterChange}
                                selectedDate={selectedDate}
                                MonthAndYearPicker={true}
                                timePicker={true}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </div>

            <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                {activeBedrooms.length > 0 ? (
                    <div className="mt-8">
                        <Label htmlFor="detail" className="control-label mb-3 font-bold">
                            {t('trackers.trackerBedroom.specialInformation')}
                        </Label>
                        <Textarea
                            value={specialInformation}
                            onChange={handleSpecialInformationChange}
                            className="w-1/2 px-2 py-1 bg-gray-100 rounded-md focus:outline-none mt-2"
                            readOnly={!['Super Administrator', 'Executive'].includes(auth?.roles[0]?.name)} />
                    </div>
                ) : ''}
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <p>{t('contacts.loading')}...</p>
                    </div>
                ) : activeBedrooms.length > 0 ? (
                    activeBedrooms.sort((a, b) => a.name.localeCompare(b.name)) // Ordenar alfabéticamente
                        .map((bedroom: Bedroom) => (
                            <div key={bedroom.bedroomId} className="flex flex-col bg-white mb-4 p-4 rounded-lg border-2 border-solid mt-4">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="flex items-center md:col-span-1 col-span-3 gap-4">
                                        <Switch
                                            checked={bedroomData[bedroom.bedroomId]?.isActive}
                                            onCheckedChange={() => handleSwitchChange(bedroom.bedroomId)}
                                        />
                                        <p className="font-bold text-[#71717A]">{bedroom.name}</p>
                                    </div>
                                    <div className="flex items-center justify-between md:col-span-2 col-span-3 gap-6">
                                        <Separator
                                            className={`${bedroomData[bedroom.bedroomId]?.isActive ? 'bg-primary' : 'bg-gray-200'} hidden md:flex w-[2px]`}
                                            orientation='vertical'
                                        />
                                        <div className='flex flex-wrap md:flex-nowrap gap-4 w-full'>
                                            {['Super Administrator', 'Executive'].includes(auth?.roles[0].name) && (
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                            {formattDate(bedroomData[bedroom.bedroomId]?.date)}
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-auto p-0">
                                                        <CustomCalendar
                                                            selectedDate={bedroomData[bedroom.bedroomId]?.date ? new Date(bedroomData[bedroom.bedroomId].date) : undefined}
                                                            onChange={(date: Date) => handleDateChange(bedroom.bedroomId, date)}
                                                            MonthAndYearPicker={true}
                                                            timePicker={true}
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                            )}
                                            <Input
                                                value={bedroomData[bedroom.bedroomId]?.details || ''}
                                                onChange={(e) => handleInputChange(bedroom.bedroomId, e.target.value)}
                                                placeholder={t('trackers.enterDetails')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                ) : (
                    <div className="flex items-center justify-center">
                        <p>{t('trackers.trackerBedroom.validateBedroom')}.</p>
                    </div>
                )}
            </Card>

            {activeBedrooms.length > 0 ? (
                <div className=" mt-6 flex items-center justify-end">
                    <Button onClick={handleSave} disabled={isSubmit}>{t('trackers.Save')}</Button>
                </div>
            ) : ''}
        </div>
    );
}