import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

interface Props {
  title?: string;
  titleComponent?: React.ReactNode;
  path?: string;
  onlyBack?: boolean;
  onBack?: () => void
}

export const BackNavigationBar = ({
  title,
  titleComponent,
  path = '/',
  onlyBack = false,
  onBack = () => {},
}: Props) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    onBack();
    if (onlyBack) {
      navigate(path, { replace: true });
    } else {
      navigate(path);
    }
  };
  return (
    <>
      <div className="flex items-center gap-x-2 text-primary mt-8 mb-6">
        <Button
          variant={'ghost'}
          size={'sm'}
          onClick={handleNavigation}
        >
          <ArrowLeft className="h-6 w-6 items-center" />
        </Button>
        <h1 className='text-2xl font-bold leading-normal'>
          {title}
        </h1>
        {titleComponent}
      </div>
    </>
  );
};