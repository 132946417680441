import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getTrackerHygieneType, updateTrackerHygieneType } from "../../slices/TrackerHygieneSlice";
import OptionsManager from "@/common/presentation/components/OptionsManager/OptionsManager";
import { Switch } from "@/common/presentation/components/ui/switch";

const TrackerHygieneTypeUpdateDialog = ({ row }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const { toast } = useToast();
    const [options, setOptions] = useState<string[]>([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        name: "",
        status: false
    });

    useEffect(() => {
        setOptions(JSON.parse(row.options));
        setValues({
            name: row.type_name,
            status: row.clientTrackerTypes ? row.clientTrackerTypes.status === "active" : row.status === "active"
        });
    }, [row]);

    const getTypes = () => {
        dispatch(
            getTrackerHygieneType(
                {
                    location_id: locationSelectedId
                }
            )
        );
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleCheckboxChange = (value: any) => {
        setValues({
            ...values,
            status: value
        });
    }

    const setTypeOptions = (options: string[]) => {
        setOptions(options);
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        if(values.name) {
            dispatch(
                updateTrackerHygieneType(
                    {
                        id: row.id,
                        ...values,
                        options,
                        status: values.status ? "active" : "inactive"
                    }
                )
            );
    
            fetchData();

            toast(
                {
                    description: t("trackers.trackerhygiene.successTypeUpdated")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.task.edit.toast.required")
                }
            );
        }

        fetchData();
        closeEditDialog();
    }

    const fetchData = () => {
        setOptions([]);
        getTypes();
    };

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>
                                                        
            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="34rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
                description={t("trackers.trackerhygiene.editTypeDialogDescription")}
            >
                <div>
                    <div className='mb-3'>
                        <Label htmlFor="name" className="text-right">
                            {t("cleaning.create.task.edit.form.name")}
                        </Label>

                        <Input
                            id="name"
                            name="name"
                            className="col-span-3"
                            disabled={!row.client_id}
                            value={values.name}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='flex justify-between space-x-2 p-2 border-solid border-gray-200 mb-2' style={{ borderWidth: "1px", borderRadius: "0.3rem" }}>
                        <label
                            htmlFor="terms"
                            className="flex items-center text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            {t("common.active")}
                        </label>

                        <Switch id="terms" checked={values.status} onCheckedChange={handleCheckboxChange}/>
                    </div>
                    
                    <OptionsManager setTypeOptions={setTypeOptions} options={options} disabled={!row.client_id} />

                    <div className='flex justify-end mt-5'>
                        <Button onClick={handleEdit}>
                            { t("trackers.trackercleaning.accept") }
                        </Button>
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
};

export default TrackerHygieneTypeUpdateDialog;
