import i18n from 'i18next';
/**
 * Converts a string from any format (camelCase, snake_case, kebab-case) 
 * into a format with words separated by spaces.
 * 
 * @param value - The string to format
 * @param caseType - Optional case type for the formatted words: 'lower', 'upper', or 'capitalize'
 * @returns The formatted string with spaces
 */
export function convertToSpacedWords(
    value: string, 
    caseType: 'lower' | 'upper' | 'capitalize' = 'capitalize'
): string {

    if (!value) return '';

    // Replace snake_case and kebab-case with spaces
    let formatted = value.replace(/[_-]/g, ' ');
    
    // Split camelCase into separate words
    formatted = formatted.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Apply the desired case
    switch (caseType.toLowerCase()) {
        case 'lower':
            formatted = formatted.toLowerCase();
            break;
        case 'upper':
            formatted = formatted.toUpperCase();
            break;
        case 'capitalize':
        default:
            formatted = formatted
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            break;
    }

    return formatted;
}


/**
 * Formats a given field value based on the specified rules.
 * 
 * @param fieldName - The name of the field being processed
 * @param fieldValue - The value of the field to format
 * @param fieldFormats - An associative array defining the field names and their expected formats
 * @returns The formatted value, or the original value if no format is matched
 */
export function formatValues(
    fieldName: string | null, 
    fieldValue: any
): any {
    // const { t } = useTranslation();

    const fieldFormats: { [key: string]: string } = {
        status: 'boolean',
        cycle_start_date: 'string',
        index: 'string',
        routes_medication_id: '',
        pharmacy_id: 'string',
        cycle_end_date: 'string',
        rx: '',
        refills: '',
        current_quantity: '',
        form: 'string',
        expiration_date: '',
        images: 'array',
        qtyReceived: '',
        filleDate: '',
        purpose: '',
        special_instructions: '',
        responsible_administrations_id: 'array',
        special_days: 'json',
        strength: '',
        location_type_id: '',
        minimum_quantity: '',
        not_exceed: '',
        received: '',
        instruction: '',
        generic_name: '',
        time: 'array',
        frequency: '',
        medication_id: '',
        date_prescribed: ''
    };
    
    if (!fieldValue || fieldValue.length === 0) {
        return '';
    }

    // If no specific field name is provided, try to process directly
    if (!fieldName || !fieldFormats[fieldName]) {
        return typeof fieldValue === 'string' ? fieldValue : JSON.stringify(fieldValue);
    }

    // Determine the format for the given field name
    const formatType = fieldFormats[fieldName] ?? 'string';

    switch (formatType) {
        case 'boolean':
            return fieldValue ? 'active' : 'inactive';

        case 'json':
            // Format JSON into a readable string
            try {
                const decoded = JSON.parse(fieldValue);
                return formatJsonValue(decoded);
            } catch {
                return fieldValue;
            }

        case 'array':
            // Handle array values decode stringified JSON arrays
            let processedValue = fieldValue;

            if (!processedValue || processedValue === '') {
                processedValue = [];
            }

            if (typeof processedValue === 'string') {
                try {
                    const decodedJson = JSON.parse(processedValue);
                    processedValue = decodedJson;
                } catch {}
            }

            if (Array.isArray(processedValue)) {
                // Handle non-associative arrays
                if (processedValue.every((v, i) => i === processedValue.indexOf(v))) {
                    return processedValue.join(', ') ?? '';
                }

                // Handle associative arrays
                return Object.entries(processedValue)
                    .map(([key, value]) => {
                        const formattedKey = typeof key === 'string' 
                            ? i18n.t('medications.audithColumns.' + key) ?? key
                            : key;
                        return `${formattedKey}: ${value} \n`;
                    })
                    .join(', ') ?? '';
            }

            return processedValue;

        case 'string':
            return convertToSpacedWords(String(fieldValue));

        default:
            // If no format matches, return the value as-is
            return fieldValue;
    }
}

/**
 * Helper function to format a decoded JSON value.
 * 
 * @param decoded - The decoded JSON value (could be an array)
 * @returns The formatted string representation of the JSON value
 */
export function formatJsonValue(decoded: any): string {
    if (Array.isArray(decoded)) {
        // Handle arrays
        const formatted = decoded.map((item, index) => {
            const formattedKey = typeof index === 'string' 
                ? i18n.t('medications.audithColumns.' + index) ?? convertToSpacedWords(index)
                : index.toString();

            const formattedValue = Array.isArray(item) || typeof item === 'object' 
                ? formatJsonValue(item) 
                : (typeof item === 'string' 
                    ? i18n.t('medications.audithColumns.' + item) ?? convertToSpacedWords(item)
                    : JSON.stringify(item));

            return `${formattedKey}: ${formattedValue}<br>`;
        });

        return formatted.join(', ');
    }

    if (typeof decoded === 'object' && decoded !== null) {
        const formatted = Object.entries(decoded).map(([key, value]) => {
            // Format the key (e.g., camelCase to spaced words)
            const formattedKey = i18n.t('medications.audithColumns.' + key) ?? convertToSpacedWords(key);

            const formattedValue = Array.isArray(value) || typeof value === 'object' 
                ? formatJsonValue(value) 
                : (typeof value === 'string' 
                    ? i18n.t('medications.audithColumns.' + key) ?? convertToSpacedWords(key) 
                    : JSON.stringify(value));

            return `${formattedKey}: ${formattedValue}\n`;
        });

        return formatted.join(', ');
    }

    return decoded;
}
