import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { useState, useEffect, useCallback } from 'react';
import { Pencil, Trash } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { useTranslation } from 'react-i18next';
import { FiltersTable } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/FiltersTable';
import { getFiles } from '../../slices/DocumentsFiles';
import { DialogCreateOrUpdate } from './DialogCreateOrUpdate';
import { DocumentsCategoryI } from '../../infrastructure/interfaces/DocumentsFolder';
import { getUnpaginatedCategories } from '../../../DocumentCategories/slices/DocumentsCategory';
import { SelectCategoryDocument } from './SelectCategoryDocument';
import { SelectStatusFolder } from './SelectStatusFolder';

interface DocumentsFileI {
    id: string;
    name: string;
    key: string;
    category: string;
    status: 'active' | 'inactive';
    admin_only: 'yes' | 'no';
}

export function DocumentsFile() {

    const { t } = useTranslation();
    const [documentFiles, setDocumentFiles] = useState<DocumentsFileI[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<DocumentsFileI | null>(null);
    const [action, setAction] = useState<"edit" | "delete" | ''>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [categoryKey, setCategoryKey] = useState<string>('');
    const [searchStatus, setSearchStatus] = useState<string>('');
    const [documentCategories, setDocumentCategories] = useState<DocumentsCategoryI[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load

    const dataDocumentsCategory = async () => {
        try {
            const response = await dispatch(getUnpaginatedCategories());
            setDocumentCategories(response.payload.categories || []);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };


    const dataDocumentsFile = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true)
        const response = await dispatch(getFiles({
            search: searchTerm,
            search_category: categoryKey,
            search_status: searchStatus,
            per_page: perPage,
            page
        }))

        const { folders }: {
            folders: { data: DocumentsFileI[], current_page: number, last_page: number, total: number }
        } = response.payload;
        const data = folders.data
        if (resetData) {
            setDocumentFiles(data);
        } else {
            setDocumentFiles(prevData => [...prevData, ...data]);
        }

        if (folders.current_page >= folders.last_page ||
            folders.total < data.length) {
            setHasMoreRows(false);
        } else {
            setHasMoreRows(true);
        }

        setIsLoading(false)

    }

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const handleStatusChange = (status: string) => {
        setSearchStatus(status);
    };

    const handleCategoryChange = (key: string) => {
        setCategoryKey(key);
    };

    const handleClearFilter = () => {
        setSearchTerm('');
    };

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataDocumentsFile(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataDocumentsFile]);



    useEffect(() => {
        setDocumentFiles([]);
        setCurrentPage(1);
        setHasMoreRows(true);

        dataDocumentsFile(1, rowsPerPage, true)
    }, [searchTerm, categoryKey, searchStatus]);

    useEffect(() => {
        dataDocumentsCategory();
    }, [])

    const handleOpenModal = (rowData: DocumentsFileI, actionType: 'edit' | 'delete') => {
        setSelectedRow(rowData)
        setOpenModal(true)
        setAction(actionType)
    }

    const renderActions = (row: DocumentsFileI) => (

        <div className="flex space-x-2">
            <span className="cursor-pointer">
                <Pencil className="mr-2 h-4 w-4" onClick={() => handleOpenModal(row, 'edit')} />
            </span>
            <span className="cursor-pointer">
                <Trash className="mr-2 h-4 w-4" onClick={() => handleOpenModal(row, 'delete')} />
            </span>
        </div>

    );


    const columnsreport = [
        { key: 'name', labelTranslationKey: t('trackers.customtype.Name'), value: 'name' },
        {
            key: 'category', labelTranslationKey: t('notes.category'),
            value: 'category',
        },
        {
            key: 'status', labelTranslationKey: t('medications.filterTable.status'),
            value: 'status'
        },
        {
            key: 'actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    const handleCloseModal = () => {
        setOpenModal(false)
        setAction('');
    }

    return (
        <div>
            <TableCustomVirtuoso
                data={documentFiles}
                columns={columnsreport}
                renderCellContent={(index, column, data) => {
                    const row = data[index];
                    switch (column.key) {
                        case 'name':
                            return `${capitalizeWords(row.name)}`;

                        case 'category':
                            return `${capitalizeWords(row.category_data.label)}`;


                        case 'status':
                            return row.status
                                ? row.status === 'active'
                                    ? t('medications.discontinuedActive')
                                    : row.status === 'inactive'
                                        ? t('medications.discontinued')
                                        : capitalizeWords(row.status)
                                : t('medications.discontinuedActive');


                        default:
                            return row[column.key];
                    }
                }}
                additionalButtons={<Button
                    variant="outline"
                    size="sm"
                    className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
                    onClick={() => setOpenModal(true)}
                ><span>{t('common.new')}</span></Button>}
                isLoading={isLoading}
                filters={
                    <div className="flex items-center justify-between gap-4">
                        <FiltersTable
                            onFilterChange={handleSearchChange}
                            filterValue={searchTerm}
                            onClear={handleClearFilter}
                        />

                        <SelectCategoryDocument
                            categoryKey={categoryKey}
                            handleCategoryChange={handleCategoryChange}
                            documentCategories={documentCategories}
                        />

                        <SelectStatusFolder
                            searchStatus={searchStatus}
                            handleStatusChange={handleStatusChange}
                        />
                    </div>
                }
                showSearchInput={false}
                loadMore={loadMore}
                hasMore={hasMoreRows}
            />

            <DialogCreateOrUpdate
                openModal={openModal}
                closeModal={handleCloseModal}
                dataDocumentsFile={dataDocumentsFile}
                selectedRow={selectedRow}
                action={action}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                documentCategories={documentCategories}
            />
        </div>
    )

}
