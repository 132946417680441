import React from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { FormFieldComponent } from './FormFieldComponent';

interface SubCategoryComponentProps {
  tab: string;
  category: string;
  subCategory: { label: string; value: string };
  isSelected: boolean;
  onSelectionChange: () => void;
  control: any;
  provideResource: (tree: string) => any;
  addCompletitionToTextarea: (value: string, tree: string) => void;
  removeCompletionFromTextarea: (value: string, tree: string) => void; // Añadido
}

export const SubCategoryComponent: React.FC<SubCategoryComponentProps> = ({
  tab,
  category,
  subCategory,
  isSelected,
  onSelectionChange,
  control,
  provideResource,
  addCompletitionToTextarea,
  removeCompletionFromTextarea // Añadido
}) => {
  return (
    <div className="flex flex-col items-baseline gap-2 w-full">
      <div className="flex items-center gap-2 mt-4">
        <Checkbox checked={isSelected} onCheckedChange={onSelectionChange} />
        <label>{subCategory.label}</label>
      </div>
      {isSelected && (
        <div className="grid grid-cols-1 grid-rows-auto md:grid-cols-2 md:grid-rows-2 gap-4 w-full">
          <FormFieldComponent
            name={`${tab}.${category}.${subCategory.value}.needs`}
            label="Needs"
            control={control}
            defaultValue={provideResource(`${tab}.${category}.${subCategory.value}.needs`)}
            addCompletitionToTextarea={(value: string) =>
              addCompletitionToTextarea(value, `${tab}.${category}.${subCategory.value}.needs`)
            }
            removeCompletionFromTextarea={(value: string) =>
              removeCompletionFromTextarea(value, `${tab}.${category}.${subCategory.value}.needs`)
            }
          />

          <FormFieldComponent
            name={`${tab}.${category}.${subCategory.value}.objectives`}
            label="Objectives"
            control={control}
            defaultValue={provideResource(`${tab}.${category}.${subCategory.value}.objectives`)}
            addCompletitionToTextarea={(value: string) =>
              addCompletitionToTextarea(value, `${tab}.${category}.${subCategory.value}.objectives`)
            }
            removeCompletionFromTextarea={(value: string) =>
              removeCompletionFromTextarea(value, `${tab}.${category}.${subCategory.value}.objectives`)
            }
          />
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-4">
            <FormFieldComponent
              name={`${tab}.${category}.${subCategory.value}.time_frame`}
              label="Time Frame"
              control={control}
              defaultValue={provideResource(`${tab}.${category}.${subCategory.value}.time_frame`)}
            />
            <FormFieldComponent
              name={`${tab}.${category}.${subCategory.value}.person_responsible_for_implementation`}
              label="Person Responsible For Implementation"
              control={control}
              defaultValue={provideResource(
                `${tab}.${category}.${subCategory.value}.person_responsible_for_implementation`
              )}
            />
            <FormFieldComponent
              name={`${tab}.${category}.${subCategory.value}.method_of_evaluating_progress`}
              label="Method Of Evaluating Progress"
              control={control}
              defaultValue={provideResource(`${tab}.${category}.${subCategory.value}.method_of_evaluating_progress`)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
