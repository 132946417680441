import React, { useCallback, useEffect, useState } from 'react';
import { courseStatus, IFilters } from '../../domain/training.domain';
import { trainingFilters } from '../../domain/constants/training';
import { cn } from '@/lib/utils';

interface IProps {
    currentFilter: courseStatus;
    setCurrentFilter: (filter: courseStatus) => void;
}

const CoursesFilter: React.FC<IProps> = ({ currentFilter, setCurrentFilter }) => {
  const [filters, setFilters] = useState<IFilters[]>([]);

  useEffect(() => {
    setFilters(trainingFilters);
  }, []);

  const clickHandler = useCallback((key: courseStatus) => {
    setCurrentFilter(key);
  }, []);

  return (
    <div className="flex flex-wrap gap-2">
      {filters.map((filter: IFilters) => (
        <div className={
            cn('text-center rounded-full py-1 px-3 bg-zinc-200 dark:bg-zinc-700 text-sm font-medium text-zinc-900 dark:text-zinc-200 cursor-pointer hover:!bg-primary/20',
                currentFilter === filter.key && '!bg-primary text-white'
            )
        }
            onClick={() => clickHandler(filter.key)}
        >
          {filter.label}
        </div>
      ))}
    </div>
  );
};

export default CoursesFilter;
