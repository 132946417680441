import React from 'react';
import { Row } from "@tanstack/react-table";
import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Trash } from 'lucide-react';

interface RenderActionsColumnProps {
  rowData: Row<any>;
}

const TrackerVisitorsRenderActionsColumn: React.FC<RenderActionsColumnProps> = (rowData) => {
    return (
        <div className='flex justify-center'>
            <Button variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <Button variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>
        </div>
    );
};

export default TrackerVisitorsRenderActionsColumn;
