import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { getTrackerDirectTypes } from '../slices/TrackerDirectSlice';
import UtilService from '@/common/services/UtilService';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useHyDirectTypeColumns from '../components/Direct/DirectTypeColumns';
import DirectTypeCreateDialog from '../components/Direct/DirectTypeCreateDialog';

function TrackerDirectType() {
    const { locationSelectedId } = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [types, setTypes] = useState([]);
    const trackerDirect = useSelector((state: RootState) => state.trackerDirect);
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);
    
    useEffect(() => {
        if (trackerDirect.directTypes && Array.isArray(trackerDirect.directTypes) && trackerDirect.directTypes.length > 0) {
            setTypes(
                UtilService.sortObjectsAlphabetically(trackerDirect.directTypes, "name")
            );
        } else {
            setTypes([]);
        }
    }, [trackerDirect.directTypes]);
 
    useEffect(() => {
        getTypes();
    }, [locationSelectedId]);

    useEffect(() => {
        console.log(trackerDirect.directTypes);
    }, [trackerDirect.directTypes]);

    const getTypes = () => {
        dispatch(getTrackerDirectTypes());
    };
    
    return (
        <div>
            <div>
                {
                    locationSelectedId && (
                        <div>
                            <Card className="border-t-4 border-t-primary/50">
                                <CardHeader>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                            {t('trackers.trackerdirect.typeCustom')}
                                    </CardTitle>
                                </CardHeader>

                                <CardContent>
                                    <div className="rounded-md overflow-hidden">
                                        <TableCustomVirtuoso
                                            data={types}
                                            columns={useHyDirectTypeColumns()}
                                            renderCellContent={(index, column, data) => data[index][column.key]}
                                            showSearchInput={false}
                                            additionalButtons={<DirectTypeCreateDialog />}
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TrackerDirectType;
