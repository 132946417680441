import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { HistoryTableColumns } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/HistoryTableColumns';
import { RootState } from '@/store/store';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const FormsHistory = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const { getFormHistory, forms } = useFormsStore();

  const getFormHistoryReq = async (residentId: string, key: string) => {
    await getFormHistory(residentId, key);
  };

  useEffect(() => {
    if (resident?.id) {
      getFormHistoryReq(resident.id, 'lic625_appraisal_needs_and_service_plan');
    }
  }, [resident]);

  const sortedForms = useMemo(() => {
    return forms.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [forms]);

  return (
    <div>
      <SingleTable
        data={sortedForms}
        columns={HistoryTableColumns}
        onDelete={() => {}}
        disable={false}
        filter={false}
      />
    </div>
  );
};
