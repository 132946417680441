import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { t } from "i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/common/presentation/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { FormMessage } from "@/common/presentation/components/ui/form";
import { Switch } from "@/common/presentation/components/ui/switch";
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Alert, AlertDescription } from "@/common/presentation/components/ui/alert";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import CustomSelect from "@/common/presentation/components/CustomSelect/CustomSelect";
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';

import { CheckCircle2 } from "lucide-react";

import {
    getCategories,
    sheetFieldsLoading,
    saveOrUpdateResidentFields
} from "@/modules/settings/presentation/components/menus/Residents/SheetField/slices/SheetFieldSlice";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";


interface IDialogCreateOrUpdate {
    onChangeOpen: (open: boolean) => void;
    open: boolean;
    isUpdate?: boolean;
    onChangeIsUpdate?: (state: boolean) => void
    dataUpdate?: {
        id: string;
        name: string;
        detail: string | null;
        related_to: string;
        status: boolean;
        category_id: string;
        category: string;
        categoryId: string;
        residents: string[];
    }
    onChangeGetData?: (currentPage: number) => void
}

const FormSchema = z.object({
    name: z.string(),
    detail: z.string(),
    resident: z.array(z.string({ 
        required_error: "resident is required",
        invalid_type_error: "Name must be a resident name",
    })).min(1, {
        message: "resident is required"
    }),
    relatedTo: z.string(),
    status: z.boolean(),
    category: z.string({
        required_error: "Category is required",
        invalid_type_error: "Name must be a category name",
    }).min(1, {
        message: "Category is required"
    })

});

type FormValues = z.infer<typeof FormSchema>;

interface SelectOption {
    value: string;
    label: string;
}

interface IResident {
    id: string,
    first_name: string,
    last_name: string
}

export const DialogCreateOrUpdate: React.FC<IDialogCreateOrUpdate> = ({
    onChangeOpen,
    open,
    isUpdate = false,
    onChangeIsUpdate,
    dataUpdate,
    onChangeGetData
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showSuccess, setShowSuccess] = useState(false);
    const { categories, isLoading } = useSelector((state: RootState) => state.settings.sheetFields);
    const residents = useSelector((state: RootState) => state.residents.allResidents.residents) ?? [];
    const [selectedResidents, setSelectedResidents] = useState<SelectOption[]>([]);
    const { locationSelectedId } = useLocation();

    const relatedTo = [
        {id:"face_sheets", name: t('settings.residents.faceSheet') },
        {id:"emergency_sheets", name: t("settings.residents.emergencySheet")},
        {id:"both", name: t("settings.residents.both")}
    ]

    const [selectedCategory, setSelectedCategory] = useState<SelectOption>({
        value: "",
        label: t('settings.select')
    });

    const [selectedRelatedTo, setSelectedRelatedTo] = useState<SelectOption>({
        value: "",
        label: t('settings.select')
    });


    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            name: '',
            detail: '',
            relatedTo: '',
            status: true,

        }
    });

    const handleResidentSelect = (options: SelectOption[]) => {
        const isAllSelected = options.some((option) => option.value === 'allResidents');
        let formatOptions = [];
        if (isAllSelected) {
            formatOptions = [options.find((option) => option.value === 'allResidents')!];
          setSelectedResidents(formatOptions);
        } else {
            formatOptions = options.filter((option) => option.value !== 'allResidents')
          setSelectedResidents(formatOptions);
        }
        return formatOptions.map(option => option.value);

      };

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        try {
            const payload = {
                ...data,
                idUpdate: isUpdate ? dataUpdate?.id : undefined,
                locationId: locationSelectedId
            };

            dispatch(sheetFieldsLoading());
            const response = await dispatch(saveOrUpdateResidentFields(payload));
            if (response.payload.result === 'ok') {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    onChangeOpen(false);
                    onChangeIsUpdate?.(false);
                }, 2000);

                if (onChangeGetData) {
                    onChangeGetData(1);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const categoryOptions: SelectOption[] = useMemo(() => [
        { value: "", label: t('settings.select') },
        ...(categories?.map((nCategory: {id: string, name: string}) => ({
            value: nCategory.id,
            label: nCategory.name
        })) || [])
    ], [categories, t]);

    const residentOptions: SelectOption[] = useMemo(() => [
        { value: "allResidents", label: t('settings.residents.allResidents') },
        ...(residents?.map((resident: IResident) => ({
            value: resident.id,
            label: resident.first_name + ' ' + resident.last_name
        })) || [])
    ], [residents, t]);

    const relatedToOptions: SelectOption[] =  [
        { value: "", label: t('settings.select') },
        ...(relatedTo?.map((rT: {id: string, name: string}) => ({
            value: rT.id,
            label: rT.name
        })) || [])
    ];


    useEffect(() => {
        if (open) {
            if (isUpdate && dataUpdate) { // when update
                form.reset({
                    name: dataUpdate.name,
                    detail: dataUpdate.detail || "",
                    relatedTo: dataUpdate.related_to,
                    status: dataUpdate.status,
                    category: dataUpdate.categoryId,
                    resident: dataUpdate.residents
                });
                setSelectedCategory({value: dataUpdate.categoryId, label: dataUpdate.category});

                const rt = relatedTo.find((rt) => rt.id === dataUpdate.related_to);
                setSelectedRelatedTo(rt 
                    ? { value: rt.id, label: rt.name } 
                    : {value: "", label: t('settings.select')}
                );
                
                const residentValue: SelectOption[] = residents
                    .map((r: IResident) => 
                        dataUpdate.residents.includes(r.id)
                            ? { value: r.id ?? '', label: `${r.first_name} ${r.last_name}` }
                            : undefined
                    ).filter((r): r is SelectOption => r !== undefined);

                    
                setSelectedResidents(residentValue);

            } else { // create
                form.reset({
                    name: '',
                    detail: '',
                    relatedTo: '',
                    category: '',
                    resident: [],
                    status: true,
                });
                setSelectedCategory({
                    value: "",
                    label: t('settings.select')
                })
                setSelectedRelatedTo({
                    value: "",
                    label: t('settings.select')
                });
                setSelectedResidents([])
            }
        } else {
            onChangeIsUpdate?.(false);
        }
    }, [open, isUpdate, dataUpdate, residents]);
    
    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch])

    return <>

        <CustomDialog
            height="auto"
            width="30rem"
            title={isUpdate ? t('common.update') : t('common.create')}
            newDialog={true}
            isOpen={open}
            onClose={() => onChangeOpen(false)}
            className="z-[999999] max-w-[92%] sm:max-w-[450px] "
            overFlow={true}
        >
            <div className="h-auto">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 flex flex-col justify-between h-auto">
                        {!isLoading && (
                            <ScrollArea className="h-auto flex-grow pr-4">
                                <div>

                                    {<FormField
                                            name="name"
                                            control={form.control}
                                            render={({ field }) => (
                                                <FormItem className="pb-4">
                                                    <FormLabel>{'Name'}</FormLabel>
                                                    <FormControl>
                                                        <Input {...field} placeholder="Enter name" />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />}


                                    <div className="flex flex-col md:flex-row justify-between items-start gap-2">
                                        <FormField
                                            control={form.control}
                                            name="resident"
                                            render={({ field }) => (
                                                <FormItem className="pb-4 w-full md:w-[50%]">
                                                <FormLabel>{t('common.residents')}</FormLabel>
                                                <MultiSelect
                                                    className="text-[13px] text-gray-600"
                                                    placeHolder={t('common.select')}
                                                    options={residentOptions}
                                                    selectedOptions={selectedResidents}
                                                    name={field.name}
                                                    onChange={(options) => {
                                                    setSelectedResidents(options);
                                                    const formatOptions = handleResidentSelect(options)
                                                    field.onChange(formatOptions);
                                                    }}
                                                />
                                                <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="category"
                                            render={({ field }) => (
                                                <FormItem className="pb-4 w-full md:w-[50%]">
                                                    <FormLabel>{t('common.categories')}</FormLabel>
                                                    <CustomSelect
                                                        value={selectedCategory}
                                                        onChange={(val: SelectOption) => {
                                                            if (val) {
                                                                setSelectedCategory(val);
                                                                field.onChange(val.value);
                                                            }
                                                        }}
                                                        className="z-[0]"
                                                        options={categoryOptions}
                                                        isSearchable={true}
                                                    />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>

                                    <FormField
                                        control={form.control}
                                        name="relatedTo"
                                        render={({ field }) => (
                                            <FormItem className="pb-4 w-full md:w-[50%]">
                                                <FormLabel>{t('common.relatedTo')}</FormLabel>
                                                <CustomSelect
                                                    value={selectedRelatedTo}
                                                    className="z-[0]"
                                                    onChange={(val: SelectOption) => {
                                                        if (val) {
                                                            setSelectedRelatedTo(val);
                                                            field.onChange(val.value);
                                                        }
                                                    }}
                                                    options={relatedToOptions}
                                                    isSearchable={true}
                                                />
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name="detail"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <FormLabel>{'Detail'}</FormLabel>
                                                <FormControl>
                                                    <Textarea {...field} placeholder="Enter detail" />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="status"
                                        render={({ field }) => (
                                            <FormItem className="pb-20">
                                                <div className="space-y-0.5">
                                                    <FormLabel>{t('settings.status')}</FormLabel>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </ScrollArea>
                        )}

                        {isLoading && (
                            <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                                <Spinner size={'lg'} />
                            </div>
                        )}
                        <div className="absolute bottom-0 left-0 right-0 p-4 bg-white">
                            {showSuccess ? (
                                <Alert className="bg-green-50 border-green-200 mb-4">
                                    <CheckCircle2 className="h-4 w-4 text-green-600" />
                                    <AlertDescription className="text-green-600">
                                        {isUpdate 
                                            ? t('settings.updatedSuccessfully') 
                                            : t('settings.createdSuccessfully')
                                        }
                                    </AlertDescription>
                                </Alert>
                            ) : (
                                <div className="flex justify-end">
                                    <Button type="submit">
                                        {t('settings.save')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </Form>
            </div>
        </CustomDialog>



    </>
};