import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/common/presentation/components/ui/select';
import { useTranslation } from 'react-i18next';

interface ISelectFolderProps {
    searchStatus: string;
    handleStatusChange: (key: string) => void;
}

export function SelectStatusFolder({ searchStatus, handleStatusChange }: ISelectFolderProps) {
    const { t } = useTranslation();

    return (
        <div className="w-1/2 flex items-center justify-between">
            <Select value={searchStatus} onValueChange={handleStatusChange}>
                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                    {searchStatus ? (<SelectValue> {searchStatus === 'active'
                        ? t('medications.discontinuedActive')
                        : searchStatus === 'inactive'
                            ? t('medications.discontinued')
                            : searchStatus === 'all'
                                ? t('notes.all') 
                                : t('settings.status')}</SelectValue>) :
                        (searchStatus === 'active'
                            ? t('medications.discontinuedActive')
                            : searchStatus === 'inactive'
                                ? t('medications.discontinued')
                                : searchStatus === 'all'
                                    ? t('notes.all')
                                    : t('settings.status'))}
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">{t('notes.all')}</SelectItem>
                    <SelectItem value="active">{t('medications.discontinuedActive')}</SelectItem>
                    <SelectItem value="inactive">{t('medications.discontinued')}</SelectItem>
                </SelectContent>
            </Select>
        </div>
    );
}
