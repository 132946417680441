import React from 'react'
import { useNavigate } from 'react-router-dom';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { CircleFadingPlus, Settings } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { useTranslation } from 'react-i18next';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import RenderActionsTrackerDirectColumn from '../components/Direct/renderActionsTrackerDirectColumn';
import useTrackerDirectColumns from '../components/Direct/TrackerDirectColumns';
import FilterForm from '../components/Direct/FilterForm';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSchema } from '../components/Direct/schema';
import { exportReportDirect } from '../slices/TrackerSlice';
import TrackerDirectType from './TrackerDirectType';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import QuickTracker from '@/common/presentation/components/QuickTracker/QuickTracker';
import QuickTrackerItem from '@/common/presentation/components/QuickTracker/interfaces/QuickTrackerItemInterface';
import { format } from 'date-fns';
import { getDayTrackerDirect, getTrackerDirect, getTrackerDirectTypes } from '../slices/TrackerDirectSlice';
import DirectDetailedTracker from '../components/Direct/DirectDetailedTracker';
export interface DirectFilterDataI {
    resident_id: string;
    resident?: string;
    location_id: string;
    page: number;
    per_page: number;
    date_range?: {
        from: string;
        to: string | null;
    } | null;
}

function TrackerDirect() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState([]);
    const [dataQuickTracker, setDataQuickTracker] = useState([]);
    const [dataReport, setDatareport] = useState([]);
    const dispatch = useDispatch();
    const { reportBlob, tracker } = useSelector((state: RootState) => state.tracker);
    const trackerDirect = useSelector((state: RootState) => state.trackerDirect);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedDirect, setSelectedDirect] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    const { t } = useTranslation();
    const onSelectDirect = (e: string) => {
        setParam('e', e);
        setSelectedDirect(e);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);
    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    const [dateRange, setDateRange] = useState({ from: '', to: '' });
    const { locationSelectedId } = useLocation();
    const [selectedTab, setSelectedTab] = useState<string>("quick");
    const [currentPage, setCurrentPage] = useState(0);
    const [reportRowsPerPage, setReportRowsPerPage] = useState(20);
    const [hasMoreRows, setHasMoreRows] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [resetData, setResetData] = useState(false);

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (!types) {
            setpTypes();
        }
    }, [types]);

    useEffect(() => {
        setpTypes();
        dataDayDirectReport();
    }, [locationSelectedId]);

    useEffect(() => {
        if(trackerDirect.dayDirects && trackerDirect.dayDirects.trackerDirect) {
            setDataQuickTracker(
                trackerDirect.dayDirects.trackerDirect.map(
                    (value) => (
                        {
                            id: value.id,
                            id_data: value.data.id,
                            action: value.data.value,
                            resident: {
                                id: value.data.resident.id,
                                name: value.data.resident.first_name
                            },
                            type: value.data.type
                        }
                    )
                )
            );
        } else {
            setDataQuickTracker([]);
        }
    }, [trackerDirect.dayDirects]);

    useEffect(() => {
        if (trackerDirect.directs && Array.isArray(trackerDirect.directs.data) && trackerDirect.directs.data.length > 0) {
            const newData = trackerDirect.directs.data;

            if (resetData) {
                setDatareport(newData);
            } else {
                setDatareport(prevData => [...prevData, ...newData]);
            }

            if (trackerDirect.directs.current_page >= trackerDirect.directs.last_page || trackerDirect.directs.total < newData.length) {
                setHasMoreRows(false);
            } else {
                setHasMoreRows(true);
            }
        } else {
            setDatareport([]);
        }
    }, [trackerDirect.directs]);

    useEffect(() => {
        if (trackerDirect.directTypes && Array.isArray(trackerDirect.directTypes) && trackerDirect.directTypes.length > 0) {
            const newTypes = trackerDirect.directTypes.filter((value) => value.status === "active");

            setTypes(
                Array.isArray(newTypes) ? newTypes : Object.values(newTypes)
            );
        } else {
            setTypes([]);
        }
    }, [trackerDirect.directTypes]);

    const setpTypes = async () => {
        dispatch(getTrackerDirectTypes());
    };
    
    const dataDayDirectReport = () => {
        dispatch(
            getDayTrackerDirect(
                { 
                    location_id: locationSelectedId 
                }
            )
        );
    }

    const dataDirectReport = async (page: number, resetData: boolean = false) => {
        setResetData(resetData);

        dispatch(
            getTrackerDirect(
                { 
                    location_id: locationSelectedId,
                    per_page: rowsPerPage,
                    page
                }
            )
        );
    };    

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const [data, setData] = useState([]);

    const createData = (filteredReports) => {
        const columnField = types || [];
        if (!types || !residents || !filteredReports) return [];
    
        const groupedData = [];
    
        residents.forEach((resident) => {
            // Busca el reporte para el residente filtrado
            const report = filteredReports.find(report => report.resident_id === resident.id);
    
            // Si no encuentra un reporte, no agrega ninguna fila
            if (!report) return;
    
            const row = {
                date: report ? new Date(report.date).toLocaleString() : 'N/A',
                resident_name: resident.first_name + ' ' + resident.last_name,
                type_name: report ? report.type_name || 'N/A' : 'N/A',
                value: report ? report.value || 'N/A' : 'N/A',
                detail: report ? (report.detail !== null ? report.detail : 'N/A') : 'N/A',
                who_name: report ? report.who_name || 'N/A' : 'N/A',
            };
    
            groupedData.push(row);
        });
    
        return groupedData;
    };       

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const renderCellContent = (index: number, column: Column, data: any[]) => {
        const rowData = data[index];
      
        // Revisa si los datos están llegando correctamente
        //console.log('Rendering Cell Content:', rowData, column.key);
      
        switch (column.key) {
          case 'resident_name':
            return <span>{rowData.resident_name}</span>;
          case 'date':
            return <span>{rowData.date}</span>;
          case 'type_name':
            return <span>{rowData.type_name}</span>;
          case 'value':
            return <span>{rowData.value}</span>;
          case 'actions':
            return (
              <RenderActionsTrackerDirectColumn 
                row={rowData}
                handleButtonOpenDialog={handleButtonOpenDialog} 
                dataDirectReport={dataDirectReport}
              />
            );
          default:
            return <span>{rowData[column.key]}</span>;
        }
      };
      
      const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema)
    });

    const [loading, setLoading] = useState(false); // Para mostrar loading
    const [rowsPerPage, setRowsPerPage] = useState(20);
    
    const fetchData = (filterData: DirectFilterDataI, page: number, rowsPerPage: number) => {
        const { date_range, resident_id, location_id } = filterData;
    
        // Filtra los datos según el rango de fechas y otros criterios
        const filteredReports = dataReport.filter((report) => {
            const reportDate = new Date(report.date);
            const from = new Date(date_range?.from || '1970-01-01');
            const to = new Date(date_range?.to || '2100-12-31');
    
            const matchesResident = !resident_id || report.resident_id === resident_id;
            const matchesLocation = !location_id || report.location_id === location_id;
    
            // Verifica si el reporte cae dentro del rango de fechas y cumple los otros filtros
            return reportDate >= from && reportDate <= to && matchesResident && matchesLocation;
        });
    
        //console.log('Filtered Reports:', filteredReports); // Verifica que el filtro funcione correctamente
    
        if (filteredReports.length === 0) {
            setData([]); // Si no hay reportes, vacía la tabla
            return;
        }
    
        // Actualizamos los datos de la tabla basados en los datos filtrados
        const newData = createData(filteredReports);
        //console.log('New Data:', newData); // Verifica que los datos generados para la tabla sean correctos
        setData(newData);
    };    

    const filter = async (data: z.infer<typeof FormSchema>) => {
        try {
            const { date_range, resident } = data;
            const filterData: DirectFilterDataI = {
                date_range: {
                    from: date_range?.from?.toISOString().split('T')[0] ?? '',
                    to: date_range?.to?.toISOString().split('T')[0] ?? ''
                },
                page: 1,
                per_page: 20,
                resident_id: params.r,
                location_id: locationSelectedId,
                resident: resident
            };
    
            // Guardar las fechas en el estado
            setDateRange({
                from: filterData.date_range.from,
                to: filterData.date_range.to
            });
    
            // Llamar a fetchData para aplicar el filtro
            await fetchData(filterData, 1, rowsPerPage);
            
            //console.log('Filter applied:', filterData);
        } catch (error) {
            //console.error('Error filtering', error);
        }
    };

    const onSelectedTab = (event: string) => {
        setSelectedTab(event);
    }

    const cleanFilter = () => {
        form.reset({ resident: '', date_range: null });
    };

    const generateReportPdf = async (): Promise<void> => {
        try {
            if (!dateRange.from || !dateRange.to) {
                //console.error('No date range selected');
                return;
            }

            // Obtener el token almacenado
            const token = localStorage.getItem('access_token'); // Asumiendo que guardaste el token en localStorage
    
            const formData = {
                datestart: dateRange.from,
                datesend: dateRange.to,    
                resident_id: params.r,
                location_id: locationSelectedId
            };
    
            // Despachar la acción de Redux para exportar el reporte
            await dispatch(exportReportDirect({ formData, token }));
    
            // Si la exportación fue exitosa, abrir el PDF
            if (status === 'idle' && reportBlob) {
                const blob = new Blob([reportBlob], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url); // Abrir el PDF en una nueva pestaña
            }
        } catch (error) {
            //console.error('Error generating report:', error);
        }
    };

    const submitQuickTracker = async (newItems: QuickTrackerItem[]) => {
        const saveQueries = newItems
        .filter((value) => !value.exist)
        .map(
            (value) => (
                {
                    type: value.type.id,
                    value: value.action,
                    resident_id: value.resident.id,
                    detail: "",
                    location_id: locationSelectedId
                }
            )
        );

        const updateQueries = newItems
        .filter((value) => value.exist)
        .map(
            (value) => (
                {
                    id: value.id,
                    id_data: value.id_data,
                    data: {
                        type: value.type.id,
                        value: value.action,
                        resident_id: value.resident.id,
                        detail: "",
                        location_id: locationSelectedId
                    }
                }
            )
        );

        setLoading(true);

        if(saveQueries.length) await TrackerService.saveTrackerDirect(saveQueries);
        if(updateQueries.length) await TrackerService.updateTrackerDirect(updateQueries);

        setLoading(false);

        dataDirectReport(1, true);
        dataDayDirectReport();
    };

    const loadMore = () => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataDirectReport(nextPage);
    };

    return (
        <div>
            <LocationSelector quickView={true} />

            {
                    locationSelectedId && (
                        <div className="my-4 flex items-center justify-between">
                            <div className="flex flex-col gap-2">
                                <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerdirect.title')}</div>
                                <div className="font-semibold text-1xl">{t('trackers.trackerdirect.description')}</div>
                            </div>

                            <Tabs defaultValue={selectedDirect} onValueChange={(e) => { onSelectDirect(e); }}
                                className="w-auto">
                                <TabsList>
                                    <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        <CircleFadingPlus className="h-4 w-4 mr-1" />
                                        {t('trackers.Form')}
                                    </TabsTrigger>
                                    <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        {t('trackers.Report')}
                                    </TabsTrigger>
                                    <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        <Settings className='w-5 h-5' />
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>

                    )
                }
            
            {
                locationSelectedId && selectedDirect === 'form' && selectedTab === "detailed" && (
                    <ResidentSelector
                        onSelect={onResidentSelect}
                        locationId={location}
                        residentId={params.r}
                        showAllResidents={false}
                    />
                )
            }

            {
                selectedDirect === 'form' && (
                    <Card className="border-t-4 border-t-primary/50 mt-4 mb-5" style={{ height: "min-content" }}>
                        <CardHeader>
                            <div className='flex justify-between'>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                    {
                                        selectedTab === "quick" ? (
                                            <span>
                                                {t('trackers.trackerdirect.quickDescription')}
                                            </span>
                                        ) : (
                                            <span>
                                                {t('trackers.trackerdirect.detailedDescription')}
                                            </span>
                                        )
                                    }
                                </CardTitle>

                                <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                                    <TabsList>
                                        <TabsTrigger value="quick" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t("trackers.trackerhygiene.quick")}
                                        </TabsTrigger>

                                        <TabsTrigger value="detailed" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t("trackers.trackerhygiene.detailed")}
                                        </TabsTrigger>
                                    </TabsList>
                                </Tabs>
                            </div>
                        </CardHeader>

                        {
                            selectedTab === "quick" ? (
                                <CardContent style={{ padding: 0 }}>
                            <>
                                {
                                    locationSelectedId && selectedTab === "quick" && (
                                        <QuickTracker 
                                            types={types} 
                                            residents={residents} 
                                            loading={loading} 
                                            submit={submitQuickTracker} 
                                            data={dataQuickTracker}
                                            input={"text"}
                                        />
                                    )
                                }
                            </>
                        </CardContent>
                            ) : (
                                <CardContent>
                                    <>
                                        {
                                            locationSelectedId && selectedTab === "detailed" && (
                                                <DirectDetailedTracker dataDirectReport={dataDirectReport} />
                                            )
                                        }
                                    </>
                                </CardContent>
                            )
                        }
                    </Card>
                )
            }

            {
                selectedDirect === 'report' && (
                    <div>
                        <div>
                            <ResidentSelector
                                onSelect={onResidentSelect}
                                locationId={location}
                                residentId={params.r}
                                showAllResidents={false}
                            />
                        </div>
                        <br/>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackerdirectreport.title')}</CardTitle>
                                <div className="flex w-full items-center justify-between">
                                    <div>
                                        {t('trackers.trackerdirectreport.description')}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <div className="rounded-md overflow-hidden">
                                    <TableCustomVirtuoso
                                    data={dataReport}
                                    columns={[
                                        { 
                                            key: 'resident_name', 
                                            labelTranslationKey: 'trackers.trackerdirect.resident', 
                                            render: (rowData) => rowData.data.resident.first_name + " " + rowData.data.resident.last_name,
                                            sortable: true ,
                                            width: "15%"
                                        },
                                        { 
                                            key: 'date', 
                                            labelTranslationKey: 'trackers.trackerdirect.date', 
                                            render: (rowData) => format(new Date(rowData.created_at), "dd/MM/yyyy hh:mm a"),
                                            sortable: true,
                                            width: "15%"
                                        },
                                        { 
                                            key: 'type_name', 
                                            labelTranslationKey: 'trackers.trackerdirect.type', 
                                            render: (rowData) => rowData.data.type.name,
                                            sortable: true, 
                                            width: "15%"
                                        },
                                        { 
                                            key: 'value', 
                                            labelTranslationKey: 'trackers.trackerdirect.value', 
                                            render: (rowData) => rowData.data.value,
                                            sortable: true, 
                                            width: "15%"
                                        },
                                        { 
                                            key: 'actions', 
                                            labelTranslationKey: 'trackers.trackerdirect.actions',
                                            width: "5%"
                                        }
                                    ]}
                                    renderCellContent={renderCellContent}
                                    showSearchInput = {false}
                                    loadMore={loadMore}
                                    hasMore={hasMoreRows}
                                    isLoading={isLoading}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )
            }
                {
                selectedDirect === 'type' && (
                    <div>
                        {
                           <div>
                            <TrackerDirectType />
                           </div>
                        }
                    </div>
                )
            }
        </div >
    )
}

export default TrackerDirect;
