import { Button } from '@/common/presentation/components/ui/button';
import { Label } from '@/common/presentation/components/ui/label';
import { Input } from '@/common/presentation/components/ui/input';
import { useEffect, useState } from 'react';
import { Pencil } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAreas, getTasks, updateTask } from '../../slices/TrackerCleaningSlice';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { ITrackerCleaningArea } from './TrackerCleaningMockup';
import { RootState } from '@/store/store';

export function TrackerCleaningTaskEditDialog(props: { id: string, name: string, status: string, area: string }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const { toast } = useToast();
    const [areas, setAreas] = useState<ITrackerCleaningArea[]>([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        name: "",
        status: false,
        area: ""
    });

    useEffect(() => {
        setValues({
            name: props.name,
            status: props.status === "active" ? true : false,
            area: props.area
        });
    }, [props]);

    useEffect(() => {
        if(trackerCleaning.activeAreas) setAreas(trackerCleaning.activeAreas);
    }, [trackerCleaning]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleAreaChange = (value: any) => {
        setValues({
             ...values,
             area: value
         });
     };

    const handleCheckboxChange = (event: any) => {
        setValues({
            ...values,
            status: event
        });
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        if(values.name) {
            dispatch(updateTask(
                {
                    id: props.id,
                    ...values,
                    area: values.area,
                    status: values.status ? 'active' : 'inactive'
                }
            ));
    
            fetchData();
            cleanInputs();

            toast(
                {
                    description: t("cleaning.create.task.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.task.edit.toast.required")
                }
            );
        }

        fetchData();
        setOpenEdit(false);

        return true;
    }

    const fetchData = () => {
        dispatch(getTasks(
            {
                location_id: locationSelectedId
            }
        ));
    };

    const cleanInputs = () => {
        setValues({
            name: "",
            status: false,
            area: ""
        });
    };

    return (
        <div>
             <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>
                                                        
            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >
                <div>
                    <div className='mb-3'>
                        <Label htmlFor="name" className="text-right">
                            {t("cleaning.create.task.edit.form.name")}
                        </Label>

                        <Input
                            id="name"
                            name="name"
                            defaultValue="@peduarte"
                            className="col-span-3"
                            value={values.name}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-1 mb-3">
                        <Label 
                            htmlFor={"area"} 
                            className="text-right justify-self-start"
                            style={{ color: "#6B7280" }}
                        >
                            { t("cleaning.form.detailed.form.area") }
                        </Label>

                        <Select 
                            onValueChange={handleAreaChange} 
                            value={values.area}
                            disabled={!areas.length}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder={t("cleaning.form.detailed.form.areaPlaceholder")} />
                            </SelectTrigger>
                            
                            <SelectContent>
                                <SelectGroup>
                                    {
                                        areas.length ? (
                                            areas.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>)
                                        ) : null
                                    }
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className='flex justify-between space-x-2 p-2 border-solid border-gray-200 mb-2' style={{ borderWidth: "1px", borderRadius: "0.3rem" }}>
                        <label
                            htmlFor="terms"
                            className="flex items-center text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            {t("common.active")}
                        </label>

                        <Switch id="terms" checked={values.status} onCheckedChange={handleCheckboxChange}/>
                    </div>

                    <div className='flex justify-end'>
                        <Button type="submit" onClick={handleEdit}>
                            { t("trackers.trackercleaning.accept") }
                        </Button>
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
};
