import React, { useState, useEffect } from 'react';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { Popover, PopoverContent } from '@/common/presentation/components/ui/popover';
import { PopoverTrigger } from '@/common/presentation/components/registry/default/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

interface TypeOptionsProps {
  isNewMedication: boolean,
  type: string;
  selectStartDate: Date | null;
  selectEndDate: Date | null;
  onUpdateFormData: (updatedData: { special_day?: string[] | Date[]; text?: string; type?: string }) => void;
  special_days: {
    type?: string;
    special_day?: string[] | Date[];
    text?: string;
  };
}

const TypeOptions: React.FC<TypeOptionsProps> = ({ selectStartDate = null, selectEndDate = null, isNewMedication = false, type, onUpdateFormData, special_days }) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    Array.isArray(special_days.special_day) && special_days.type === 'alternate_days' && special_days.special_day[0]
      ? new Date(special_days.special_day[0])
      : undefined
  );

  const [highlightedDates, setHighlightedDates] = useState<Date[]>([]);

  const [showOption, setShowOption] = useState<'every_day' | 'days_of_week' | 'calendar' | 'alternate_days'>(
    special_days.type || 'every_day'
  );

  useEffect(() => {
    if (showOption === 'alternate_days' && Array.isArray(special_days?.special_day)) {
      setHighlightedDates(special_days.special_day.map((item) => (typeof item === 'string' ? new Date(item) : item)));
    } else if (showOption === 'alternate_days' && !special_days.special_day) {
      handleOptionChange('alternate_days');
      setHighlightedDates([]);
    }
  }, [showOption, special_days.special_day]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>(
    Array.isArray(special_days.special_day) && special_days.type === 'days_of_week'
      ? (special_days.special_day as string[])
      : []
  );

  const [selectedDates, setSelectedDates] = useState<Date[]>(
    Array.isArray(special_days.special_day) && special_days.type === 'calendar'
      ? (special_days.special_day as string[]).map((dateString) => {
          const [year, month, day] = dateString.split('-').map(Number);
          return new Date(year, month - 1, day);
        })
      : []
  );

  const [text, setText] = useState(special_days.text || '');

  useEffect(() => {
    if (!special_days.type) {
      onUpdateFormData({ special_day: 'every_day', text, type: 'every_day' });
    }
  }, [special_days, onUpdateFormData, text]);

  const medicationsResident: any = useSelector((state: RootState) => state.medications.medicationsResident);
  const chartData = medicationsResident.chart;

  const handleDayChange = (day: string) => {
    const updatedDays = selectedDays.includes(day) ? selectedDays.filter((d) => d !== day) : [...selectedDays, day];
    setSelectedDays(updatedDays);
    onUpdateFormData({ special_day: updatedDays, text, type: showOption });
  };

  const handleCalendarChange = (dates: Date[] | { from?: Date; to?: Date } | Date | undefined) => {
    if (!dates) return;

    if (Array.isArray(dates)) {
      setSelectedDates(dates);
      onUpdateFormData({
        special_day: dates.map((date) => format(date, 'yyyy-MM-dd')),
        type: showOption,
        text
      });
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setText(newText);
    onUpdateFormData({
      special_day: selectedDates.map((date) => format(date, 'yyyy-MM-dd')),
      text: newText,
      type: showOption
    });
  };

  const handleOptionChange = (option: 'every_day' | 'days_of_week' | 'calendar' | 'alternate_days') => {
    setShowOption(option);
    setIsPopoverOpen(option === 'calendar' || option === 'alternate_days');

    if (option === 'alternate_days' && chartData && !isNewMedication) {
      const today = formatDateWithOut(new Date());
      console.log(chartData);
      const startOfMonth = chartData[0].cycle_start_date
        ? formatDateWithOut(chartData[0].cycle_start_date)
        : new Date(today.getFullYear(), today.getMonth(), 1);

      const endOfMonth = chartData[0].cycle_end_date
        ? formatDateWithOut(chartData[0].cycle_end_date)
        : new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const alternateDays = [];
        for (let d = new Date(startOfMonth); d <= endOfMonth; d.setDate(d.getDate() + 2)) {
          alternateDays.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
        }

      setHighlightedDates(alternateDays);
      onUpdateFormData({ special_day: alternateDays, text, type: option });
    } else {
      setShowOption(option);
      setSelectedDates([]);
      onUpdateFormData({
        special_day: option === 'every_day' ? 'every_day' : option === 'days_of_week' ? [] : [],
        text,
        type: option
      });
    }
  };

  const handleSetSelectedDate = (date: Date) => {
    console.log(date);
    let charDataStartDate;
    if(isNewMedication && selectStartDate){
      charDataStartDate = selectStartDate ;
    } else {
      charDataStartDate = (Array.isArray(chartData) && chartData?.[0].cycle_start_date) ? chartData[0].cycle_start_date : null ;
    }

    let charDataEndDate;
    if(isNewMedication && selectEndDate){
      charDataEndDate = selectEndDate;    
    } else {
      charDataEndDate = (Array.isArray(chartData) && chartData?.[0].cycle_end_date) ? chartData[0].cycle_start_date: null;    
    }
    
    
    console.log(charDataStartDate);
    let compareDates = false;
    let startOfMonth: any;
    
    if (!charDataStartDate){
      startOfMonth = date;
      console.log(startOfMonth);
    } else {            
      compareDates = formatDateWithOut(date) < formatDateWithOut(charDataStartDate);
      startOfMonth = compareDates ? formatDateWithOut(charDataStartDate) : formatDateWithOut(date);
    }   

    console.log(startOfMonth);
    const endOfMonth = charDataEndDate ? formatDateWithOut(charDataEndDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const alternateDays:any[] = [];
    for (let d = startOfMonth; d <= endOfMonth; d.setDate(d.getDate() + 2)) {
      if (d >= date) {
        alternateDays.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
      }
    }
    console.log(alternateDays);
    setHighlightedDates(alternateDays);
    onUpdateFormData({ special_day: alternateDays, text, type: showOption });
  };

  const formatDateWithOut = (input: any) => {
    let year, month, day;

    if (typeof input === 'string') {
      [year, month, day] = input.split('-').map(Number);
      month -= 1;
    } else if (input instanceof Date) {
      year = input.getFullYear();
      month = input.getMonth();
      day = input.getDate();
    } else {
      throw new Error("El parámetro debe ser una cadena en formato 'yyyy-mm-dd' o un objeto Date");
    }
    return new Date(year, month, day);
  };

  const handleAlternateDaysClick = () => {
    handleOptionChange('alternate_days');
  };

  const getCalendarDisplayText = () => {
    if (showOption === 'calendar' && selectedDates.length > 0) {
      const datesText = selectedDates
        .slice(0, 8)
        .map((date) => format(date, 'LLL dd, y'))
        .join(', ');

      return selectedDates.length > 2 ? `${datesText} y ${selectedDates.length - 2} más` : datesText;
    }
    return 'Seleccionar fechas';
  };

  return (
    <div className="p-6 border border-gray-300 rounded-lg shadow-sm bg-gray-50 mb-4">
      <h2 className="text-lg font-semibold mb-4">Select Type of Days</h2>
      <div className="mb-6">
        <div className="mt-3 flex flex-wrap items-center gap-4">
          <div className="flex items-center">
            <Input
              id="every_day"
              type="radio"
              name="days_option"
              value="every_day"
              checked={showOption === 'every_day'}
              onChange={() => handleOptionChange('every_day')}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="every_day" className="ml-2 text-gray-700 cursor-pointer">
              Every Day
            </Label>
          </div>
          <div className="flex items-center">
            <Input
              id="alternate_days"
              type="radio"
              name="days_option"
              value="alternate_days"
              checked={showOption === 'alternate_days'}
              onChange={handleAlternateDaysClick}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="alternate_days" className="ml-2 text-gray-700 cursor-pointer">
              Every Other Day
            </Label>
          </div>
        </div>
        <div className="mt-3 flex flex-wrap items-center gap-4">
          <div className="flex items-center">
            <Input
              id="days_of_week"
              type="radio"
              name="days_option"
              value="days_of_week"
              checked={showOption === 'days_of_week'}
              onChange={() => handleOptionChange('days_of_week')}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="days_of_week" className="ml-2 text-gray-700 cursor-pointer">
              Days of the Week
            </Label>
          </div>
          <div className="flex items-center">
            <Input
              id="calendar"
              type="radio"
              name="days_option"
              value="calendar"
              checked={showOption === 'calendar'}
              onChange={() => handleOptionChange('calendar')}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="calendar" className="ml-2 text-gray-700 cursor-pointer">
              According to the calendar
            </Label>
          </div>
        </div>
      </div>

      {showOption === 'days_of_week' && (
        <div className="grid grid-cols-7 gap-2 mb-6">
          {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
            <Button
              key={day}
              className={`py-2 px-3 rounded-md border ${
                selectedDays.includes(day) ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
              onClick={() => handleDayChange(day)}
            >
              {day}
            </Button>
          ))}
        </div>
      )}

      {showOption === 'alternate_days' && (
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={'outline'}
              className={`w-full justify-start text-left font-normal ${!selectedDate ? 'text-muted-foreground' : ''}`}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {highlightedDates.length > 0 ? (
                `${format(highlightedDates[0], 'LLL dd, y')} - ${format(
                  highlightedDates[highlightedDates.length - 1],
                  'LLL dd, y'
                )}`
              ) : (
                <span>Select a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={highlightedDates[0]}
              onSelect={(date) => {
                console.log(date);
                if (date) {
                  handleSetSelectedDate(date);
                }
              }}
              initialFocus
              modifiers={{
                highlighted: highlightedDates
              }}
              modifiersStyles={{
                highlighted: { backgroundColor: 'blue', color: 'white' }
              }}
            />
          </PopoverContent>
        </Popover>
      )}

      {showOption === 'calendar' && (
        <div className="mb-4">
          <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <PopoverTrigger asChild>
              <Button variant="outline" className="w-full justify-start text-left font-normal">
                <CalendarIcon className="mr-2 h-4 w-4" />
                {getCalendarDisplayText()}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="multiple"
                selected={selectedDates}
                onSelect={handleCalendarChange}
                numberOfMonths={2}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
      )}
      <div className="mb-6">
        <Label htmlFor="text">Special Instructions:</Label>
        <textarea
          id="text"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
          rows={3}
          value={text ?? ''}
          onChange={handleTextChange}
          placeholder="Enter special instructions (optional)"
        ></textarea>
      </div>
    </div>
  );
};

export default TypeOptions;
