import { TFunction } from 'i18next';
import { FileUser, FileCheck, ClipboardPlus, HandCoins, NotebookPen, PhoneOutgoing } from 'lucide-react';
export const getResidentNavigation  = (t: TFunction) => [
  {
    title: t('residents.profile.residentNavigation.primaryInformation'),
    value: 'primary',
    icon: <FileUser className="mr-2 size-5" />
  },
  {
    title: t('residents.profile.residentNavigation.otherRequiredInformation'),
    value: 'other',
    icon: <FileCheck className="mr-2 size-5" />
  },
  {
    title: t('residents.profile.residentNavigation.medicalInformation'),
    value: 'medical',
    icon: <ClipboardPlus className="mr-2 size-5" />
  },
  {
    title: t('contacts.contacts'),
    value: 'contacts',
    icon: <PhoneOutgoing className="mr-2 size-5" />
  },
  {
    title: t('residents.profile.residentNavigation.insuranceAndFinancial'),
    value: 'insuranceAndFinancial',
    icon: <HandCoins className="mr-2 size-5" />
  },
  {
    title: t('residents.profile.residentNavigation.planOfCare'),
    value: 'planOfCare',
    icon: <NotebookPen className="mr-2 size-5" />
  }
];