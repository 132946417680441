import React from 'react';
import { Row } from "@tanstack/react-table";
import DirectTypeUpdateDialog from './DirectTypeUpdateDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
}

const DirectRenderTypeActionColumn: React.FC<RenderActionsColumnProps> = (row) => {
    return (
        <div className='flex'>
            <DirectTypeUpdateDialog row={row} />
        </div>
    );
};

export default DirectRenderTypeActionColumn;
