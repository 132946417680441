import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { SaveFolderPayload } from '../../../../Staff/DocumentFiles/infrastructure/interfaces/DocumentsFolder';

const LocationDocumentsFolder = {
    getDocumentFolders: async (search: string, search_category: string, search_status: string, per_page: number, page: number) => {
        try {
            const response = await axios.get('locations/documents/folders', {
                params: { search, search_category, search_status, per_page, page },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching folders: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching folders: ' + error.message);
        }
    },

    updateFolder: async (formData: SaveFolderPayload) => {
        try {
            const response = await axios.post(`locations/documents/update-folder/${formData.id}`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error updating folder: No data returned from server');
            }
        } catch (error: any) {
            if (error.response && error.config) {
                const serverMessage = error.response.data?.error || error.response.data?.message || 'Unknown server error';
                throw new Error(serverMessage);
            } else {

                throw new Error('Error updating folders: ' + (error.message || 'Unknown error'));
            }
        }
    },

    saveFolders: async (formData: SaveFolderPayload) => {
        try {
            const response = await axios.post(`locations/documents/save-folder`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating folders: No data returned from server');
            }
        } catch (error: any) {
            if (error.response && error.config) {
                const serverMessage = error.response.data?.error || error.response.data?.message || 'Unknown server error';
                throw new Error(serverMessage);
            } else {

                throw new Error('Error creating folders: ' + (error.message || 'Unknown error'));
            }
        }
    },

    deleteFolder: async (id: string) => {
        try {
            const response = await axios.delete(`locations/documents/delete-folder/${id}`);
            return response;
        } catch (error: any) {
            throw new Error('Error deleting folders: ' + error.message);
        }

    },


}

export default LocationDocumentsFolder