import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isToday,
  isSameDay
} from 'date-fns';
import { es, enUS } from 'date-fns/locale';

const FullYearCalendar = ({
  initialYear = new Date().getFullYear(),
  onDaySelect,
  selectedDate,
  showNavigationButtons = true,
  highlightCurrentDay = true
}) => {
  const { t, i18n } = useTranslation();
  const [currentYear, setCurrentYear] = useState(initialYear);

  const getCurrentLocale = () => {
    switch (i18n.language) {
      case 'es':
        return es;
      default:
        return enUS;
    }
  };

  // Función para capitalizar la primera letra del mes
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getDaysInMonth = (month) => {
    const start = startOfMonth(new Date(currentYear, month, 1));
    const end = endOfMonth(start);
    const startOfWeekDate = startOfWeek(start, {
      locale: getCurrentLocale(),
      weekStartsOn: t('weekStartsOn', { defaultValue: 1 })
    });
    const endOfWeekDate = endOfWeek(end, {
      locale: getCurrentLocale(),
      weekStartsOn: t('weekStartsOn', { defaultValue: 1 })
    });
    return eachDayOfInterval({ start: startOfWeekDate, end: endOfWeekDate });
  };

  const handlePreviousYear = () => setCurrentYear(currentYear - 1);
  const handleNextYear = () => setCurrentYear(currentYear + 1);

  const renderMonthGrid = () => {
    const months = Array.from({ length: 12 }, (_, index) => new Date(currentYear, index, 1));
    const weekdayInitials = t('weekdayInitials', { returnObjects: true });

    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 p-1">
        {months.map((monthDate, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 p-1"
          >
            <div className="text-center text-xs font-bold text-blue-600 mb-1">
              {capitalizeFirstLetter(format(monthDate, 'MMMM', { locale: getCurrentLocale() }))}
            </div>
            <div className="grid grid-cols-7 gap-0.5 text-center">
              {weekdayInitials.map((day, i) => (
                <div key={i} className="text-[0.6rem] font-semibold text-gray-500">
                  {day}
                </div>
              ))}
              {getDaysInMonth(index).map((day) => {
                const isSelected = selectedDate && isSameDay(day, selectedDate);
                const today = highlightCurrentDay && isToday(day);
                const isCurrentMonth = day.getMonth() === index;

                return (
                  <button
                    key={day.toISOString()}
                    onClick={() => onDaySelect(day)}
                    className={`
                      text-[0.6rem] p-0.5 rounded-full leading-none h-5 w-5 flex items-center justify-center
                      transition-all duration-100 
                      ${!isCurrentMonth ? 'text-gray-300' : 'text-gray-700'}
                      ${isSelected ? 'bg-blue-500 text-white font-bold' : ''}
                      ${today ? 'ring-1 ring-green-500' : ''}
                      ${isCurrentMonth ? 'hover:bg-blue-100' : ''}
                    `}
                    disabled={!isCurrentMonth}
                  >
                    {format(day, 'd')}
                  </button>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-xl p-2 shadow-lg">
      {showNavigationButtons && (
        <div className="flex justify-between items-center mb-2">
          <button
            onClick={handlePreviousYear}
            className="px-2 py-1 text-xs bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
          >
            {t('previous')}
          </button>
          <h2 className="text-sm font-bold text-primary">{currentYear}</h2>
          <button
            onClick={handleNextYear}
            className="px-2 py-1 text-xs bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
          >
            {t('next')}
          </button>
        </div>
      )}
      {renderMonthGrid()}
    </div>
  );
};

export default FullYearCalendar;
