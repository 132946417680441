import { EmarContent } from '@/modules/settings/presentation/components/menus/emar/EmarSettings';
import { AllowStaffPermissionsMedication } from '@/modules/settings/presentation/components/menus/medications/AllowStaffPermissionsMedication';
import { ResidentContent } from '@/modules/settings/presentation/components/menus/Residents/ResidentParameters/ResidentsSettings';
import { StaffContent, StaffTab } from '@/modules/settings/presentation/components/menus/Staff/StaffSettings';
import { EnabledTrackers } from '@/modules/settings/presentation/components/menus/trackers/presentation/components/EnabledTrackers';
import { TrackerCleaningAreaTask } from '@/modules/tracker/presentation/components/cleaning/TrackerCleaningAreaTask';
import * as LucideIcon from 'lucide-react';


import { Module } from './interfaceModules'
import { QuickNotes } from '@/modules/settings/presentation/components/menus/notes/presentation/components/QuickNotes/QuickNotes';
import { EMarXs } from '@/modules/settings/presentation/components/menus/medications/presentation/components/EMarXs';
import { Questions } from '../../presentation/components/menus/Questions/presentation/components/Questions';
import TrackerHygieneType from '@/modules/tracker/presentation/pages/TrackerHygieneType';
import TrackerVitalsType from '@/modules/tracker/presentation/pages/TrackerVitalsType';
import TrackerDirectType from '@/modules/tracker/presentation/pages/TrackerDirectType';
import { BedroomQrCategory } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/BedroomQrCategory';
import { VisitQuestion } from '@/modules/settings/presentation/components/menus/Staff/ScreeningQuestions/presentation/components/VisitQuestion';
import { NoteCategory } from '@/modules/settings/presentation/components/menus/notes/presentation/components/NoteCategory/NoteCategory';
import { SheetField } from '../../presentation/components/menus/Residents/SheetField/presentation/components/SheetField';
import i18next from 'i18next';
import MainMenu from '../../presentation/components/menus/MainMenu/presentation/components/MainMenu';
import EmailNotifications from '@/modules/pharmacy/presentation/pages/settings/EmailNotifications';
import { DocumentsCategory } from '../../presentation/components/menus/Residents/DocumentCategories/presentation/components/DocumentsCategory';
import { DocumentsCategory as StaffDocumentsCategory } from '../../presentation/components/menus/Staff/DocumentCategories/presentation/components/DocumentsCategory';
import { DocumentsFile } from '../../presentation/components/menus/Staff/DocumentFiles/presentation/components/DocumentsFile';
import { LocationDocumentsCategory } from '../../presentation/components/menus/Locations/LocationDocumentCategories/presentation/components/LocationDocumentsCategory';
import { LocationDocumentsFolder } from '../../presentation/components/menus/Locations/LocationDocumentFolder/presentation/components/LocationDocumentsFolder';

export const modules: Module[] = [
  {
    key: 'adl',
    label: i18next.t('settings.adlArfOnly'),
    icon: <LucideIcon.ClipboardCheck />,
    component: null
  },
  {
    key: 'addressbook',
    label: i18next.t('settings.addressbookParameters'),
    icon: <LucideIcon.BookOpen />,
    component: null
  },
  {
    key: 'calendar',
    label: i18next.t('settings.calendarParameters'),
    icon: <LucideIcon.Calendar />,
    component: null
  },
  {
    key: 'caseload',
    label: i18next.t('settings.caseloadConfiguration'),
    icon: <LucideIcon.FileStack />,
    component: null
  },
  {
    key: 'clientConfig',
    label: i18next.t('settings.clientConfiguration'),
    icon: <LucideIcon.Settings />,
    component: null
  },
  {
    key: 'facilities',
    label: i18next.t('settings.facilities'),
    icon: <LucideIcon.Building2 />,
    subModules: [
      {
        key: "facilitiesDocumentationParameters",
        label: i18next.t('settings.facilitiesDocumentationParameters'),
        icon: <LucideIcon.ClipboardList />,
        component: ''
      },
      {
        key: "documentsCategory",
        label: i18next.t('settings.documentCategories'),
        icon: <LucideIcon.FileArchive />,
        component: <LocationDocumentsCategory />
      },
      {
        key: "documentsFolder",
        label: i18next.t('settings.documentFiles'),
        icon: <LucideIcon.File />,
        component: <LocationDocumentsFolder />
      },

    ]
  },
  {
    key: 'library',
    label: i18next.t('settings.libraryOfResourcesParameters'),
    icon: <LucideIcon.Library />,
    component: null
  },
  {
    key: "mainMenu",
    label: i18next.t('settings.mainMenu'),
    component: <MainMenu />,
    icon: <LucideIcon.Layout />
  },
  {
    key: 'medications',
    label: i18next.t('settings.medications'),
    icon: <LucideIcon.Pill />,
    subModules: [
      {
        key: "enabledMedications",
        label: i18next.t('settings.staffPermissionsAssignments'),
        icon: <LucideIcon.ClipboardList />,
        component: <AllowStaffPermissionsMedication />
      },
      {
        key: "xMar",
        label: i18next.t('settings.eMarXs'),
        icon: <LucideIcon.FileCheck />,
        component: <EMarXs />
      }
    ]
  },
  {
    key: 'notes',
    label: i18next.t('settings.notesParameters'),
    icon: <LucideIcon.FileText />,
    subModules: [
      {
        key: "quickNotes",
        label: i18next.t('settings.quickNotes'),
        icon: <LucideIcon.FileEdit />,
        component: <QuickNotes />
      },
      {
        key: "noteCategories",
        label: i18next.t('settings.noteCategories'),
        icon: <LucideIcon.Files />,
        component: <NoteCategory />
      },
    ]
  },
  {
    key: 'reports',
    label: i18next.t('settings.reports'),
    icon: <LucideIcon.FileBarChart />,
    component: null
  },
  {
    key: 'residents',
    label: i18next.t('common.residents'),
    icon: <LucideIcon.Users />,
    subModules: [
      {
        key: 'residentsParameters',
        label: i18next.t('settings.residentsParameters'),
        component: <ResidentContent />,
        icon: <LucideIcon.Users />,
      },
      {
        key: "sheetFields",
        label: i18next.t('settings.residents.sheetFields'),
        icon: <LucideIcon.StickyNote />,
        component: <SheetField />,
      },
      {
        key: "documentsCategory",
        label: i18next.t('settings.documentCategories'),
        icon: <LucideIcon.FileArchive />,
        component: <DocumentsCategory />
      },

    ]
  },
  {
    key: 'responsibleParty',
    label: i18next.t('settings.responsiblePartyAccess'),
    icon: <LucideIcon.Users />,
    component: null
  },
  {
    key: 'shiftConfig',
    label: i18next.t('settings.shiftConfiguration'),
    icon: <LucideIcon.Clock />,
    component: null
  },
  {
    key: 'staff',
    label: i18next.t('settings.staffParameters'),
    icon: <LucideIcon.UserCog />,
    subModules: [
      {
        key: "staffActive",
        label: i18next.t('settings.staffFormCards'),
        icon: <LucideIcon.UserCheck />,
        component: <StaffContent />,
      },
      {
        key: "visitQuestion",
        label: i18next.t('settings.activateVisitQuestions'),
        icon: <LucideIcon.ClipboardCheck />,
        component: <VisitQuestion />,
      },
      {
        key: "staffDocumentsCategory",
        label: i18next.t('settings.documentCategories'),
        icon: <LucideIcon.FileArchive />,
        component: <StaffDocumentsCategory />
      },
      {
        key: "StaffDocumentsFile",
        label: i18next.t('settings.documentFiles'),
        icon: <LucideIcon.File />,
        component: <DocumentsFile />
      }
    ]
  },
  {
    key: 'subscription',
    label: i18next.t('settings.mySubscription'),
    icon: <LucideIcon.CreditCard />,
    component: null
  },
  {
    key: 'trackers',
    label: i18next.t('settings.trackerParameters'),
    icon: <LucideIcon.Activity />,
    subModules: [
      {
        key: "cleaning",
        label: i18next.t('settings.cleaning'),
        icon: <LucideIcon.SprayCan />,
        isRequiredLocation: true,
        component: <TrackerCleaningAreaTask
          hasModal={true}
        />
      },
      {
        key: "bedroomqr",
        label: i18next.t('settings.bedroomQrCategories'),
        icon: <LucideIcon.QrCode />,
        component: <BedroomQrCategory />
      },
      {
        key: "vitals",
        label: i18next.t('settings.vitals'),
        icon: <LucideIcon.Heart />,
        component: <TrackerVitalsType />
      },
      {
        key: "direct",
        label: i18next.t('settings.direct'),
        icon: <LucideIcon.Target />,
        component: <TrackerDirectType />
      },
      {
        key: "hygiene",
        label: i18next.t('settings.hygiene'),
        icon: <LucideIcon.ShowerHead />,
        component: <TrackerHygieneType />
      },
      {
        key: "enabledTrackers",
        label: i18next.t('settings.enabledTrackers.enabledTrackers'),
        icon: <LucideIcon.ToggleRight />,
        isRequiredLocation: true,
        component: <EnabledTrackers />
      }
    ]
  },
  {
    key: 'visitor',
    label: i18next.t('settings.visitorModule'),
    icon: <LucideIcon.UserPlus />,
    subModules: [
      {
        key: "questions",
        label: i18next.t('settings.questions'),
        icon: <LucideIcon.HelpCircle />,
        component: <Questions />
      },
    ]
  }
];

export const pharmacyModules: Module[] = [
  {
    key: 'emailNotification',
    label: i18next.t('settings.emailNotifications'),
    icon: <LucideIcon.Mail />,
    component: <EmailNotifications />
  },
];