import * as QRCode from 'qrcode';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { encrypt } from '@/utils/helpers/encryption.helper';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

export function QrCode() {
    const { t } = useTranslation();
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
    const params = new URLSearchParams(location.search)
    const residentId = params.get('residentId');
    const residentName = params.get('residentName');
    const residentSpecialInfo = useSelector((state: RootState) => {
        const resident = state.residents.allResidents.residents.find((resident) => resident.id === residentId);
        return resident ? resident.special_information : '';
    });
    const reactUrl = import.meta.env.VITE_REACT_FRONT_URL;

    const navigate = useNavigate();

    useEffect(() => {
        if (!residentId || !residentName) {
            navigate('/dashboard')
            return
        }

    }, [residentId]);

    useEffect(() => {
        const generateQRCode = async () => {
            const redirectUrl = `/tracker/update-bedroom-qr/?residentId=${residentId}&residentName=${residentName}`; // La URL de redirección que quieres pasar
            const encryptedUrl = encodeURIComponent(encrypt(redirectUrl)); // Encriptamos y codificamos la URL

            const loginUrl = `${reactUrl}/login?redirect=${encryptedUrl}`;

            try {
                const qrCodeUrl = await QRCode.toDataURL(loginUrl);
                setQrCodeUrl(qrCodeUrl);
            } catch (error) {
                console.error('Error generando el código QR:', error);
            }
        };
        generateQRCode();
    }, []);

    const gradientBorderStyle = {
        background: "linear-gradient(to bottom, rgba(250, 250, 250, 0.2) 10%, rgba(47, 154, 214, 0.2) 90%)",
        transform: 'rotate(-25deg)',
        backgroundClip: "padding-box, border-box",
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen max-w-screen max-h-screen">

            <div className="hidden lg:block absolute top-0 left-0 transform -translate-x-24 -translate-y-28">
                <div className='w-[400px] h-[400px] flex items-center justify-center rounded-full' style={gradientBorderStyle}>
                    <div className='w-[385px] h-[385px] rounded-full bg-white'>
                        <div className="flex items-center justify-center h-full ml-24">
                            <img src="/img/caring_homes.png" className="w-40 my-auto rounded-lg transform rotate-[25deg]" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden lg:block absolute top-0 right-0 w-80 h-80 transform">
                <div className="w-full h-full rounded-bl-full bg-blue-300/50">

                </div>
            </div>

            <div className="hidden lg:block absolute bottom-0 left-0 w-80 h-80  opacity-50 transform">
                <div className="w-full h-full rounded-tr-full bg-blue-300"></div>
            </div>

            <div className="bg-[#A6D1E9]/30 shadow-md rounded-lg p-6 w-96 text-center">
                <h2 className="text-2xl font-bold text-[#3F3F46]">{t('trackers.trackerBedroom.qrCode')}</h2>
                <p className="text-[#3F3F46]">{t('trackers.trackerBedroom.repositioningCode')}</p>

                {!qrCodeUrl ? (
                    <p className="text-gray-500">{t('trackers.trackerBedroom.loadingQrCode')}...</p>
                ) : (

                    <div className="flex justify-center mb-4 mt-5">

                        <img src={qrCodeUrl} alt="QR Code" className="w-52 h-52 rounded-md" />
                    </div>
                )}

                <div className="mt-4">
                    <span className="text-sm font-bold text-[#3F3F46]">{t('trackers.Resident')}:</span>
                    <p className="text-2xl font-bold text-[#3F3F46]">{residentName}</p>
                </div>
                <Separator className="bg-[#2F9AD6] mb-4 mt-4" />
                <div>
                    <p className="text-sm text-[#3F3F46]">{t('trackers.trackerBedroom.focusSmarthphone')}</p>
                </div>
            </div>
            {residentSpecialInfo ? (
                <div className="mt-4">
                    <p className="font-bold text-[#2F9AD6] mt-2">{t('trackers.trackerBedroom.specialInformation')}:</p>
                    <p className="text-sm text-[#3F3F46]">{residentSpecialInfo}</p>
                </div>
            ) : ''}

            <div className="mt-8 text-center">
                <img src="/img/logo_redondo.png" alt="Caring Homes Logo" className="w-12 mx-auto" />
                <p className="font-bold text-[#2F9AD6] mt-2">Powered by Caring Data</p>
            </div>
        </div>
    )
}