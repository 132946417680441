import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { AppDispatch, RootState } from "@/store/store";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { Pencil, Trash } from "lucide-react";
import { DialogCreateOrUpdate } from "./DialogCreateOrUpdate";
import {
  deleteResidentField,
  getSummary,
  sheetFieldsLoading
  } from "@/modules/settings/presentation/components/menus/Residents/SheetField/slices/SheetFieldSlice";

import DeleteDialog from "@/common/presentation/components/DeleteDialog/DeleteDialog";
import { FiltersTable } from "./FiltersTable";

interface IDataItem {
  id: string,
  category: string;
  categoryId: string;
  category_id: string;
  detail: string;
  name: string;
  related_to: "both" | "face_sheets" | "emergency_sheets"; 
  residents: string[];
  status: boolean;
}


export const SummaryReport = () => {

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [data, setData] = useState<IDataItem[]>([]);

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const [filterValue, setFilterValue] = useState('');
  const [filteredData, setFilteredData] = useState<[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const { summaryData, isLoading } = useSelector((state: RootState) => state.settings.sheetFields);


  const [dataUpdate, setDataUpdate] = useState<IDataItem>({
    id: '',
    name: '',
    detail: '',
    related_to: 'both',
    status: true,
    category_id: '', 
    category: '',  
    categoryId: '',  
    residents: [],     
});

  const renderActionsColumn = (rowData: IDataItem) => (

    <div className="flex gap-2 justify-end z-[9999999999]">
      <Button variant="outline" size="icon" onClick={() => {
        setIsUpdate(true);
        setOpenDialog(true);
        setDataUpdate(rowData);
        
      }}>
      <Pencil className="h-4 w-4" />
      </Button>
      <Button variant="outline" size="icon" 
        onClick={() => {
          setIdDelete(rowData.id);
          setOpenDeleteAlert(true);
        }}
      >
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );

  const columns = [
    { key: "name", labelTranslationKey: "common.name" },
    { key: "category", labelTranslationKey: "common.category" },
    { key: "related_to", labelTranslationKey: "common.relatedTo" },
    { key: "status", labelTranslationKey: "settings.status" },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: '',
      render: renderActionsColumn,
      width: 'w-64',
      static: true
    }
  ];

  const handleSort = (columnKey: string, direction: 'descending' | 'ascending') => {
    // setSorting({ key: columnKey, direction });

    const sortedData = [...summaryData?.items ?? []].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    // setData(sortedData);
  };


  const loadMore = useCallback(async () => {
    if (!hasMoreRows) return;
    try {
      const nextPage = currentPage + 1;
      const params = {
        currentPage: nextPage,
        perPage: rowsPerPage
      };

      dispatch(sheetFieldsLoading());

      const response = await dispatch(getSummary(params));
      if (response?.payload?.items) {
        const parseData: IDataItem[] = Object.values(response.payload.items);

        setData((prevData: IDataItem[]) => [...prevData, ...parseData]);
        setCurrentPage(nextPage);
      }

    } catch (error) {
      throw new Error(`Error get data: ${error}`);
    }

  }, [hasMoreRows, currentPage]);


  const callbackRenderContent = (index: number, column: {key: string}, data: IDataItem[]) => {
    const row = data[index];
    switch (column.key) {
      case 'name':
        return <>{row?.name}</>
      case 'category':
        return <>
          {row?.category}
        </>;
      case 'related_to':
        return <>
          {t('settings.residents.' + row?.related_to)}
        </>;
      case 'status':
        return row?.status ? t('common.active') : t('common.inactive');
    }
  }

  const handleDelete = () => {
    if (idDelete) {
      dispatch(sheetFieldsLoading());
      dispatch(deleteResidentField(idDelete))
      getData();
    }
  }

  const handleFilterChange = async (value: string) => {
    setFilterValue(value);
    
    if (value.length >= 1) {
      dispatch(sheetFieldsLoading());
      const response = await dispatch(getSummary({ 
        currentPage: 1, 
        perPage: rowsPerPage,
        search: value
      }));
      
      if (response?.payload?.items) {
        setData(response.payload.items);

        
        setHasMoreRows(response.payload.pagination.hasMorePages);
      }
    } else if (value === '') {
      getData(1);
    }
  };

  const handleClearFilter = () => {
    setFilterValue('');
    getData(1);
  };

  const getData = async (cPage = currentPage) => {
    
    dispatch(sheetFieldsLoading());
    const response = await dispatch(getSummary({ cPage, perPage: rowsPerPage }));
    const data = response?.payload;
    if (data?.items) {
      setData(data.items);
  
      setHasMoreRows(data.pagination.hasMorePages)
    }
  };

  useEffect(() => {
    getData();
  }, [dispatch]);


  return <>

    <TableCustomVirtuoso
      additionalButtons={
        <Button
          variant="outline"
          size="sm"
          className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
          onClick={() => setOpenDialog(true)}
        ><span>{t('settings.notes.quickNotes.new')}</span></Button>
      }
      data={data}
      columns={columns}
      renderCellContent={callbackRenderContent}
      onSort={handleSort}
      loadMore={loadMore}
      hasMore={hasMoreRows}
      isLoading={isLoading}
      filters={
        <FiltersTable
          onFilterChange={handleFilterChange}
          filterValue={filterValue}
          onClear={handleClearFilter}
        />
      }
      showSearchInput={false}
    />

    <DialogCreateOrUpdate
      onChangeOpen={setOpenDialog}
      open={isOpenDialog}
      isUpdate={isUpdate}
      onChangeIsUpdate={setIsUpdate}
      dataUpdate={dataUpdate}
      onChangeGetData={getData}
    />

    <DeleteDialog
      isOpen={isOpenDeleteAlert}
      onOpenChange={setOpenDeleteAlert}
      handleDelete={handleDelete}
    />

  </>;


}

