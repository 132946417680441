import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RotateCcw, TriangleAlert, X } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';
import { EmptyIcon } from '@/modules/residents/presentation/components/EmptyIcon';

interface StaffAlertsProps {
  className?: string;
}

const alerts = [
  {
    title: 'Physician Report Due.',
    description: '09/14/2025'
  },
  {
    title: 'Physician Report Due.',
    description: '09/14/2025'
  },
  {
    title: 'Physician Report Due.',
    description: '09/14/2025'
  },
  {
    title: 'Physician Report Due.',
    description: '09/14/2025'
  },
  {
    title: 'Physician Report Due.',
    description: '09/14/2025'
  }
];

export const StaffAlerts = ({ className }: StaffAlertsProps) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState(alerts);

  const removeAlert = (index: number) => {
    const newAlerts = alert.filter((_, i) => i !== index);
    setAlert(newAlerts);
  };

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[249px] flex flex-col"
        headerTitle={t('residents.alerts')}
        headerIconLeft={TriangleAlert}
        headerIconRight={RotateCcw}
        numberOfAlerts={alert.length}
        handleEdit={() => setAlert(alerts)}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 max-h-[145px] overflow-y-hidden">
          <div className="space-y-3.5">
            {alert.length === 0 && (
              <div className="flex flex-col items-center gap-4 pt-2">
                <EmptyIcon className="h-auto w-20" />
                <p>There are no alerts for this resident.</p>
              </div>
            )}

            {alert.length > 0 && (
              <div className="flex flex-col gap-2">
                {alert.map((alert, index) => (
                  <div
                    key={index}
                    className="p-2 rounded-md bg-gray-100 text-gray-600
                      text-xs font-medium px-4 py-1.5 dark:bg-zinc-900"
                  >
                    <div className="grid grid-cols-[2fr_1fr_auto] grid-rows-1 text-right gap-2
                      lg:grid-cols-[1fr_auto] lg:grid-rows-[auto_auto] xl:grid-cols-[2fr_1fr_auto] xl:grid-rows-1
                      items-center rounded-md bg-zinc-100 dark:bg-zinc-900
                    ">
                      <div className="text-start items-center justify-start text-zinc-600 dark:text-white">
                        {alert.title}
                      </div>
                      <div className="items-center text-zinc-600 truncate whitespace-normal break-words
                        lg:text-left lg:row-start-2 lg:col-span-1 lg:px-0
                        xl:col-auto xl:row-auto xl:text-right xl:px-2.5 dark:text-white">
                        {alert.description}
                      </div>
                      <X
                        className="items-center row-auto col-auto justify-self-end
                        lg:row-span-2 lg:justify-self-end xl:row-auto xl:col-auto h-5 w-5 hover:cursor-pointer text-zinc-500 dark:text-white"
                        onClick={() => removeAlert(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};