import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

const formSchema = z.object({
  health: z.string().optional(),
  history: z.string().optional(),
  special_condition: z.string().optional(),
  family_history: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const HealthForm = () => {
  const {
    resident
  }: {
    resident: Resident | null;
    status: string;
  } = useSelector((state: RootState) => state.residents.resident);

  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>(
    resident?.health_information || {
      health: '',
      health_history: '',
      special_condition: '',
      family_history: ''
    }
  );

  const { updateHealthInformation, status: medicalInformationFormStatus } = useMedicalInformationStore();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const onSubmit = async (values: FormValues) => {
    const payload = {
      resident_id: resident?.id,
      ...values
    };

    try {
      await updateHealthInformation(payload);

      form.reset({ ...values });

      setDefaultValues({
        ...values
      });

      toast({
        description: 'Health information updated successfully'
      });
    } catch (error) {
      toast({
        description: 'Error while updating health information',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div>
        <CustomAccordion
          openOption={true}
          titleComponent={<h1 className="text-xl font-bold">General Information</h1>}
          defaultOpen={true}
        >
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4 p-2">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full xl:col-span-4">
                  <h1 className="text-lg font-semibold">Health</h1>
                  <p className="text-muted-foreground">
                    (Describe overall health condition including any dietary limitations)
                  </p>
                </div>
                <FormField
                  control={form.control}
                  name="health"
                  render={({ field }) => (
                    <FormItem className="col-span-full xl:col-span-8 ">
                      <FormControl>
                        <Textarea
                          className="min-h-auto h-auto"
                          {...field}
                          value={field.value || ''}
                          disabled={medicalInformationFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full xl:col-span-4">
                  <h1 className="text-lg font-semibold mb-2">Health History</h1>
                  <p className="text-muted-foreground">
                    (List currently prescribed medications and major illnesses, surgery, accidents; specify whether
                    hospitalized and length of hospitalization in last 5 years)
                  </p>
                </div>
                <FormField
                  control={form.control}
                  name="history"
                  render={({ field }) => (
                    <FormItem className="col-span-full xl:col-span-8">
                      <FormControl>
                        <Textarea
                          className="min-h-auto h-auto"
                          {...field}
                          value={field.value || ''}
                          disabled={medicalInformationFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full xl:col-span-4">
                  <h1 className="text-lg font-semibold mb-2">Special Condition</h1>
                  <p className="text-muted-foreground">(List any special conditions or needs)</p>
                </div>
                <FormField
                  control={form.control}
                  name="special_condition"
                  render={({ field }) => (
                    <FormItem className="col-span-full xl:col-span-8">
                      <FormControl>
                        <Textarea
                          className="min-h-auto h-auto"
                          {...field}
                          value={field.value || ''}
                          disabled={medicalInformationFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full xl:col-span-4">
                  <h1 className="text-lg font-semibold mb-2">Family History</h1>
                  <p className="text-muted-foreground">(List any major illnesses in family)</p>
                </div>

                <FormField
                  control={form.control}
                  name="family_history"
                  render={({ field }) => (
                    <FormItem className="col-span-full xl:col-span-8">
                      <FormControl>
                        <Textarea
                          className="min-h-auto h-auto"
                          {...field}
                          value={field.value || ''}
                          disabled={medicalInformationFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                <div className="col-span-2 space-y-4">
                  <Separator />

                  <div className="flex items-end gap-2 col-span-full xl:col-span-1">
                    <Button
                      className="flex gap-2"
                      variant={'default'}
                      type="submit"
                      disabled={medicalInformationFormStatus === 'loading'}
                    >
                      {medicalInformationFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </Form>
        </CustomAccordion>
      </div>
    </>
  );
};
