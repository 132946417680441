import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/common/presentation/components/ui/select";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { RootState } from "@/store/store";
import { useDateHelpers } from "@/utils/helpers/dates.helper";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDayTrackerDirect, getTrackerDirectTypes, saveTrackerDirect } from "../../slices/TrackerDirectSlice";
import UtilService from "@/common/services/UtilService";

const DirectDetailedTracker = ({ dataDirectReport }: { dataDirectReport: (page: number, reset: boolean) => void }) => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { toast } = useToast();
    const trackerDirect = useSelector((state: RootState) => state.trackerDirect);
    const { t } = useTranslation();
    const [types, setTypes] = useState([]);
    const { locationSelectedId } = useLocation();
    const { formatDate } = useDateHelpers();
    const [values, setValues] = useState(
        {
            date: new Date(),
            type: "",
            data: "",
            detail: "",
        }
    );

    useEffect(() => {
        dispatch(getTrackerDirectTypes());
    }, []);
    
    useEffect(() => {
        if(trackerDirect.directTypes && trackerDirect.directTypes.length) {
            setTypes(
                UtilService.sortObjectsAlphabetically(trackerDirect.directTypes, "name").filter(
                    (value) => value.status === "active"
                )
            );
        } else {
            setTypes([]);
        }
    }, [trackerDirect]);
        
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };


    const handleTypesChange = (value: any) => {        
        setValues({
             ...values,
             type: value
         });
    };

    const handleDateChange = (value: any) => {
        setValues({
             ...values,
             date: value
         });
     };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(params.r && params.r != "all-residents") {
            if(values.type) {
                dispatch(saveTrackerDirect(
                    [
                        {
                            date: values.date.toISOString(),
                            type: values.type,
                            value: values.data,
                            resident_id: params.r,
                            detail: values.detail,
                            location_id: locationSelectedId
                        }
                    ]
                ));
                
                fetchData();
                cleanInputs();
        
                toast(
                    {
                        description: t('trackers.trackerdirect.createDescription')
                    }
                );
            } else {
                toast(
                    {
                        description: t('trackers.trackerdirect.typeRequired')
                    }
                );
            }
        } else {
            toast(
                {
                    description: t('trackers.trackerdirect.residentRequired')
                }
            );
        }
    }

    const fetchData = () => {
        dispatch(
            getDayTrackerDirect(
                { 
                    location_id: locationSelectedId 
                }
            )
        );

        dataDirectReport(1, true);
    }

    const cleanInputs = () => {
        setValues(
            {
                date: new Date(),
                type: "",
                data: "",
                detail: ""
            }
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 mt-5">
                <div className="grid grid-cols-1 items-center gap-1">
                        <Label htmlFor={"date"} className="text-left">
                            {t('trackers.trackerdirect.date')}
                        </Label>

                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[280px] justify-start text-left font-normal w-100",
                                    !values.date && "text-muted-foreground"
                                )}
                                >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {values.date ? formatDate(values.date.toISOString()) : <span>mm/dd/yyyy - hh:mm aa</span>}
                                </Button>
                            </PopoverTrigger>

                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                value={values.date}
                                timePicker={true}
                                onChange={handleDateChange}
                                />
                            </PopoverContent>
                        </Popover>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"type"} className="text-right justify-self-start">
                        {t('trackers.trackerdirect.type')}
                    </Label>

                    <Select onValueChange={handleTypesChange} value={values.type}>
                        <SelectTrigger>
                            <SelectValue placeholder={t('trackers.trackerdirect.selectType')} />
                        </SelectTrigger>
                        
                        <SelectContent>
                            <SelectGroup>
                                {
                                    types.length ? (
                                        types.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>)
                                    ) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"data"} className="text-right justify-self-start">
                    {t('trackers.trackerdirect.value')}
                    </Label>

                    <Input
                        id={"data"}
                        name={"data"}
                        type="text"
                        value={values.data}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"detail"} className="text-right justify-self-start">
                        { t("cleaning.form.detailed.form.detail") }
                    </Label>

                    <Input
                        id={"detail"}
                        name={"detail"}
                        type="text"
                        value={values.detail}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="w-100 mt-4 flex flex-row justify-end">
                <Button type="submit" className="mr-2">
                    { t("cleaning.form.detailed.form.submit") }
                </Button>
            </div>
        </form>
    )
};

export default DirectDetailedTracker;
