import { useTranslation } from 'react-i18next';
import { CookingPot } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';

interface Props {
  className?: string;
}

const dataSections = [
  {
    title: "Diet Details",
    items: [
      { label: "Diet:", value: "Regular" },
      { label: "Allergies:", value: "None" },
      { label: "Food Preferences:", value: "None" },
      { label: "Dietary Restrictions:", value: "None" },
    ],
  },
  {
    title: "Feeding Details",
    items: [
      { label: "Feeding Assistance:", value: "Yes" },
      { label: "Feeding Tube:", value: "Yes" },
      { label: "Nutritional Supplements:", value: "None" },
      { label: "Special Instructions:", value: "None" },
    ],
  },
  {
    title: "Fluid and Dysphagia",
    items: [
      { label: "Fluid Consistency:", value: "Thin" },
      { label: "Dysphagia:", value: "Yes" },
      { label: "Choking Risk:", value: "Yes" },
      { label: "Chewing Difficulty:", value: "Yes" },
    ],
  },
  {
    title: "Oral and Swallowing Care",
    items: [
      { label: "Swallowing Difficulty:", value: "Yes" },
      { label: "Aspiration Risk:", value: "Yes" },
      { label: "Oral Care:", value: "Yes" },
      { label: "Denture Care:", value: "Yes" },
    ],
  },
];

export const FoodNutrition = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('residents.profile.foodAndNutrition')}
        headerIconLeft={CookingPot}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[166px]">
          <div className="space-y-2">
            {dataSections.map((section, index) => (
              <div
                className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2"
                key={index}
              >
                {section.items.map((item, index) => (
                  <div key={index} className="grid grid-cols-2 items-center gap-2 w-full border-b-[0.5px] border-zinc-200">
                    <span className="text-zinc-600 text-xs font-semibold leading-normal dark:text-white">
                      {item.label}
                    </span>
                    <span className="text-primary text-xs font-medium leading-normal text-left">
                      {item.value}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
      </div>
    </>
  );
};