import React from 'react'
import { Button } from '@/common/presentation/components/ui/button';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Trash} from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { format } from "date-fns";
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import TrackerSkinCheckDetailed from '../components/Skin Check/TrackerSkinCheckDetailed';

function TrackerSkinCheck() {
    const dispatch = useDispatch();
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const configurations = useSelector((state: RootState) => state.configurations);
    const [location, setLocation] = useState<string | null>(null);
    const { params, setParam } = useRouteParams();
    const { formatDate } = useDateHelpers();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [accentColor, setAccentColor] = useState("");
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [dataReport, setDatareport] = useState([]);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedSkin, setSelectedSkin] = useState<string>(params.e || 'form');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [sexResident, setsexResident] = useState<string>("");
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataOriginal, setDataOriginal] = useState<any[]>([]);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    
    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                    locationSelectedId === 'all-locations'
                    ? response.payload
                    : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (params.r) {
            onResidentSelect(params.r);
        }
    }, [residents]);

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedSkin('form');
        }

        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (!params.r) {
            setParam('r', 'all-residents');
        }
    }, [params.r, setParam]);
    
    useEffect(() => {
        // Initializes the state with complete data only if dataOriginal is empty
        if (!isInitialized && dataReport.length > 0) {
            setDataOriginal(dataReport); // Saves a copy of all the data initially
            setIsInitialized(true); // Marks initialization as complete
        }

        // Applies filtering when the original data is already loaded
        if (dataOriginal.length > 0 || dataReport.length > 0) {
            let updatedData = dataOriginal.length > 0 ? dataOriginal : dataReport;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: any) => item.location_id === locationSelectedId);

                }
            } else {
                updatedData = updatedData.filter((item: any) => {
                    if (locationSelectedId === "all-locations") {
                        return item.data.resident.id === params.r;
                    }

                    return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                });
            }

            // Only updates if dataReport needs to be changed
            if (JSON.stringify(updatedData) !== JSON.stringify(dataReport)) {
                setDatareport(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginal, dataReport]);

    useEffect(() => {
        setDatareport([]);
        setDataOriginal([]);
        setCurrentPage(1);
        setHasMoreRows(true);

        dataSkinReport(1, rowsPerPage, true);
    }, [locationSelectedId, params.r]);

    useEffect(() => {
        dataSkinReport(1, rowsPerPage, true);
    }, [locationSelectedId]);

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const onSelectHygiene = (e: string) => {
        setParam('e', e);
        setSelectedSkin(e);
    };

    const onResidentSelect = (residentId: any) => {
        setParam('r', residentId);
    
        if (residents && residents.length > 0) {
            const selectedResident = residents.find(resident => resident.id === residentId);
            
            if (selectedResident) {
                setsexResident(selectedResident.sex);
            } else {
                console.warn(`Resident with ID ${residentId} not found`);
            }
        } else {
            console.warn('Residents array is empty or not loaded yet');
        }
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    const renderActions = (row: any) => (
        <Button variant={"outline"} size={"icon"} onClick={() => handleButtonOpenDialog([], 'Delete', row, "Are you sure you want to delete this item?")}>
            <Trash className="h-4 w-4" />
        </Button>
    );

    const columnsreport = [
        { key: 'date', labelTranslationKey: t('trackers.Date/Time'), value: 'date', type: 'date' },
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name' },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), value: 'detail' },
        { key: 'who_name', labelTranslationKey: t('trackers.Staff'), value: 'who_name' },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    const dataSkinReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true);
        const json = { location_id: locationSelectedId, per_page: perPage, page, resident_id: params.r };
        const response = await TrackerService.getTrackerSkinCheck(json);
        if (response && Array.isArray(response.data) && response.data.length > 0) {
            let allData = [];

            response.data.forEach(item => {
                if (item) {
                    allData.push({
                        ...item,
                        created_at: item.created_at,
                        dataReportid: item.id
                    });
                }
            });

            if (resetData) {
                setDatareport(allData);
                setDataOriginal(allData);
            } else {
                setDatareport((prevData) => [...prevData, ...allData]);
                setDataOriginal((prevData) => [...prevData, ...allData]);
            }

            const { current_page, last_page, total } = response;

            if (current_page >= last_page || total <= allData.length) {
                setHasMoreRows(false);
            } else {
                setHasMoreRows(true);
            }

        } else {
            if (resetData) {
                setDatareport([]);
                setDataOriginal([]);
            }
            setHasMoreRows(false);
        }

        setIsLoading(false);
    };

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        dataSkinReport(currentPage + 1, rowsPerPage);

    }, [hasMoreRows, currentPage, dataSkinReport]);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string) => {
        console.log(fields, title, row, description);
        setShowEditDialog(true);
        setFormValue(row);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleSubmit = async () => {
        if (action === 'Delete') {
            await TrackerService.destroyTrackerSkinCheck(
                {
                    id: formData.dataReportid,
                    id_data: formData.data.id
                }
            );
        }

        setShowEditDialog(false);
        dataSkinReport(1, rowsPerPage, true);
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>
                                {t('trackers.trackerskin.title')}
                            </div>

                            <div className="font-semibold text-1xl">
                                {t('trackers.trackerskin.description')}
                            </div>
                        </div>

                        <Tabs defaultValue={selectedSkin} onValueChange={(e) => { onSelectHygiene(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('seizure.form.label')}
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }

            {
                selectedSkin === 'form' && (
                    <div>
                        {
                            locationSelectedId && (
                                <div className="flex flex-col gap-4">
                                    <ResidentSelector
                                        onSelect={onResidentSelect}
                                        locationId={location}
                                        residentId={params.r}
                                        showAllResidents={false}
                                    />
                                    
                                    <Card className="border-t-4 border-t-primary/80 mb-4">
                                        <CardHeader>
                                            <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                                {t('trackers.bodypart')}
                                            </CardTitle>
                                        </CardHeader>

                                        <CardContent>
                                            <TrackerSkinCheckDetailed dataSkinReport={dataSkinReport} sexResident={sexResident} orientation='horizontal' />
                                        </CardContent>
                                    </Card>
                                </div>
                            )
                        }
                    </div>
                )
            }
            
            {
                selectedSkin === 'report' && (
                    <div>
                        <div className="mt-4 mb-4">
                            <ResidentSelector
                                showKeepInEye={true}
                                onSelect={onResidentSelect}
                                residentId={params.r}
                                locationId={location}
                                description={t('calendar.selectAResident')}
                            />
                        </div>

                        <Card className="border-t-4 border-t-primary/50 mb-4">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                    {t('trackers.trackerskinreport.title')}
                                </CardTitle>
                            </CardHeader>

                            <CardContent>
                                <TableCustomVirtuoso
                                    data={dataReport}
                                    columns={columnsreport}
                                    renderCellContent={(index, column, data) => {
                                        const row = data[index];

                                        switch (column.key) {
                                            case 'date':
                                                return (
                                                    <>
                                                        {formatDate(row.data.date, false)}
                                                        <br />
                                                        {formatDate(row.data.date, true, true)}
                                                    </>
                                                );

                                            case 'resident_name':
                                                return `${row.data.resident.first_name} ${row.data.resident.last_name}`;

                                            case 'who_name':
                                                return `${row.data.who.first_name} ${row.data.who.last_name}`;

                                            case 'detail':
                                                return row.data.detail ? row.data.detail : '';

                                            default:
                                                return row[column.key];

                                        }
                                    }}
                                    loadMore={loadMore}
                                    hasMore={hasMoreRows}
                                    key={params.r}
                                    isLoading={isLoading}
                                    showSearchInput={false}
                                />

                            </CardContent>
                        </Card>

                        <CustomDialog
                            isOpen={showEditDialog}
                            onClose={handleCloseEditDialog}
                            title={dialogTitle}
                            onSubmit={handleSubmit}
                            description={dialogDescription}
                        >
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                <div className="flex flex-row justify-end mt-4">
                                    <Button type="submit" className="bg-primary">
                                        {action === "Delete" ? "Delete" : "Edit"}
                                    </Button>
                                </div>

                            </form>
                        </CustomDialog>
                    </div>
                )
            }
        </div>
    )
}

export default TrackerSkinCheck;
