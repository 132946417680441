import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TrackerVisitorsReportColumns from "./TrackerVisitorsReportColumns";
import { t } from "i18next";

const TrackerVisitorsReport = () => {
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    return (
        <div>
            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("visitors.reportTitle")}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    <TableCustomVirtuoso
                        data={dataSource}
                        columns={TrackerVisitorsReportColumns()}
                        renderCellContent={(index, column, data) => data[index][column.key]}
                        showSearchInput={false}
                    />
                </CardContent>
            </Card>
        </div>
    );
};

export default TrackerVisitorsReport;
