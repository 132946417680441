import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocationDocumentsFolder from '../infrastructure/service/LocationDocumentsFolderService';
import { SaveFolderPayload, DocumentsFolderI } from '../../../Staff/DocumentFiles/infrastructure/interfaces/DocumentsFolder';

export const getFolders = createAsyncThunk("locations/documents/files",
    async (params: { search: string; search_category: string; search_status: string; per_page: number; page: number }) => {
        const response = await LocationDocumentsFolder.getDocumentFolders(
            params.search,
            params.search_category,
            params.search_status,
            params.per_page,
            params.page);
        return response;
    });

export const saveFolder = createAsyncThunk(
    "locations/documents/save-folder",
    async (body: SaveFolderPayload, { rejectWithValue }) => {
        try {
            const response = await LocationDocumentsFolder.saveFolders(body);

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateFolder = createAsyncThunk("locations/documents/update-folder",
    async (body: SaveFolderPayload, { rejectWithValue }) => {
        try {
            const response = await LocationDocumentsFolder.updateFolder(body);
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteFolder = createAsyncThunk('locations/documents/delete-category',
    async (id: string) => {
        const response = await LocationDocumentsFolder.deleteFolder(id);
        return response;
    }
);

interface DocumentsCategoryState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    documentFiles: DocumentsFolderI[];
    saveFolders?: DocumentsFolderI
}

const initialState: DocumentsCategoryState = {
    status: 'idle',
    error: null,
    documentFiles: []
};

const locationFolders = createSlice({
    name: 'documentFilesStaff',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getFolders.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getFolders.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentFiles = action.payload;
        });

        builder.addCase(getFolders.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(saveFolder.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveFolders = action.payload;
        });

        builder.addCase(saveFolder.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(updateFolder.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentFiles = action.payload;
        });

        builder.addCase(updateFolder.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(deleteFolder.fulfilled, (state) => {
            state.status = 'idle';
        });

    }
});

export default locationFolders.reducer;


