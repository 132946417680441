import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

interface IUpdateTrackerBedroomQr {
    id: string;
    date: string;
    detail: string;
    bedroom_id: string | undefined;
}

const TrackerBedroomQrService = {

    getTrackerBedroomQr: async (search: string, location_id: string, resident_id: string, bedroom_id: string, user_id: string, perPage: number, page: number) => {
        try {
            const response = await axios.get('trackerBedroomQr/getTrackerBedroomQr', {
                params: { search, location_id, resident_id, bedroom_id, user_id, perPage, page },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching tracker BedroomQr: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching tracker BedroomQr: ' + error.message);
        }
    },

    getTrackerBedroomQrByDate: async (residentId: string, date: string) => {
        try {
            const response = await axios.get('trackerBedroomQr/filterBedroomByDate', {
                params: { residentId, date },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching tracker BedroomQr: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching tracker BedroomQr: ' + error.message);
        }
    },

    saveTrackerBedroomQr: async (formData: any) => {
        try {
            const response = await axios.post(`trackerBedroomQr/saveTrackerBedroomQr`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating tracker BedroomQr: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error creating tracker BedroomQr: ' + error.message);
        }
    },

    updateTrackerBedroomQr: async (formData: IUpdateTrackerBedroomQr) => {
        try {
            const response = await axios.post(`trackerBedroomQr/updateTrackerBedroomQr`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error updating tracker BedroomQr: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error updating tracker BedroomQr: ' + error.message);
        }
    },

    deleteTrackerBedroomQr: async (id: string) => {
        try {
            const response = await axios.delete(`trackerBedroomQr/deleteTrackerBedroomQr/${id}`);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error deleting tracker BedroomQr: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error deleting tracker BedroomQr: ' + error.message);
        }
    }
}

export default TrackerBedroomQrService;