import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as LucideIcons from "lucide-react";
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import ModuleCard from '@/common/presentation/components/ModuleCard/ModuleCard';
import { MENU_COLORS } from '@/modules/tracker/domain/constants/menuColors';
import { MainMenu } from '@/common/domain/MainMenu';

interface MenuCardProps {
  submenu: MainMenu[];
  translationContext: string;
  isLoading: boolean;
}

const MenuCard: React.FC<MenuCardProps> = ({ submenu, translationContext, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ScrollArea className="w-full max-h-[70vh] h-auto bg-neutralLight dark:bg-zinc-950
      p-4 flex flex-col items-center gap-4 rounded-lg shadow-md overflow-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-3 gap-x-2 md:gap-x-4 md:gap-y-5">
        {isLoading ? (
          <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
            <Spinner size={'lg'} />
          </div>
        ) : (
          submenu.map((item, index) => {
            const color = MENU_COLORS[index % MENU_COLORS.length];
            const IconComponent = LucideIcons[item.icon as keyof typeof LucideIcons] as React.ElementType;
            const IconTracker = IconComponent ? <IconComponent className="h-6 w-6" /> : null;
            const titleKey = `${translationContext}.${item.key}`;

            return (
              <ModuleCard
                key={item.id}
                id={item.id}
                color={color}
                title={t(titleKey)}
                icon={IconTracker}
                onClick={() => navigate(item.path)}
              />
            );
          })
        )}
      </div>
    </ScrollArea>
  );
};

export default MenuCard;