import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { CircleFadingPlus } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getDayTrackerDirect, getTrackerDirectTypes, saveTrackerDirectTypes } from "../../slices/TrackerDirectSlice";

const DirectTypeCreateDialog = () => {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        name: ""
    });

    const dataDayDirectReport = () => {
        dispatch(
            getDayTrackerDirect(
                { 
                    location_id: locationSelectedId 
                }
            )
        );
    }

    const getTypes = () => {
        dispatch(getTrackerDirectTypes());
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(values.name) {
            dispatch(
                saveTrackerDirectTypes(
                    {
                        data: {
                            name: values.name,
                            status: "active",
                            location_id: locationSelectedId
                        }
                    }
                )
            );
    
            fetchData();
            cleanInputs();
            closeEditDialog();

            toast(
                {
                    description: t("trackers.trackerdirect.successTypeCreated")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.task.create.toast.required")
                }
            );
        }
    }

    const fetchData = async () => {
        getTypes();
        dataDayDirectReport();
    }

    const cleanInputs = () => {
        setValues({
            name: ""
        });
    };

    return (
        <div>
            <Button variant="outline" size="sm" className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end' onClick={openEditDialog}>
                <CircleFadingPlus className="h-4 w-4 mr-1" />
                <span className='mr-1'>{t("cleaning.create.area.newButton")}</span>
            </Button>

            <CustomDialog 
                title={"Create"}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >
                <div>
                    <Label htmlFor="name" className="text-right">
                        {t("cleaning.create.task.create.form.name")}
                    </Label>
                    
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        className="col-span-3"
                        value={values.name}
                        onChange={handleInputChange}
                    />
                </div>

                <div className='flex justify-end mt-5' onClick={handleSubmit}>
                    <Button type="submit">
                        {t("cleaning.create.task.create.form.submit")}
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};

export default DirectTypeCreateDialog;
