import { t } from "i18next";
import DirectRenderTypeActionColumn from "./DirectRenderTypeActionColumn";

const useHyDirectTypeColumns = () => {
  const columns = [
    {
        key: 'name',
        id: 'name',
        labelTranslationKey: 'trackers.customtype.Name',
        value: 'name',
        visible: true,
        sortable: true,
        width: '70%'
    },
    {
        key: 'status',
        id: 'status',
        labelTranslationKey: 'common.status',
        value: 'status',
        render: (row) => row.status === "active" ? t("common.active") : t("common.inactive"),
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: DirectRenderTypeActionColumn,
        visible: true,
        static: true,
        width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(columns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = columns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useHyDirectTypeColumns;
