import { useTranslation } from 'react-i18next';
import { Contact, Mail, MapPin, Phone } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';

interface PrimaryPhysicianContactProps {
  className?: string;
  contactDetails?: {
    address: string;
    email: string;
    phone: string;
  }
}

export const PrimaryPhysicianContact = ({ className, contactDetails }: PrimaryPhysicianContactProps) => {
  const { t } = useTranslation();
  const contentClass = "flex items-center gap-6 rounded-md bg-zinc-100 dark:bg-zinc-900 h-8 px-5 py-2"
  const textClass = "text-xs text-zinc-600 dark:text-zinc-50 font-medium"

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('residents.profile.primaryPhysicianContactInformation')}
        headerIconLeft={Contact}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[130px] xl:max-h-[145px]">
          <div className="space-y-3.5">
            <div className={contentClass}>
              <Phone className="w-6 h-6 text-primary" />
              <p className={textClass}>
                {contactDetails?.phone || t('general.not_specified')}
              </p>
            </div>
            <div className={contentClass}>
              <Mail className="w-6 h-6 text-primary" />
              <p className={textClass}>
                {contactDetails?.email || t('general.not_specified')}
              </p>
            </div>
            <div className={`${contentClass}  ${
              typeof contactDetails?.address === 'string' && contactDetails.address.length > 15
              ? 'h-auto'
              : 'h-8'
            }`}>
              <MapPin className="w-6 h-6 text-primary" />
              <p className={textClass}>
                {contactDetails?.address || t('general.not_specified')}
              </p>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};