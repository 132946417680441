import QuickTrackerItem from "../interfaces/QuickTrackerItemInterface";
import QuickTrackerResident from "../interfaces/QuickTrackerResidentInterface";
import QuickTrackerType from "../interfaces/QuickTrackerTypeInterface";

const QuickTrackerService = {
    insertNewValue: (
        currentItems: QuickTrackerItem[], 
        action: string, 
        resident: QuickTrackerResident, 
        type: QuickTrackerType, 
        itemExist: boolean,
        volume?: any,
        id?: string, 
        id_data?: string
    ) => {
        const exist = currentItems.filter(
            (value) => value.resident.id === resident.id && value.type.id === type.id
        ).length;

        if(exist) {
            return currentItems.map(
                (value) => {
                    const newValue = Object.assign({}, value);

                    if(value.resident.id === resident.id && value.type.id === type.id) {
                        if(volume) newValue.volume = volume;
                        if(action) newValue.action = action;
                    }

                    return newValue;
                }
            );
        } else {
            return [
                ...currentItems,
                {
                    id,
                    id_data,
                    action,
                    resident,
                    type,
                    exist: itemExist,
                    volume
                }
            ];
        }
    },
    existOnList: (currentItems: QuickTrackerItem[], resident: QuickTrackerResident, type: QuickTrackerType) => {
        return !!currentItems.filter(
            (value) => value.resident.id === resident.id && value.type.id === type.id
        ).length;
    },
    existOnVolumes: (volumes, type) => {
        if(volumes) {
            return Object.keys(volumes).includes(type.name)
        } else {
            return false;
        }
    }
};

export default QuickTrackerService;
