import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import columnsReportMedications from './columnsReportMedications';
import { fetchCentralizedMedication } from '../../../slices/medicationsSlice';
import { RootState } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';

const ReportCentralizedMedicationRecord = () => {
  const { params } = useRouteParams();
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
  const { centralizedmedications } = useSelector((state: RootState) => state.medications);
  const residentId = params.r || 'all-residents';
  const locationId = params.l || 'all-location';

  useEffect(() => {
    dispatch(fetchCentralizedMedication({ locationId, residentId, page: 1, limit: 50 }));
  }, [residentId, locationId, dispatch]);

  const handleSort = (columnKey) => {
    setSorting((prevSorting) => ({
      key: columnKey,
      direction: prevSorting.direction === 'ascending' ? 'descending' : 'ascending'
    }));
  };

  return (
    <div className="flex flex-col gap-4 my-4">
      <TableCustomVirtoso
        data={centralizedmedications}
        columns={columnsReportMedications(residentId)}
        renderCellContent={(index, column, data) => data[index][column.key]}
        additionalButtons={[]}
        onSort={handleSort}
        disableAllFilters={true}
      />
    </div>
  );
};

export default ReportCentralizedMedicationRecord;
