import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMedicationsByResidentId, updateMassiveResidentMedications } from '../../../slices/medicationsSlice';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { RootState } from '@/store/store';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { Label } from '@/common/presentation/components/ui/label';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import DateInputWithCalendar from '../../MedicationsDetails/DateInputWithCalendar';
import useColumnsCentralizedMedications from './columnsCentralizedMedications';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import FullYearCalendar from './FullYearCalendar';
import { format } from 'date-fns';

const FormCentralizedMedicationRecord = () => {
  const createEmptyRow = () => {
    return columns.reduce(
      (acc, column, index) => {
        if (index >= 2) {
          acc[column.key] = '';
        }
        return acc;
      },
      { id: `sample-${Date.now()}` }
    );
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultResidentId = searchParams.get('r');
  const [residentId, setResidentId] = useState(defaultResidentId || '');
  const { medicationsByResidentId } = useSelector((state: RootState) => state.medications);
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const { params } = useRouteParams();
  const [filters, setFilters] = useState<{ filter: string }>({ filter: '' });
  const columns = useColumnsCentralizedMedications();
  const [data, setData] = useState([]);
  const [changedData, setChangedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [sampleRow, setSampleRow] = useState(createEmptyRow());
  const [modifiedRows, setModifiedRows] = useState({});
  const [hasSampleData, setHasSampleData] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFillDateModified, setIsFillDateModified] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const dialogCloseRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();
  const dateFormat = t('dateFormats.dateFormat');

  useEffect(() => {
    dispatch(
      getAllMedicationsByResidentId({
        residentId: params.r || 'all-residents',
        filter: filters.filter,
        page: currentPage,
        limit: pageSize
      })
    )
      .unwrap()
      .then((action) => {
        if (action.data) {
          const transformedData = action.data.map((medication) => {
            const transformedChart = medication.chart.map((chartItem) => ({
              rx: chartItem.rx,
              status: chartItem.status,
              refills: chartItem.refills,
              filleDate: chartItem.filleDate,
              qtyReceived: chartItem.qtyReceived,
              cycle_end_date: chartItem.cycle_end_date,
              expirationDate: chartItem.expiration_date,
              cycle_start_date: chartItem.cycle_start_date
            }));
            return { ...medication, chart: transformedChart };
          });
          setData(transformedData);
        }
      })
      .catch((error) => {
        console.error('Error al obtener medicaciones:', error);
      });
  }, [residentId, filters.filter, currentPage, dispatch, params.r]);

  useEffect(() => {
    setEditedData({});
  }, [residentId]);

  const handleSampleRowChange = (columnKey, value) => {
    const columnIndex = columns.findIndex((col) => col.key === columnKey);
    if (columnIndex >= 2) {
      setSampleRow((prevSampleRow) => {
        const newSampleRow = {
          ...prevSampleRow,
          [columnKey]: value
        };

        const hasAnyData = Object.values(newSampleRow).some((val) => val !== '');
        setHasSampleData(hasAnyData);

        return newSampleRow;
      });
    }
  };

  const applySampleToRow = (index) => {
    if (!hasSampleData) return;

    const currentRow = data[index];

    const updatedRow = {
      ...currentRow,
      ...Object.keys(sampleRow).reduce((acc, key) => {
        if (!['id', 'medication_name', 'type_medication_name', 'rx'].includes(key)) {
          acc[key] = sampleRow[key];
        }
        return acc;
      }, {})
    };

    const isFillDateChanged = updatedRow?.filleDate ? updatedRow.filleDate !== currentRow.filleDate : false;

    if (isFillDateChanged) {
      setIsFillDateModified(true);
    }

    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedRow;
      return newData;
    });

    setChangedData((prevChangedData) => ({
      ...prevChangedData,
      [index]: updatedRow
    }));

    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [index]: {
        id: currentRow.id,
        ...updatedRow
      }
    }));

    toast({ description: 'Sample data applied to row.' });
  };

  const handleCheckboxChange = (index, isChecked) => {
    if (isChecked && hasSampleData) {
      applySampleToRow(index);
    }
    handleCellChange(index, 'selected', isChecked, '', data[index].id);
  };

  const handleCellChange = useCallback(
    (index, columnKey, value, medicationName, medicationId) => {
      let formattedValue = value;
      if (columnKey === 'calendar' && isValid(new Date(value))) {
        formattedValue = new Date(value).toISOString();
      }

      setData((prevData) => {
        const updatedData = prevData.map((row, idx) => (idx === index ? { ...row, [columnKey]: formattedValue } : row));
        return updatedData;
      });

      setEditedData((prevEditedData) => ({
        ...prevEditedData,
        [index]: {
          id: medicationId,
          [columnKey]: formattedValue,
          medicationName,
          medicationId
        }
      }));

      setModifiedRows((prevModifiedRows) => ({
        ...prevModifiedRows,
        [medicationId]: true
      }));

      if (columnKey === 'filleDate') {
        setIsFillDateModified(value !== data[index].filleDate);
      }
    },
    [data]
  );

  const submitChart = async () => {
    setIsSaving(true);
    const rowsToSave = data.filter((row) => modifiedRows[row.id]);

    try {
      const response = await dispatch(updateMassiveResidentMedications(rowsToSave)).unwrap();
      if (response) {
        toast({ description: 'Actualización realizada con éxito!' });
        setModifiedRows({});
        dispatch(
          getAllMedicationsByResidentId({
            residentId: params.r || 'all-residents',
            filter: '',
            page: currentPage,
            limit: pageSize
          })
        );
      }
    } catch (error) {
      toast({ description: 'Error al guardar los cambios.', variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const renderCellContent = (index, column) => {
    if (column.key === 'expiration_date') {
      return (
        <td key={column.key}>
          <Dialog>
            <DialogTrigger>
              <Input
                type="text"
                value={data[index][column.key] ? format(new Date(data[index][column.key]), dateFormat) : ''}
                placeholder={t('medications.medicationForm.selectDate')}
                className="pr-10"
                maxLength={10}
                style={{ width: '150px' }}
              />
            </DialogTrigger>
            <DialogContent className="max-w-xl w-full overflow-auto">
              <div className="relative">
                <FullYearCalendar
                  initialYear={new Date().getFullYear()}
                  selectedDate={selectedDate}
                  onDaySelect={(day) => {
                    handleCellChange(index, column.key, day.toISOString(), '', data[index].id);
                    setSelectedDate(day);

                    if (dialogCloseRef.current) {
                      dialogCloseRef.current.click();
                    }
                  }}
                />
              </div>
              <DialogClose ref={dialogCloseRef} className="mt-2 text-xs hidden">
                Cerrar
              </DialogClose>
            </DialogContent>
          </Dialog>
        </td>
      );
    }

    return (
      <td key={column.key}>
        {column.editable ? (
          column.type === 'calendar' ? (
            <DateInputWithCalendar
              value={data[index][column.key] || ''}
              onChange={(date) => handleCellChange(index, column.key, date, '', data[index].id)}
            />
          ) : column.type === 'checkbox' ? (
            <Checkbox
              checked={data[index][column.key]}
              onCheckedChange={(isChecked) => handleCheckboxChange(index, isChecked)}
            />
          ) : (
            <Input
              type={column.type === 'number' ? 'number' : 'text'}
              value={data[index][column.key]}
              onChange={(e) => handleCellChange(index, column.key, e.target.value, '', data[index].id)}
              style={{ width: '100px' }}
            />
          )
        ) : (
          data[index][column.key]
        )}
      </td>
    );
  };

  return (
    <div className="space-y-6">
      <div className="border border-gray-300 p-4 bg-gray-100 rounded-md">
        <div className="flex flex-col mb-4">
          <h2 className="text-lg font-semibold text-primary mb-2">Sample</h2>
          <div className="w-full">
            <div className="flex flex-col bg-primary/10 p-3 rounded-lg border-l-8 border-primary hover:bg-primary/20 transition-all">
              <span className="text-primary font-bold text-sm ml-1">
                When clicking on the checkbox, the data from the SAMPLE row will be copied into the selected rows.
              </span>
            </div>
          </div>
        </div>
        <div className="flex gap-6">
          {columns.slice(4).map((column) => (
            <div key={column.key} className="flex flex-col">
              <Label htmlFor={`sample-${column.key}`} className="text-sm font-medium mb-1">
                {t(column.labelTranslationKey)}
              </Label>
              {column.type === 'calendar' ? (
                <DateInputWithCalendar
                  value={sampleRow[column.key] || ''}
                  onChange={(date) => handleSampleRowChange(column.key, date)}
                />
              ) : column.type === 'expiration_date' ? (
                <Dialog>
                  <DialogTrigger>
                    <Input
                      type="text"
                      value={sampleRow[column.key] ? format(new Date(sampleRow[column.key]), dateFormat) : ''}
                      placeholder={t('medications.medicationForm.selectDate')}
                      className="pr-10"
                      maxLength={10}
                      style={{ width: '150px' }}
                    />
                  </DialogTrigger>

                  <DialogContent className="max-w-xl w-full overflow-auto">
                    <div className="relative">
                      <FullYearCalendar
                        initialYear={new Date().getFullYear()}
                        selectedDate={sampleRow[column.key]}
                        onDaySelect={(day) => {
                          handleSampleRowChange(column.key, day);
                          if (dialogCloseRef.current) {
                            dialogCloseRef.current.click();
                          }
                        }}
                      />
                    </div>
                    <DialogClose ref={dialogCloseRef} className="mt-2 text-xs hidden">
                      Cerrar
                    </DialogClose>
                  </DialogContent>
                </Dialog>
              ) : (
                <Input
                  id={`sample-${column.key}`}
                  type={column.type === 'number' ? 'number' : 'text'}
                  value={sampleRow[column.key] || ''}
                  onChange={(e) => handleSampleRowChange(column.key, e.target.value)}
                  className="w-[140px]"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <TableCustomVirtoso
        columns={columns}
        data={data}
        total={medicationsByResidentId?.meta?.total}
        pageSize={pageSize}
        page={currentPage}
        selectedRows={selectedRows}
        onSelectRows={setSelectedRows}
        renderCellContent={renderCellContent}
        showViewOptions={false}
        tableId="medication-cmr-table"
      />
      <div className="flex justify-end gap-2">
        <Button
          onClick={submitChart}
          type="submit"
          className={`bg-primary text-white flex items-center gap-2`}
          disabled={isSaving || !isFillDateModified}
        >
          {isSaving ? (
            <>
              <svg className="w-4 h-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
              </svg>
              Saving...
            </>
          ) : (
            'Save in Cycle & LIC622'
          )}
        </Button>
      </div>
    </div>
  );
};

export default FormCentralizedMedicationRecord;
