import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import {
  createAlert,
  fetchAlertsCategories,
  setAlertStatus,
  updateAlert
} from '@/modules/alerts/presentation/slices/AlertSlice';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { Calendar } from '@/components/ui/calendar';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { CalendarIcon, Loader2, Trash } from 'lucide-react';
import { cn } from '@/lib/utils';
import { AlertCreateFormI, AlertI, FrequencyDetailsI, FrequencyT } from '../../domain/alerts.domain';
import { useConfirm } from '@/common/hooks/useConfirm';
import { Select, SelectContent, SelectTrigger, SelectValue, SelectItem } from '@/components/ui/select';
import WeekDaySelector from '@/common/presentation/components/WeekDaySelector/WeekDaySelector';
import MonthlyFrequencySelector from './MonthlyFrequencySelector';
import { frequencyOptions } from '../../domain/constants/options';
import { useTranslation } from 'react-i18next';
import { es } from 'date-fns/locale';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

type actionT = 'create' | 'update';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  relatedTo: 'resident' | 'user' | 'location';
  action?: actionT;
  alert?: AlertI;
}

interface OptionI {
  label: string;
  value: string;
  imageUrl?: string;
}

interface ErrorsI {
  title?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  residents?: string;
  users?: string;
  daysOfWeek?: string;
  monthlyDetails?: string;
}

const AlertCreationDialog: React.FC<IProps> = ({ isOpen, onClose, relatedTo, action = 'create', alert }) => {
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents) ?? [];
  const users = useSelector((state: RootState) => state.staff.allStaff.staff) ?? [];

  const [residentOptionsSelected, setResidentOptionsSelected] = useState<OptionI[]>([]);
  const [userOptionSelected, setUserOptionSelected] = useState<OptionI[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [residentOptions, setResidentOptions] = useState<OptionI[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState<ErrorsI>({});
  const [title, setTitle] = useState('');
  const [frequency, setFrequency] = useState<FrequencyT>('once');
  const [monthlyDetails, setMonthlyDetails] = useState<string>('');
  const [ConfirmDialog, confirm] = useConfirm('Are you sure?', 'are you sure you want to delete it?');
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);

  const { locationSelectedId } = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { formatDate } = useDateHelpers();
  const { params } = useRouteParams();

  const { t, i18n } = useTranslation();

  const commonInputClasses =
    'focus:border-2 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none focus-visible:ring-0 focus-visible:ring-primary focus-visible:ring-offset-0';

  useEffect(() => {
    if (!isOpen) return;
    getAlertCategories();
    getAlertData();
    getUsers();
  }, [isOpen]);

  const getUsers = async () => {
    if (relatedTo !== 'user') return;
    dispatch(
      getStaffByLocation(!locationSelectedId || locationSelectedId == 'all-locations' ? undefined : locationSelectedId)
    );
  };

  const usersOption: OptionI[] = useMemo(() => {
    return users.map((user) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      imageUrl: user.profile_url
    }));
  }, [users]);

  const locale = i18n.language === 'es' ? es : undefined;

  const formatters = React.useMemo(
    () => ({
      formatMonthCaption: (date: Date) => t(`calendar.month_${date.getMonth() + 1}`)
    }),
    [t]
  );

  const getAlertData = () => {
    if (action === 'update' && alert) {
      setTitle(alert.title);
      setDescription(alert.description);
      setStartDate(new Date(alert.start_date));
      setFrequency(alert.frequency);
      setEndDate(alert.end_date ? new Date(alert.end_date) : undefined);
      const frequencyDetails: FrequencyDetailsI = JSON.parse(alert.frequency_details);
      setDaysOfWeek(frequencyDetails.days);

      if (frequencyDetails?.monthly) {
        setMonthlyDetails(JSON.stringify(frequencyDetails.monthly));
      }

      if (alert.related_to === 'resident') {
        const residentIds = alert.residents.map((resident) => resident.id);
        const selectedResidents = residents
          .filter((resident) => residentIds.includes(resident.id))
          .map((resident) => ({
            label: `${resident.first_name} ${resident.last_name}`,
            value: resident.id,
            imageUrl: resident.image_url
          }));
        setResidentOptionsSelected(selectedResidents);
      }

      if (alert.related_to === 'user') {
        const userIds = alert.users.map((user) => user.id);        
        const selectedUsers = users
          .filter((user) => userIds.includes(user.id))
          .map((user) => ({
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
            imageUrl: user.image_url
          }));          
        setUserOptionSelected(selectedUsers);
      }
    }
  };

  useEffect(() => {
    setResidentDefault();
  }, [residents, params.r]);

  useEffect(() => {
    if (relatedTo == 'user' && !alert) {
      setUserDefault();
    }
  }, [usersOption]);

  const setResidentDefault = () => {
    const updatedResidentOptions = residents.map((resident) => {
      const option: OptionI = {
        label: `${resident.first_name} ${resident.last_name}`,
        value: resident.id,
        imageUrl: resident.image_url
      };
      if (resident.id === params.r) {
        setResidentOptionsSelected([option]);
      }
      return option;
    });
    setResidentOptions(updatedResidentOptions);
  };

  const setUserDefault = () => {
    const defaultUser = usersOption.find((user) => user.value === params.s);
    if (defaultUser) {
      setUserOptionSelected([defaultUser]);
    }
  };

  const getAlertCategories = useCallback(async () => {
    try {
      dispatch(fetchAlertsCategories());
    } catch (error) {
      console.log(error);
    }
  }, []);

  const validateForm = (): boolean => {
    const newErrors: ErrorsI = {};
    if (!title.trim()) {
      newErrors.title = t('customAlerts.validation.titleRequired');
    } else if (title.trim().length < 5) {
      newErrors.title = t('customAlerts.validation.titleMinLength');
    }

    if (!description.trim()) {
      newErrors.description = t('customAlerts.validation.descriptionRequired');
    } else if (description.trim().length < 5) {
      newErrors.description = t('customAlerts.validation.descriptionMinLength');
    }
    if (!startDate) {
      newErrors.startDate = t('customAlerts.validation.startDateRequired');
    }

    if (mustSelectWeekDay && daysOfWeek.length == 0) {
      newErrors.daysOfWeek = t('customAlerts.validation.daysOfWeekRequired');
    }

    if (relatedTo === 'resident' && residentOptionsSelected.length === 0) {
      newErrors.residents = t('customAlerts.validation.residentsRequired');
    }

    if (relatedTo === 'user' && userOptionSelected.length === 0) {
      newErrors.residents = t('customAlerts.validation.usersRequired');
    }

    if (frequency === 'monthly' && !monthlyDetails) {
      newErrors.monthlyDetails = t('customAlerts.validation.monthlyDetailsRequired');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    if (action == 'create') {
      const alertData: AlertCreateFormI = {
        title: title,
        description: description,
        start_date: startDate?.toISOString() ?? '',
        end_date: endDate?.toISOString() ?? '',
        days_of_week: daysOfWeek,
        frequency: frequency,
        resident_ids: relatedTo === 'resident' ? residentOptionsSelected.map((option) => option.value) : null,
        location_id: relatedTo === 'resident' ? params.l : null,
        user_ids: relatedTo === 'user' ? userOptionSelected.map((option) => option.value) : null,
        monthly_frequency_details: monthlyDetails,
        related_to: relatedTo
      };
      await createCustomAlert(alertData);
    } else {
      await updateCustomAlert();
    }

    await dispatch(setAlertStatus('refresh'));
    setIsLoading(false);
  };

  const createCustomAlert = async (data: AlertCreateFormI) => {
    const response: any = await dispatch(createAlert(data));

    if (response?.payload?.result == 'ok') {
      toast({
        title: t('customAlerts.toast.createSuccess.title'),
        description: t('customAlerts.toast.createSuccess.description'),
        className: 'bg-green-500 text-white'
      });
      resetForm();
    } else {
      setIsLoading(false);
      toast({
        title: t('customAlerts.toast.createError.title'),
        description: response?.error?.message ?? '',
        variant: 'destructive'
      });
    }
  };

  const updateCustomAlert = async () => {
    try {
      const response = await dispatch(
        updateAlert({
          id: alert?.id ?? '',
          title: title,
          description: description,
          start_date: startDate?.toISOString() ?? '',
          end_date: endDate?.toISOString() ?? '',
          days_of_week: daysOfWeek,
          monthly_frequency_details: monthlyDetails,
          frequency: frequency,
          resident_ids: relatedTo === 'resident' ? residentOptionsSelected.map((option) => option.value) : null,
          location_id: relatedTo === 'resident' ? params.l : null,
          user_ids: relatedTo === 'user' ? userOptionSelected.map((option) => option.value) : null,
          related_to: relatedTo
        })
      );

      if (response?.payload?.result == 'ok') {
        toast({
          title: t('customAlerts.toast.updateSuccess.title'),
          description: t('customAlerts.toast.updateSuccess.description'),
          className: 'bg-green-500 text-white'
        });
        resetForm();
      } else {
        toast({
          title: t('customAlerts.toast.updateError.title'),
          description: response.payload.message,
          variant: 'destructive'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = useCallback(() => {
    setTitle('');
    setDescription('');
    setStartDate(undefined);
    if (alert && alert.related_to === 'resident') {
      setResidentDefault();
    }
    if (alert && alert.related_to === 'user') {
      setUserDefault();
    }
    setEndDate(undefined);
    setDaysOfWeek([]);
    setErrors({});
    onClose();
  }, []);

  const residentSelectHandler = useCallback((options: OptionI[]) => {
    setResidentOptionsSelected(options);
    if (options.length > 0) {
      setErrors((prev) => {
        const { residents, ...rest } = prev;
        return rest;
      });
    }
  }, []);

  const userSelectHandler = useCallback((options: OptionI[]) => {
    setUserOptionSelected(options);
    if (options.length > 0) {
      setErrors((prev) => {
        const { residents, ...rest } = prev;
        return rest;
      });
    }
  }, []);

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    const ok = await confirm();
    if (ok) {
      setIsLoading(true);
      try {
        const response = await dispatch(
          updateAlert({
            id: alert?.id ?? '',
            status: 'removed'
          })
        );

        if (response.payload.result == 'ok') {
          toast({
            title: t('customAlerts.toast.deleteSuccess.title'),
            description: t('customAlerts.toast.deleteSuccess.description'),
            className: 'bg-green-500 text-white'
          });
          resetForm();
        } else {
          toast({
            title: t('customAlerts.toast.deleteError.title'),
            description: response.payload.message,
            variant: 'destructive'
          });
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      await dispatch(setAlertStatus('refresh'));
    }
  };

  const handlerWeekDaySelector = (day: string) => {
    setDaysOfWeek((prevSelected) => {
      const updateDays = prevSelected.includes(day) ? prevSelected.filter((k) => k !== day) : [...prevSelected, day];
      return updateDays;
    });
    if (day) {
      setErrors((prev) => {
        const { daysOfWeek, ...rest } = prev;
        return rest;
      });
    }
  };

  const mustSelectWeekDay = useMemo(() => {
    return ['weekly'].includes(frequency);
  }, [frequency]);

  const onCloseHandler = () => {
    resetForm();
    onClose();
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onCloseHandler}
      title={action == 'create' ? t('customAlerts.title.create') : t('customAlerts.title.update')}
      description=""
      className="w-11/12 md:max-w-[625px] lg:max-w-[625px]"
      newDialog={true}
      overFlow={true}
    >
      <ConfirmDialog />
      <form className="space-y-4">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="md:col-span-2 space-y-2">
            <Label>
              {t('customAlerts.form.alertTitle')} <span className="text-red-500">*</span>
            </Label>
            <Input
              value={title}
              disabled={isLoading}
              onChange={(e) => {
                setTitle(e.target.value);
                if (e.target.value.trim().length >= 5) {
                  setErrors((prev) => {
                    const { title, ...rest } = prev;
                    return rest;
                  });
                }
              }}
              placeholder={t('customAlerts.form.placeholderTitle')}
              className={cn(commonInputClasses, errors.title && 'border-destructive focus:ring-destructive')}
            />
            {errors.title && <p className="text-destructive text-sm mt-1">{errors.title}</p>}
          </div>

          <div className="space-y-2">
            <Label>
              {t('customAlerts.form.startDate')} <span className="text-red-500">*</span>
            </Label>{' '}
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  disabled={isLoading}
                  className={cn(
                    commonInputClasses,
                    'w-full justify-start text-left font-normal',
                    (!startDate || errors.startDate) && 'text-muted-foreground',
                    errors.startDate && 'border-destructive'
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {startDate
                    ? formatDate(startDate.toISOString() ?? '', false, false, false)
                    : t('customAlerts.form.pickDate')}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="z-[99999] flex justify-center">
                <Calendar
                  mode="single"
                  className="z-[9999]"
                  locale={locale}
                  formatters={formatters}
                  selected={startDate}
                  onSelect={(dateSelected) => {
                    setStartDate(dateSelected);
                    setErrors((prev) => {
                      const { startDate, ...rest } = prev;
                      return rest;
                    });
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            {errors.startDate && <p className="text-destructive text-sm mt-1">{errors.startDate}</p>}
          </div>

          <div className="space-y-2">
            <Label>{t('customAlerts.form.endDate')}</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  disabled={isLoading}
                  className={cn(
                    commonInputClasses,
                    'w-full justify-start text-left font-normal',
                    (!endDate || errors.endDate) && 'text-muted-foreground',
                    errors.endDate && 'border-destructive'
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {endDate
                    ? formatDate(endDate.toISOString() ?? '', false, false, false)
                    : t('customAlerts.form.pickDate')}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="z-[99999] flex justify-center">
                <Calendar
                  mode="single"
                  className="z-[9999]"
                  locale={locale}
                  formatters={formatters}
                  selected={endDate}
                  onSelect={(dateSelected) => {
                    setEndDate(dateSelected);
                    setErrors((prev) => {
                      const { endDate, ...rest } = prev;
                      return rest;
                    });
                  }}
                  disabled={(date) => {
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    return date < yesterday;
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className="space-y-2 z-[100000]">
            <Label>
              {t('customAlerts.form.frequency')} <span className="text-red-500">*</span>
            </Label>
            <Select
              value={frequency}
              onValueChange={(value: FrequencyT) => {
                setFrequency(value);
              }}
            >
              <SelectTrigger className={cn(commonInputClasses, 'w-full z-[100000]')}>
                <SelectValue placeholder="Frequency" />
              </SelectTrigger>
              <SelectContent className="z-[100000]">
                {frequencyOptions.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {t(item.label)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {mustSelectWeekDay && (
            <div className="space-y-2 z-[100000]">
              <Label>
                {t('customAlerts.form.daysOfWeek')} <span className="text-red-500">*</span>
              </Label>
              <div className="pt-2">
                <WeekDaySelector daysSelected={daysOfWeek} onSelect={handlerWeekDaySelector} />
              </div>
              {errors.daysOfWeek && <p className="text-destructive text-sm mt-1">{errors.daysOfWeek}</p>}
            </div>
          )}

          {frequency === 'monthly' && (
            <div className="space-y-2 z-[100000]">
              <MonthlyFrequencySelector
                date={startDate}
                setOptionSelected={setMonthlyDetails}
                optionSelected={monthlyDetails}
              />
              {errors.monthlyDetails && <p className="text-destructive text-sm mt-1">{errors.monthlyDetails}</p>}
            </div>
          )}

          <div className="md:col-span-2 space-y-2">
            <Label>{t('customAlerts.form.description')}</Label>
            <Textarea
              rows={2}
              disabled={isLoading}
              name="description"
              placeholder={t('customAlerts.form.placeholderDescription')}
              className={cn(commonInputClasses, errors.description && 'border-destructive focus:ring-destructive')}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                if (e.target.value.trim().length >= 5) {
                  setErrors((prev) => {
                    const { description, ...rest } = prev;
                    return rest;
                  });
                }
              }}
            />
            {errors.description && <p className="text-destructive text-sm mt-1">{errors.description}</p>}
          </div>

          {relatedTo == 'resident' && (
            <div className="md:col-span-2 space-y-2">
              <Label>{t('customAlerts.form.selectResidents')}</Label>
              <MultiSelect
                name="residents"
                isDisabled={isLoading}
                options={residentOptions}
                selectedOptions={residentOptionsSelected}
                onChange={residentSelectHandler}
                placeHolder={t('customAlerts.form.selectResidents')}
                className={cn('w-full mt-1', errors.residents && 'border-destructive')}
              />
              {errors.residents && <p className="text-destructive text-sm mt-1">{errors.residents}</p>}
            </div>
          )}

          {relatedTo == 'user' && (
            <div className="md:col-span-2 space-y-2">
              <Label>{t('customAlerts.form.selectUsers')}</Label>
              <MultiSelect
                name="users"
                isDisabled={isLoading}
                options={usersOption}
                selectedOptions={userOptionSelected}
                onChange={userSelectHandler}
                placeHolder={t('customAlerts.form.selectUsers')}
                className={cn('w-full mt-1', errors.residents && 'border-destructive')}
              />
              {errors.residents && <p className="text-destructive text-sm mt-1">{errors.residents}</p>}
            </div>
          )}
        </div>

        <div className="flex justify-end gap-2">
          {action === 'update' && (
            <Button
              onClick={handleDelete}
              disabled={isLoading}
              type="button"
              className="mt-4 bg-background hover:bg-background transition-colors text-red-500 flex gap-2"
            >
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              <Trash className="w-4 h-4 text-red-500" />
              {t('customAlerts.buttons.delete')}
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            className="mt-4 bg-primary hover:bg-primary/90 transition-colors"
          >
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {action == 'create' ? t('customAlerts.buttons.create') : t('customAlerts.buttons.update')}
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};

export default AlertCreationDialog;
