import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { Save, Trash } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useEffect } from 'react';

export interface DefaultValues {
  id?: string | null;
  carrier: string;
  carrier_number: string;
  type: string;
}

interface Props {
  defaultValues: DefaultValues;
  disabled?: boolean;
  onSubmit: (values: any) => void;
  onRemove?: (id: string | null) => void;
}

const formSchema = z.object({
  carrier: z.string(),
  carrier_number: z.string(),
  type: z.string()
});

export type FormValues = z.infer<typeof formSchema>;

export const CarrierCard = ({ defaultValues, disabled, onSubmit, onRemove }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues
  });

  // Agrega este useEffect
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      ...values,
      id: defaultValues.id
    });
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onRemove?.(defaultValues.id ?? null);
  };

  return (
    <div className="bg-white drop-shadow-md border rounded-md p-4 w-full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="grid grid-cols-3 gap-4">
          <FormField
            control={form.control}
            name="carrier"
            render={({ field }) => (
              <FormItem className="col-span-full xl:col-span-1">
                <FormLabel>Carrier Name</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem className="col-span-full xl:col-span-1">
                <FormLabel>Carrier Type</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-end gap-2 col-span-full xl:col-span-1">
            <FormField
              control={form.control}
              name="carrier_number"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Carrier Number</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
              <Button
                className="flex gap-2 border-green-500 hover:bg-green-500/10"
                variant={'outline'}
                size={'icon'}
                type="submit"
                disabled={disabled}
              >
                <Save className="h-4 w-4 flex-1 text-green-500" />
              </Button>
            )}

            <Button
              className="flex gap-2 border-red-500 hover:bg-red-500/10"
              variant={'outline'}
              size={'icon'}
              onClick={(e) => handleRemove(e)}
            >
              <Trash className="h-4 w-4 flex-1 text-red-500" />
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
