import { HistoryTableActions } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/HistoryTableActions';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

export const HistoryTableColumns: ColumnDef<any>[] = [
  {
    header: 'Date',
    cell: ({ row }) => format(new Date(row.original.created_at), 'MM/dd/yyyy hh:mm a')
  },
  {
    header: 'Staff',
    cell: ({ row }) => {
      return (
        <div>
          {row.original.created_by?.first_name} {row.original.created_by?.last_name}
        </div>
      );
    }
  },
  {
    accessorKey: 'actions',
    header: () => {
      return <div className="w-12">Actions</div>;
    },
    cell: ({ row }) => (
      <HistoryTableActions
        document_id={row.original.id}
        data={row.original.data}
        form_id={row.original.form_id}
        url={row.original.url}
      />
    )
  }
];
