import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { Avatar } from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';

const AlertsSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 pt-1">
      {Array.from({ length: 6 }).map((_, index) => (
        <div className="flex flex-col gap-3 w-full" key={index}>
          <Card className="w-full rounded-md p-2">
            <div className="flex flex-col gap-1 h-12">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center gap-2">
                  <Avatar className="h-6 w-6">
                    <Skeleton className="h-full w-full rounded-full" />
                  </Avatar>
                  <div className="flex flex-col 2xl:flex-row gap-1">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-4 w-16 sm:ml-2 md:ml-0 2xl:ml-1" />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default AlertsSkeleton;
