import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';

const CustomCombobox = ({ options = [], onSelect, onCreate, placeholder, value, onChange, entityType, fields }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const { control, handleSubmit, reset } = useForm();
  const searchInputRef = useRef(null);

  const filteredOptions = options.filter((option) => option?.name?.toLowerCase().includes(searchTerm.toLowerCase()));

  const onCreateSubmit = (data) => {
    const newEntity = { ...data, id: Date.now().toString() };
    onCreate(newEntity);
    onSelect(newEntity);
    onChange(newEntity.id);
    setIsCreating(false);
    reset();
    setSearchTerm('');
    setOpen(false);
  };

  return (
    <div className="relative w-full max-w-xs">
      <Select
        open={open}
        onOpenChange={(isOpen) => {
          if (isCreating) {
            setOpen(true);
            return;
          }

          setOpen(isOpen);

          if (isOpen) {
            setTimeout(() => searchInputRef.current?.focus(), 0);
          }
        }}
        value={value}
        onValueChange={(selectedValue) => {
          const selected = options.find((option) => option.id === selectedValue);
          onSelect(selected);
          onChange(selectedValue);

          setOpen(false);
          setSearchTerm('');
        }}
      >
        <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="w-full">
          <div className="p-2">
            {!isCreating && (
              <Input
                ref={searchInputRef}
                type="text"
                placeholder={t('medications.customCombobox.searchPlaceholder')}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setOpen(true); // Mantén el Select abierto mientras escribes
                }}
                className="w-full mb-2 focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
            {isCreating ? (
              <form onSubmit={handleSubmit(onCreateSubmit)} className="space-y-2">
                {fields.map((field) => (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    defaultValue=""
                    rules={{ required: field.required }}
                    render={({ field: { onChange, value } }) =>
                      field.type === 'phone' ? (
                        <PhoneInput
                          value={value}
                          onChange={onChange}
                          className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                        />
                      ) : field.options ? (
                        <Select onValueChange={onChange} value={value}>
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder={t(field.placeholder)} />
                          </SelectTrigger>
                          <SelectContent>
                            {field.options.map((option) => (
                              <SelectItem key={option.id} value={option.id}>
                                {option.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      ) : (
                        <Input
                          value={value}
                          onChange={onChange}
                          className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                          placeholder={t(field.placeholder)}
                        />
                      )
                    }
                  />
                ))}
                <div className="flex justify-between">
                  <Button type="button" variant="outline" size="sm" onClick={() => setIsCreating(false)}>
                    {t('medications.customCombobox.cancel')}
                  </Button>
                  <Button type="submit" size="sm">
                    {t('medications.customCombobox.create')}
                  </Button>
                </div>
              </form>
            ) : null}
            <div className="max-h-60 overflow-y-auto">
              {!isCreating &&
                filteredOptions.map((option) => (
                  <SelectItem key={option.id} value={option.id}>
                    <div className="flex items-center">{option.name}</div>
                  </SelectItem>
                ))}
            </div>
            {!isCreating && (
              <div className="sticky bottom-0 bg-white p-2">
                <Button variant="outline" className="w-full" onClick={() => setIsCreating(true)}>
                  <Plus className="mr-2 h-4 w-4" /> {t('medications.customCombobox.createNew', { entityType })}
                </Button>
              </div>
            )}
          </div>
        </SelectContent>
      </Select>
    </div>
  );
};

export default CustomCombobox;
