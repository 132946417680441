import { Separator } from '@/common/presentation/components/ui/separator';
import { TabsContent, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';

import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { Users } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFields } from './CustomFields';
import { FormCards } from './FormCards';

export const ResidentTab = () => {
  return (
    <TabsTrigger value="residents" className="w-full flex items-center justify-start gap-2">
      <Users />
      Residents
    </TabsTrigger>
  );
};


export const ResidentContent = () => {
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);
  const dispatch = useDispatch<AppDispatch>();


  useEffect(() => {
    if (Object.keys(configurations).length === 0) {
      dispatch(residentConfigurationsActions.getResidentsConfigurations());
    }
  }, [dispatch]);

  
  return ( 
    <>
    <ScrollArea>
      <FormCards  values={configurations.resident_form_cards || {}} />
      <Separator className="my-4" />
      <CustomFields />
    </ScrollArea>
    </>
  );
};
