import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/store/store';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card } from '@/components/ui/card';
import { getResidentNavigation } from '@/modules/residents/application/services/getResidentNavigation';

export const ResidentNavigationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const residentNavigationTabs = getResidentNavigation(t)

  return (
    <>
      <div className="hidden lg:block">
        <Card
          className={"w-full p-1.5 dark:bg-zinc-950 col-span-4 h-full"}
        >
          <div className="grid gap-2 rounded-lg bg-card w-full mx-auto dark:bg-zinc-950
            grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 justify-items-start items-center">
            {residentNavigationTabs.map((item, index) => (
              <button
                key={index}
                className={`group flex flex-col xl:flex-row items-center justify-start w-full h-full min-h-20 xl:min-h-12 lg:py-3
                  px-4 gap-2 rounded-sm text-sm hover:bg-primary hover:text-white hover:shadow-md cursor-pointer text-zinc-600 dark:text-white`}
                  onClick={() => navigate(`/residents/update?r=${resident?.id}&t=${item.value}`)}
              >
                <div className="w-5 h-5 flex-shrink-0">
                  {item.icon}
                </div>

                <span className="truncate text-center break-words whitespace-normal leading-tight">
                  {item.title}
                </span>
              </button>
            ))}
          </div>
        </Card>
      </div>
      <div className='block lg:hidden'>
        <Select
          onValueChange={(selectedValue) => {
            navigate(`/residents/update?r=${resident?.id}&t=${selectedValue}`);
          }}
        >
          <SelectTrigger className="w-full p-2 text-zinc-600 rounded-sm focus:ring-1 focus:ring-primary
            focus:border-primary dark:text-white">
            <SelectValue placeholder={t('residents.profile.profileOptions')} />
          </SelectTrigger>
          <SelectContent>
            {residentNavigationTabs.map((item, index) => (
              <SelectItem
                key={index}
                value={item.value}
                className="whitespace-normal break-words leading-tight px-1"
              >
                {item.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};
