import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const MenuService = {
  getLocationMenus: async(locationId: string)=>{
    const response = await axios.get(`/menus/getLocationMenus/${locationId}`);
    if(response?.data){
      return response.data;
    }
  },
  getClientMenu: async(locationId: string ='all-locations')=>{
    const response = await axios.get(`/menus/getClientsMenus/${locationId}`);
    if(response?.data){
      return response.data;
    }
  },
  updateClientsMenus: async(params: any)=>{
    const response = await axios.post(`/menus/updateClientsMenus`, params);
    if(response?.data){
      return response.data;
    }
  },
};

export default MenuService;
