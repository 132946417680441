import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertCircle, FileStack, LucideIcon, UserRoundCog } from 'lucide-react';
import { Button } from '@/common/presentation/components/ui/button';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { useNewDocument } from '@/modules/residents/infrastructure/hooks/documents/useNewDocument';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/common/presentation/components/ui/accordion"


type Props = {
  className?: string;
  setTabSelected: (tab: string) => void;
};

type NavItemProps = {
  label: string;
  onClick: () => void;
  icon?: LucideIcon;
};

const NavItem = ({ label, onClick, icon: Icon }: NavItemProps) => {
  return (
    <div className="flex items-center gap-2">
      <Button
        onClick={onClick}
        variant={'default'}
        size={'sm'}
        className={`flex items-center w-full px-4 py-1.5
          justify-start gap-2.5 rounded shadow-md hover:shadow-lg
          ${label.length > 20 ? 'h-auto' : 'h-6'}`}
      >
        {Icon && <Icon className="w-6 h-6" />}
        <span className='font-semibold leading-normal whitespace-normal break-words text-start
        dark:text-white'>
          {label}
        </span>
      </Button>
    </div>
  );
};

export const QuickLinks = ({ className, setTabSelected }: Props) => {
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const navigate = useNavigate();
  const { onOpen } = useNewDocument();
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        label: t('staff.profile.editStaff'),
        icon: UserRoundCog,
        onClick: () => navigate(`/staff/update?s=${staffMember?.id}`)
      },
      {
        label: t('general.documents'),
        icon: FileStack,
        onClick: () => onOpen()
      },
      {
        label: t('residents.alerts'),
        icon: AlertCircle,
        onClick: () => { setTabSelected('alerts') }
      }
    ];
  }, [navigate, onOpen, staffMember?.id]);

  const quickLinksItems = items.map((item, index) => (
    <div key={index} className="pb-2 last:pb-0">
      <NavItem label={item.label} onClick={item.onClick} />
    </div>
  ));

  return (
    <div className={cn(className, 'flex flex-row justify-center lg:justify-start')}>
      <Card
        className={'w-full p-4 lg:p-4 lg:pt-5 border-t-4 border-t-primary/80 dark:bg-zinc-950 col-span-4 h-full'}
      >
        <CardContent className='p-0 flex-1'>
          <div className='block lg:hidden'>
            <Accordion type="single" collapsible>
              <AccordionItem value="quickLinks" className='border-b-0'>
                <AccordionTrigger className='p-0 no-underline focus:outline-none focus:ring-0 hover:no-underline'>
                  <CardTitle className='text-primary leading-normal text-base font-semibold'>
                    { t('general.quickLinks')}
                  </CardTitle>
                </AccordionTrigger>
                <AccordionContent className="pb-0 mt-4">
                  {quickLinksItems}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className='hidden lg:block'>
            <CardHeader className='shrink-0 p-0'>
                  <CardTitle className='text-primary leading-normal text-base font-semibold'>
                    { t('general.quickLinks')}
                  </CardTitle>
            </CardHeader>
            <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto mt-4 max-h-[180px]">
              {quickLinksItems}
              <ScrollBar orientation="vertical" />
            </ScrollArea>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
