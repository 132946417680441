import { AppDispatch, RootState } from '@/store/store';
import { fetchGetDepartments } from '@/modules/departments/presentation/slices/DepartmentSlice.tsx';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice.ts';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { Note } from '../components/note.tsx';
import { setNoteFilters, cleanFilters, resetState, fetchGetNotesCategories } from '../slices/NoteSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider.tsx';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { RoleHelper } from '@/utils/helpers/user.helper.ts';

export default function NotesPage() {
  const { params, setParam } = useRouteParams();

  const dispatch = useDispatch<AppDispatch>();

  const [location, setLocation] = useState<string | null>(null);
  const { locationSelectedId } = useLocation();
  const [tabValue, setTabValue] = useState<string>('all');
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    dispatch<any>(resetState());
    dispatch(cleanFilters());
    calculateParams();
    setLocation(locationSelectedId);
    dispatch<any>(getResidentsByLocation(params.l === 'all-locations' ? undefined : params.l));
    dispatch<any>(getStaffByLocation(params.l === 'all-locations' ? undefined : params.l));
    dispatch<any>(fetchGetDepartments());
    dispatch<any>(fetchGetNotesCategories());
    dispatch(setNoteFilters(['nextPage', 1]));
    dispatch(setNoteFilters(['betweenTime', "last15days"]));
    dispatch(setNoteFilters(['location', params.l]));
  }, [params, locationSelectedId]);

  const isPharmacy = useMemo(() => RoleHelper.hasAnyRole(['Pharmacy']), [user]);

  const calculateParams = useCallback(() => {
    if (!params.l) {
      setLocation(locationSelectedId ?? 'all-locations');
      setParam('l', locationSelectedId ?? 'all-locations');
    }
    setTabValue('all');
  }, [params]);

  return (
    <div>
      {params.l && location && (
        <div className="flex-col md:flex">
          <LocationSelector quickView={true} allLocations={!isPharmacy} />
          <Note sizes={undefined} tabValue={tabValue} setTabValue={setTabValue} />
        </div>
      )}
    </div>
  );
}
