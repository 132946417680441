import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import React from 'react';

const AlertCardSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col rounded-lg border p-4 relative animate-pulse">
      <div className="flex flex-row gap-2 items-center">
        <Skeleton className="h-5 w-5 rounded-full" />
        <Skeleton className="h-6 w-1/2 rounded" />
        <div className="flex-grow" />
        <Skeleton className="h-6 w-12 rounded-full" />
      </div>

      <div className="my-3">
        <Skeleton className="h-[1px] w-full" />
      </div>

      <div className="space-y-4">
        {[1, 2, 3].map((item) => (
          <div key={item} className="flex items-center gap-3">
            <Skeleton className="h-4 w-4" />
            <Skeleton className="h-4 w-1/4" />
            <Skeleton className="h-4 w-1/2" />
          </div>
        ))}

        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <Skeleton className="h-4 w-1/4" />
            <Skeleton className="h-4 w-1/4" />
          </div>
          <Skeleton className="h-2 w-full rounded" />
        </div>
      </div>
    </div>
  );
};

export default AlertCardSkeleton;
