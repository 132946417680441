import { MenuProtectedRoute } from "./MenuProtectedRoute";
import { Route, Routes } from 'react-router-dom';
import ReportShareNote from '@/modules/reports/presentation/pages/ReportShareNote';
import ReportsPage from '@/modules/reports/presentation/pages/ReportsPage';


const ReportsRouter = () => {

    const parentRoute: string = '/reports';

    return (
        <Routes>
            <Route path="/" element={<ReportsPage />} />

            <Route path="/shared-note"
                element={
                    <MenuProtectedRoute
                        navigateTo={'/reports/shared-note'}
                        parentRoute={parentRoute}
                        childrenRoute="/reports/shared-note"
                        element={<ReportShareNote />}
                    />}
            />
        </Routes>
    );
};

export default ReportsRouter;