import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { DocumentResourceResponse, DocumentResourcesParams } from '../types/documents';

const ResidentFormService = {
  createCategory: async (data: any) => {
    const response = await axios.post(`/residents/documents/category/${data.category_id}`, { ...data });
    return response;
  },

  updateCategory: async (data: any) => {
    const response = await axios.post(`/residents/documents/updateCategory/${data.id}`, { ...data });
    return response;
  },

  deleteCategory: async (id: string) => {
    const response = await axios.delete(`/residents/documents/deleteCategory/${id}`);
    return response;
  },


  getCategories: async () => {
    const response = await axios.get(`/residents/documents/categories`);
    return response;
  },

  getCategoriesPaginate : async(search: string, page: number, per_page: number) => {
    const response = await axios.get(`/residents/documents/categoriesPaginate`, {params: { search, page, per_page }});
    return response;
  },

  getResource: async (data: DocumentResourcesParams) => {
    const response = await axios.get<DocumentResourceResponse>(`/documents/resource`, { params: { ...data } });
    return response;
  }
};

export default ResidentFormService;
