import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { RootState } from "@/store/store";
import { CircleFadingPlus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TrackerVisitorsDetailed from "../components/visitors/TrackerVisitorsDetailed";
import TrackerVisitorsReport from "../components/visitors/TrackerVisitorsReport";

const VisitorsPage = () => {
    const [selectedTab, setTab] = useState("form");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onSelectedTab = (event: string) => {
        setTab(event);
    }

    return (
        <div>
            <LocationSelector quickView={true} />

            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{color: accentColor}}>{t("visitors.title")}</div>
                            <div className="text-1xl">
                                {t("visitors.description")}
                            </div>
                        </div>

                        <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    { t("seizure.form.label") }
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    { t("seizure.report.label") }
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                )
            }

            {
                locationSelectedId && selectedTab === "form" && <TrackerVisitorsDetailed />
            }

            {
                locationSelectedId && selectedTab === "report" && <TrackerVisitorsReport />
            }
        </div>
    );
};

export default VisitorsPage;
