import { Dialog, DialogContent, DialogTrigger } from '@/common/presentation/components/ui/dialog';
import { X, Trash } from 'lucide-react';

interface NoteImageProps {
  images: Array<{ id: string, image: string }>;
  removeImage?: (item: { id: string, image: string }) => void;
  imagesDelete?: Array<string>;
}

export function ImgageGrid({ images, removeImage, imagesDelete }: NoteImageProps) {
  const gridCols = images.length === 1 ? 'grid-cols-1' : images.length === 2 ? 'grid-cols-2' : 'grid-cols-3';

  return (
    <div className="flex w-max space-x-4 p-4">
      <div className={`grid ${gridCols} gap-4`}>
        {images.map((item, index) => (
          <Dialog key={index}>
            <DialogTrigger>
              {!imagesDelete?.includes(item.id) && (
                <div className='relative'>
                  {removeImage && (
                    <div className='h-5 w-5 bg-white flex items-center justify-center rounded-md absolute right-1 top-1' onClick={(e) => {
                      e.stopPropagation();
                      removeImage(item);
                    }}>
                      <X className="h-4 w-4 text-zinc-500" />
                    </div>
                  )}
                  <img alt="Description" className="w-full h-full max-w-[90px] max-h-[90px] object-cover rounded-lg" src={item.image} />
                </div>
              )}
            </DialogTrigger>
            <DialogContent className="max-w-none w-[80%] h-[80%] bg-transparent flex items-center justify-center">
              <div className="w-full h-full max-w-full max-h-full flex items-center justify-center">
                <img alt="Description" src={item.image} className="object-contain max-w-full max-h-full rounded-lg" />
              </div>
            </DialogContent>
          </Dialog>
        ))}
      </div>
    </div >
  );
}
