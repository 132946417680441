import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/registry/new-york/ui/avatar';
import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { cn } from '@/lib/utils';
import { EllipsisVertical, Loader, Pencil, Trash } from 'lucide-react';
import { ExpandableText } from './expandableText';
import { ImgageGrid } from '../../common/imageGrid';
import { NoteAnswerI, NoteI } from '../../../../domain/note.domain';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { QuillJS } from '../../common/quilljs';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../../common/confirmDialog';
import NoteService from '../../../../infrastructure/services/NoteService';
import { format } from 'date-fns';

interface ChatProps {
  note: NoteI;
  displayChat?: boolean;
  displayAnswer?: boolean;
  editAnswer: () => void;
  showAnswers: () => void;
}

interface FormData {
  answer: string;
  html_answer: string;
  note_id: string;
  images: string[] | null;
}

export function Chat({ note, displayChat = true, displayAnswer, editAnswer, showAnswers }: ChatProps) {
  // Initial
  const { formatDate, t } = useDateHelpers();

  // Redux
  const authUser = useSelector((state: RootState) => state.auth.user);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);

  // Hooks
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [optionSelectedConfirmDialog, setOptionSelectedConfirmDialog] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState<NoteAnswerI>();
  const [forceRender, setForceRender] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    answer: '',
    html_answer: '',
    note_id: '',
    images: []
  });

  // Tools
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [note, displayChat]);

  useEffect(() => {
    if (optionSelectedConfirmDialog == 'yes') {
      removeAnswer()
    } else {
      setCurrentAnswer(undefined);
      setIsOpenConfirmDialog(false);
    }
  }, [optionSelectedConfirmDialog]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollElement) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }
  };

  // Behaviors
  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      answer: values.content,
      html_answer: values.html_content,
      images: values.images
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    setFormData({
      ...formData,
      ['note_id']: noteSelected
    });

    formData['note_id'] = noteSelected;

    let response;
    let images = formData.images;
    formData.images = [];
    if (currentAnswer?.id) {
      let data = { ...formData, id: currentAnswer.id };
      response = await NoteService.updateAnswer(data);
      setCurrentAnswer(undefined);
    } else {
      response = await NoteService.createAnswer(formData);
    }

    if (images?.length) {
      let form = new FormData();
      form.append('id', response.payload.id);
      form.append('type', 'answer');
      images.forEach(img => form.append('images[]', img))
      await NoteService.uploadImges(form);
    }

    setFormData({
      ...formData,
      answer: '',
      html_answer: '',
      images: []
    });

    setForceRender(prev => prev + 1);

    setIsLoading(false);
    showAnswers();
  };

  const staffPhotos = staff.reduce((acc, item) => {
    acc[item.id] = item.profile_url;
    return acc;
  }, {});

  const removeAnswer = async () => {
    if (currentAnswer) {
      await NoteService.removeAnswerNote(currentAnswer.id);
      setCurrentAnswer(undefined);
      setIsOpenConfirmDialog(false);
      setOptionSelectedConfirmDialog('');
    }
  }

  const optionsAnswerChat = (answer: NoteAnswerI) => {
    return <Popover>
      <PopoverTrigger>
        <Button variant="ghost" size="icon" disabled={!note}>
          <EllipsisVertical className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[fit-content]'>
        <Button
          className='text-red-600'
          variant="ghost"
          size="icon"
          onClick={() => {
            setCurrentAnswer(answer);
            setIsOpenConfirmDialog(true);
          }}
        >
          <Trash className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            setCurrentAnswer(answer);
            editAnswer();
          }}
        >
          <Pencil className="h-4 w-4" />
        </Button>
      </PopoverContent>
    </Popover>
  }

  const validateRol = () => {
    return authUser?.roles[0] && ['Super Administrator', 'Executive', 'Client Manager'].includes(authUser?.roles[0].name);
  }

  return (
    <>
      {displayAnswer && !displayChat && (
        <ScrollArea ref={scrollRef} type="always" className="flex-grow mt-1 h-[60%] max-h-[60%]">
          <form id="formChat" onSubmit={handleSubmit} className={cn('flex flex-col mt-1 relative')}>
            <div className="grid gap-4 absolute top-[0px] w-full">
              <div className="flex items-center space-x-2">
                <QuillJS key={forceRender} onChange={handleQuillJs} value={currentAnswer?.html_answer ?? ""} submit={true} />
                <Button type="submit" size="sm" className="flex-shrink-0 hidden" disabled={isLoading} />
              </div>
            </div>
          </form>
          {isLoading && (
            <div className="h-24 absolute inset-0 flex justify-center items-center">
              <Loader className="h-10 w-10 animate-spin" />
            </div>
          )}
        </ScrollArea>
      )}

      {!displayAnswer && displayChat && (
        <ScrollArea ref={scrollRef} type="always" className="flex-grow h-[60%] max-h-[60%]">
          <div className="flex flex-col">
            {note.answers.map((item) =>
              <Card key={item.id} className='mb-2'>
                <CardContent className='py-2'>
                  <div className="flex justify-between">
                    <div className='flex'>
                      <div className="mr-3 mt-1">
                        <Avatar className="w-8 h-8">
                          <AvatarImage
                            src={staffPhotos[item.created_by] ? staffPhotos[item.created_by] : undefined}
                            className="h-full w-full object-cover"
                          />
                          <AvatarFallback>
                            {item.author.split(' ').map((chunk: any) => chunk[0]).join('')}
                          </AvatarFallback>
                        </Avatar>
                      </div>
                      <div className="flex items-startpb-3">
                        <div className="flex flex-col">
                          <p>
                            <span className="text-sm font-bold mr-1 text-zinc-500">{item.author}</span>
                            <span className='text-xs text-zinc-500'>{item.created_at ? `${formatDate(item.format_created_at, false)} - ${format(new Date(item.format_created_at), 'hh:mm a')}` : ''}</span>
                            {item.type == 'private' && (
                              <Tooltip>
                                <TooltipTrigger>
                                  <Badge className="text-xs bg-primary hover:bg-primary text-white rounded-full shadow ml-3 capitalize hover" variant={'secondary'}>
                                    {item.type}
                                  </Badge>
                                </TooltipTrigger>
                                <TooltipContent className='bg-white border-t-4 border-t-primary/80 text-dark shadow ml-3'>
                                  <span className='font-regular'>{item.private_for}</span>
                                </TooltipContent>
                              </Tooltip>
                            )}
                          </p>
                          <div className='text-xs mt-1'>
                            <ExpandableText html={item.html_answer} maxLines={item.images && item.images.length > 0 ? 'line-clamp-2' : 'line-clamp-5'} />
                            {item.images && item.images.length > 0 && <ImgageGrid images={item.images} />}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center h-8'>
                      <Button className='text-xs shadow-none pr-2 pt-0 pl-0 flex items-center justify-center text-zinc-600 rounded hover:cursor-pointer hover:bg-white-900 bg-white-900 text-dark'>
                        {t("notes.reply")}
                      </Button>
                      {validateRol() && optionsAnswerChat(item)}
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        </ScrollArea>
      )}

      {isOpenConfirmDialog && (
        <ConfirmDialog setOptionModal={setOptionSelectedConfirmDialog} optionSelected={optionSelectedConfirmDialog} isOpen={isOpenConfirmDialog} message={t('notes.titleDelete')} />
      )}

    </>
  );
}
