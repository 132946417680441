import { Button } from '@/common/presentation/components/ui/button';
import { Label } from '@/common/presentation/components/ui/label';
import { Separator } from '@/common/presentation/components/ui/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '@/common/presentation/components/ui/sheet';
import { generateTextAction } from '@/modules/residents/infrastructure/services/AiService';
import { Loader2, Sparkles } from 'lucide-react';
import { useMemo, useState } from 'react';

interface SuggestionSheetProps {
  label: string;
  defaultValue?: string[];
  addCompletitionToTextarea: (value: string) => void;
  removeCompletionFromTextarea: (value: string) => void;
  currentValue: string;
}

export const SuggestionSheet = ({
  label,
  defaultValue = [],
  addCompletitionToTextarea,
  removeCompletionFromTextarea,
  currentValue
}: SuggestionSheetProps) => {
  const items = useMemo(() => defaultValue, [defaultValue]);
  const [aiItems, setAiItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

  const prompt = useMemo(() => {
    return `
    You are an expert in geriatric care and social work, specializing in evaluating potential new residents for senior care centers.
  
    **Objective:**
    Generate 5 new, unique suggestions to complete the "${label}" section of the evaluation form.
  
    **Information Provided:**
  
    - **Category:** ${label}
    - **Current Items:**
    ${items.join('\n')}
    - **Previous AI Suggestions:**
    ${aiItems.join('\n')}
  
    **Instructions:**
  
    1. Generate a list of 5 new suggestions that are relevant to the "${label}" category.
    2. Ensure that the suggestions are unique and do not duplicate any of the current items or previous AI suggestions.
    3. Each suggestion should be concise, specific, and appropriate for evaluating a potential new resident.
    4. Present the suggestions separated by commas.
    5. Do not include any personal data or identifiable information.
    6. Each suggestion should end with a period.
  
    **Output Format:**
  
    - Provide the suggestions in a single line, separated by commas.
  
    **Example:**
  
    - "Enjoys participating in musical activities., Requires assistance with daily living tasks., Has experience with community volunteering., Prefers a quiet living environment., Interested in group exercise programs."
    `;
  }, [items, label, aiItems]);

  const generateText = async () => {
    setIsLoading(true);
    const result = await generateTextAction(prompt);
    setAiItems(result.split(',').map((item) => item.trim()));
    setIsLoading(false);
  };

  const handleItemClick = (value: string) => {
    setSelectedItems((prevSelectedItems) => {
      const newSet = new Set(prevSelectedItems);
      const isCurrentlySelected = newSet.has(value);

      if (isCurrentlySelected) {
        newSet.delete(value);
        removeCompletionFromTextarea(value); // Llamamos a la función para eliminar el texto
      } else {
        newSet.add(value);
        addCompletitionToTextarea(value); // Llamamos a la función para agregar el texto
      }

      return newSet;
    });
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant={'outline'} className="rounded-full h-8">
          <Sparkles className="size-4 mr-2" />
          <span>Suggestions</span>
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{label} Suggestions</SheetTitle>
          <SheetDescription>
            There are some suggestions for you to complete this form. Please check the suggestions and add them to the
            form.
          </SheetDescription>
        </SheetHeader>

        <Separator className="my-4" />

        <div className="flex flex-col gap-4 mt-4">
          {items.map((value, index) => (
            <SuggestionItem
              key={index}
              value={value}
              onClick={() => handleItemClick(value)}
              isSelected={selectedItems.has(value)}
            />
          ))}
        </div>

        {aiItems.length > 0 && (
          <div>
            <Separator className="my-4" />
            <div className="flex flex-col gap-4 mt-4">
              <Label className="text-lg font-bold">AI Suggestions</Label>
              {aiItems.map((value, index) => (
                <AISuggestionItem
                  key={index}
                  value={value}
                  onClick={() => handleItemClick(value)}
                  isSelected={selectedItems.has(value)}
                />
              ))}
            </div>
          </div>
        )}

        <Button
          variant={'outline'}
          className="mt-4"
          onClick={() => {
            generateText();
          }}
        >
          {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <Sparkles className="size-4 mr-2" />}
          Generate More Suggestions With AI
        </Button>
      </SheetContent>
    </Sheet>
  );
};

// Componentes adicionales para los ítems
const SuggestionItem = ({
  value,
  onClick,
  isSelected
}: {
  value: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <div className="flex flex-row gap-4 cursor-pointer" onClick={onClick}>
      <div
        className={`w-full border-2 rounded-md p-2 ${isSelected ? 'bg-blue-100 border-blue-300' : 'border-gray-300'}`}
      >
        <p className="text-sm font-bold">{value}</p>
      </div>
    </div>
  );
};

const AISuggestionItem = ({
  value,
  onClick,
  isSelected
}: {
  value: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <div className="flex flex-row gap-4 cursor-pointer" onClick={onClick}>
      <div
        className={`w-full rounded-lg p-[3px] ${
          isSelected ? 'bg-blue-100' : 'bg-gradient-to-r from-[#6EE7B7] via-[#3B82F6] to-[#9333EA]'
        }`}
      >
        <div className="rounded-md bg-white">
          <p className="text-sm font-bold p-2">{value}</p>
        </div>
      </div>
    </div>
  );
};
