import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const SheetFields = {
  getFields: async ()=>{
    try {
      const response = await axios.get(`sheets/getFields`);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error get sheet fields: ${error.message}`);
    }
  },
  updateEnabledFields: async (formData: any)=>{
    try {
      const response = await axios.post(`sheets/updateEnabledFields`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error update field: ${error.message}`);
    }
  },
  saveOrUpdateResidentFields: async (formData: any)=>{
    try {
      const response = await axios.post(`sheets/saveOrUpdateResidentFields`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error update or create resident field: ${error.message}`);
    }
  },
  getSummary: async (formData: any) => {
    try {
      const { data } = await axios.post(`sheets/getSummary`, formData);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get summary: ${error.message}`);
    }
  },
  getCategories: async () => {
    try {
      const { data } = await axios.get(`sheets/getCategories`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get categories: ${error.message}`);
    }
  },
  deleteResidentField: async (id: string) => {
    try {
      const { data } = await axios.delete(`sheets/deleteResidentField/${id}`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error delete resident field: ${error.message}`);
    }
  }

}

export default SheetFields;