import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useInactivePharmacyDialog } from '@/modules/pharmacy/infrastructure/hooks/dashboard/useInactivePharmacyDialog';
import { useNewPharmacy } from '@/modules/pharmacy/infrastructure/hooks/dashboard/useNewPharmacy';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus, Pencil } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { NewStaffForm } from '@/modules/staff/presentation/components/dashboard/forms/NewStaffForm';
import { createNewPharmacy, fetchAllPharmacies, createNewPharmacyModule, fetchPharmaciesByName } from '../slices/PharmacySlice';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { PharmacyI } from '../../domain/pharmacy.domian';
import { NewPharmacyForm } from '../components/forms/NewPharmacyForm';
import Repeater from '../components/Repeater';
import { useNavigate } from 'react-router-dom';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';

const rolesToValidate = ['Super Administrator', 'Executive'];

export const PharmacyDashboardPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { onOpen, onClose, isOpen } = useNewPharmacy();
  const [sort, setSort] = useState<{ column: string; direction: string }>({
    column: '',
    direction: ''
  });
  const navigate = useNavigate();
  const pharmaciesState = useSelector((state: RootState) => state.pharmacy);
  const [pharmaciesData, setPharmaciesData] = useState<any[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);

  useEffect(() => {
    getFilterData(null,currentPage,rowsPerPage );
  }, []);


  const formatPharmacyData = (PharmacyList: PharmacyI[]) => {
    const formatedData = PharmacyList.map((pharmacy) => ({
      id: pharmacy.id,
      npi: pharmacy.npi,
      ncpdpid: pharmacy.ncpdpid,
      name: pharmacy.name,
      phone: pharmacy.phone,
      address: pharmacy.address,
      city: pharmacy.city,
      state: pharmacy.state,
      zip: pharmacy.zip,
      country: pharmacy.country,
      email: pharmacy.email,
      is_verified: pharmacy.is_verified,
      status: pharmacy.status,
      created_at: pharmacy.created_at,
      updated_at: pharmacy.updated_at
    }));
    return formatedData;
  };

  const tableSorting = (columnKey: string, direction: string) => {
    setSort({ column: columnKey, direction });
    const sortedData = [...pharmaciesData].sort((a: any, b: any) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setPharmaciesData(sortedData);
  };


  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const columnsPharmacyTable = [
    {
      key: 'name',
      id: 'name',
      labelTranslationKey: 'NAME',
      value: 'name',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'npi',
      id: 'npi',
      labelTranslationKey: 'NPI',
      value: 'npi',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'ncpdpid',
      id: 'ncpdpid',
      labelTranslationKey: 'NCPDPID',
      value: 'ncpdpid',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'phone',
      id: 'phone',
      labelTranslationKey: 'PHONE',
      value: 'phone',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'address',
      id: 'address',
      labelTranslationKey: 'ADDRESS',
      value: 'address',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'city',
      id: 'city',
      labelTranslationKey: 'CITY',
      value: 'city',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'state',
      id: 'state',
      labelTranslationKey: 'STATE',
      value: 'state',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'country',
      id: 'country',
      labelTranslationKey: 'COUNTRY',
      value: 'country',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'email',
      id: 'email',
      labelTranslationKey: 'EMAIL',
      value: 'email',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'zip',
      id: 'zip',
      labelTranslationKey: 'ZIP',
      value: 'zip',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: 'ACTIONS',
      static: true,
      render: (row: any) => renderActionsColumn(row)
    }
  ];


  const fetchSearchByName = (name: string) => {
    getFilterData(name,currentPage, rowsPerPage )
  };

  const getFilterData = async (
    name: string | null = null,
    page: number, 
    perPage: number
  ) => {

    setIsLoading(true);
    try {
      const response = await dispatch(
        fetchPharmaciesByName({name,page,perPage})
      );

      if (!response.payload) {
        setIsLoading(false);
        return;
      }
  
      const pharmacies= response.payload.data;
      const total = response.payload.total;
      let pharmaciesArray = formatPharmacyData(pharmacies);
      
      if (page !== currentPage) {
        pharmaciesArray = [...pharmaciesData, ...pharmaciesArray];
      }

      setPharmaciesData(pharmaciesArray);

      if (total > pharmaciesArray.length) {
        setHasMoreRows(true);
      } else {
        setHasMoreRows(false);
      }
  
      setTotalRows(total);
      setIsLoading(false);
  
    } catch (error) {
      console.log(error);
    }
  };

  
  const loadMore = useCallback(() => {
    if (!hasMoreRows || isLoading) return;
    setCurrentPage(currentPage + 1);
    getFilterData(null, currentPage + 1, rowsPerPage);
  }, [hasMoreRows, currentPage, getFilterData]);





  const renderActionsColumn = (row: any) => (
    <div className="flex gap-2 justify-center">
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          navigate(`update/${row.id}`);
        }}
      >
        <Pencil className="h-4 w-4" />
      </Button>
    </div>
  );

  const aditionalActions = useMemo(
    () => (
      <div className="flex ml-auto w-auto gap-2">
        {isAdmin && (
          <>
            <Button
              onClick={() => {
                navigate(`/pharmacies/update`);
              }}
              className="flex ml-auto w-auto gap-2"
              variant="default"
            >
              <span className="hidden md:block">New Pharmacy</span>
            </Button>
          </>
        )}
      </div>
    ),
    [onOpen, isAdmin]
  );

  return (
    <>
      <div className="flex flex-col gap-4 my-4 mt-20">
        <CustomCard title="Pharmacy" description="This module is designed for comprehensive pharmacy management, for creating and edit pharmacy profiles, assign new locations, and manage associated clients efficiently.">
          <TableCustomVirtuoso
            showSearchInput={true}
            columns={columnsPharmacyTable}
            data={pharmaciesData}
            isLoading={pharmaciesState.status === 'loading'}
            renderCellContent={(index: number, column: any) =>
              column.render ? column.render(pharmaciesData[index]) : pharmaciesData[index][column.key]
            }
            additionalButtons={aditionalActions}
            onSort={tableSorting}
            onFilterChange={fetchSearchByName}
            loadMore={loadMore}
            hasMore={hasMoreRows}
          />
        </CustomCard>
      </div>
    </>
  );
};
