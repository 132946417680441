import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import {
  Activity,
  CircleAlert,
  Gauge,
  HeartPulse,
  Loader2,
  LucideIcon,
  PersonStanding,
  Thermometer,
  Wind
} from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import VitalSetting from './VitalSetting';
import { Button } from '@/components/ui/button';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { fetchResidentVitalSetting, postResidentVitalSetting } from '../../slices/residentSlice';
import { VitalSettingI } from '@/modules/residents/infrastructure/types/trackerAkerts';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import CustomAlerts from './components/CustomAlerts';

interface IRowField {
  first_label: string;
  second_label: string;
  third_label?: string;
  key: string;
  value1: string;
  value2: string;
}

interface IVitalSetting {
  label: string;
  icon: LucideIcon;
  key: string;
  both_required?: boolean;
  fields: IRowField[];
}

interface IAlertsEnabling {
  bowel_movement_alerts?: 'active' | 'inactive';
  medicine_alerts?: 'active' | 'inactive';
}

type VitalsTypes = 'vitals' | 'custom';

const ResidentAlerts = ({ residentId }: { residentId: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [medicineAlert, setMedicineAlert] = useState<boolean>(false);
  const [bowelAlert, setBowelAlert] = useState<boolean>(false);
  const [savingInfo, setSavingInfo] = useState<boolean>(false);
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
  const [alertsType, setAlertsType] = useState<VitalsTypes>('vitals');
  const { t } = useTranslation();
  const [vitalSettings, setVitalSettings] = useState<IVitalSetting[]>([
    {
      icon: PersonStanding,
      label: 'residents.weight',
      key: 'weight',
      both_required: true,
      fields: [
        {
          key: 'increase_in_weight_1',
          first_label: 'residents.increaseInWeight',
          second_label: 'residents.lbsIn',
          third_label: 'residents.days',
          value1: '',
          value2: ''
        },
        {
          key: 'increase_in_weight_2',
          first_label: 'residents.increaseInWeight',
          second_label: 'residents.lbsIn',
          third_label: 'residents.days',
          value1: '',
          value2: ''
        },
        {
          key: 'decrease_in_weight_1',
          first_label: 'residents.decreaseInWeight',
          second_label: 'residents.lbsIn',
          third_label: 'residents.days',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: Gauge,
      label: 'residents.bloodPressure',
      key: 'blood_pressure',
      fields: [
        {
          key: 'systolic_if_above_1',
          first_label: 'residents.systolicIfAbove',
          second_label: 'residents.orBelow',
          value1: '',
          value2: ''
        },
        {
          key: 'diaslotic_if_above_1',
          first_label: 'residents.diastolicIfAbove',
          second_label: 'residents.orBelow',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: Wind,
      label: 'residents.pulseOxygen',
      key: 'pulse_oxygen',
      fields: [
        {
          key: 'pulse_oxygen_if_above',
          first_label: 'residents.ifAbove',
          second_label: 'residents.orBelow',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: HeartPulse,
      label: 'residents.heartRate',
      key: 'heart_rate',
      fields: [
        {
          key: 'heart_rate_if_above',
          first_label: 'residents.ifAbove',
          second_label: 'residents.orBelow',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: Thermometer,
      label: 'residents.temperature',
      key: 'temperature',
      fields: [
        {
          key: 'temperature_if_above',
          first_label: 'residents.ifAbove',
          second_label: 'residents.orBelow',
          value1: '',
          value2: ''
        }
      ]
    }
  ]);
  const [fieldsErrors, setFieldsErrors] = useState({});

  useEffect(() => {
    getVitalSettings();
  }, []);

  const handleInputChange = (
    settingIndex: number,
    fieldIndex: number,
    inputName: 'value1' | 'value2',
    value: string
  ) => {
    setVitalSettings((prevSettings) => {
      const newSettings = [...prevSettings];
      newSettings[settingIndex].fields[fieldIndex][inputName] = value;
      return newSettings;
    });
  };

  const saveAlertSettings = useCallback(async () => {
    const hasErrors = Object.values(fieldsErrors).some((error) => error);
    if (hasErrors) {
      toast({
        description: t('residents.fieldsRequired'),
        variant: 'destructive'
      });
      return;
    }
    setSavingInfo(true);
    try {
      const settingValues = formatValues(vitalSettings);
      await dispatch(
        postResidentVitalSetting({
          resident_id: residentId,
          bowel_movement_alerts: bowelAlert,
          medicine_alerts: medicineAlert,
          vitals_setting: settingValues
        })
      );

      toast({
        description: t('residents.settingsUpdated'),
        className: 'bg-green-500 text-white'
      });
    } catch (error) {}
    setSavingInfo(false);
  }, [vitalSettings, bowelAlert, medicineAlert]);

  const getVitalSettings = useCallback(async () => {
    try {
      const { payload } = await dispatch(fetchResidentVitalSetting(residentId));
      const { settings }: { settings: VitalSettingI } = payload;
      const { alerts_enabling }: { alerts_enabling: IAlertsEnabling } = payload;

      setBowelAlert(
        alerts_enabling?.bowel_movement_alerts && alerts_enabling?.bowel_movement_alerts === 'active' ? true : false
      );

      setMedicineAlert(
        alerts_enabling?.medicine_alerts && alerts_enabling?.medicine_alerts === 'active' ? true : false
      );

      setVitalSettingsValues(settings);
    } catch (error) {
      console.log(error);
    }
    setLoadingInfo(false);
  }, [saveAlertSettings]);

  const setVitalSettingsValues = useCallback((vitalSettings: VitalSettingI) => {
    setVitalSettings((prevSettings) => {
      return prevSettings.map((setting) => {
        if (vitalSettings[setting.key]) {
          const updatedFields = setting.fields.map((field) => {
            const newField = vitalSettings[setting.key].find((nf) => nf.key === field.key);
            if (newField) {
              return {
                ...field,
                value1: newField.value1 ?? field.value1,
                value2: newField.value2 ?? field.value2
              };
            }
            return field;
          });
          return { ...setting, fields: updatedFields };
        }
        return setting;
      });
    });
  }, []);

  const formatValues = (settingValues: IVitalSetting[]): VitalSettingI => {
    return settingValues.reduce((acc, setting) => {
      acc[setting.key] = setting.fields.map((field) => ({
        key: field.key,
        value1: field.value1,
        value2: field.value2
      }));
      return acc;
    }, {} as VitalSettingI);
  };

  return (
    <>
      <Tabs
        defaultValue={'vitals'}
        onValueChange={(e) => {
          setAlertsType(e as VitalsTypes);
        }}
        className="w-auto items-end flex flex-row-reverse"
      >
        <TabsList>
          <TabsTrigger
            value="vitals"
            className="data-[state=active]:bg-primary data-[state=active]:text-white flex gap-1"
          >
            <Activity className="h-4 w-4 mr-1" />
            {t('residents.vitals')}
          </TabsTrigger>
          <TabsTrigger
            value="custom"
            className="data-[state=active]:bg-primary data-[state=active]:text-white flex gap-1"
          >
            <CircleAlert className="w-4 h-4" />
            {t('residents.custom')}
          </TabsTrigger>
        </TabsList> 
      </Tabs>

      {alertsType === 'vitals' ? (
        <CustomCard
          title="Alerts"
          description=""
          button={
            <Button disabled={savingInfo} onClick={saveAlertSettings} className="dark:text-white">
              {savingInfo && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              {t('residents.save')}
            </Button>
          }
        >
          <div>
            <div className="mx-auto pt-6 pb-6">
              <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 2lg:grid-cols-3 gap-6">
                {vitalSettings.map((settings, index) => (
                  <VitalSetting
                    indexAlert={index}
                    key={index}
                    loading={loadingInfo || savingInfo}
                    icon={settings.icon}
                    label={settings.label}
                    setFieldsErrors={setFieldsErrors}
                    fields={settings.fields}
                    bothRequired={settings.both_required ?? false}
                    onInputChange={handleInputChange}
                  />
                ))}
              </div>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-row items-center justify-between rounded-lg  p-4">
                <div className="space-y-0.5 max-w-md">
                  <Label className="text-base">{t('residents.medicineAlerts')}</Label>
                  <p>{t('residents.enableMedicineAlerts')}</p>
                </div>
                <Switch
                  checked={medicineAlert as boolean}
                  onCheckedChange={() => {
                    setMedicineAlert(!medicineAlert);
                  }}
                  disabled={savingInfo || loadingInfo}
                />
              </div>

              {/* Bowel Movement Alerts */}
              <div className="flex flex-row items-center justify-between rounded-lg  p-4">
                <div className="space-y-0.5 max-w-md">
                  <Label className="text-base">{t('residents.bowelMovementAlerts')}</Label>
                  <p>{t('residents.enableAlertsBowelMovement')}</p>
                </div>
                <Switch
                  checked={bowelAlert as boolean}
                  onCheckedChange={() => {
                    setBowelAlert(!bowelAlert);
                  }}
                  disabled={savingInfo || loadingInfo}
                />
              </div>
            </div>
          </div>
        </CustomCard>
      ) : (
        <CustomAlerts/>
      )}
    </>
  );
};

export default ResidentAlerts;
