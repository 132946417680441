import React, { useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { useController } from 'react-hook-form';

const NumberInput = ({ name, control, initialValue = 0 }) => {
  const {
    field: { value, onChange }
  } = useController({
    name,
    control,
    defaultValue: initialValue
  });

  useEffect(() => {
    if (value < 0) {
      onChange(0);
    }
  }, [value, onChange]);

  const ensureNonNegative = (value) => Math.max(0, value);

  const handleChange = (e) => {
    let newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
    newValue = ensureNonNegative(newValue);
    onChange(newValue);
  };

  const handleIncrement = () => {
    let newValue = (value || 0) + 1;
    newValue = ensureNonNegative(newValue);
    onChange(newValue);
  };

  const handleDecrement = () => {
    let newValue = (value || 0) - 1;
    newValue = ensureNonNegative(newValue);
    onChange(newValue);
  };

  const displayValue = value === '' || value === undefined ? 0 : value;

  return (
    <div className="flex items-center border border-input rounded-md w-32 h-8 overflow-hidden focus-within:ring-ring-primary focus-within:ring-ring focus-within:ring-offset-2">
      <button
        onClick={handleDecrement}
        className="px-2 py-1 text-primary hover:bg-primary/10 transition-colors focus:outline-none"
        disabled={value <= 0}
      >
        -
      </button>
      <Input
        type="number"
        value={displayValue}
        onChange={handleChange}
        className="w-full h-full px-1 text-center border-none focus:outline-none focus:ring-0 text-sm focus-visible:ring-0"
      />
      <button
        onClick={handleIncrement}
        className="px-2 py-1 text-primary hover:bg-primary/10 transition-colors focus:outline-none"
      >
        +
      </button>
    </div>
  );
};

export default NumberInput;
