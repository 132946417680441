import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerHygieneService = {
    getTrackerHygiene: async (formData: any) => {
        try {
          const response = await axios.post(`trackerHygiene/getTrackerHygiene`, formData);
          if (response && response.data) {
            return response.data;
          } else {
            throw new Error('Error fetching Hygiene: No data returned from server');
          }
        } catch (error: any) {
          throw new Error('Error fetching Hygiene: ' + error.message);
        }
    },
    getDayTrackerHygiene: async (data: any) => {
        try {
            return await axios.post('trackerHygiene/getDayTrackerHygiene', data);
        } catch (error: any) {
            throw new Error('Error fetching Hygiene: ' + error.message);
        }
    },
    saveTrackerHygiene: async (formData: any) => {
        try {
          const response = await axios.post(`trackerHygiene/saveTrackerHygiene`, formData);
          if (response && response.data) {
            return response.data;
          } else {
            throw new Error('Error creating tracker hygiene: No data returned from server');
          }
        } catch (error: any) {
          throw new Error('Error creating tracker hygiene: ' + error.message);
        }
    },
    updateTrackerHygiene: async (formData: any) => {
        try {
            const response = await axios.post(`trackerHygiene/updateTrackerHygiene`, formData);
            if (response && response.data) {
            return response.data;
            } else {
            throw new Error('Error update tracker hygiene: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error update tracker hygiene: ' + error.message);
        }
    },
    getTrackerHygieneType: async (formData: any) => {
        try {
          const response = await axios.post(`trackerHygiene/getTrackerHygieneType`, formData);
          if (response && response.data) {
            return response;
          } else {
            throw new Error('Error fetching Hygiene Type:');
          }
        } catch (error: any) {
          throw new Error('Error fetching Hygiene Type: ' + error.message);
        }
    },
    updateTrackerHygieneType: async (formData: any) => {
      try {
        const response = await axios.post(`trackerHygiene/updateTrackerHygieneType`, formData);
        if (response && response.data) {
          return response;
        } else {
          throw new Error('Error update tracker hygiene type: No data returned from server');
        }
      } catch (error: any) {
        throw new Error('Error update tracker hygiene type: ' + error.message);
      }
    },
    saveTrackerHygieneType: async (formData: any) => {
      try {
        const response = await axios.post(`trackerHygiene/saveTrackerHygieneType`, formData);
        if (response && response.data) {
          return response;
        } else {
          throw new Error('Error creating tracker hygiene type: No data returned from server');
        }
      } catch (error: any) {
        throw new Error('Error creating tracker hygiene type: ' + error.message);
      }
    }
}

export default TrackerHygieneService;
