import React from 'react';
import { Row } from "@tanstack/react-table";
import { TrackerCleaningSummaryEditDialog } from './TrackerCleaningSummaryEditDialog';
import { TrackerCleaningSummaryDeleteDialog } from './TrackerCleaningSummaryDeleteDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  fetchSummary: (page: number, resetData: boolean) => void;
}

const RenderSummaryActionsColumn: React.FC<RenderActionsColumnProps> = (rowData, fetchSummary) => {

  return (
    <div className='flex'>
      <TrackerCleaningSummaryEditDialog summary={rowData} fetchSummary={fetchSummary} />
      <TrackerCleaningSummaryDeleteDialog id={rowData.id} fetchSummary={fetchSummary} />
    </div>
  );
};

export default RenderSummaryActionsColumn;
