import { Button } from "@/components/ui/button";
import React from "react";
import { HoverCardLocation } from "./HoverCardLocation";
import { cn } from "@/lib/utils";

export interface ImageProps {
    id: string;
    firstName: string;
    lastName?: string;
    description?: string;
    residentsInfo?: string;
    image?: string;
}

interface PropsI {
    item: ImageProps;
    isSelected: boolean;
    onSelect: () => void;
}

const SimpleCard: React.FC<PropsI> = ({ item, isSelected, onSelect }) => {  
    return(
        <>
        {
            item.id === 'all' ? (
                <Button
                    className={cn('border-primary', isSelected ? 'bg-primary/90 text-white hover:bg-primary/90 hover:text-white pointer-events-none' : 'bg-primary/5')} 
                    variant="outline"
                    onClick={onSelect}
                > 
                    { item.firstName } 
                </Button>
            ) : (
                <HoverCardLocation
                    locationName={item.firstName}
                    description={item.description}
                    residentsInfo={item.residentsInfo}
                    image={item.image}
                >
                    <Button
                        onClick={onSelect}
                        className={cn('border-primary', isSelected ? 'bg-primary/90 text-white hover:bg-primary/90 hover:text-white pointer-events-none' : 'bg-primary/5')} 
                        variant="outline"> { item.firstName }
                    </Button>
                </HoverCardLocation>
            )
        }
        </>
    );
};

export default SimpleCard;