import { NotebookTabs } from 'lucide-react';
import { AlertCard } from '../alert-card';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from '../skeleton';
import { useTranslation } from 'react-i18next';
import { AlertsResponseI, CustomAlertI, HiddenAlertsI } from '@/modules/dashboard/domain/dashboard.domain';
import moment from 'moment';
import AlertItemCard from '@/common/presentation/components/AlertItemCrad.tsx/AlertItemCard';
import ButtonMarkAsDone from '../ButonMarkAsDone/ButtonMarkAsDone';

interface Props {}

type IAlert = (CustomAlertI | AlertsResponseI) & { type: 'default' | 'custom' };

export const AdministrationAlerts: React.FC<Props> = () => {
  const userCustomAlerts = useSelector((state: RootState) => state.dashboard.userCustomAlerts);
  const users = useSelector((state: RootState) => state.staff.allStaff);
  const [hiddenAlerts, setHiddenAlerts] = useState<HiddenAlertsI>({});
  const { t } = useTranslation();

  const alerts: IAlert[] = useMemo(() => {
    if (userCustomAlerts.status === 'loading') {
      return [];
    }

    const currentDate = moment().format('YYYY-MM-DD');
    const customAlerts: IAlert[] = userCustomAlerts.data.map((alert) => ({
      ...alert,
      type: 'custom',
      date: currentDate,
      users: alert.users.filter((user) => !hiddenAlerts[alert.id]?.includes(user.id))
    }));
    return customAlerts.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
  }, [userCustomAlerts, hiddenAlerts]);

  const totalAlertsCount = useMemo(() => {
    return alerts.reduce((total, alert) => {
      if (alert.type === 'default') {
        return total + 1;
      } else {
        return total + (alert as CustomAlertI).users.length;
      }
    }, 0);
  }, [alerts]);

  return (
    <>
      <AlertCard
        title={t('dashboard.administrationAlerts')}
        alerts={Array.from({ length: totalAlertsCount })}
        icon={NotebookTabs}
        custom={true}
      >
        {userCustomAlerts.status === 'loading' || users.status == 'loading' ? (
          <AlertsSkeleton />
        ) : userCustomAlerts.data?.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {alerts.map((alert: IAlert, index) => {
              const customAlert = alert as CustomAlertI & { type: 'default' | 'custom' };
              return (
                <div key={customAlert.id} className="flex flex-col gap-3">
                  {customAlert.users.map((user) => (
                    <AlertItemCard
                      key={`${index}_${user.id}`}
                      names={`${user.first_name} ${user.last_name}`}
                      title={customAlert.title}
                      date={customAlert.date}
                      image={user?.profile_url ?? ''}
                      Button={
                        <ButtonMarkAsDone
                          alertId={customAlert.id}
                          relatedId={user.id}
                          hiddenAlerts={hiddenAlerts}
                          setHiddenAlerts={setHiddenAlerts}
                        />
                      }
                    />
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </AlertCard>
    </>
  );
};
