import { useState } from 'react';
import { Button } from '@/components/ui/button';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';

import AlertCreationDialog from '@/modules/alerts/presentation/components/AlertCreationDialog';
import { CircleFadingPlus } from 'lucide-react';
import AlertsList from '@/modules/alerts/presentation/components/AlertsList';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useTranslation } from 'react-i18next';


const CustomAlerts = () => {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const { params } = useRouteParams();
  const { t } = useTranslation();

  return (
    <CustomCard
      title={t('customAlerts.customAlerts')}
      description=""
      button={
        <Button
          className="flex flex-row gap-2"
          onClick={() => {
            setOpenCreate(true);
          }}
        >
          <CircleFadingPlus className="h- w-4" />
          { t('customAlerts.create') }
        </Button>
      }
    >
      <AlertCreationDialog
        isOpen={openCreate}
        onClose={() => {
          setOpenCreate(false);
        }}
        relatedTo="resident"
      />
      <AlertsList residentId={params.r} relatedTo="resident" />
    </CustomCard>
  );
};

export default CustomAlerts;
