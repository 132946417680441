import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DateRangeI, InitialStateI } from '../../domain/dashboard.domain';
import DashboardService from '../../infraestructure/services/DashboardService';
import { NotesStructureI } from '@/modules/notes/domain/note.domain';

const initialNotes: NotesStructureI = {
  result: 'loading',
  payload: [],
  meta: []
};

const initialState: InitialStateI = {
  urgentNotes: initialNotes,
  messagesForYou: initialNotes,
  notes: initialNotes,
  residentAlerts: {
    status: 'loading',
    data: []
  },
  residentCustomAlerts: {
    status: 'loading',
    data: []
  },
  userCustomAlerts: {
    status: 'loading',
    data: []
  },
  administrationAlerts: {
    status: 'loading',
    data: []
  },
  medicationAlerts: {
    status: 'loading',
    data: null
  },
  medicationDispensingAlerts: {
    status: 'loading',
    data: null
  }
};

export const fetchDashboardAlerts = createAsyncThunk('alerts/all', async (params: { location: string, signal: AbortSignal }) => {
  try {
    const response = await DashboardService.getAlerts(params.location, params.signal);    
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchSpeedTest = createAsyncThunk('alerts/speedTest', async ({date, noCheck = null} : {date: any, noCheck?: boolean|null}) => {
  try {
    const response = await DashboardService.getSpeedTest(date, noCheck ?? null);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchSystemTest = createAsyncThunk('alerts/systemTest', async (date: any) => {
  try {
    const response = await DashboardService.getSystemTest(date);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchSpeedTransactionTest = createAsyncThunk('alerts/SpeedTransactionTest', async (date: any) => {
  try {
    const response = await DashboardService.getSpeedTransactionTest(date);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchNotGivenMedicationAlerts = createAsyncThunk('alerts/NotGivenMedication', async (params: { locationId: string, date_range?: DateRangeI, signal?: AbortSignal }) => {
  try {
    const response = await DashboardService.getNotGivenMedicationAlerts(params.locationId, params?.date_range, params?.signal);
    return response;
  } catch (error) {
    throw error;
  }
});

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setUrgentNotes: (state, action) => {
        state.urgentNotes = action.payload;
    },
    setMessagesForYou(state, action) {
      state.messagesForYou = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardAlerts.pending, (state, _) => {
      state.residentAlerts = {
        status: 'loading',
        data: []
      };
      state.residentCustomAlerts = {
        status: 'loading',
        data: []
      };
      state.userCustomAlerts = {
        status: 'loading',
        data: []
      };
      state.administrationAlerts = {
        status: 'loading',
        data: []
      };
      state.urgentNotes = {
        result: 'loading',
        payload: [],
        meta: []
      };
      state.messagesForYou = {
        result: 'loading',
        payload: [],
        meta: []
      };
      state.notes = {
        result: 'loading',
        payload: [],
        meta: []
      };
      state.medicationAlerts = {
        status: 'loading',
        data: null
      };
      state.medicationDispensingAlerts = {
        status: 'loading',
        data: null
      };
    });

    builder.addCase(fetchDashboardAlerts.fulfilled, (state, action) => {
      state.residentAlerts = {
        status: 'idle',
        data: action.payload?.resident_alerts ?? []
      };
      state.residentCustomAlerts = {
        status: 'idle',
        data: action.payload?.resident_custom_alerts ?? []
      };
      state.userCustomAlerts = {
        status: 'idle',
        data: action.payload?.user_custom_alerts ?? []
      };
      state.administrationAlerts = {
        status: 'idle',
        data: action.payload?.administration_alerts ?? []
      };
      state.urgentNotes = {
        result: 'idle',
        payload: action.payload?.staff_notes.data ?? [],
        meta: []
      };
      state.messagesForYou = {
        result: 'idle',
        payload: action.payload?.private_notes?.data ?? [],
        meta: []
      };
      state.notes = {
        result: 'idle',
        payload: action.payload?.notes?.data ?? [],
        meta: []
      }
      state.medicationAlerts = {
        status: 'idle',
        data: action.payload?.medication_alerts ?? []
      };
    });
    builder.addCase(fetchNotGivenMedicationAlerts.fulfilled, (state, action) => {
      state.medicationDispensingAlerts = {
        status: 'idle',
        data: action.payload?.medication_no_given_alerts ?? {}
      }
    });
  }
});

export const { setUrgentNotes, setMessagesForYou } = DashboardSlice.actions;
export default DashboardSlice.reducer;
