import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { useState, useEffect, useCallback } from 'react';
import { Pencil, Trash } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { useTranslation } from 'react-i18next';
import { LocationDialogCreateOrUpdate } from './LocationDialogCreateOrUpdate';
import { FiltersTable } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/FiltersTable';
import { getCategories } from '../../slices/LocationDocumentsCategory';

interface DocumentsCategoryI {
    id: string;
    status: 'active' | 'inactive'
    label: string;
    order: number;
    value: string;
}

export function LocationDocumentsCategory() {

    const { t } = useTranslation();
    const [documentCategories, setDocumentCategories] = useState<DocumentsCategoryI[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<DocumentsCategoryI | null>(null);
    const [action, setAction] = useState<"edit" | "delete" | ''>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();
    const nextOrder = documentCategories.length > 0 
    ? Math.max(...documentCategories.map(record => record.order)) + 1 
    : 1;

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load



    const dataDocumentsCategory = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true)
        const response = await dispatch(getCategories({
            search: searchTerm,
            per_page: perPage,
            page
        }))

        const { categories }: {
            categories: { data: DocumentsCategoryI[], current_page: number, last_page: number, total: number }
        } = response.payload;
        const data = categories.data
        if (resetData) {
            setDocumentCategories(data);
        } else {
            setDocumentCategories(prevData => [...prevData, ...data]);
        }

        if (categories.current_page >= categories.last_page ||
            categories.total < data.length) {
            setHasMoreRows(false);
        } else {
            setHasMoreRows(true);
        }

        setIsLoading(false)

    }

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const handleClearFilter = () => {
        setSearchTerm('');
    };

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataDocumentsCategory(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataDocumentsCategory]);



    useEffect(() => {
        setDocumentCategories([]);
        setCurrentPage(1);
        setHasMoreRows(true);

        dataDocumentsCategory(1, rowsPerPage, true)
    }, [searchTerm]);

    const handleOpenModal = (rowData: DocumentsCategoryI, actionType: 'edit' | 'delete') => {
        setSelectedRow(rowData)
        setOpenModal(true)
        setAction(actionType)
    }

    const renderActions = (row: DocumentsCategoryI) => (

        <div className="flex space-x-2">
            <span className="cursor-pointer">
                <Pencil className="mr-2 h-4 w-4" onClick={() => handleOpenModal(row, 'edit')} />
            </span>
            <span className="cursor-pointer">
                <Trash className="mr-2 h-4 w-4" onClick={() => handleOpenModal(row, 'delete')} />
            </span>
        </div>

    );


    const columnsreport = [
        { key: 'name', labelTranslationKey: t('trackers.customtype.Name'), value: 'name' },
        {
            key: 'status', labelTranslationKey: t('medications.filterTable.status'),
            value: 'status'
        },
        {
            key: 'actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    const handleCloseModal = () => {
        setOpenModal(false)
        setAction('');
    }

    return (
        <div>
            <TableCustomVirtuoso
                data={documentCategories}
                columns={columnsreport}
                renderCellContent={(index, column, data) => {
                    const row = data[index];
                    switch (column.key) {
                        case 'name':
                            return `${capitalizeWords(row.label)}`;


                        case 'status':
                            return row.status
                                ? row.status === 'active'
                                    ? t('medications.discontinuedActive')
                                    : row.status === 'inactive'
                                        ? t('medications.discontinued')
                                        : capitalizeWords(row.status)
                                : t('medications.discontinuedActive');


                        default:
                            return row[column.key];
                    }
                }}
                additionalButtons={<Button
                    variant="outline"
                    size="sm"
                    className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
                    onClick={() => setOpenModal(true)}
                ><span>{t('common.new')}</span></Button>}
                isLoading={isLoading}
                filters={
                    <div className="w-1/2">
                        <FiltersTable
                            onFilterChange={handleSearchChange}
                            filterValue={searchTerm}
                            onClear={handleClearFilter}
                        />
                    </div>
                }
                showSearchInput={false}
                loadMore={loadMore}
                hasMore={hasMoreRows}
            />

            <LocationDialogCreateOrUpdate
                openModal={openModal}
                closeModal={handleCloseModal}
                dataDocumentsCategory={dataDocumentsCategory}
                selectedRow={selectedRow}
                action={action}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                nextOrder={nextOrder}
            />
        </div>
    )

}
