import { useLocation, useSearchParams } from 'react-router-dom';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Separator } from '@/common/presentation/components/ui/separator';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { RootState } from '@/store/store';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DateSelector } from './DateSelector';
import { EmarPrnSection } from './EmarPrnSection';
import { EmarRoutineSection } from './EmarRoutineSection';
import OverviewTable from './tables/OverviewTable';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useTranslation } from 'react-i18next';
import { fetchReasons } from '../slices/emarRoutineSlice';

export const EmarMedicationsCard = () => {
  const { params, setParam } = useRouteParams();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const l = queryParams.get('l');
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const effectiveLocationId = l ?? 'all-locations';
  const [date, setDate] = useState<Date>(new Date());
  const { getMedicationsTimesByResident, medications } = useMedicationStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident, shallowEqual);

  const routineMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'Routine');
  }, [medications]);

  const prnMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'PRN');
  }, [medications]);

  const formattedDate = useMemo(() => format(date, 'yyyy-MM-dd'), [date]);
  const residentId = params.r ?? 'all-residents';

  const accordionTitle = useMemo(() => {
    return `Medications for ${residentId === 'all-residents' ? 'All Residents' : resident?.first_name || ''}`;
  }, [residentId, resident]);

  useEffect(() => {
    const fetchMedications = () => {
      getMedicationsTimesByResident(effectiveLocationId as string, residentId, formattedDate);
    };

    fetchMedications();
    dispatch(fetchReasons());
  }, [effectiveLocationId, residentId, formattedDate, getMedicationsTimesByResident]);

  useEffect(() => {
    const storedLocation = sessionStorage.getItem('location_selected');
    if (storedLocation && !searchParams.has('l')) {
      setSearchParams({ l: storedLocation });
    }
  }, [searchParams, setSearchParams]);

  const handleSubTabChange = (tab: string) => {
    setParam('s', tab);
  };

  const renderTabsContent = () => (
    <Tabs value={params.s} onValueChange={handleSubTabChange}>
      <TabsList>
        <TabsTrigger
          value="managerRoutine"
          className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
        >
          {t('Manager Routine')}
        </TabsTrigger>
        <TabsTrigger
          value="managerPRN"
          className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
        >
          {t('Manager PRN')}
        </TabsTrigger>
        <TabsTrigger
          value="exception"
          className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
        >
          {t('Exception')}
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );

  const renderContent = () => {
    if (params.e === 'summary') {
      return (
        <>
          {renderTabsContent()}
          <OverviewTable />
        </>
      );
    }

    if (params.e === 'routine') {
      return (
        <>
          <DateSelector onSelectDate={setDate} defaultDate={date} />
          <p>Filter by date and time to view the medications that need to be administered.</p>
          <Separator />
          <EmarRoutineSection medications={routineMedications} residentId={residentId} date={date} emar={params.e} />
        </>
      );
    }

    if (params.e === 'prn') {
      return <EmarPrnSection medications={prnMedications} residentId={residentId} date={date} emar={params.e} />;
    }

    return null;
  };

  return (
    <CustomAccordion title={accordionTitle} defaultOpen openOption={false}>
      <div className="flex flex-col gap-4">{renderContent()}</div>
    </CustomAccordion>
  );
};
