import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useState, useEffect } from 'react';
import { saveCategories, deleteCategory, updateCategories } from '../../slices/LocationDocumentsCategory';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';

interface IDocumentsCategory {
    id: string;
    label: string;
    order: number;
    value: string;
    status: 'active' | 'inactive'
}

interface DocumentsCategoryProps {

    dataDocumentsCategory: (page: number, rowsPerPage: number, resetData: boolean) => void;
    rowsPerPage: number;
    action: 'edit' | 'delete' | '';
    openModal: boolean;
    closeModal: () => void;
    selectedRow: IDocumentsCategory | null;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    nextOrder: number;
}


export function LocationDialogCreateOrUpdate({ openModal, closeModal, dataDocumentsCategory, selectedRow, action,
    rowsPerPage, setCurrentPage, nextOrder,
}: DocumentsCategoryProps) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        id: undefined,
        label: '',
        status: false,
    });
    const dispatch = useDispatch<AppDispatch>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (action === 'edit' && selectedRow) {
            setFormData({
                id: selectedRow.value,
                label: selectedRow.label || '',
                status: selectedRow.status === 'active' ? true : selectedRow.status === 'inactive' ? false : true,
            });
        } else {
            setFormData({
                id: undefined,
                label: '',
                status: false,
            });
        }
    }, [selectedRow, action]);

    useEffect(() => {
        if (error) {
            setError('');
        }
    }, [closeModal]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsSubmitting(true);

        if (formData.label.trim().length < 3) {
            setError(t("settings.validThreeCharacters"));
            setIsSubmitting(false);
            return;
        }

        const regex = /^[a-zA-Z0-9'_\- ]*$/;
        if (!regex.test(formData.label.trim())) {
            setError(t("settings.charactersValids"));
            setIsSubmitting(false);
            return;
        }

        const payload = {
            id: action ? formData.id : undefined,
            category_id: formData.label.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase(),
            label: formData.label.trim(),
            order: nextOrder,
            status: formData.status ? ('active' as const) : ('inactive' as const),
        };


        try {
            let response;
            if (action === 'edit') {

                response = await dispatch(updateCategories(payload))

            } else {
                response = await dispatch(saveCategories(payload))

            }

            if ((response.payload.message).includes('code 400')) {

                setError(t("settings.categoryExists"));
                return
            }

            setCurrentPage(1);
            dataDocumentsCategory(1, rowsPerPage, true);
            closeModal();
            setFormData({
                id: undefined,
                label: '',
                status: false,
            });

        } catch (error: any) {


            console.error('Error saving category:', error);
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            await dispatch(deleteCategory(selectedRow!.value))
            setCurrentPage(1);
            dataDocumentsCategory(1, rowsPerPage, true);
            closeModal();
            setIsSubmitting(false);
        } catch (error) {
            console.log(error)
        }
    }


    return (

        <CustomDialog
            newDialog={true}
            isOpen={openModal}
            title={
                action === 'edit'
                    ? t('notes.edit')
                    : action === 'delete'
                        ? t('trackers.Delete')
                        : t('medications.customCombobox.create')
            }
            onClose={closeModal}
        >
            <div>
                {action === 'delete' ? (
                    <div>
                        <div className='flex justify-center'>
                            <h2>{t('trackers.sureDelete')}</h2>
                        </div>

                        <div className="w-full flex justify-end mt-5">
                            <Button onClick={handleDelete} disabled={isSubmitting} className="bg-red-600 text-white px-4 py-2 rounded">
                                {t('trackers.Delete')}
                            </Button>
                            <Button onClick={closeModal} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                                {t('calendar.cancel')}
                            </Button>
                        </div>
                    </div>
                ) :
                    <form onSubmit={handleSubmit}>

                        <div className="grid gap-4">
                            <Label htmlFor="label" className="control-label mb-3 mt-4">
                                {t('trackers.customtype.Name')}
                            </Label>
                            <div className="input-group">
                                <Input
                                    className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                    id="label"
                                    name="label"
                                    type="text"
                                    value={formData.label}
                                    onChange={handleInputChange}

                                />
                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>

                        <div className="grid gap-4">
                            <Label htmlFor="name" className="control-label mb-3 mt-4">
                                {t('medications.filterTable.status')}

                            </Label>
                            <div className="input-group">
                                <Switch
                                    checked={formData.status}
                                    onCheckedChange={(checked: boolean) =>
                                        setFormData((prev) => ({ ...prev, status: checked }))
                                    }
                                />

                            </div>
                        </div>

                        <div className="w-full flex justify-end mt-4">
                            <Button
                                type="submit"
                                className="bg-primary text-white px-4 py-2 rounded"
                                disabled={isSubmitting}
                            >
                                {t('trackers.Save')}
                            </Button>
                        </div>
                    </form>}
            </div>
        </CustomDialog>


    )

}