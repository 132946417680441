import React, { Component, useEffect, useState } from "react";
import { Switch } from "@/common/presentation/components/ui/switch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getClientMenu, updateClientsMenus } from "@/common/presentation/slices/menuSlice";
import { AppDispatch, RootState } from "@/store/store";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Activity,
  Calendar,
  Contact,
  FileClock,
  Folders,
  Lock,
  LayoutDashboard,
  PanelLeftClose,
  StickyNote,
  Store,
  UsersRound,
  BriefcaseMedical,
  ContactRound,
} from "lucide-react";
import * as configActions from "@/common/presentation/slices/configurationSlice";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

interface MenuItem {
  id: string;
  name: string;
  order: number;
  path: string;
  icon: string;
  parent_menu_id: string | null;
  service_id: string | null;
  permission_id: string | null;
  created_at: string;
  updated_at: string;
  enabled?: boolean;
  submenus?: MenuItem[];
  category?: string;
}

interface MenuState {
  clientMenus: MenuItem[];
  allMenus: MenuItem[];
}

const iconMap: { [key: string]: React.ComponentType } = {
  Activity,
  Calendar,
  Contact,
  FileClock,
  Folders,
  Lock,
  LayoutDashboard,
  PanelLeftClose,
  StickyNote,
  Store,
  UsersRound,
  BriefcaseMedical,
  ContactRound,
};

const MainMenu = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { configurations } = useSelector((state: RootState) => state.configurations);
  const { locationSelectedId } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getMenus = async () => {
      const response = await dispatch(getClientMenu());
      const { clientMenus, allMenus } = response.payload.data as MenuState;
      
      const processedMenus = allMenus.map((menu) => ({
        ...menu,
        enabled: clientMenus.some((clientMenu) => clientMenu.id === menu.id),
        category: mapMenuToCategory(menu.name),
      }));
      
      setMenuItems(processedMenus);
    };

    getMenus();
  }, [dispatch]);

  const handleSwitchChange = async (id: string, checked: boolean) => {
    setMenuItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, enabled: checked } : item))
    );

    const { menus = [] } = configurations;
    let newMenus = [...menus];

    if (!checked) {
      newMenus = newMenus.filter((m) => m.id !== id);
    } else {
      const menuToAdd = menuItems.find((m) => m.id === id);
      if (menuToAdd) {
        newMenus.push(menuToAdd);
      }
    }

    const response = await dispatch(
      updateClientsMenus({
        menuId: id,
        status: checked,
        locationId: locationSelectedId,
      })
    );

    if (response?.payload?.data) {
      dispatch(configActions.setConfigurationsLoading());
      dispatch(
        configActions.setConfigurations({
          ...configurations,
          menus: response.payload.data,
        })
      );
    }
  };

  const mapMenuToCategory = (menuName: string): string => {
    // Add new items based categories here
      const generalMenuItems: string[] = ["Calendar", "Notes", "Reports", "Tracker", "Contacts"];
      const residentMenuItems: string[] = ["E-MAR", "Medications", "Residents", "Family Access", "Outings"];
      const staffMenuItems: string[] = ["Library of Resources", "Training", "Staff", "Tutorials"];
      const visitorsProvidersMenuItems: string[] = ["Address Book", "Providers", "Visitors", "Pharmacy"];
      const additionalMenuItems: string[] = ["Contacts", "Market Place", "Security"];
  
       if (generalMenuItems.includes(menuName)) return "General";
       if (residentMenuItems.includes(menuName)) return "Resident";
       if (staffMenuItems.includes(menuName)) return "Staff";
       if (visitorsProvidersMenuItems.includes(menuName)) return "Visitors and Providers";
       if (additionalMenuItems.includes(menuName)) return "Additional";
       
       return "Uncategorized";
  };

  const renderCategory = (category: string, index: number) => {
    const items = menuItems.filter((item) => item.category === category);
    
    return items?.length >0 && (
      <div key={category} className={`pb-4 bg-gray-100 rounded-lg `}>
        <h2 className="text-[14px] font-semibold mb-3 text-[#52525B]">
          {t(category)}
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2 ">
          {items.map((item) => {
            const Icon: any = iconMap[item?.icon];
            return (
              <div key={item.id} className={`flex items-center justify-between pt-2 pb-2 pl-8 pr-8 bg-white shadow rounded-md`}>
                <div className="flex items-center space-x-4">
                  {Icon && <Icon className="h-5 w-5 text-blue-500" />}
                  <span className="text-[14px] font-semibold text-[#71717A]">{t(item.name)}</span>
                </div>
                <Switch
                  checked={item.enabled}
                  onCheckedChange={(checked: boolean) => handleSwitchChange(item.id, checked)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const categories = ["General", "Resident", "Staff", "Visitors and Providers"];

  return (
    <ScrollArea className="pl-4 pr-4 h-[450px] bg-neutralLight rounded-lg">
      <div className="pt-4 pb-4 pl-3 pr-3 ">
        {categories.map(renderCategory)}
      </div>
    </ScrollArea>
  );
};

export default MainMenu;
