import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import { format, parse } from 'date-fns';
import { FileClock } from 'lucide-react';
import { RefObject, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useEmarPrnHistory } from '../../infrastructure/hooks/UseEmarPrnHistory';
import { usePrnDialog } from '../../infrastructure/hooks/UsePrnDialog';
import { useTranslation } from 'react-i18next';

interface MedicationCardProps {
  className?: string;
  data: any;
  children?: React.ReactNode;
  setRef?: (ref: RefObject<HTMLDivElement>) => void;
  selectedDate: Date;
}

export const EmarPrnCard = ({ className, data, setRef }: MedicationCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { onOpen } = usePrnDialog();
  const { onOpen: onOpenEmarPrnHistory } = useEmarPrnHistory();
  const { locationId } = useParams();
  const displayFormat = t('dateFormats.dateFormat');

  useEffect(() => {
    if (cardRef.current) {
      setRef?.(cardRef);
    }
  }, [cardRef]);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onOpenEmarPrnHistory(data);
  };

  const safeToString = (value: any) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') {
      if (value.name) return value.name.toString();
      return JSON.stringify(value);
    }
    return value.toString();
  };

  return (
    <Card
      className={cn('flex flex-col hover:cursor-pointer', className)}
      ref={cardRef}
      onClick={() => onOpen(data, locationId!)}
    >
      <CardContent className="space-y-2">
        <CardHeader className="px-0">
          {data?.emar_medication_prns?.length > 2 && (
            <Button variant="ghost" size={'icon'} className="size-8" onClick={handleOpen}>
              <FileClock className="size-6" />
            </Button>
          )}

          <div className="flex justify-between items-center w-full">
            <div className="flex flex-wrap gap-2 w-full max-w-[calc(100%-2rem)]">
              <CardTitle className="text-xl font-bold break-words w-full">
                {safeToString(data?.medication?.name)?.toUpperCase()}{' '}
                {data?.medication?.strength
                  ? `${safeToString(data?.strength)} ${safeToString(data?.form)}`
                  : safeToString(data?.form)}
              </CardTitle>
            </div>

            {data?.responsible_of_administration?.length > 0 && (
              <span className="text-2xl font-bold text-red-500 ml-2 whitespace-nowrap">C</span>
            )}
          </div>
        </CardHeader>
        <Separator />
        <div className="text-md">
          <p>
            RX #: <span className="font-bold">{safeToString(data?.chart[0]?.rx)}</span>
          </p>
          <p>
            Start Date:{' '}
            <span className="font-bold">
              {data?.chart[0].cycle_start_date
                ? format(parse(data?.chart[0]?.cycle_start_date, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                : ''}
            </span>
          </p>
          <p>
            Physician: <span className="font-bold">{safeToString(data?.prescribing_physician?.name)}</span>
          </p>
          <p>
            Frequency: <span className="font-bold">{safeToString(data?.frequency)}</span>
          </p>
          <p>
            Not to Exceed: <span className="font-bold">{safeToString(data?.not_exceed)}</span>
          </p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Instructions</h3>
          <p className="text-md">{safeToString(data?.instruction)}</p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Special Instructions</h3>
          <p className="text-md">{safeToString(data?.special_instructions)}</p>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="bg-gray-100 p-4 w-full">
        <div className="w-full space-y-2">
          <h3 className="text-lg font-bold">PRN History</h3>
          {data?.emar_medication_prns?.slice(0, 2).map((prn: any, index: number) => {
            const date = prn?.dispensed_at ? new Date(prn?.dispensed_at) : null;
            return (
              <Card key={index} className="w-full">
                <CardContent className="pt-2 space-y-2">
                  <p>
                    Date:{' '}
                    <span className="font-bold">
                      {date && !isNaN(date.getTime()) ? format(date, displayFormat) : <span> </span>}
                    </span>
                  </p>
                  <p>
                    Reason: <span className="font-bold">{safeToString(prn?.reason)}</span>
                  </p>
                  <p>
                    Result: <span className="font-bold">{safeToString(prn?.result)}</span>
                  </p>
                </CardContent>
                {(prn?.reason || prn?.result) && <Separator />}
              </Card>
            );
          })}
        </div>
      </CardFooter>
    </Card>
  );
};
