import { useEffect, useState } from "react";
import { ITrackerCleaningSummary } from "./TrackerCleaningMockup";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { getAreas, getSummaries, getTasks } from "../../slices/TrackerCleaningSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import useCleaningSummaryColumns from "./CleaningSummaryColumns";

export function TrackerCleaningSummary() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const configurations = useSelector((state: RootState) => state.configurations);
    const { t } = useTranslation();
    let [dataSource, setDataSource] = useState<ITrackerCleaningSummary[]>([]);
    const { locationSelectedId } = useLocation();
    const [accentColor, setAccentColor] = useState("");
    const [columns, setColumns] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [reportRowsPerPage, setReportRowsPerPage] = useState(20);
    const [hasMoreRows, setHasMoreRows] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [resetData, setResetData] = useState(false);

    useEffect(() => {
        fetchData();
        setColumns(useCleaningSummaryColumns(fetchSummary))

        return () => {
            setCurrentPage(1);
            fetchSummary(1, true);
        }
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (trackerCleaning.summaries && Array.isArray(trackerCleaning.summaries.data) && trackerCleaning.summaries.data.length > 0) {
            const newData = trackerCleaning.summaries.data;

            if (resetData) {
                setDataSource(newData);
            } else {
                setDataSource(prevData => [...prevData, ...newData]);
            }

            if (trackerCleaning.summaries.current_page >= trackerCleaning.summaries.last_page || trackerCleaning.summaries.total < newData.length) {
                setHasMoreRows(false);
            } else {
                setHasMoreRows(true);
            }
        } else {
            setDataSource([]);
        }
    }, [trackerCleaning.summaries]);

    const fetchSummary = async (page: number, resetData: boolean = false) => {
        setResetData(resetData);

        if(resetData) setCurrentPage(1);

        dispatch(
            getSummaries(
                { 
                    location_id: locationSelectedId,
                    per_page: 20,
                    page
                }
            )
        );
    }

    const fetchData = async () => {
        dispatch(getAreas(
            {
                location_id: locationSelectedId
            }
        ));

        dispatch(getTasks(
            {
                location_id: locationSelectedId
            }
        ));
    }

    const loadMore = () => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        fetchSummary(nextPage);
    };

    return (
            <div>
            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("cleaning.report.summary.title")}
                    </CardTitle>
                </CardHeader>
                    
                <CardContent>
                    <div className="grid grid-cols-1 grid-row-2 w-100">
                        {
                            columns ? (
                                <TableCustomVirtuoso
                                    data={dataSource}
                                    columns={columns}
                                    renderCellContent={(index, column, data) => data[index][column.key]}
                                    loadMore={loadMore}
                                    hasMore={hasMoreRows}
                                    additionalButtons={<></>}
                                    showSearchInput={false}
                                />
                            ): null
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
