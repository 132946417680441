import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { useRequestRestrictByDeviceDialog } from '@/modules/security/infraestructure/hooks/useRestrictByDeviceDialog';
import { AlertCircle } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    errors: { deviceName: string; deviceDescription: string }
    setErrors: (errors: { deviceName: string; deviceDescription: string }) => void;
}

const RequestDeviceForm: React.FC<IProps> = ({ errors, setErrors }) => {
  const { setRequestDeviceInfo, requestDeviceInfo } = useRequestRestrictByDeviceDialog();
  const { t } = useTranslation();
 
  const handleDeviceNameChange = (e: any) => {
    const value = e.target.value;
    setRequestDeviceInfo({ ...requestDeviceInfo, deviceName: value });

    if (value.length >= 3) {
      setErrors({ ...errors, deviceName: '' });
    }
  };

  const handleDeviceDescriptionChange = (e: any) => {
    const value = e.target.value;
    setRequestDeviceInfo({ ...requestDeviceInfo, deviceDescription: value });

    if (value.length >= 5) {
      setErrors({ ...errors, deviceDescription: '' });
    }
  };

  return (
    <div className="space-y-4 pt-2">
      <div className="space-y-2">
        <Label htmlFor="deviceName" className="text-sm font-medium text-left block">
          {t('security.deviceName')}
        </Label>
        <Input
          id="deviceName"
          placeholder={t('security.deviceNamePlaceholder')}
          className={`w-full focus:ring-2 text-left ${errors.deviceName ? 'border-destructive' : ''}`}
          value={requestDeviceInfo.deviceName}
          onChange={handleDeviceNameChange}
        />
        {errors.deviceName && (
          <div className="text-destructive text-sm flex items-center gap-2 mt-1">
            <AlertCircle size={16} />
            {errors.deviceName}
          </div>
        )}
      </div>

      <div className="space-y-2">
        <Label htmlFor="description" className="text-sm font-medium text-left block">
          {t('security.deviceDescription')}
        </Label>
        <Textarea
          id="description"
          placeholder={t('security.descriptionPlaceholder')}
          className={`w-full min-h-[100px] focus:ring-2 text-left ${
            errors.deviceDescription ? 'border-destructive' : ''
          }`}
          value={requestDeviceInfo.deviceDescription}
          onChange={handleDeviceDescriptionChange}
        />
        {errors.deviceDescription && (
          <div className="text-destructive text-sm flex items-center gap-2 mt-1">
            <AlertCircle size={16} />
            {errors.deviceDescription}
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestDeviceForm;
