import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerDirectService = {
  getTrackerDirect: async (formData: any) => {
    try {
      return await axios.post(`trackerDirect/getTrackerDirect`, formData);
    } catch (error: any) {
      throw new Error('Error fetching Direct: ' + error.message);
    }
  },
  getDayTrackerDirect: async (body: { location_id: string }) => {
    try {
      return await axios.post(`trackerDirect/getDayTrackerDirect`, body);
    } catch (error) {
      throw new Error('Error fetching: ' + error);
    }
  },
  saveTrackerDirect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/saveTrackerDirect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Direct: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Direct: ' + error.message);
    }
  },
  updateTrackerDirect: async (formData: any) => {
    try {
      return await axios.post(`trackerDirect/updateTrackerDirect`, formData);
    } catch (error: any) {
      throw new Error('Error update tracker Direct: ' + error.message);
    }
  },
  destroyTrackerDirect: async (formData: any) => {
    try {
      return await axios.post(`trackerDirect/destroyTrackerDirect`, formData);
    } catch (error: any) {
      throw new Error('Error destroy tracker Direct: ' + error.message);
    }
  },
  getTrackerDirectType: async () => {
    try {
      const response = await axios.post(`trackerDirect/getTrackerDirectType`, {});
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Direct Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Direct Type: ' + error.message);
    }
  },
  updateTrackerDirectType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/updateTrackerDirectType`, formData);
      if (response) {
        return response;
      } else {
        throw new Error('Error update tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker direct type: ' + error.message);
    }
  },
  saveTrackerDirectType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/saveTrackerDirectType`, formData);
      if (response) {
        return response;
      } else {
        throw new Error('Error creating tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker direct type: ' + error.message);
    }
  }
};

export default TrackerDirectService;
