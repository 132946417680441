import { Progress } from '@/common/presentation/components/ui/progress';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { AlignLeft, Calendar, CalendarCheck, CalendarRange, CircleAlert, Edit, Repeat } from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertI, FrequencyDetailsI } from '../../domain/alerts.domain';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { updateAlert } from '../slices/AlertSlice';
import { Button } from '@/components/ui/button';
import AlertCreationDialog from './AlertCreationDialog';
import { Badge } from '@/common/presentation/components/ui/badge';
import ItemInfo from './ItemInfo';
import WeekDaySelector from '@/common/presentation/components/WeekDaySelector/WeekDaySelector';
import { getLabelFromFrequencyDetails } from '../../infraestructure/utils/frequency.helper';
import { useTranslation } from 'react-i18next';

interface IProps {
  alert: AlertI;
  relatedTo: 'user' | 'resident' | 'location';
}

const AlertCard: React.FC<IProps> = ({ alert, relatedTo }) => {
  const [isActive, setIsActive] = useState(false);
  const { formatDate } = useDateHelpers();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [alertExpired, setAlertExpired] = useState<boolean>(false);
  const [frequencyDetails, setFrequencyDetails] = useState<FrequencyDetailsI | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setIsActive(alert.status === 'active');
    setFrequencyDetails(JSON.parse(alert.frequency_details));
  }, [alert]);

  const calculateAlertMetrics = (alert: AlertI) => {
    const startDate = moment(alert.start_date.split(' ')[0]);
    const createdAt = moment(alert.created_at.split(' ')[0]);
    const currentDate = moment();

    const totalDays = startDate.startOf('day').diff(createdAt.startOf('day'), 'days');
    const daysUntilNotification = startDate.startOf('day').diff(currentDate.startOf('day'), 'days');

    const progress = daysUntilNotification <= 0 ? 100 : Math.max(0, (1 - daysUntilNotification / totalDays) * 100);

    const daysUntilAlert = startDate.startOf('day').diff(currentDate.startOf('day'), 'days');

    const monthsUntilAlert = startDate.startOf('day').diff(currentDate, 'months');
    const yearsUntilAlert = startDate.startOf('day').diff(currentDate, 'years');

    return {
      progress,
      timeUntilAlert: calculateTimeUntilAlert(daysUntilAlert, daysUntilAlert, monthsUntilAlert, yearsUntilAlert)
    };
  };

  const calculateTimeUntilAlert = (
    daysUntilAlert: number,
    daysUntilAlertNoDaysBefore: number,
    monthsUntilAlert: number,
    yearsUntilAlert: number
  ): string => {
    if (daysUntilAlertNoDaysBefore < 0) {
      const currentDate = moment().startOf('day');
      const endDate = alert.end_date ? moment(alert.end_date.split(' ')[0]) : null;
      if (alert.frequency === 'once' || (endDate && currentDate.isAfter(endDate))) {
        setAlertExpired(true);
        return t('customAlerts.alertExpired');
      }
    }

    if (daysUntilAlert <= 0) return t('customAlerts.alertPosted');

    if (yearsUntilAlert > 0) return t('customAlerts.years', { count: yearsUntilAlert });
    if (monthsUntilAlert > 0) return t('customAlerts.months', { count: monthsUntilAlert });

    return t('customAlerts.daysCard', { count: daysUntilAlert });
  };

  const { progress, timeUntilAlert } = useMemo(() => calculateAlertMetrics(alert), [alert, t]);

  const updateStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsActive(!isActive);
      await dispatch(
        updateAlert({
          status: isActive ? 'inactive' : 'active',
          id: alert.id
        })
      ).unwrap();
    } catch (error) {
      setIsActive(isActive);
      console.log(error);
    }
    setIsLoading(false);
  }, [isActive]);

  return (
    <>
      <AlertCreationDialog
        isOpen={isUpdateDialogOpen}
        onClose={() => {
          setIsUpdateDialogOpen(false);
        }}
        relatedTo={relatedTo}
        action="update"
        alert={alert}
      />
      <div className="flex flex-col rounded-lg border p-4 relative">
        <div className="flex flex-row gap-2 items-center justify-between">
          <div className="flex items-center gap-2">
            <CircleAlert className="text-primary" size={20} />
            <Label className="text-base text-gray-600 dark:text-gray-300 font-semibold">{alert.title}</Label>
          </div>
          <div className="flex items-center gap-2">
            <Button
              className="p-2 bg-transparent hover:bg-transparent"
              onClick={() => {
                setIsUpdateDialogOpen(true);
              }}
            >
              <Edit className="text-primary" size={20} />
            </Button>
            <Switch checked={isActive} onCheckedChange={updateStatus} disabled={isLoading} />
          </div>
        </div>
        <Separator className="mt-3 mb-4 border border-primary rounded-lg" />

        <div className="space-y-4 text-base">
          <ItemInfo Icon={AlignLeft} labelKey="customAlerts.form.description" value={alert.description} />
          <ItemInfo
            Icon={CalendarCheck}
            labelKey="customAlerts.form.startDate"
            value={formatDate(alert.start_date, false, false, false)}
          />
          <ItemInfo
            Icon={CalendarRange}
            labelKey="customAlerts.form.endDate"
            value={
              alert.end_date && alert.frequency !== 'once' ? formatDate(alert.end_date, false, false, false) : '--'
            }
          />
          <ItemInfo
            Icon={Repeat}
            labelKey="customAlerts.form.frequency"
            value={t(`customAlerts.frequencyOptions.${alert.frequency}`)}
          />
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Calendar className="text-muted-foreground" size={16} />
              <span className="font-medium text-sm text-gray-600 dark:text-gray-300">
                {t('customAlerts.form.scheduledDays')}:
              </span>
            </div>
            {frequencyDetails?.monthly && Object.values(frequencyDetails.monthly).length > 0 ? (
              <span className="text-sm text-gray-800 dark:text-white">
                {getLabelFromFrequencyDetails(frequencyDetails.monthly, t)}
              </span>
            ) : frequencyDetails && frequencyDetails?.days.length > 0 ? (
              <WeekDaySelector daysSelected={frequencyDetails.days} onlyDisplay={true} />
            ) : (
              '--'
            )}
          </div>
          {isActive && (
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                {alertExpired ? (
                  <div className="flex-1 flex justify-end">
                    <Badge className="bg-gray-200 text-gray-600 hover:bg-gray-200">{timeUntilAlert}</Badge>
                  </div>
                ) : (
                  <>
                    <Label className="text-sm text-gray-600 dark:text-gray-300">
                      {t('customAlerts.timeUntilAlert')}
                    </Label>
                    <span className="text-sm text-gray-800 dark:text-white">{timeUntilAlert}</span>
                  </>
                )}
              </div>
              {!alertExpired && <Progress value={progress} className="w-full rounded" />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AlertCard;
