export const FingerPrintHelper = {
  getFingerPrint: async (): Promise<{
    finger_print: string | null;
    browser: string;
    agent: string;
    operative_system: string;
    device_type: string;
    full_data: any[];
  } | null> => {
    try {
      const userAgent = navigator.userAgent;
      const normalizedUserAgent = normalizeUserAgent(userAgent);
      const browser = getBrowser(userAgent);
      const operativeSystem = getOperatingSystem(userAgent, navigator?.platform);
      const deviceType = getDeviceType(userAgent);

      const components: (string | number | boolean)[] = [
        normalizedUserAgent,
        screen.width,
        screen.height,
        screen.colorDepth,
        navigator.hardwareConcurrency || '',
        navigator.platform || '',
        navigator.vendor || '',
        detectTouchSupport(),
        detectGPUTier()
      ];

      const webGLInfo = getStableWebGLInfo();
      if (webGLInfo.vendor && webGLInfo.renderer) {
        components.push(normalizeGPUInfo(webGLInfo.vendor));
        components.push(normalizeGPUInfo(webGLInfo.renderer));
      }

      const fingerprintString = components.join('###');
      const encoder = new TextEncoder();
      const data = encoder.encode(fingerprintString);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

      return {
        finger_print: hashHex,
        browser,
        agent: userAgent,
        operative_system: operativeSystem,
        device_type: deviceType,
        full_data: components
      };
    } catch (error) {
      console.error('Error generating fingerprint:', error);
      return null;
    }
  }
};

const normalizeUserAgent = (userAgent: string): string => {
  return userAgent
    .replace(/Chrome\/[\d\.]+/g, 'Chrome/X')
    .replace(/Firefox\/[\d\.]+/g, 'Firefox/X')
    .replace(/Safari\/[\d\.]+/g, 'Safari/X')
    .replace(/Edge\/[\d\.]+/g, 'Edge/X')
    .replace(/OPR\/[\d\.]+/g, 'OPR/X')
    .replace(/Version\/[\d\.]+/g, 'Version/X')
    .replace(/AppleWebKit\/[\d\.]+/g, 'AppleWebKit/X')
    .replace(/Gecko\/\d+/g, 'Gecko/X')
    .replace(/Mobile\/\w+/g, 'Mobile/X')
    .replace(/CrOS\s+\w+\s+[\d\.]+/g, 'CrOS X')
    .replace(/\([^\)]+\)/, '(NORMALIZED)')
    .replace(/\s+/g, ' ')
    .trim();
};

const normalizeGPUInfo = (info: string): string => {
  return info
    .replace(/\([^\)]+\)/g, '')
    .replace(/\s+/g, ' ')
    .replace(/\d+/g, 'X')
    .replace(/mesa/gi, 'MESA')
    .replace(/directX/gi, 'DIRECTX')
    .replace(/openGL/gi, 'OPENGL')
    .trim();
};

const getStableWebGLInfo = () => {
  try {
    const canvas = document.createElement('canvas');
    const gl: any = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) return { vendor: '', renderer: '' };

    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    if (!debugInfo) return { vendor: '', renderer: '' };

    const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
    const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

    return { vendor, renderer };
  } catch (e) {
    return { vendor: '', renderer: '' };
  }
};

const detectTouchSupport = (): boolean => {
  return 'ontouchstart' in window || 
         navigator.maxTouchPoints > 0 || 
         (navigator as any).msMaxTouchPoints > 0;
};

const detectGPUTier = (): string => {
  const canvas = document.createElement('canvas');
  const gl: any = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  if (!gl) return 'no-gpu';

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  if (!debugInfo) return 'basic-gpu';

  const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  
  if (renderer.toLowerCase().includes('intel')) return 'integrated-gpu';
  if (renderer.toLowerCase().includes('nvidia') || 
      renderer.toLowerCase().includes('amd') || 
      renderer.toLowerCase().includes('radeon')) return 'dedicated-gpu';
  
  return 'basic-gpu';
};

const getBrowser = (userAgent: string): string => {
  if (userAgent.includes('Edg/')) return 'Microsoft Edge';
  if (userAgent.includes('OPR/') || userAgent.includes('Opera')) return 'Opera';
  if (userAgent.includes('Brave')) return 'Brave';
  if (userAgent.includes('Vivaldi')) return 'Vivaldi';
  if (userAgent.includes('YaBrowser')) return 'Yandex Browser';
  if (userAgent.includes('UCBrowser')) return 'UC Browser';
  if (userAgent.includes('SamsungBrowser')) return 'Samsung Browser';
  if (userAgent.includes('Chrome')) {
    if (userAgent.includes('Chromium')) return 'Chromium';
    return 'Google Chrome';
  }

  if (userAgent.includes('Firefox')) {
    if (userAgent.includes('Seamonkey')) return 'Seamonkey';
    if (userAgent.includes('Waterfox')) return 'Waterfox';
    if (userAgent.includes('PaleMoon')) return 'Pale Moon';
    return 'Mozilla Firefox';
  }

  if (userAgent.includes('Safari')) {
    if (userAgent.includes('Android')) return 'Android Browser';
    if (userAgent.includes('Coast')) return 'Coast by Opera';
    return 'Safari';
  }

  if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
    const version = userAgent.match(/MSIE\s?(\d+\.\d+)/);
    if (version) return `Internet Explorer ${version[1]}`;
    return 'Internet Explorer';
  }

  if (userAgent.includes('DuckDuckGo')) return 'DuckDuckGo Browser';
  if (userAgent.includes('QQBrowser')) return 'QQ Browser';
  if (userAgent.includes('Maxthon')) return 'Maxthon';
  if (userAgent.includes('Dolphin')) return 'Dolphin';
  if (userAgent.includes('FBAV') || userAgent.includes('FB_IAB')) return 'Facebook In-App Browser';
  if (userAgent.includes('Instagram')) return 'Instagram In-App Browser';
  if (userAgent.includes('Line')) return 'Line In-App Browser';
  if (userAgent.includes('KAKAOTALK')) return 'KakaoTalk In-App Browser';
  if (userAgent.includes('WeChat') || userAgent.includes('MicroMessenger')) return 'WeChat Browser';

  if (userAgent.includes('Gecko/')) return 'Gecko-based Browser';
  if (userAgent.includes('WebKit/')) return 'WebKit-based Browser';
  if (userAgent.includes('Presto/')) return 'Presto-based Browser';

  return 'Unknown';
};

const getOperatingSystem = (userAgent: string, platform?: string): string => {
  if (userAgent.includes('Win')) return 'Windows';
  if (userAgent.includes('Mac')) return 'MacOS';
  if (userAgent.includes('Android')) return 'Android';
  if (userAgent.includes('Linux')) return 'Linux';
  if (userAgent.includes('like Mac')) return 'iOS';

  if (platform) {
    if (platform.includes('Win')) return 'Windows';
    if (platform.includes('Mac')) return 'MacOS';
    if (platform.includes('Android')) return 'Android';
    if (platform.includes('Linux')) return 'Linux';
    if (platform.includes('like Mac')) return 'iOS';
  }
  return 'Unknown';
};

const getDeviceType = (userAgent: string): string => {
  if (userAgent.includes('Mobi') || userAgent.includes('Android')) {
    return 'phone';
  }
  if (userAgent.includes('Tablet')) {
    return 'tablet';
  }
  return 'computer';
};