import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { cn } from '@/lib/utils';
import { RootState } from '@/store/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const Logo = ({ path, clientName }: { path: string; clientName: string }) => {
  const { hasAnyRole } = useRoleHelper();
  const user = useSelector((state: RootState) => state.auth.user);
  const showClientName = useMemo(() => !hasAnyRole(['Pharmacy']), [user]);
  return (
    <div className={cn('gap-2 flex items-center flex-col')}>
      <img src={path} alt="Caring Data Client Logo" width={100} height={100} />
      <p className="text-sm font-semibold">{showClientName ? clientName : 'Caring Data'}</p>
    </div>
  );
};
