import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from '@/common/presentation/components/ui/dialog';
import clsx from 'clsx';

interface DialogDemoProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  width?: string,
  height?: string,
  newDialog: boolean;
  children: React.ReactNode;
  onSubmit?: (formData: { [key: string]: string }) => void;
  buttonLabel?: string;
  className?: string;
  overFlow?: boolean;
}

export function CustomDialog({ isOpen, onClose, title, description, children, className, overFlow=true, width }: DialogDemoProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose} modal>
      <DialogContent
        className={clsx(
          'flex flex-col max-h-[90vh] min-w-[300px] min-h-[200px] shadow-lg rounded-md border-t-4 border-primary p-6', // Adjust max width and height
          className
        )}
        style={
          {
            maxWidth: width
          }
        }
      >
        <DialogHeader>
          <DialogTitle className="text-primary text-2xl font-bold">{title}</DialogTitle>
        </DialogHeader>
        <hr className="border-primary w-full my-2" />
        {description && <DialogDescription className="mb-2">{description}</DialogDescription>}
        <div className={(overFlow ? 'overflow-auto ' : ''+ 'flex-grow') + "p-1"}>{children}</div>
      </DialogContent>
    </Dialog>
  );
}
