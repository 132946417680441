import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MonitorSmartphone, ChevronDown } from 'lucide-react';
import { AppDispatch, RootState } from '@/store/store';
import { DeviceI } from '@/modules/security/domain/device.domain';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { fetchGeoLocation } from '@/modules/wizard/presentation/slices/SignUpSlice';

import { Button } from '@/components/ui/button';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { AvatarFallback } from '@radix-ui/react-avatar';
import RegisterDevice from './RegisterDevice';
import { cn } from '@/lib/utils';

interface IProps {
  getDevices: () => void;
}

const DeviceInfo = ({ label, value }: { label: string; value: string | React.ReactNode }) => (
  <div className="flex items-center gap-2 py-1">
    <span className="text-sm text-gray-600 dark:text-gray-300 min-w-[120px]">{label}</span>
    <span className="text-sm text-gray-800 dark:text-white capitalize flex-1 break-words truncate">{value || '-'}</span>
  </div>
);

const LocationsBadge = ({ device }: { device: DeviceI }) => (
  <div className="flex flex-wrap gap-2">
    {device.locations.map((location) => (
      <Badge className="flex items-center px-2 py-1 bg-primary/10 text-primary" key={location.id}>
        <Avatar className="h-5 w-5 mr-1">
          <AvatarImage src={location.image_path} className="object-cover" />
          <AvatarFallback className="text-xs">
            {location.name
              .split(' ')
              .map((chunk) => chunk[0])
              .join('')}
          </AvatarFallback>
        </Avatar>
        <span className="text-xs">{location.name}</span>
      </Badge>
    ))}
  </div>
);

const CurrentDevice: React.FC<IProps> = ({ getDevices }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { deviceInfo } = useConfigurationsProvider();

  const [isExpanded, setIsExpanded] = useState(false);
  const [ipClient, setIpClient] = useState<string | null>(null);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);

  const deviceState = useSelector((state: RootState) => state.security.current_device) ?? [];
  const currentDeviceRegistered = deviceState.device;

  const getIpClient = useCallback(async () => {
    const response = await dispatch<any>(fetchGeoLocation());
    if (!response.error) {
      setIpClient(response.payload.ip);
    }
  }, [dispatch]);

  useEffect(() => {
    getIpClient();
  }, [getIpClient]);

  const canEditDevice = currentDeviceRegistered && currentDeviceRegistered;

  const ActionButton = () =>
    !currentDeviceRegistered ? (
      <Button
        className="bg-primary whitespace-nowrap"
        disabled={false}
        onClick={() => setOpenRegisterDialog(true)}
      >
        {t('security.registerDevice')}
      </Button>
    ) : (
      canEditDevice && (
        <Button className="bg-primary whitespace-nowrap" onClick={() => navigate(`edit/${currentDeviceRegistered.id}`)}>
          {t('security.editDevice')}
        </Button>
      )
    );

  return (
    <div className="border rounded-lg py-0 shadow-sm dark:bg-zinc-950/50">
      <RegisterDevice
        getDevices={getDevices}
        isOpen={openRegisterDialog}
        onClose={() => setOpenRegisterDialog(false)}
      />

      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-4 dark:hover:bg-zinc-900/50 transition-colors"
      >
        <div className="flex items-center gap-3">
          <MonitorSmartphone className="text-primary h-5 w-5" />
          <span className="font-medium text-gray-800 dark:text-white">{t('security.currentDevice')}</span>
        </div>
        <ChevronDown className={`h-4 w-4 text-gray-500 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
      </button>

      {isExpanded && (
        <div className="p-4 space-y-4 border-t dark:bg-zinc-900">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-2">
            {
              currentDeviceRegistered?.name && (
                <DeviceInfo label={t('security.deviceName')} value={currentDeviceRegistered.name} />
              )
            }
            <DeviceInfo label={t('security.fingerPrint')} value={deviceInfo?.finger_print} />
            <DeviceInfo label={t('security.ipAddress')} value={ipClient} />
            <DeviceInfo label={t('security.browser')} value={deviceInfo?.browser} />
            <DeviceInfo
              label={t('security.deviceType')}
              value={t(`security.${deviceInfo?.device_type?.toLowerCase() ?? ''}`)}
            />
            <DeviceInfo label={t('security.operatingSystem')} value={deviceInfo?.operative_system} />
          </div>

          <div className="pt-2 border-t dark:border-zinc-800">
            <div className={cn("flex flex-col md:flex-row md:items-start md:justify-between space-y-4 md:space-y-0 md:space-x-4", !currentDeviceRegistered?.locations ? "!flex-row-reverse" : "")}>
              {currentDeviceRegistered?.locations && (
                <div className="flex-1">
                  <DeviceInfo
                    label={t('security.facilities')}
                    value={<LocationsBadge device={currentDeviceRegistered} />}
                  />
                </div>
              )}
              <div className={cn("flex justify-end", !currentDeviceRegistered?.locations ? "md:justify-end" : " md:justify-start")}>
                <ActionButton />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentDevice;
