import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { t } from "i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/common/presentation/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { saveOrUpdateQuestion, questionsLoading } from "../../slices/QuestionsSlice";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { FormMessage } from "@/common/presentation/components/ui/form";
import { Switch } from "@/common/presentation/components/ui/switch";
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Alert, AlertDescription } from "@/common/presentation/components/ui/alert";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';

import { CheckCircle2 } from "lucide-react";
import CustomSelect from "@/common/presentation/components/CustomSelect/CustomSelect";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

type answerType = 'yes_no' | 'multiple_choice' | 'text' | 'numeric' | 'scale';
type availability = 'all_clients' | 'single_client';
type status = 'active' | 'inactive';
type isRequired = 'yes' | 'no';

interface IDialogCreateOrUpdate {
    onChangeOpen: (open: boolean) => void;
    open: boolean;
    isUpdate?: boolean;
    onChangeIsUpdate?: (state: boolean) => void
    dataUpdate?: {
        id: string,
        title: string,
        availability?: availability;
        description?: string,
        answer_type?: answerType,
        answer_options?: any,
        order?: number,
        is_required?: isRequired,
        status: status,
        desired_numeric_answer_type?: 'single' | 'between' | null,
        desired_answer_yes_no?: 'yes' | 'no' | null,
        desired_numeric_answer_single?: any | null,
        desired_numeric_answer_min?: any | null,
        desired_numeric_answer_max?: any | null
    };
    onChangeGetData?: (currentPage: number) => void
}

const FormSchema = z.object({
    question: z.string({
        required_error: "Question is required",
    }).min(1, {
        message: "Question is required"
    }),
    answerType: z.enum(["yes_no", "multiple_choice", "text", "numeric", "scale"]).default('yes_no'),
    status: z.boolean(),
    order: z.number().optional(),
    desiredYesNoAnswer: z.enum(["yes", "no"]).optional().nullable(),
    desiredNumericAnswerType: z.enum(["single", "between"]).optional().nullable(),
    desiredNumericSingle: z.string().optional().nullable(),
    desiredNumericMin: z.string().optional().nullable(),
    desiredNumericMax: z.string().optional().nullable()
});

interface ISelectOption {
    value: string;
    label: string;
}

type FormValues = z.infer<typeof FormSchema>;

export const DialogCreateOrUpdate: React.FC<IDialogCreateOrUpdate> = ({
    onChangeOpen,
    open,
    isUpdate = false,
    onChangeIsUpdate,
    dataUpdate,
    onChangeGetData
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showSuccess, setShowSuccess] = useState(false);
    const { isLoading } = useSelector((state: RootState) => state.settings.questions);
    const [answerType, setAnswerType] = useState({
        value: "",
        label: t('settings.select')
    });

    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            question: "",
            status: true,
            answerType: 'yes_no',
            order: dataUpdate?.order ?? 0
        }
    });


    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        try {
            const payload = {
                ...data,
                idUpdate: isUpdate ? dataUpdate?.id : undefined,
                order: dataUpdate?.order,
            };

            dispatch(questionsLoading());
            const response = await dispatch(saveOrUpdateQuestion(payload));


            if (response.payload.result === 'ok') {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    onChangeOpen(false);
                    onChangeIsUpdate?.(false);
                }, 2000);

                if (onChangeGetData) {
                    onChangeGetData(1);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        if (open) {
            if (isUpdate && dataUpdate) {
                const title = (dataUpdate.availability === 'all_clients')
                    ? t(`settings.visitor.questions.${dataUpdate?.title}`)
                    : dataUpdate?.title;

                form.reset({
                    question: title,
                    status: dataUpdate.status == 'active' ? true : false,
                    desiredYesNoAnswer: dataUpdate?.desired_answer_yes_no,
                    answerType: dataUpdate?.answer_type,
                    desiredNumericAnswerType: dataUpdate?.desired_numeric_answer_type ?? null,
                    desiredNumericSingle: dataUpdate?.desired_numeric_answer_single?.toString() ?? '',
                    desiredNumericMin: dataUpdate?.desired_numeric_answer_min?.toString() ?? '',
                    desiredNumericMax: dataUpdate?.desired_numeric_answer_max?.toString() ?? ''
                });

                setAnswerType({
                    value: dataUpdate?.answer_type ?? 'yes_no',
                    label: t(`settings.visitQuestion.${dataUpdate?.answer_type}`)
                })
            }
            if (!isUpdate) {
                form.reset({
                    question: "",
                    status: true,
                });
                setAnswerType({
                    value: "",
                    label: t('settings.select')
                })
            }
        } else {
            onChangeIsUpdate?.(false);
        }
    }, [open, isUpdate, dataUpdate]);

    const answerTypeOptions: ISelectOption[] = [
        { value: "", label: t('common.select') },
        { value: 'yes_no', label: t('settings.visitQuestion.yesNo') },
        { value: 'numeric', label: t('settings.visitQuestion.numeric') }
    ];


    return <>
        <CustomDialog
            height="auto"
            width="30rem"
            title={isUpdate ? t('common.update') : t('common.create')}
            newDialog={true}
            isOpen={open}
            onClose={() => onChangeOpen(false)}
            className="z-[999999] max-w-[92%] sm:max-w-[450px] "
            overFlow={false}
        >
            <div className="h-[500px]">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 flex flex-col justify-between h-full">
                        {!isLoading && (
                            <ScrollArea className="flex-grow pr-4">
                                <div>

                                    {(isUpdate == false || (isUpdate == true && dataUpdate?.availability == 'single_client')) && <>
                                        <FormField
                                            name="question"
                                            control={form.control}
                                            render={({ field }) => (
                                                <FormItem className="pb-4">
                                                    <FormLabel>{t('settings.visitor.questions.question')}</FormLabel>
                                                    <FormControl>
                                                        <Textarea {...field} placeholder="Enter your question" />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="answerType"
                                            render={({ field }) => (
                                                <FormItem className="pb-4">
                                                    <div className="">
                                                        <FormLabel>{t('settings.visitQuestion.answerType')}</FormLabel>
                                                    </div>
                                                    <FormControl>
                                                        <CustomSelect
                                                            value={answerType}
                                                            onChange={(val: ISelectOption) => {
                                                                if (val) {
                                                                    setAnswerType(val);
                                                                    field.onChange(val.value);
                                                                }
                                                            }}
                                                            options={answerTypeOptions}
                                                            isSearchable={true}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        {form.watch('answerType') === 'yes_no' && (
                                            <FormField
                                                control={form.control}
                                                name="desiredYesNoAnswer"
                                                render={({ field }) => (
                                                    <FormItem className="pb-4">
                                                        <FormLabel>{t('settings.visitQuestion.desiredAnswerIs')}</FormLabel>
                                                        <FormControl>
                                                            <RadioGroup
                                                                onValueChange={field.onChange}
                                                                defaultValue={field.value || 'yes'}
                                                                className="flex space-x-4"
                                                            >
                                                                <div className="flex items-center space-x-2">
                                                                    <RadioGroupItem value="yes" id="yes" />
                                                                    <Label htmlFor="yes">{t('settings.visitQuestion.yes')}</Label>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <RadioGroupItem value="no" id="no" />
                                                                    <Label htmlFor="no">{t('settings.visitQuestion.no')}</Label>
                                                                </div>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        )}

                                        {form.watch('answerType') === 'numeric' && (
                                            <>
                                                <FormField
                                                    control={form.control}
                                                    name="desiredNumericAnswerType"
                                                    render={({ field }) => (
                                                        <FormItem className="pb-4">
                                                            <FormLabel>{t('settings.visitQuestion.desiredAnswerIs')}</FormLabel>
                                                            <FormControl>
                                                                <RadioGroup
                                                                    onValueChange={field.onChange}
                                                                    defaultValue={field.value || ''}
                                                                    className="flex space-x-4"
                                                                >
                                                                    <div className="flex items-center space-x-2">
                                                                        <RadioGroupItem value="single" id="single" />
                                                                        <Label htmlFor="single">{t('settings.visitQuestion.single')}</Label>
                                                                    </div>
                                                                    <div className="flex items-center space-x-2">
                                                                        <RadioGroupItem value="between" id="between" />
                                                                        <Label htmlFor="between">{t('settings.visitQuestion.between')}</Label>
                                                                    </div>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />

                                                {form.watch('desiredNumericAnswerType') === 'single' && (
                                                    <FormField
                                                        control={form.control}
                                                        name="desiredNumericSingle"
                                                        render={({ field }) => (
                                                            <FormItem className="pb-4">
                                                                <FormLabel>{t('settings.visitQuestion.singleNumericValue')}</FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        type="number"
                                                                        placeholder={t('settings.visitQuestion.enterSingleNumericValue')}
                                                                        {...field}
                                                                        value={field.value || ''}

                                                                    />
                                                                </FormControl>
                                                            </FormItem>
                                                        )}
                                                    />
                                                )}

                                                {form.watch('desiredNumericAnswerType') === 'between' && (
                                                    <div className="flex space-x-2 pt-1">
                                                        <FormField
                                                            control={form.control}
                                                            name="desiredNumericMin"
                                                            render={({ field }) => (
                                                                <FormItem className="pb-4 w-1/2">
                                                                    <FormLabel>{t('settings.visitQuestion.minValue')}</FormLabel>
                                                                    <FormControl>
                                                                        <Input
                                                                            type="number"
                                                                            placeholder={t('settings.visitQuestion.minimum')}
                                                                            {...field}
                                                                            value={field.value || ''}

                                                                        />
                                                                    </FormControl>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={form.control}
                                                            name="desiredNumericMax"
                                                            render={({ field }) => (
                                                                <FormItem className="pb-4 w-1/2">
                                                                    <FormLabel>{t('settings.visitQuestion.maxValue')}</FormLabel>
                                                                    <FormControl>
                                                                        <Input
                                                                            type="number"
                                                                            placeholder={t('settings.visitQuestion.maximum')}
                                                                            {...field}
                                                                            value={field.value || ''}

                                                                        />
                                                                    </FormControl>
                                                                </FormItem>
                                                            )}
                                                        />

                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>}
                                    <FormField
                                        control={form.control}
                                        name="status"
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <div className="space-y-0.5">
                                                    <FormLabel>{t('settings.status')}</FormLabel>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </ScrollArea>
                        )}

                        {isLoading && (
                            <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                                <Spinner size={'lg'} />
                            </div>
                        )}
                        <div className="absolute bottom-0 left-0 right-0 p-4 bg-white">
                            {showSuccess ? (
                                <Alert className="bg-green-50 border-green-200 mb-4">
                                    <CheckCircle2 className="h-4 w-4 text-green-600" />
                                    <AlertDescription className="text-green-600">
                                        {isUpdate ? t('settings.updatedSuccessfully') : t('settings.createdSuccessfully')}
                                    </AlertDescription>
                                </Alert>
                            ) : (
                                <div className="flex justify-end">
                                    <Button type="submit">
                                        {t('settings.save')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </Form>
            </div>
        </CustomDialog>
    </>
};