import { MonthlyFrequencyDetailsI } from '../../domain/alerts.domain';

export const getOrdinalSuffix = (n: number, t: any): string => {
  const num = Number(n);
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return t('customAlerts.ordinalSuffixes.default');
  }

  switch (lastDigit) {
    case 1:
      return t('customAlerts.ordinalSuffixes.1');
    case 2:
      return t('customAlerts.ordinalSuffixes.2');
    case 3:
      return t('customAlerts.ordinalSuffixes.3');
    default:
      return t('customAlerts.ordinalSuffixes.default');
  }
};

export const getTranslatedDayOfWeek = (day: string, t: any): string => {
  return t(`customAlerts.daysOfWeek.${day.toLowerCase()}`);
};

export const getLabelFromFrequencyDetails = (details: MonthlyFrequencyDetailsI, t: any): string => {
  if (!details || !details.type) return t('customAlerts.monthlyFrequency.noDetails');

  switch (details.type) {
    case 'exact_day':
      return t('customAlerts.monthlyFrequency.exactDay', {
        day: details.day,
        ordinalSuffix: getOrdinalSuffix(Number(details.day), t)
      });
    case 'first_week':
      return t('customAlerts.monthlyFrequency.firstWeek', {
        day: getTranslatedDayOfWeek(typeof details.day === 'string' ? details.day : String(details.day), t)
      });
    case 'last_week':
      return t('customAlerts.monthlyFrequency.lastWeek', {
        day: getTranslatedDayOfWeek(typeof details.day === 'string' ? details.day : String(details.day), t)
      });
    case 'first_day':
      return t('customAlerts.monthlyFrequency.firstDay');
    case 'last_day':
      return t('customAlerts.monthlyFrequency.lastDay');
    case 'day_week_number':
      return t('customAlerts.monthlyFrequency.dayWeekNumber', {
        weekNumber: details.week_number,
        ordinalSuffix: getOrdinalSuffix(Number(details.week_number), t),
        day: getTranslatedDayOfWeek(typeof details.day === 'string' ? details.day : String(details.day), t)
      });
    default:
      return JSON.stringify(details);
  }
};

export const generateMonthlyFrequencyOptions = (date: Date, t: any) => {
  if (!date) return [];

  const dayOfMonth = date.getDate();
  const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
  const weekNumber = getWeekOfMonth(date);

  return [
    {
      label: t('customAlerts.monthlyFrequency.exactDay', {
        day: dayOfMonth,
        ordinalSuffix: getOrdinalSuffix(dayOfMonth, t)
      }),
      value: JSON.stringify({ day: dayOfMonth, type: 'exact_day' })
    },
    {
      label: t('customAlerts.monthlyFrequency.firstWeek', {
        day: getTranslatedDayOfWeek(dayOfWeek, t)
      }),
      value: JSON.stringify({ day: dayOfWeek, type: 'first_week' })
    },
    {
      label: t('customAlerts.monthlyFrequency.lastWeek', {
        day: getTranslatedDayOfWeek(dayOfWeek, t)
      }),
      value: JSON.stringify({ day: dayOfWeek, type: 'last_week' })
    },
    {
      label: t('customAlerts.monthlyFrequency.firstDay'),
      value: JSON.stringify({ type: 'first_day' })
    },
    {
      label: t('customAlerts.monthlyFrequency.lastDay'),
      value: JSON.stringify({ type: 'last_day' })
    },
    {
      label: t('customAlerts.monthlyFrequency.dayWeekNumber', {
        weekNumber: weekNumber,
        ordinalSuffix: getOrdinalSuffix(weekNumber, t),
        day: getTranslatedDayOfWeek(dayOfWeek, t)
      }),
      value: JSON.stringify({
        day: dayOfWeek,
        type: 'day_week_number',
        week_number: weekNumber
      })
    }
  ];
};

export const getWeekOfMonth = (date: Date): number => {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const startDayOfWeek = startOfMonth.getDay();
  const dayOfMonth = date.getDate();

  return Math.ceil((dayOfMonth + startDayOfWeek) / 7);
};
