import { useTranslation } from 'react-i18next';
import { GraduationCap } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';

interface TrainingInfoProps {
  className?: string;
}

export const TrainingInfo = ({ className }: TrainingInfoProps) => {
  const { t } = useTranslation();
  const contentClass = "items-center gap-4 rounded-md bg-zinc-100 dark:bg-zinc-900 px-4 py-1.5 border border-gray-200 dark:border-zinc-700"
  const textClass = "text-xs text-zinc-600 dark:text-zinc-50 font-medium"

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('staff.profile.trainingInformation')}
        headerIconLeft={GraduationCap}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[160px]">
          <div className="space-y-2">
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                Training Assigned
              </span>
              <span className={`${textClass} font-normal`}>
                Initial Training
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                Additional Trainings
              </span>
              <span className={`${textClass} font-normal`}>
                -Med Tech <br />
                -Sexual Harassment
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                Digital Hours This Year
              </span>
              <span className={`${textClass} font-normal`}>
                18 hrs
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                Total Hours This Year
              </span>
              <span className={`${textClass} font-normal`}>
                24 hrs
              </span>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};