import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ResidentCarriersFinancialInformationService = {
  getCarriers: async (resident_id: string) => {
    const response = await axios.get(`/residents/carriers-financial-information/carriers/${resident_id}`);
    return response;
  },

  createCarrier: async (data: any) => {
    const response = await axios.post(`/residents/carriers-financial-information/carriers`, {
      ...data
    });
    return response;
  },

  updateCarrier: async (data: any) => {
    const response = await axios.put(`/residents/carriers-financial-information/carriers/${data.id}`, {
      ...data
    });
    return response;
  },

  deleteCarrier: async (data: any) => {
    const response = await axios.delete(`/residents/carriers-financial-information/carriers/${data.id}`);
    return response;
  },

  getFinancial: async (resident_id: string) => {
    const response = await axios.get(`/residents/carriers-financial-information/financial/${resident_id}`);
    return response;
  },

  upsertFinancial: async (data: any) => {
    const response = await axios.post(`/residents/carriers-financial-information/financial`, {
      ...data
    });
    return response;
  },

  deleteFinancial: async (data: any) => {
    const response = await axios.delete(`/residents/carriers-financial-information/financial/${data.resident_id}`);
    return response;
  }

  // createOrUpdateCarrierInformation: async (data: any) => {
  //   const response = await axios.post(`/residents/carriers-financial-information/carrier`, {
  //     ...data
  //   });
  //   return response;
  // },

  // removeCarrierInformation: async (data: any) => {
  //   const response = await axios.delete(
  //     `/residents/carriers-financial-information/carrier/${data.id}/resident/${data.resident_id}`
  //   );
  //   return response;
  // },

  // createOrUpdateFinancialInformation: async (data: any) => {
  //   const response = await axios.post(`/residents/carriers-financial-information/financial/${data.resident_id}`, {
  //     ...data
  //   });
  //   return response;
  // },

  // removeFinancialInformation: async (data: any) => {
  //   const response = await axios.delete(
  //     `/residents/carriers-financial-information/financial/${data.id}/resident/${data.resident_id}`
  //   );
  //   return response;
  // }
};

export default ResidentCarriersFinancialInformationService;
