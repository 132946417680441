import { cn } from '@/lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  daysSelected: string[];
  onSelect?: (day: string) => void;
  isDisabled?: boolean;
  onlyDisplay?: boolean;
}

interface DaysOfWeekI {
  [key: string]: string;
}

const WeekDaySelector: React.FC<IProps> = ({ daysSelected, onSelect, isDisabled = false, onlyDisplay = false }) => {
  const { t } = useTranslation();
  const daysOfWeek: DaysOfWeekI = {
    sunday: t('customAlerts.days.sunday'),
    monday: t('customAlerts.days.monday'),
    tuesday: t('customAlerts.days.tuesday'),
    wednesday: t('customAlerts.days.wednesday'),
    thursday: t('customAlerts.days.thursday'),
    friday: t('customAlerts.days.friday'),
    saturday: t('customAlerts.days.saturday')
  };

  const handlerSelector = (key: string) => {
    if (!onSelect || onlyDisplay) return;
    onSelect(key);
  };

  return (
    <div className="flex gap-2">
      {Object.keys(daysOfWeek).map((key) => (
        <button
          disabled={isDisabled}
          type="button"
          className={cn('rounded-full p-2 text-xs w-[22px] h-[22px] flex items-center justify-center',
            `${daysSelected.find((k: string) => k === key) ? 'bg-primary text-white' : ''}`,
            onlyDisplay ? 'cursor-default' : ''
          )}
          onClick={() => handlerSelector(key)}
          key={key}
        >
          {daysOfWeek[key]}
        </button>
      ))}
    </div>
  );
};

export default WeekDaySelector;
