import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/common/presentation/components/ui/table';
import { Button } from '@/common/presentation/components/ui/button';
import { CircleFadingPlus, UserRoundX } from 'lucide-react';
import { Input } from '@/common/presentation/components/ui/input';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/common/presentation/components/ui/accordion';
import { ShowerHead , HeartPulse, Droplet, NotebookPen, Waves } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { getCombinedReport, exportVitalsReport, fetchNotesCategories, fetchVitalsCategories } from "../slices/ReportByResidentSlice";
import { startOfToday, endOfToday } from "date-fns";

const ReportByResidentPage = () => {
    const [location, setLocation] = useState<string | null>(null);
    const { params, setParam } = useRouteParams();
    const { locationSelectedId } = useLocation();
    const dispatch = useDispatch();
    const { isLoading, registersvitals } = useSelector((state: RootState) => state.reportbyresident);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [searchParams] = useSearchParams();

    const [vitalsData, setVitalsData] = useState<any[]>([]);
    const [bowelData, setBowelData] = useState<any[]>([]);
    const [cleaningData, setCleaningData] = useState<any[]>([]);
    const [hygieneData, setHygieneData] = useState<any[]>([]);
    const [notesData, setNotesData] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState([{ label: 'All', value: 'all' }]);
    const [selectedNotesCategoriesOptions, setSelectedNotesCategoriesOptions] = useState([]);
    const [selectedVitalsCategoriesOptions, setSelectedVitalsCategoriesOptions] = useState([]);
    const categories = useSelector((state: any) => state.reportbyresident.categories);
    const categoriesVitals = useSelector((state: any) => state.reportbyresident.categoriesvitals);

    // Agregar opción "All"
    const categoriesWithAll = [{ label: "All", value: "all" }, ...categories];
    const categoriesVitalsWithAll = [{ label: "All", value: "all" }, ...categoriesVitals];
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [trackersText, setTrackersText] = useState('');

    useEffect(() => {
    // Al iniciar el componente, aplica los filtros automáticamente
        onApplyFilters();
    }, []); // Solo se ejecuta cuando el componente se monta
    
    useEffect(() => {
    // Aplica el filtro cada vez que `selectedOptions` cambie
        onApplyFilters();
    }, [selectedOptions]);

    const options = [
        { label: 'All', value: 'all' },
        { label: 'Vitals', value: 'vitals' },
        { label: 'Bowel', value: 'bowel' },
        { label: 'Cleaning', value: 'cleaning' },
        { label: 'Hygiene', value: 'hygiene' },
        { label: 'Notes', value: 'notes' },
    ];

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleChange = (selected: any) => {
        setSelectedOptions(selected);
        console.log('Selected trackers options:', selected);
    };

    useEffect(() => {
        dispatch(fetchNotesCategories());
    }, [dispatch]);

    const handleNoteCategoriesChange = (selected: any) => {
        setSelectedNotesCategoriesOptions(selected);
        console.log('Selected Categories Notes options:', selected);
    };

    useEffect(() => {
        dispatch(fetchVitalsCategories());
    }, [dispatch]);

    const handleVitalsCategoriesChange = (selected: any) => {
        setSelectedVitalsCategoriesOptions(selected);
        console.log('Selected options:', selected);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }
    }, [params]);

    const [appliedFilters, setAppliedFilters] = useState<{
        residentId: string;
        locationId: string;
        startDate: string;
        endDate: string;
        trackers: string[];
        categoriesNotes: any[];
        categoriesVitals: any[];
    } | null>(null); // Permite null como inicial

    const onApplyFilters = () => {
        const filters = {
            residentId: params.r,
            locationId: params.l,
            startDate,
            endDate,
            trackers: selectedOptions.map(option => option.value),
            categoriesNotes: selectedNotesCategoriesOptions.map(option => option.value),
            categoriesVitals: selectedVitalsCategoriesOptions.map(option => option.value),
        };

        console.log("Filters applied:", filters);

        // Guardar filtros aplicados en el estado
        setAppliedFilters(filters);

        console.log("appliedFilters", filters);

        // Calcular el número total de trackers seleccionados
        const selectedTrackersCount = filters.trackers.includes('all') 
        ? options.length - 1 // Si 'all' está seleccionado, contamos todas las opciones excepto 'all'
        : filters.trackers.length; // Si no, usamos el tamaño real de trackers seleccionados

        // Crear el texto dinámico
        setTrackersText(`${selectedTrackersCount} Trackers`);

        if (filters.residentId && filters.locationId && filters.startDate && filters.endDate) {
            dispatch(getCombinedReport(filters)).then((response: any) => {
                if (response.payload) {
                    const combinedData = response.payload;

                    const vitals = combinedData.filter((item: any) => item.type === 'vitals');
                    const bowel = combinedData.filter((item: any) => item.type === 'bowel');
                    const cleaning = combinedData.filter((item: any) => item.type === 'cleaning');
                    const hygiene = combinedData.filter((item: any) => item.type === 'hygiene');
                    const notes = combinedData.filter((item: any) => item.type === 'notes');

                    setVitalsData(filters.trackers.includes('vitals') || filters.trackers.includes('all') ? vitals : []);
                    setBowelData(filters.trackers.includes('bowel') || filters.trackers.includes('all') ? bowel : []);
                    setCleaningData(filters.trackers.includes('cleaning') || filters.trackers.includes('all') ? cleaning : []);
                    setHygieneData(filters.trackers.includes('hygiene') || filters.trackers.includes('all') ? hygiene : []);
                    setNotesData(filters.trackers.includes('notes') || filters.trackers.includes('all') ? notes : []);
                }
            });
        } else {
            console.warn("Algunos filtros están vacíos:", filters);
        }
    };

    const handleExportPdf = () => {
        dispatch(exportVitalsReport({
            residentId: params.r,
            locationId: params.l,
            startDate,
            endDate,
            trackers: selectedOptions.map(option => option.value), 
            categoriesNotes: selectedNotesCategoriesOptions.map(option => option.value),
            categoriesVitals: selectedVitalsCategoriesOptions.map(option => option.value),
        }));
    };

    const [dateRange, setDateRange] = useState<DateRange | undefined>({
        from: startOfToday(),
        to: endOfToday(),
    });
    
    const handleDateChange = (range: DateRange | undefined) => {
        setDateRange(range);
        if (range?.from) setStartDate(format(range.from, "yyyy-MM-dd"));
        if (range?.to) setEndDate(format(range.to, "yyyy-MM-dd"));
    };
    
    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} allLocations={false} quickView={true} />

            {locationSelectedId && (
                <>
                    <div className="text-2xl font-bold py-5" style={{color: accentColor}}>Report by Resident</div>
                    <ResidentSelector
                        onSelect={onResidentSelect}
                        locationId={location}
                        residentId={params.r}
                        showAllResidents={false}
                        showKeepInEye={true}
                    />
                    <Card className="mt-2 mb-2 py-4 px-6 border-t-4 border-t-primary/80">
                        <div className="flex flex-wrap items-center gap-4">
                            {/* Date Picker */}
                            <div>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            id="date"
                                            variant="outline"
                                            className={cn(
                                                "w-[300px] justify-start text-left font-normal",
                                                !dateRange?.from && "text-muted-foreground"
                                            )}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {dateRange?.from ? (
                                                dateRange.to ? (
                                                    <>
                                                        {format(dateRange.from, "LLL dd, y")} - {format(dateRange.to, "LLL dd, y")}
                                                    </>
                                                ) : (
                                                    format(dateRange.from, "LLL dd, y")
                                                )
                                            ) : (
                                                <span>Pick a date range</span>
                                            )}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0" align="start">
                                        <Calendar
                                            initialFocus
                                            mode="range"
                                            defaultMonth={dateRange?.from}
                                            selected={dateRange}
                                            onSelect={handleDateChange}
                                            numberOfMonths={2}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            {/* MultiSelects */}
                            <div className="flex flex-wrap min-w-[200px]">
                                <MultiSelect
                                    name="trackers"
                                    options={options}
                                    selectedOptions={selectedOptions}
                                    onChange={handleChange}
                                    placeHolder="Type"
                                    className="w-full"
                                />
                            </div>
                            <div className="flex flex-wrap min-w-[200px]">
                                <MultiSelect
                                    name="notescategories"
                                    options={categoriesWithAll}
                                    selectedOptions={selectedNotesCategoriesOptions}
                                    onChange={handleNoteCategoriesChange}
                                    placeHolder="Notes Categories"
                                    className="w-full"
                                />
                            </div>
                            <div className="flex flex-wrap min-w-[200px]">
                                <MultiSelect
                                    name="vitalscategories"
                                    options={categoriesVitalsWithAll}
                                    selectedOptions={selectedVitalsCategoriesOptions}
                                    onChange={handleVitalsCategoriesChange}
                                    placeHolder="Vitals Categories"
                                    className="w-full"
                                />
                            </div>

                            {/* Apply Filters Button */}
                            <div>
                                <Button
                                    onClick={onApplyFilters}
                                    className="w-auto gap-2" style={{ backgroundColor: accentColor, color: '#fff' }}
                                    variant="outline"
                                >
                                    Apply Filters
                                </Button>
                            </div>
                        </div>
                    </Card>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                                <CardHeader>
                                    <div className="flex justify-between items-center">
                                        {/* Texto del número total de trackers */}
                                        <span className="text-gray-600 font-medium">
                                            {trackersText} {/* Asegúrate de que `trackersText` contenga el texto dinámico */}
                                        </span>
                                        {/* Botón Export PDF */}
                                        <Button 
                                            onClick={handleExportPdf} 
                                            className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                                            variant="default"
                                        >
                                            <CircleFadingPlus className="h-4 w-4" />
                                            Export PDF
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <Accordion type="single" collapsible className="w-full space-y-4">
                                        {/* Acordeón para Tabla de Vitals */}
                                        {appliedFilters && (appliedFilters.trackers.includes('all') || appliedFilters.trackers.includes('vitals')) && (
                                            <AccordionItem value="vitals" className="p-4 border border-[var(--Base-Gris-lines,#E4E4E7)] rounded-md">
                                                <AccordionTrigger>
                                                <div className="flex items-center gap-2">
                                                    <HeartPulse className="text-primary" />
                                                    <span className="text-[16px]" style={{color: accentColor}}>Vitals Tracker</span>
                                                </div>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                {vitalsData.length > 0 ? (
                                                    <div className="rounded-md border overflow-hidden">
                                                    <Table>
                                                        <TableHeader>
                                                        <TableRow className="bg-primary hover:bg-primary rounded-t-md">
                                                            <TableHead className="text-white text-[16px]">Date</TableHead>
                                                            <TableHead className="text-white text-[16px]">Type</TableHead>
                                                            <TableHead className="text-white text-[16px]">Value</TableHead>
                                                        </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                        {vitalsData.map((record: any) => (
                                                            <TableRow key={record.id} className="bg-background">
                                                            <TableCell className="text-[14px] ">
                                                                {new Date(record.data.date).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.type_name}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.value}
                                                            </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                    </div>
                                                ) : (
                                                    <p>No vitals data available</p>
                                                )}
                                                </AccordionContent>
                                            </AccordionItem>
                                        )}

                                        {/* Acordeón para Tabla de Bowel */}
                                        {appliedFilters && (appliedFilters.trackers.includes('all') || appliedFilters.trackers.includes('bowel')) && (
                                            <AccordionItem 
                                                value="bowel" 
                                                className="p-4 border border-[var(--Base-Gris-lines,#E4E4E7)] rounded-md"
                                            >
                                                <AccordionTrigger>
                                                <div className="flex items-center gap-2">
                                                    <Waves className="text-primary" />
                                                    <span className="text-[16px]" style={{color: accentColor}}>Bowel Tracker</span>
                                                </div>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                {bowelData.length > 0 ? (
                                                    <div className="rounded-md border overflow-hidden">
                                                    <Table>
                                                        <TableHeader>
                                                        <TableRow className="bg-primary hover:bg-primary rounded-t-md">
                                                            <TableHead className="text-white text-[16px]">Date</TableHead>
                                                            <TableHead className="text-white text-[16px]">Type</TableHead>
                                                            <TableHead className="text-white text-[16px]">Detail</TableHead>
                                                            <TableHead className="text-white text-[16px]">Who</TableHead>
                                                        </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                        {bowelData.map((record: any) => (
                                                            <TableRow key={record.id} className="bg-background">
                                                            <TableCell className="text-[14px] ">
                                                                {new Date(record.data.date).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.type_name}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.detail || "N/A"}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.who}
                                                            </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                    </div>
                                                ) : (
                                                    <p>No bowel data available</p>
                                                )}
                                                </AccordionContent>
                                            </AccordionItem>
                                        )}

                                        {/* Acordeón para Tabla de Cleaning */}
                                        {appliedFilters && (appliedFilters.trackers.includes('all') || appliedFilters.trackers.includes('cleaning')) && (
                                            <AccordionItem 
                                                value="cleaning" 
                                                className="p-4 border border-[var(--Base-Gris-lines,#E4E4E7)] rounded-md"
                                            >
                                                <AccordionTrigger>
                                                <div className="flex items-center gap-2">
                                                    <Droplet className="text-primary" />
                                                    <span style={{color: accentColor}}>Cleaning Tracker</span>
                                                </div>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                {cleaningData.length > 0 ? (
                                                    <div className="rounded-md border overflow-hidden">
                                                    <Table>
                                                        <TableHeader>
                                                        <TableRow className="bg-primary hover:bg-primary rounded-t-md">
                                                            <TableHead className="text-white text-[16px]">Date</TableHead>
                                                            <TableHead className="text-white text-[16px]">Task</TableHead>
                                                            <TableHead className="text-white text-[16px]">Area</TableHead>
                                                            <TableHead className="text-white text-[16px]">Detail</TableHead>
                                                            <TableHead className="text-white text-[16px]">Staff</TableHead>
                                                        </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                        {cleaningData.map((record: any) => (
                                                            <TableRow key={record.id} className="bg-background">
                                                            <TableCell className="text-[14px] ">
                                                                {new Date(record.data.date).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.task}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.area || "N/A"}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.detail || "N/A"}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.staff}
                                                            </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                    </div>
                                                ) : (
                                                    <p>No cleaning data available</p>
                                                )}
                                                </AccordionContent>
                                            </AccordionItem>
                                        )}

                                        {/* Acordeón para Tabla de Hygiene */}
                                        {appliedFilters && (appliedFilters.trackers.includes('all') || appliedFilters.trackers.includes('hygiene')) && (
                                            <AccordionItem 
                                                value="hygiene" 
                                                className="p-4 border border-[var(--Base-Gris-lines,#E4E4E7)] rounded-md"
                                            >
                                                <AccordionTrigger>
                                                <div className="flex items-center gap-2">
                                                    <ShowerHead className="text-primary" />
                                                    <span style={{color: accentColor}}>Hygiene Tracker</span>
                                                </div>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                {hygieneData.length > 0 ? (
                                                    <div className="rounded-md border overflow-hidden">
                                                    <Table>
                                                        <TableHeader>
                                                        <TableRow className="bg-primary hover:bg-primary rounded-t-md">
                                                            <TableHead className="text-white text-[16px]">Date</TableHead>
                                                            <TableHead className="text-white text-[16px]">Type</TableHead>
                                                            <TableHead className="text-white text-[16px]">Value</TableHead>
                                                            <TableHead className="text-white text-[16px]">Detail</TableHead>
                                                            <TableHead className="text-white text-[16px]">Who</TableHead>
                                                        </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                        {hygieneData.map((record: any) => (
                                                            <TableRow key={record.id} className="bg-background">
                                                            <TableCell className="text-[14px] ">
                                                                {new Date(record.data.date).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.type_name}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.value || "N/A"}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.detail || "N/A"}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.who}
                                                            </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                    </div>
                                                ) : (
                                                    <p>No hygiene data available</p>
                                                )}
                                                </AccordionContent>
                                            </AccordionItem>
                                        )}

                                        {/* Acordeón para Tabla de Notes */}
                                        {appliedFilters && (appliedFilters.trackers.includes('all') || appliedFilters.trackers.includes('notes')) && (
                                            <AccordionItem 
                                                value="notes" 
                                                className="p-4 border border-[var(--Base-Gris-lines,#E4E4E7)] rounded-md"
                                            >
                                                <AccordionTrigger>
                                                <div className="flex items-center gap-2">
                                                    <NotebookPen className="text-primary" />
                                                    <span style={{color: accentColor}}>Notes</span>
                                                </div>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                {notesData.length > 0 ? (
                                                    <div className="rounded-md border overflow-hidden">
                                                    <Table>
                                                        <TableHeader>
                                                        <TableRow className="bg-primary hover:bg-primary rounded-t-md">
                                                            <TableHead className="text-white text-[16px]">Date</TableHead>
                                                            <TableHead className="text-white text-[16px]">Who</TableHead>
                                                            <TableHead className="text-white text-[16px]">Category</TableHead>
                                                            <TableHead className="text-white text-[16px]">Note</TableHead>
                                                        </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                        {notesData.map((record: any) => (
                                                            <TableRow key={record.id} className="bg-background">
                                                            <TableCell className="text-[14px] ">
                                                                {new Date(record.data.created_at).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.created_by_name}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.category || "N/A"}
                                                            </TableCell>
                                                            <TableCell className="text-[14px] ">
                                                                {record.data.content || "N/A"}
                                                            </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                    </div>
                                                ) : (
                                                    <p>No Notes data available</p>
                                                )}
                                                </AccordionContent>
                                            </AccordionItem>
                                        )}
                                    </Accordion>
                                </CardContent>
                            </Card>
                        </>
                    )}
                </>
            )}
        </div>
    );
};


export default ReportByResidentPage;