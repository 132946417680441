import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/common/presentation/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Code } from '@/modules/residents/infrastructure/types/resident';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CheckIcon, Save, Search, Trash, X } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export interface DefaultValues {
  id?: string;
  description: string;
  codes: Code[];
  created_at?: Date;
  type: string;
}

interface Props {
  defaultValues: DefaultValues;
  disabled?: boolean;
  onSubmit: (values: any) => void;
  onRemove: (id: string | undefined) => void;
}

const formSchema = z.object({
  codes: z.array(z.object({ name: z.string().optional() })).default([]),
  description: z.string().optional()
});

export type FormValues = z.infer<typeof formSchema>;

export const DiagnosisCard = ({ defaultValues, onRemove, onSubmit, disabled = false }: Props) => {
  const [searchItems, setSearchItems] = useState<Code[]>([]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      codes: defaultValues.codes || []
    },
    values: { ...defaultValues, codes: defaultValues.codes || [] } as FormValues
  });

  const { searchCode, status: medicalInformationFormStatus } = useMedicalInformationStore();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      onSubmit({ id: defaultValues?.id, ...values });
      form.reset(values);
    },
    [onSubmit, defaultValues?.id, form]
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onRemove((defaultValues?.id as string) || undefined);
    },
    [onRemove, defaultValues?.id]
  );

  function transformObject(obj: Record<string, any> | null): Code[] {
    if (!obj) return [];
    const result: Code[] = [];

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];

        if (typeof value === 'string') {
          result.push({ name: value });
        } else if (typeof value === 'object') {
          result.push({ name: key });
        }
      }
    }

    return result;
  }

  const handleSearch = useCallback(async (value: string) => {
    if (value.length > 2) {
      const response = await searchCode(value);
      if (response) setSearchItems(transformObject(response));
    }
  }, []);

  const handleOpen = (isOpen: boolean) => {
    if (!isOpen) {
      setSearchItems([]);
    }
  };

  return (
    <div className="bg-white drop-shadow-md border rounded-md p-4 w-full">
      {(defaultValues?.type === 'primary' || defaultValues?.type === 'secondary') && (
        <h3 className="py-2 font-bold text-primary/80 text-lg">
          {defaultValues?.type === 'primary' ? 'Primary Diagnosis' : 'Secondary Diagnosis'}
        </h3>
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="codes"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>IC10 Code</FormLabel>
                <FormControl>
                  <Popover onOpenChange={handleOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant={'outline'}
                        className={cn(
                          'w-full flex items-center justify-start font-normal min-h-[45px] h-auto',
                          !field.value && 'text-muted-foreground'
                        )}
                        disabled={medicalInformationFormStatus === 'loading'}
                      >
                        {field.value?.length === 0 ? (
                          <div className="flex gap-2 items-center justify-start">
                            <Search className="size-4" />
                            <span>Search values...</span>
                          </div>
                        ) : (
                          <div className="flex gap-2 flex-wrap">
                            {(field.value || []).map((item, index) => (
                              <Badge
                                variant="outline"
                                className="rounded-sm py-1 px-2 font-normal border-primary/50"
                                key={index}
                              >
                                <X
                                  className="size-4 mr-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    field.onChange(
                                      (field.value || []).filter((value: any) => value.name !== item.name)
                                    );
                                  }}
                                />
                                {item.name}
                              </Badge>
                            ))}
                          </div>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="p-1 w-[500px]">
                      <Command className="w-full" filter={() => 1}>
                        <CommandInput
                          placeholder="Please enter 3 or more characters to search for items..."
                          onValueChange={(value) => handleSearch(value)}
                        />
                        <CommandList>
                          <CommandEmpty>No results found.</CommandEmpty>
                          <CommandGroup>
                            {searchItems.map((item: any, index: number) => {
                              const isSelected = (field.value || []).some((value: any) => value.name === item.name);

                              return (
                                <CommandItem
                                  value={item.name}
                                  key={index}
                                  onSelect={() =>
                                    field.onChange(
                                      isSelected
                                        ? (field.value || []).filter((value: any) => value.name !== item.name)
                                        : [...(field.value || []), item]
                                    )
                                  }
                                >
                                  <div
                                    className={cn(
                                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                      isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                                    )}
                                  >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                  </div>
                                  {item.name}
                                </CommandItem>
                              );
                            })}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    className="min-h-[40px] h-[40px]"
                    {...field}
                    value={field.value || ''}
                    disabled={medicalInformationFormStatus === 'loading'}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="col-span-2 flex flex-col gap-4">
            <Separator />
            <div className="flex items-center justify-between gap-2">
              {defaultValues?.created_at && (
                <p>
                  <span className="font-bold">Created on:</span>{' '}
                  {format(new Date(defaultValues?.created_at ?? ''), 'MM-dd-yyyy hh:mm a')}
                </p>
              )}

              <div className="ml-auto flex items-center gap-2">
                {form.formState.isDirty && (
                  <Button
                    variant="outline"
                    className="border-green-500 hover:bg-green-500/10"
                    size="icon"
                    type="submit"
                    disabled={disabled}
                  >
                    <Save className="h-4 w-4 text-green-500" />
                  </Button>
                )}

                <Button
                  className="flex gap-2 border-red-500 hover:bg-red-500/10"
                  variant={'outline'}
                  size={'icon'}
                  onClick={handleRemove}
                  disabled={disabled}
                >
                  <Trash className="h-4 w-4 flex-1 text-red-500" />
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
