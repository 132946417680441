import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { toast } from '@/common/presentation/components/ui/use-toast';

import { PrimaryInformation } from '@/modules/residents/presentation/components/PrimaryInformation';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch, RootState } from '@/store/store';

import { CarriersFinancial } from '@/modules/residents/presentation/components/CarrierAndFinancialForm/CarriersFinancial';
import { MedicalInformation } from '@/modules/residents/presentation/components/MedicalInformationForm/MedicalInformation';
import { OtherRquiredInformation } from '@/modules/residents/presentation/components/OtherRequiredInformation';
import { PlanOfCare } from '@/modules/residents/presentation/components/PlanOfCareForm/PlanOfCarePage';
import { getResidentNavigation } from '../../application/services/getResidentNavigation';

export const UpdateResident = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { params, setParam } = useRouteParams();
  const residentNavigationTabs = getResidentNavigation(t)
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const [selectedTab, setSelectedTab] = useState(params.t ? params.t : 'primary');

  const getResident = async () => {
    const response: any = await dispatch(residentActions.getResidentById(params.r));

    if (response?.error) {
      toast({
        description: 'Failed to load resident',
        variant: 'destructive'
      });
      navigate('/residents');
    }
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    navigate(`/residents/update?r=${params.r}&t=${tab}`);
  };

  useEffect(() => {
    getResident();
  }, [params.r]);

  useEffect(() => {
    if (!params.t) {
      setParam('t', 'primary');
    }
  }, [params.t, setParam]);

  if (status === 'loading' && !resident) {
    return <SplashScreen />;
  }

  return (
    <>
      <div className="py-4 flex flex-col gap-6">
        <div>
          <h1 className="text-2xl font-semibold ">
            Update Information for{' '}
            <span className="font-bold">
              {resident?.first_name} {resident?.last_name}
            </span>
          </h1>
        </div>

        <div className="">
          <Tabs defaultValue={selectedTab} className="w-full">
            <TabsList className="w-full h-full grid grid-cols-2 md:grid-cols-6 gap-2">
              {residentNavigationTabs.map((tab, index) => (
                <TabsTrigger
                  key={index}
                  value={tab.value}
                  className="w-full data-[state=active]:bg-primary data-[state=active]:text-white whitespace-normal overflow-ellipsis overflow-hidden"
                  onClick={() => handleTabChange(tab.value)}
                >
                  {tab.icon}
                  {tab.title}
                </TabsTrigger>
              ))}
            </TabsList>
            <TabsContent value="primary" className="h-full  ">
              <PrimaryInformation />
            </TabsContent>
            <TabsContent value="other" className="h-full  ">
              <OtherRquiredInformation />
            </TabsContent>
            <TabsContent value="medical" className="h-full  ">
              <MedicalInformation />
            </TabsContent>
            <TabsContent value="planOfCare" className="h-full  ">
              <PlanOfCare />
            </TabsContent>
            <TabsContent value="insuranceAndFinancial" className="h-full  ">
              <CarriersFinancial />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
};
