import { create } from 'zustand';
import ResidentMedicalInformationService from '../../../infrastructure/services/MedicalInformationService';

type MedicalInformationState = {
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  updateHealthInformation: (data: any) => Promise<any>;
  uppsertDiagnosisInformation: (data: any) => Promise<any>;
  deleteDiagnosisInformation: (data: any) => Promise<any>;
  uppsertAllergyInformation: (data: any) => Promise<any>;
  deleteAllergyInformation: (data: any) => Promise<any>;
  uppsertExamOrProcedureInformation: (data: any) => Promise<any>;
  deleteExamOrProcedureInformation: (data: any) => Promise<any>;
  uppsertMentalConditionInformation: (data: any) => Promise<any>;
  uppsertTubercolosisInformation: (data: any) => Promise<any>;
  createOrUpdateActionsInformation: (data: any) => Promise<any>;
  searchCode: (code: string) => Promise<any>;
};

export const useMedicalInformationStore = create<MedicalInformationState>((set) => ({
  status: 'idle',
  error: null,

  updateHealthInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.updateHealthInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  uppsertDiagnosisInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.uppsertDiagnosisInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteDiagnosisInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.deleteDiagnosisInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  uppsertAllergyInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.uppsertAllergyInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteAllergyInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.deleteAllergyInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  uppsertExamOrProcedureInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.uppsertExamOrProcedureInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteExamOrProcedureInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.deleteExamOrProcedureInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  uppsertMentalConditionInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.uppsertMentalConditionInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  uppsertTubercolosisInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.uppsertTubercolosisInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateActionsInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateActionsInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  searchCode: async (code: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.searchCode(code);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
