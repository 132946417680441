import { Label } from '@/common/presentation/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { OptionI } from '../../domain/alerts.domain';
import React, { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { generateMonthlyFrequencyOptions } from '../../infraestructure/utils/frequency.helper';
import { useTranslation } from 'react-i18next';

interface IProps {
  date: Date | undefined;
  optionSelected: string;
  setOptionSelected: (option: string) => void;
}

const MonthlyFrequencySelector: React.FC<IProps> = ({ date, optionSelected, setOptionSelected }) => {
  const [options, setOptions] = useState<OptionI[]>([]);
  const commonInputClasses =
    'focus:border-2 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none focus-visible:ring-0 focus-visible:ring-primary focus-visible:ring-offset-0';
  const { t } = useTranslation();

  useEffect(() => {
    if (date) {
      setOptions(generateMonthlyFrequencyOptions(date, t));
    }
  }, [date]);

  return (
    <div className="space-y-2 z-[100000]">
      <Label>
        { t('customAlerts.form.repeatOn') }<span className="text-red-500">*</span>
      </Label>
      <div>
        <Select
          value={optionSelected}
          onValueChange={(value: string) => {
            setOptionSelected(value);
          }}
        >
          <SelectTrigger className={cn(commonInputClasses, 'w-full z-[100000]')}>
            <SelectValue placeholder={t('customAlerts.form.frequency')} />
          </SelectTrigger>
          <SelectContent className={cn('z-[100000]')}>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default MonthlyFrequencySelector;
