import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { t } from "i18next";
import { CircleFadingPlus, Trash } from "lucide-react";
import { useState } from "react";

interface OptionsManagerProps {
    setTypeOptions: (setTypeOptions: string[]) => void;
    options: string[];
    disabled?: boolean;
};

const OptionsManager = ({ setTypeOptions, options, disabled = false }: OptionsManagerProps) => {
    const [newOption, setNewOption] = useState<string>();

    const addNewOption = () => {
        if(newOption) setTypeOptions([...options, newOption]);

        setNewOption("");
    };

    const removeOption = (option: string) => {
        setTypeOptions(
            options.filter(
                (value) => value != option
            )
        );
    }

    return (
        <div>
            <Label htmlFor="option" className="text-right">
                {t("common.options")}
            </Label>
            
            <div className="flex">
                <Input
                    id="option"
                    name="option"
                    type="text"
                    className="col-span-3 focus-visible:ring-0 focus-visible:ring-offset-0"
                    disabled={disabled}
                    value={newOption}
                    onChange={(event) => setNewOption(event.target.value)}
                    />

                <Button 
                    variant="outline" 
                    size="default" 
                    disabled={disabled}
                    className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end ml-1' 
                    onClick={() => addNewOption()}
                >
                    <CircleFadingPlus className="h-4 w-4" />
                    <span className="ml-2">
                        {t("common.add")}
                    </span>
                </Button>
            </div>

            <ScrollArea className="h-72 rounded-md border mt-1">
                <div className="p-4 relative">
                    {
                        disabled ? (
                            <div 
                                className="bg-white absolute top-0 left-0" 
                                style={
                                    {
                                        width: "100%", 
                                        height: "100%",
                                        opacity: "0.5"
                                    }
                                }
                            ></div>
                        ): null
                    }

                    {
                        options && options.length ? (
                            options.map(
                                (option) => (
                                    <>
                                        <div key={option} className="flex justify-between text-sm">
                                            <span>{option}</span>
                                            <Trash 
                                                className="w-4 h-4 cursor-pointer"
                                                onClick={
                                                    () => {
                                                        if(!disabled) removeOption(option)
                                                    }
                                                } 
                                            />
                                        </div>
    
                                        <Separator className="my-2" />
                                    </>
                                )
                            )
                        ): null
                    }
                </div>
            </ScrollArea>
        </div>
    );
};

export default OptionsManager;
