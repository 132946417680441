import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { RootState } from '@/store/store';
import { Contact, Mail, MapPin, Phone } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface Props {
  className?: string;
}

export const ContactCard = ({ className }: Props) => {
  const { t } = useTranslation();
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const { telephone, email, address } = staffMember || {};
  const commonClasses = 'text-xs text-zinc-600  dark:text-zinc-50 hover:underline cursor-pointer'


  const handleCopyToClipboard = (text: string, message: string) => {
    navigator.clipboard.writeText(text);
    toast({ description: `${message} copiado al portapapeles.` });
  };

  return (
    <div className={className}>
      <CustomAccordion
        titleComponent={
          <div className="flex items-center text-primary">
            <Contact className="w-6 h-6 mr-2" />
            <h1 className="font-bold">{t('staff.profile.contact.title')}</h1>
          </div>
        }
        openOption={false}
        defaultOpen
        className="min-h-[250px]"
      >
        <div className="flex flex-col gap-4">
          <Separator className="w-full bg-primary" />

          <div className="space-y-4">
            <div className="flex items-center gap-6 rounded-md bg-zinc-100 dark:bg-zinc-900 h-8 px-6 py-1">
              <Phone size={16} className="text-primary" />
              {telephone ? (
                <a
                  href={`tel:${telephone}`}
                  onClick={() => handleCopyToClipboard(telephone, 'Número de teléfono')}
                  className={commonClasses}
                >
                  {telephone}
                </a>
              ) : (
                <p className={commonClasses}>
                  {t('general.not_specified')}
                </p>
              )}
            </div>

            <div className="flex items-center gap-6 rounded-md bg-zinc-100 dark:bg-zinc-900  h-8 px-6 py-1">
              <Mail size={16} className="text-primary" />
              {email ? (
                <a
                  href={`mailto:${email}`}
                  className={commonClasses}
                >
                  {email}
                </a>
              ) : (
                <p className={commonClasses}>
                  {t('general.not_specified')}
                </p>
              )}
            </div>

            <div className="flex items-center gap-6 rounded-md bg-zinc-100 dark:bg-zinc-900 h-8 px-6 py-1">
              <MapPin size={16} className="text-primary" />
              {address ? (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={commonClasses}
                >
                  {address}
                </a>
              ) : (
                <p className={commonClasses}>
                  {t('general.not_specified')}
                </p>
              )}
            </div>
          </div>
        </div>
      </CustomAccordion>
    </div>
  );
};
