import { t } from "i18next";
import RenderAreaActionsColumn from "./renderAreaActionsColumns";

const useCleaningAreaColumns = () => {
  const CleaningAreaColumns = [
    {
      key: 'name',
      id: 'name',
      labelTranslationKey: 'cleaning.create.area.table.area',
      value: 'name',
      visible: true,
      sortable: true,
      width: '70%'
    },
    {
      key: 'status',
      id: 'status',
      labelTranslationKey: 'common.status',
      value: 'status',
      render: (row) => row.status === "active" ? t("common.active") : t("common.inactive"),
      visible: true,
      sortable: true,
      width: '30%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: (rowData: any) => RenderAreaActionsColumn(rowData),
        visible: true,
        static: true,
        width: '10%'
      }
  ];

  const translateColumnLabel = (key) =>
    t(CleaningAreaColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = CleaningAreaColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useCleaningAreaColumns;
