import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Card } from '@/common/presentation/components/ui/card';
import { useTranslation } from 'react-i18next';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Ellipsis, FileDown } from 'lucide-react';
import { Button } from '@/common/presentation/components/ui/button';
import { FiltersTable } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/FiltersTable';

interface TrackerI {

    data: TrackerDataI;
    resident_id: string;
    resident_name: string;
    location_id: string;
}

interface TrackerDataI {
    date: string;
    id_tracker: string;
    injuries: 'yes' | 'no' | '';
    persons_informed: string | '';
    reported_by: string;
    created_by: {
        id: string;
        first_name: string;
        last_name: string;
    };
    witness: 'yes' | 'no' | '';
    id: string;
    location_id: string;
    location_fall_id: string;
    created_at: string;

}

interface TrackerFallSumaryI {
    dataReport: TrackerI[];
    isLoading: boolean;
    loadMore: () => void;
    hasMore: boolean;
    resident: string;
    onFilterChange: (value: string) => void;
    filterValue: string;
    onClear: () => void;
}


export function FallSummary({ dataReport, isLoading, loadMore, hasMore, resident, onFilterChange,
    filterValue, onClear }: TrackerFallSumaryI) {

    const { t } = useTranslation();

    const renderActions = () => (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon">
                    <Ellipsis className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem>
                        <FileDown className="mr-2 h-4 w-4" />
                        <span> {t('general.export')}</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );

    const columnsreport = [
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    return (
        <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
            <TableCustomVirtuoso
                data={dataReport}
                columns={columnsreport}
                renderCellContent={(index, column, data) => {
                    const row = data[index];

                    return row[column.key];
                }}
                loadMore={loadMore}
                hasMore={hasMore}
                key={resident}
                isLoading={isLoading}
                additionalButtons={<></>}
                filters={
                    <div className="w-1/2">
                        <FiltersTable
                            onFilterChange={onFilterChange}
                            filterValue={filterValue}
                            onClear={onClear}
                        />
                    </div>
                }
                showSearchInput={false}

            />
        </Card>
    )
}