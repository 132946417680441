import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/common/presentation/components/ui/form';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { SuggestionSheet } from './SuggestionSheet';

interface FormFieldComponentProps {
  name: string;
  label: string;
  control: any;
  defaultValue?: string[];
  addCompletitionToTextarea?: (value: string) => void;
  removeCompletionFromTextarea?: (value: string) => void;
}

export const FormFieldComponent: React.FC<FormFieldComponentProps> = ({
  name,
  label,
  control,
  defaultValue,
  addCompletitionToTextarea,
  removeCompletionFromTextarea
}) => {
  return (
    <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const addText = (value: string) => {
            if (addCompletitionToTextarea) {
              addCompletitionToTextarea(value);
            } else {
              field.onChange(`${field.value ? field.value + '\n' : ''}${value}`);
            }
          };

          const removeText = (value: string) => {
            if (removeCompletionFromTextarea) {
              removeCompletionFromTextarea(value);
            } else {
              const updatedValue = field.value
                .split('\n')
                .filter((line: string) => line.trim() !== value.trim())
                .join('\n');
              field.onChange(updatedValue);
            }
          };

          return (
            <FormItem>
              <FormLabel>{label}</FormLabel>
              <FormControl>
                <div className="relative cursor-pointer">
                  <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                    {addCompletitionToTextarea && removeCompletionFromTextarea ? (
                      <SuggestionSheet
                        label={`${label}`}
                        defaultValue={defaultValue}
                        addCompletitionToTextarea={addText}
                        removeCompletionFromTextarea={removeText}
                        currentValue={field.value}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <Textarea
                    className="h-[200px] border-2 focus-visible:ring-0 focus-visible:ring-offset-0 pb-14"
                    {...field}
                  />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
};
