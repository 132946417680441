import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Clock } from 'lucide-react';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(moment().tz('America/Los_Angeles').format('h:mm:ss a'));
  const { configurations } = useConfigurationsProvider();
  const { t } = useTranslation();

  useEffect(() => {
    if (!configurations.timezone) {
      return;
    }
    setCurrentTime(moment().tz(configurations.timezone).format('h:mm:ss a'));
  }, [configurations]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz(configurations.timezone).format('h:mm:ss a'));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Card className="w-full border-t-4 border-t-primary/80 h-full flex flex-col">
      <CardHeader>
        <div className="flex flex-row items-center mb-2">
          <Clock className="shrink-0 h-6 w-6 mr-2 text-muted-foreground" />
          <CardTitle className="flex-1 font-bold text-muted-foreground text-lg">
            {t('dashboard.currentTimeZone')} ({configurations.timezone})
          </CardTitle>
        </div>
        <hr />
      </CardHeader>
      <CardContent className="flex-grow flex flex-col">
        <div className="text-base font-bold text-muted-foreground">{currentTime}</div>
      </CardContent>
    </Card>
  );
};

export default CurrentTime;
