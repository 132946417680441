import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { useNewResident } from '@/modules/residents/infrastructure/hooks/use-new-resident';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus, UsersRound, X } from 'lucide-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import { toast } from '../ui/use-toast';
import { ImageCard } from './ImageCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeepInEye from '../KeepInEye/KeepInEye';
import { RoleHelper, UserHelper } from '@/utils/helpers/user.helper';
import Title from '../Title/Title';

interface ResidentSelectorProps {
  onSelect?: (id: string) => void;
  className?: React.ReactNode;
  locationId?: string;
  residentId?: string | null;
  showAllResidents?: boolean;
  setUrlParams?: boolean;
  description?: string | null;
  filterByGender?: string;
  showKeepInEye?: boolean;
  showFilterInput?: boolean;
  setLocationParams?: boolean;
  showInCard?: boolean;
  closeButton?: () => void;
}

export const ResidentSelector = ({
  onSelect,
  className,
  locationId = 'all-locations',
  residentId = 'all-residents',
  showAllResidents = true,
  setUrlParams = true,
  description = null,
  filterByGender,
  showKeepInEye = false,
  showFilterInput = true,
  setLocationParams = true,
  showInCard = true,
  closeButton
}: ResidentSelectorProps) => {
  const { params, setParam, resetParams } = useRouteParams();
  const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
  const [selectedUser, setSelectedUser] = useState(params.r || residentId);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredResidents, setFilteredResidents] = useState(residents);
  const residentKeepRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const { onOpen } = useNewResident();

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const canCreateResident = useMemo(() => {
    return !RoleHelper.hasRole('Pharmacy');
  }, [user]);

  const getResidents = async () => {
    setIsLoading(true);
    const response: any = await dispatch(
      getResidentsByLocation(locationId === 'all-locations' ? undefined : locationId)
    );
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
    setIsLoading(false);
  };

  const getResident = async (id: string) => {
    const response: any = await dispatch(residentActions.getResidentById(id));
    if (response.error) {
      toast({
        description: 'Failed to get resident',
        variant: 'destructive'
      });
      resetParams();
      navigate('/');
    }
  };

  const onSelectedUser = (id: string) => {
    setSelectedUser(id);
    onSelect?.(id);
    if (setUrlParams) setParam('r', id);

    if (id === 'all-residents') {
      dispatch(residentActions.resetResident());
    } else {
      getResident(id);
    }
  };

  const handleFilterResidents = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue = event.target.value.toLowerCase();
    if (filterValue) {
      const filtered = residents.filter((resident) => {
        return `${resident.first_name} ${resident.last_name}`.toLowerCase().includes(filterValue)
      });
      setFilteredResidents(filtered);
    } else {
      setFilteredResidents(residents);
    }
  };

  const handleFilterByGender = (residents: any[], sex: string) => {
    return residents.filter((resident) => resident.sex === sex);
  };

  useEffect(() => {
    if (locationId) {
      getResidents();
    }
  }, [locationId, dispatch]);

  useEffect(() => {
    dispatch(residentActions.resetResident());
    if (params.l) {
      dispatch(residentsActions.getResidentsByLocation(params.l === 'all-locations' ? undefined : params.l));
    } else {
      if (setLocationParams) setParam('l', 'all-locations');
    }
  }, []);

  useEffect(() => {
    if (params.r) {
      setSelectedUser(params.r);
      if (params.r === 'all-residents') {
        dispatch(residentActions.resetResident());
      } else {
        getResident(params.r);
      }
    }
  }, [params.r, dispatch]);

  useEffect(() => {
    setFilteredResidents(residents);
  }, [residents]);

  useEffect(() => {
    if (residents && residents.length > 0) {
      let filtered = residents;
      if (filterByGender) {
        filtered = handleFilterByGender(residents, filterByGender);
      }
      setFilteredResidents(filtered);
    }
  }, [residents, filterByGender]);

  const residentSelected = useMemo(() => {
    return UserHelper.getResidentInformation(params.r, residents);
  }, [params.r, residents]);

  return (
    showInCard ? (
      <div className={cn(className)}>
        <Card className="border-t-4 border-t-primary/80">
          <CardHeader>
            <CardTitle className="flex flex-row items-center text-lg font-bold">
              <Title>{t('residentSelector.residents')} </Title>

              {residents.length > 0 && showFilterInput && (
                <Input
                  placeholder={t('residentSelector.filterByResident')}
                  className="w-full md:w-[350px] mt-4 md:mt-0 ml-auto"
                  onChange={handleFilterResidents}
                />
              )}
            </CardTitle>
            <CardDescription className="flex flex-col md:flex-row items-center">
              {/* Records all medications that the resident is taking for a specific period of time or for an indefinite
            period of time. */}
            </CardDescription>
          </CardHeader>
          <CardContent ref={residentKeepRef}>
            {isLoading ? (
              <div className="flex items-center justify-start">
                <div className="flex flex-col items-center gap-2">
                  <Skeleton className="h-20 w-20 rounded-full bg-primary/20" />
                  <Skeleton className="h-4 w-16 mt-3 rounded-full bg-primary/20" />
                  <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
                </div>
              </div>
            ) : (
              <ScrollArea>
                <div className="flex gap-4 mb-4">
                  {filteredResidents.length === 0 && (
                    <div className="w-full flex flex-col gap-2 items-center justify-center">
                      <p>{t('residentSelector.notFound')}</p>
                      {
                        canCreateResident && (
                          <Button onClick={onOpen} className="flex gap-2 bg-primary">
                            <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                            {t('residentSelector.createResident')}
                          </Button>
                        )
                      }
                    </div>
                  )}

                  {filteredResidents.length > 0 && (
                    <div className="flex gap-2">
                      {showAllResidents && (
                        <ImageCard
                          item={{
                            id: 'all-residents',
                            firstName: t('residentSelector.allResidents').split(',')[0],
                            lastName: t('residentSelector.allResidents').split(',')[1]
                          }}
                          icon={UsersRound}
                          onSelect={() => onSelectedUser('all-residents')}
                          aspectRatio={'rounded'}
                          isSelected={selectedUser === 'all-residents'}
                        />
                      )}

                      {filteredResidents.map((resident, index) => (
                        <ImageCard
                          key={index}
                          item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                          }}
                          onSelect={() => onSelectedUser(resident.id)}
                          aspectRatio={'rounded'}
                          isSelected={selectedUser === resident.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            )}
          </CardContent>
        </Card>
        {showKeepInEye && residentSelected && (
          <KeepInEye
            observedRef={residentKeepRef}
            text={`${residentSelected.first_name} ${residentSelected.last_name}`}
            image={residentSelected.image_url ?? ''}
            whileHover={{ scale: 1.1 }}
          />
        )}
      </div>
    ) : (
      <div className={cn(className)}>
        <div className="flex flex-row items-center text-lg font-bold justify-between mb-3">
          <Title className='text-primary text-xl'>{t('residentSelector.residents')} </Title>

          <div className='flex justify-end items-center'>
            {residents.length > 0 && showFilterInput && (
              <Input
                placeholder={t('residentSelector.filterByResident')}
                className="w-full md:w-[350px] mt-4 md:mt-0 ml-auto shadow"
                onChange={handleFilterResidents}
              />
            )}
            {closeButton && (
              <X className="h-5 w-5 flex-1 gap-4 cursor-pointer ml-3" onClick={closeButton} />
            )}
          </div>
        </div>
        <Card>
          <CardContent className='p-4 pb-0 bg-zinc-50 dark:bg-zinc-900 rounded-lg' ref={residentKeepRef}>
            {isLoading ? (
              <div className="flex items-center justify-start">
                <div className="flex flex-col items-center gap-2 p-2">
                  <Skeleton className="h-12 w-12 rounded-full bg-primary/20" />
                  <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
                  <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
                </div>
              </div>
            ) : (
              <ScrollArea>
                <div className="flex gap-4 mb-4">
                  {filteredResidents.length === 0&& (
                    <div className="w-full flex flex-col gap-2 items-center justify-center">
                      <p>{t('residentSelector.notFound')}</p>
                      {
                        canCreateResident && (
                          <Button onClick={onOpen} className="flex gap-2 bg-primary">
                            <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                            {t('residentSelector.createResident')}
                          </Button>
                        ) 
                      }
                    </div>
                  )}

                  {filteredResidents.length > 0 && (
                    <div className="flex gap-2">
                      {showAllResidents && (
                        <ImageCard
                          item={{
                            id: 'all-residents',
                            firstName: t('residentSelector.allResidents').split(',')[0],
                            lastName: t('residentSelector.allResidents').split(',')[1]
                          }}
                          icon={UsersRound}
                          classNameAvatar='h-12 w-12'
                          onSelect={() => onSelectedUser('all-residents')}
                          aspectRatio={'rounded'}
                          isSelected={selectedUser === 'all-residents'}
                        />
                      )}

                      {filteredResidents.map((resident, index) => (
                        <ImageCard
                          key={index}
                          item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                          }}
                          classNameAvatar='h-12 w-12'
                          onSelect={() => onSelectedUser(resident.id)}
                          aspectRatio={'rounded'}
                          isSelected={selectedUser === resident.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            )}
          </CardContent>
        </Card>
        {showKeepInEye && residentSelected && (
          <KeepInEye
            observedRef={residentKeepRef}
            text={`${residentSelected.first_name} ${residentSelected.last_name}`}
            image={residentSelected.image_url ?? ''}
            whileHover={{ scale: 1.1 }}
          />
        )}
      </div>
    )
  );
};
