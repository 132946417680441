import { Button } from '@/common/presentation/components/ui/button';
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from '@/lib/utils';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { format } from 'date-fns';
import { FormData as FormDataModel } from '@/modules/notes/domain/note.domain';
import { getDoctorsSpecialtiesNote } from '../../../slices/NoteSlice';
import { MedicalAppoinment } from '../createNote';
import { Popover, PopoverContent, PopoverTrigger, } from "@/common/presentation/components/ui/popover";
import { RootState } from '@/store/store';
import { SingleValue } from 'react-select';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';


interface Option {
    value: string;
    label: string;
}

interface InputsMedicalAppoinmentsProps {
    formData: FormDataModel;
    setFormData: (crudState: FormDataModel) => void;
    setDataMedicalAppoinmentormData: (data: MedicalAppoinment) => void;
    dataMedicalAppoinment: MedicalAppoinment
}

export function InputsMedicalAppoinments({ formData, setFormData, dataMedicalAppoinment, setDataMedicalAppoinmentormData }: InputsMedicalAppoinmentsProps) {
    // Init values
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const doctorsSpecialties = useSelector((state: RootState) => state.notes.doctorsSpecialties);
    const { formatDate } = useDateHelpers();

    const [selectedDate, setSelectedDate] = useState(formData.medical_appoinment_date ? new Date(formData.medical_appoinment_date) : new Date());
    const formattedDate = selectedDate ? selectedDate.toISOString() : new Date().toISOString();

    useEffect(() => {
        dispatch<any>(getDoctorsSpecialtiesNote());
    }, [])

    useEffect(() => {
        setFormData({
            ...formData,
            medical_appoinment_date: selectedDate.toISOString()
        });
        setDataMedicalAppoinmentormData({ ...dataMedicalAppoinment, date: format(new Date(formattedDate), "yyyy-MM-dd hh:mm a") })
    }, [selectedDate])

    let optionsDoctors: Array<Option> = doctorsSpecialties.doctors.map(option => {
        return {
            label: option.name,
            value: option.id
        }
    })

    let optionsSpecialties: Array<Option> = doctorsSpecialties.specialties.map(option => {
        return {
            label: option.name,
            value: option.id
        }
    })

    const customClassNames = {
        control: () =>
            cn(
                '!bg-background !border !border-gray-300 dark:!border-gray-700 !rounded-md focus:!outline-none !shadow-none !min-h-10',
                'max-h-20 overflow-y-auto'
            ),
        menu: () => cn('!bg-background !border !border-gray !z-[999999]'),
        option: ({ isSelected, isFocused }: any) =>
            cn(
                'dark:!text-white dark:hover:!bg-primary/60 hover:!bg-primary/60',
                isSelected ? '!bg-primary text-white' : isFocused ? '!bg-primary/10' : ''
            ),
        singleValue: () => cn('text-dark dark:text-white'),
        multiValue: () => cn('!bg-primary !text-white !rounded flex-shrink-0'),
        multiValueLabel: () => cn('!text-white'),
        multiValueRemove: () => cn('hover:!bg-gray-400 hover:!text-white'),
        valueContainer: () => cn('!flex !flex-row !flex-wrap !gap-1 !overflow-x-auto !py-1 max-h-20'),
        input: () => cn('!text-sm'),
        container: () => cn('!min-h-10')
    };

    const handleSelectDoctor = (selectedOptions: SingleValue<Option>) => {
        setFormData({
            ...formData,
            doctor: selectedOptions ? selectedOptions.value : null
        });
        setDataMedicalAppoinmentormData({ ...dataMedicalAppoinment, doctor: selectedOptions ? selectedOptions.label : '' })
    };

    const handleSelectSpecialty = (selectedOptions: SingleValue<Option>) => {
        setFormData({
            ...formData,
            specialty: selectedOptions ? selectedOptions.value : null
        });
        setDataMedicalAppoinmentormData({ ...dataMedicalAppoinment, specialty: selectedOptions ? selectedOptions.label : '' })
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
    };

    const findValue = (type: string, key: string) => {
        if (type == 'doctor') {
            return optionsDoctors.find(option => option.value == key);
        }
        return optionsSpecialties.find(option => option.value == key);
    }

    return (
        <>
            <div className='col-span-12 md:col-span-4'>
                <CreatableSelect
                    name="specialtyNote"
                    classNames={customClassNames}
                    value={findValue('specialty', formData.specialty || '')}
                    options={optionsSpecialties}
                    classNamePrefix="select"
                    placeholder={t('notes.specialtyNote')}
                    onChange={handleSelectSpecialty}
                    className="w-full"
                />
            </div>
            <div className='col-span-12 md:col-span-4'>
                <CreatableSelect
                    name="doctorNote"
                    value={findValue('doctor', formData.doctor || '')}
                    classNames={customClassNames}
                    options={optionsDoctors}
                    classNamePrefix="select"
                    placeholder={t('notes.doctorNote')}
                    onChange={handleSelectDoctor}
                    className="w-full"
                />
            </div>
            <div className='col-span-12 md:col-span-4'>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {formatDate(formattedDate)}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <CustomCalendar
                            onChange={handleDateChange}
                            selectedDate={selectedDate}
                            MonthAndYearPicker={true}
                            timePicker={true}
                        />
                    </PopoverContent>
                </Popover>
            </div>
        </>
    );
}
