import React from 'react'
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Pencil, CircleFadingPlus } from 'lucide-react';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import UtilService from '@/common/services/UtilService';
import { Input } from '@/common/presentation/components/ui/input';

interface TrackerVitalTypeProps {
    setpTypesVital?: () => void;
}

function TrackerVitalsType({ setpTypesVital }: TrackerVitalTypeProps) {
    const { t } = useTranslation();
    const [types, setTypes] = useState(null);
    const [dataid, setDataid] = useState(null);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const { locationSelectedId } = useLocation();
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
+
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const setpTypes = async () => {
        const response = await TrackerService.getTrackerVitalsType(
            {
                location_id: locationSelectedId 
            }
        );

        if (response && response.data) {
            const jsonData = JSON.parse(response.data);
            const columnField = jsonData.column_field;

            setTypes(
                UtilService.sortObjectsAlphabetically(columnField, "name")
            );

            setDataid(response.id);
        }

    };

    useEffect(() => {
        if (!types) {
            setpTypes();
        }
    }, [types, dataid]);


    const Fields = {
        'Edit': [
            { fieldName: 'name', label: t('trackers.customtype.Name'), type: 'text' },
            { fieldName: 'status', label: "Active", type: 'switch' },
        ],
        'Create': [
            { fieldName: 'name', label: t('trackers.customtype.Name'), type: 'text' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[]) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setAction(title);
        
        if(title === "Delete") {
            setDialogTitle(t("cleaning.report.summary.delete.title"));
            setDialogDescription(t("trackers.trackerdirect.DeleteDescription"));
        } else if(title === "Edit") {
            setDialogTitle(t("cleaning.report.summary.edit.title"));
            setDialogDescription("");
        } else {
            setDialogTitle(t("common.create"));
            setDialogDescription("");
        }
    };

    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (action == 'Edit') {
            json["id"] = dataid;
            json["id_data"] = formData.id;
            json["data"] = {
                "name": formData.name,
                "status": (formData.status === 'inactive' || !formData.status) ? "inactive" : "active"
            };

            await TrackerService.updateTrackerVitalsType(json);

            if(setpTypesVital) setpTypesVital();

            setShowEditDialog(false);
        } else {
            json["data"] = {
                "name": formData.name,
                "status": 'active'
            };
            json["location_id"] = locationSelectedId;

            await TrackerService.saveTrackerVitalsType(json);

            if(setpTypesVital) setpTypesVital();

            setShowEditDialog(false);
        }
        setpTypes();
    };

    useEffect(() => {
        setpTypes();
    }, [locationSelectedId]);

    const columnsreport = [
        { label: t('trackers.customtype.Name'), value: 'name', type: 'date' },
        { label: t('common.status'), value: 'status', type: 'date' },
    ];

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
        setFormData({});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChangechecked = (checked, column) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [column.fieldName]: checked
        }));
    };

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <Input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                    />
                );
            case 'switch':
                return (
                    <div className='flex justify-between space-x-2 p-2 border-solid border-gray-200 mb-2' style={{ borderWidth: "1px", borderRadius: "0.3rem" }}>
                        <label
                            htmlFor="terms"
                            className="flex items-center text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            {t("common.active")}
                        </label>

                        <Switch 
                            id="terms" 
                            checked={formData[column.fieldName] === 'inactive' ? false : !!formData[column.fieldName]} 
                            onCheckedChange={(checked) => handleChangechecked(checked, column)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {
                locationSelectedId && (
                    <Card className="border-t-4 border-t-primary/50">
                        <CardHeader>
                            <CardTitle className="flex justify-between text-lg font-bold" style={{ color: accentColor }}>
                                <span>{t('trackers.trackervitals.customTypesTitle')}</span>
                                
                                <Button onClick={() => handleButtonOpenDialog(Fields['Create'], 'New', [])}>
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    <span>{t('trackers.customtype.New')}</span>
                                </Button>
                            </CardTitle>
                        </CardHeader>

                        <CardContent>
                            <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden" style={{ height: "30rem", overflowY: "auto" }}>
                                <Table className="shadow-lg overflow-hidden">
                                    <TableHeader>
                                        <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                            {
                                                columnsreport.map(
                                                    (column) => (
                                                        <TableHead className="text-white" key={column.value}>
                                                            {column.label}
                                                        </TableHead>
                                                    )
                                                )
                                            }
                                            <TableHead className="text-white" style={{ width: "8rem" }}>{t('trackers.Actions')}</TableHead>
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody className="bg-white dark:bg-background">
                                        {
                                            types && types.map(
                                                    (row, index) => (
                                                        <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                            {
                                                                columnsreport.map(
                                                                    (column, columnIndex) => (
                                                                        <TableCell key={columnIndex} className="py-2 px-4">
                                                                            { capitalizeWords(String(row[column.value as keyof typeof row])) }
                                                                        </TableCell>
                                                                    )
                                                                )
                                                            }

                                                            <TableCell className="py-2 px-4">
                                                                <Button onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', row)} variant="outline" size="icon" className='mr-2'>
                                                                    <Pencil className="h-4 w-4" />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                        }
                                    </TableBody>

                                    <CustomDialog
                                        width="30rem"
                                        newDialog={true}
                                        isOpen={showEditDialog}
                                        onClose={handleCloseEditDialog}
                                        title={dialogTitle}
                                        onSubmit={handleSubmit}
                                        description={dialogDescription}
                                    >
                                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            <div className="grid gap-4">
                                                {
                                                    fields && fields.map(
                                                        (field, index) => (
                                                            <div key={field.fieldName} className="flex flex-col align-start">
                                                                {
                                                                    field.label != "Active" ? (
                                                                        <Label htmlFor={field.fieldName} className="mb-1">
                                                                            { field.label }
                                                                        </Label>
                                                                    ): null
                                                                }

                                                                { renderInputForColumn(field, index) }
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>

                                            <div className="flex flex-row justify-end mt-4">
                                                <Button type="submit" className="bg-primary">
                                                    { dialogTitle }
                                                </Button>
                                            </div>
                                        </form>
                                    </CustomDialog>
                                </Table>
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        </>
    );
}

export default TrackerVitalsType