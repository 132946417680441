import { Users } from 'lucide-react';
import { AlertCard } from '../alert-card';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from '../skeleton';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useTranslation } from 'react-i18next';
import { AlertsResponseI, CustomAlertI, HiddenAlertsI } from '@/modules/dashboard/domain/dashboard.domain';
import moment from 'moment';
import AlertItemCard from '@/common/presentation/components/AlertItemCrad.tsx/AlertItemCard';
import ButtonMarkAsDone from '../ButonMarkAsDone/ButtonMarkAsDone';

type IAlert = (CustomAlertI | AlertsResponseI) & { type: 'default' | 'custom' };

export const ResidentAlerts = () => {
  const residentAlerts = useSelector((state: RootState) => state.dashboard.residentAlerts);
  const residentCustomAlerts = useSelector((state: RootState) => state.dashboard.residentCustomAlerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const [hiddenAlerts, setHiddenAlerts] = useState<HiddenAlertsI>({});
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  const alerts: IAlert[] = useMemo(() => {
    if (residentAlerts.status === 'loading' || residentCustomAlerts.status === 'loading') {
      return [];
    }

    const currentDate = moment().format('YYYY-MM-DD');
    const defultAlerts: IAlert[] = residentAlerts.data.map((alert) => ({ ...alert, type: 'default' }));
    const customAlerts: IAlert[] = residentCustomAlerts.data.map((alert) => ({
      ...alert,
      type: 'custom',
      date: currentDate,
      residents: alert.residents.filter((resident) => !hiddenAlerts[alert.id]?.includes(resident.id))
    }));

    const combinedAlerts = [...defultAlerts, ...customAlerts];

    return combinedAlerts.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
  }, [residentAlerts, residentCustomAlerts, hiddenAlerts]);

  const totalAlertsCount = useMemo(() => {
    return alerts.reduce((total, alert) => {
      if (alert.type === 'default') {
        return total + 1;
      } else {
        return total + (alert as CustomAlertI).residents.length;
      }
    }, 0);
  }, [alerts]);

  return (
    <>
      <AlertCard
        title={t('dashboard.residentAlerts')}
        alerts={Array.from({ length: totalAlertsCount })}
        icon={Users}
        custom={true}
      >
        {residentAlerts.status === 'loading' || residentCustomAlerts.status === 'loading' ? (
          <AlertsSkeleton />
        ) : alerts.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {alerts.map((alert: IAlert, index) => {
              if (alert.type === 'default') {
                const defaultAlert = alert as AlertsResponseI;
                return (
                  <div key={`div_${index}`} className="flex flex-col gap-3">
                    <AlertItemCard
                      key={index}
                      names={UserHelper.getResidentName(defaultAlert.resident_id, residents) ?? ''}
                      title={t(`dashboard.${defaultAlert.alert_key}`, {
                        value: defaultAlert.value,
                        evaluator: defaultAlert.evaluator
                      })}
                      date={formatDate(defaultAlert.date, false)}
                      image={UserHelper.getResidentInformation(defaultAlert.resident_id, residents)?.image_url ?? ''}
                    />
                  </div>
                );
              } else {
                const customAlert = alert as CustomAlertI & { type: 'default' | 'custom' };
                return (
                  <div key={customAlert.id} className="flex flex-col gap-2">
                    {customAlert.residents.map((resident) => (
                      <div key={`r_${customAlert.id}_${resident.id}`} className="flex flex-col gap-3">
                        <AlertItemCard
                          key={index}
                          names={UserHelper.getResidentName(resident.id, residents) ?? ''}
                          title={customAlert.title}
                          date={formatDate(customAlert?.date ?? '', false) ?? ''}
                          image={UserHelper.getResidentInformation(resident.id, residents)?.image_url ?? ''}
                          Button={
                            <ButtonMarkAsDone
                              alertId={customAlert.id}
                              relatedId={resident.id}
                              hiddenAlerts={hiddenAlerts}
                              setHiddenAlerts={setHiddenAlerts}
                            />
                          }
                        />
                      </div>
                    ))}
                  </div>
                );
              }
            })}
          </div>
        )}
      </AlertCard>
    </>
  );
};
