import  ContactsPage  from '@/modules/reportbyresident/presentation/pages/ReportByResidentPage';
import { Route, Routes } from 'react-router-dom';

const ReportByResidentRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContactsPage />}></Route>
    </Routes>
  );
};

export default ReportByResidentRouter;