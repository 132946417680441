import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DocumentFiles from '../infrastructure/service/DocumentFiles';
import { SaveFolderPayload, DocumentsFolderI } from '../infrastructure/interfaces/DocumentsFolder';

export const getFiles = createAsyncThunk("staff/documents/files",
    async (params: { search: string; search_category: string; search_status: string; per_page: number; page: number }) => {
        const response = await DocumentFiles.getDocumentFiles(
            params.search,
            params.search_category,
            params.search_status,
            params.per_page,
            params.page);
        return response;
    });

export const saveFolder = createAsyncThunk(
    "staff/documents/save-folder",
    async (body: SaveFolderPayload, { rejectWithValue }) => {
        try {
            const response = await DocumentFiles.saveFolders(body);

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateFolder = createAsyncThunk("staff/documents/update-folder",
    async (body: SaveFolderPayload, { rejectWithValue }) => {
        try {
            const response = await DocumentFiles.updateFolder(body);
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteFolder = createAsyncThunk('staff/documents/delete-category',
    async (id: string) => {
        const response = await DocumentFiles.deleteFolder(id);
        return response;
    }
);

interface DocumentsCategoryState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    documentFiles: DocumentsFolderI[];
    saveFolders?: DocumentsFolderI
}

const initialState: DocumentsCategoryState = {
    status: 'idle',
    error: null,
    documentFiles: []
};

const documentFiles = createSlice({
    name: 'documentFilesStaff',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getFiles.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getFiles.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentFiles = action.payload;
        });

        builder.addCase(getFiles.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(saveFolder.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveFolders = action.payload;
        });

        builder.addCase(saveFolder.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(updateFolder.fulfilled, (state, action) => {
            state.status = 'idle';
            state.documentFiles = action.payload;
        });

        builder.addCase(updateFolder.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(deleteFolder.fulfilled, (state) => {
            state.status = 'idle';
        });

    }
});

export default documentFiles.reducer;


