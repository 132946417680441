import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import { AppDispatch, RootState } from '@/store/store';
import {
  createResidentMedication,
  fetchForms,
  fetchMedications,
  fetchTypes,
  getResidentMedicationTime
} from '../../slices/medicationsSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { cn } from '@/lib/utils';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { CalendarIcon } from 'lucide-react';
import SelectWithVirtuoso from './SelectVirtuoso';
import { Label } from '@/common/presentation/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Button } from '@/common/presentation/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import TimeSelector from '../TimeSelector';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../pages/ConfirmationModal';
import TypeOptions from '../TypeOptions';
import { Input } from '@/components/ui/input';
import { useCustomToast } from '@/common/presentation/components/CustomToast/CustomToast';

interface ChartData {
  index?: number;
  status?: boolean;
  rx?: string;
  filleDate?: string;
  qtyReceived?: number;
  cycle_start_date?: string;
  cycle_end_date?: string;
  expiration_date?: string;
  received?: number;
  refills?: number;
}

interface FormData {
  resident_id: string;
  location_id: string;
  selectedMedication: string;
  medicationName: string;
  form: string;
  strength: string;
  type: string;
  typeName: string;
  time: string[];
  special_days: string;
  instruction: string;
  special_instructions: string;
  chart: ChartData[];
}

interface CreateMedicationProps {
  onClose: () => void;
}

const CreateMedication: React.FC<CreateMedicationProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const [isSpecialDay, setIsSpecialDay] = useState(false);
  const [isCustomMedication, setIsCustomMedication] = useState(false);
  const [selectedForm, setSelectedForm] = useState([]);
  const [isPRN, setIsPRN] = useState(false);
  const [redirectOnSave, setRedirectOnSave] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useCustomToast();

  const navigate = useNavigate();
  const { types, residentMedicationTime, medications, forms } = useSelector((state: RootState) => state.medications);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset
  } = useForm<FormData>({
    defaultValues: {
      resident_id: params.r || 'all-residents',
      location_id: params.l || 'all-locations',
      selectedMedication: '',
      medicationName: '',
      form: '',
      strength: '',
      type: '',
      typeName: '',
      time: [],
      special_days: '',
      instruction: '',
      special_instructions: '',
      chart: [
        {
          status: true,
          rx: '',
          filleDate: '',
          qtyReceived: 0,
          cycle_start_date: '',
          cycle_end_date: '',
          received: 0,
          refills: 0,
          expiration_date: '',
          index: 0
        }
      ]
    }
  });

  const cycleStartDate = watch('cycle_start_date');
  const cycleEndDate = watch('cycle_end_date');
  const selectedTimes = watch('time');
  const selectedType = watch('type');
  const medicationName = watch('medicationName');
  const form = watch('form');
  const [specialDays, setSpecialDays] = useState({
    type: '',
    special_day: [],
    text: ''
  });

  useEffect(() => {
    const residentId = watch('resident_id') || 'all-residents';
    const locationId = params.l || 'all-locations';
    dispatch(fetchTypes(locationId));
    dispatch(fetchForms());
    dispatch(fetchMedications({ locationId: locationId === 'all-locations' ? undefined : locationId }));
    dispatch(getResidentMedicationTime(residentId));
  }, [watch('resident_id'), dispatch, params.l]);

  useEffect(() => {
    if (selectedForm.length === 1 && !form) {
      setValue('form', selectedForm[0]);
    }
  }, [selectedForm, form, setValue]);

  const handleSelectedTypeChange = useCallback(
    (value: string) => {
      const selectedType = types.find((type) => type.id === value);

      if (selectedType) {
        setValue('type', selectedType.id);
        setValue('typeName', selectedType.name);
        setValue('time', selectedType.name === 'PRN' ? ['As Needed'] : []);
        setIsPRN(selectedType.name === 'PRN');
      }
    },
    [types, setValue]
  );

  const handleSelectedTimesChange = useCallback(
    (selectedTimes: string[]) => {
      setValue('time', selectedTimes);
    },
    [setValue]
  );

  const handleSpecialDaySwitch = useCallback((checked: boolean) => {
    setIsSpecialDay(checked);
  }, []);

  const handleMedicationSelect = useCallback(
    (medication) => {
      if (medication) {
        setValue('selectedMedication', medication.id);
        setValue('medicationName', medication.name);

        const formsArray = medication.form.split(',').map((item) => item.trim()) || [];
        setSelectedForm(formsArray);

        if (formsArray.length === 1) {
          setValue('form', formsArray[0]);
        } else {
          setValue('form', '');
        }

        setIsCustomMedication(false);
      } else {
        setValue('selectedMedication', '');
        setValue('form', '');
        setSelectedForm([]);
        setIsCustomMedication(false);
        reset();
      }
    },
    [setValue]
  );

  const handleCreateNewMedication = useCallback(
    (newMedicationName: string) => {
      if (newMedicationName) {
        setValue('medicationName', newMedicationName);
        setIsCustomMedication(true);
      } else {
        setValue('selectedMedication', '');
        setValue('form', '');
      }
    },
    [setValue]
  );

  const onSubmitForm = async (data: FormData) => {
    setIsLoading(true);
    if (!params.r || params.r === 'all-residents') {
      setShowConfirmation(true);
      return;
    }
    try {
      const response = await dispatch(createResidentMedication(data));
      if (!response.error) {
        showToast('success', t('medications.toast.success'), t('medications.toast.success'));

        if (redirectOnSave && response?.payload?.id) {
          navigate(`/medications/edit/${response.payload.id}`);
        }
        onClose();
      } else {
        showToast('success', t('response.error.message'), t('response.error.message'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = async () => {
    onClose();
    setShowConfirmation(false);
    const data = watch();
    const response = await dispatch(createResidentMedication(data));
    if (!response.error) {
      showToast('success', t('medications.toast.success'), t('medications.toast.success'));
      if (redirectOnSave && response?.payload?.id) {
        navigate(`/medications/edit/${response.payload.id}`);
      }
    } else {
      toast({ description: response.error.message, variant: 'destructive' });
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-2 mb-2">
            <div className="md:col-span-12 mb-4">
              <div className="space-y-1">
                <Label htmlFor="selectedMedication" className="text-sm block mb-1">
                  {t('medications.medicationForm.medications')} <span className="text-primary">*</span>
                </Label>
                <Controller
                  name="medicationName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectWithVirtuoso
                      value={field.value}
                      onSelect={handleMedicationSelect}
                      onCreateNew={handleCreateNewMedication}
                      editValue={field.value}
                    />
                  )}
                />
                {errors.selectedMedication && (
                  <p className="text-red-500 text-sm">{t('medications.medicationForm.requiredField')}</p>
                )}
              </div>
            </div>
          </div>
          <div
            className={`grid grid-cols-1 ${
              isCustomMedication && isPRN ? 'md:grid-cols-4' : isPRN ? 'md:grid-cols-3' : 'md:grid-cols-12'
            } gap-2 mb-2`}
          >
            <div
              className={`mb-2 space-y-1 ${
                isCustomMedication && isPRN
                  ? 'md:col-span-1'
                  : isCustomMedication
                  ? 'md:col-span-6'
                  : isPRN
                  ? 'md:col-span-1'
                  : 'md:col-span-4'
              }`}
            >
              <Label htmlFor="form" className="text-sm block mb-1">
                {isCustomMedication
                  ? `${t('medications.medicationForm.form')}`
                  : `${t('medications.medicationForm.strength')} - ${t('medications.medicationForm.form')}`}
                <span className="text-primary">*</span>
              </Label>
              <Controller
                name="form"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}
                    disabled={selectedForm.length === 0 && !isCustomMedication}
                  >
                    <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                      <SelectValue placeholder={t('medications.medicationForm.selectForm')} />
                    </SelectTrigger>
                    <SelectContent>
                      {(isCustomMedication ? forms : selectedForm).map((form, index) => (
                        <SelectItem key={index} value={isCustomMedication ? form.name : form}>
                          {isCustomMedication ? form.name : form}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />

              {errors.form && <p className="text-red-500 text-sm">{t('medications.medicationForm.requiredField')}</p>}
            </div>

            {isCustomMedication && (
              <div className={`mb-2 space-y-1 ${isPRN ? 'md:col-span-1' : 'md:col-span-6'}`}>
                <Label htmlFor="strength" className="text-sm block mb-1">
                  {t('medications.medicationForm.strength')}
                </Label>
                <Controller
                  name="strength"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                      placeholder={t('medications.medicationForm.placeholderStrength')}
                      {...field}
                    />
                  )}
                />

                {errors.strength && (
                  <p className="text-red-500 text-sm">{t('medications.medicationForm.requiredField')}</p>
                )}
              </div>
            )}

            {!isPRN && (
              <>
                <div className="mb-2 space-y-1 md:col-span-4">
                  <Label htmlFor="cycle_start_date" className="text-sm block mb-1">
                    {t('medications.medicationForm.cycleStartDate')}
                  </Label>
                  <Controller
                    name="cycle_start_date"
                    control={control}
                    render={({ field }) => (
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'w-full justify-start text-left text-sm py-1',
                              !field.value && 'text-muted-foreground'
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                              {field.value
                                ? format(parse(field.value, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                                : t('medications.medicationForm.selectDate')}
                            </span>
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <CustomCalendar
                            selectedDate={field.value ? parse(field.value, 'yyyy-MM-dd', new Date()) : null}
                            onChange={(date) => {
                              console.log(cycleEndDate);
                              const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
                              setValue('cycle_start_date', formattedDate);
                              setValue('chart', [
                                {
                                  ...watch('chart')[0],
                                  cycle_start_date: formattedDate
                                }
                              ]);
                            }}
                            MonthAndYearPicker
                          />
                        </PopoverContent>
                      </Popover>
                    )}
                  />
                </div>
                <div className="mb-2 space-y-1 md:col-span-4">
                  <Label htmlFor="cycle_end_date" className="text-sm block mb-1">
                    {t('medications.medicationForm.cycleEndDate')}
                  </Label>
                  <Controller
                    name="cycle_end_date"
                    control={control}
                    render={({ field }) => (
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'w-full justify-start text-left text-sm py-1',
                              !field.value && 'text-muted-foreground'
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                              {field.value
                                ? format(parse(field.value, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                                : t('medications.medicationForm.selectDate')}
                            </span>
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <CustomCalendar
                            selectedDate={field.value ? parse(field.value, 'yyyy-MM-dd', new Date()) : null}
                            onChange={(date) => {
                              console.log(cycleStartDate);
                              const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
                              setValue('cycle_end_date', formattedDate);
                              setValue('chart', [
                                {
                                  ...watch('chart')[0],
                                  cycle_end_date: formattedDate
                                }
                              ]);
                            }}
                            MonthAndYearPicker
                          />
                        </PopoverContent>
                      </Popover>
                    )}
                  />
                </div>
              </>
            )}

            <div className={`mb-2 space-y-1 ${isPRN ? 'md:col-span-1' : 'md:col-span-4'}`}>
              <Label htmlFor="type" className="text-sm block mb-1">
                {t('medications.medicationForm.type')} <span className="text-primary">*</span>
              </Label>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select onValueChange={handleSelectedTypeChange} value={field.value}>
                    <SelectTrigger className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                      <SelectValue placeholder={t('medications.medicationForm.selectType')} />
                    </SelectTrigger>
                    <SelectContent>
                      {types.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
              {errors.type && <p className="text-red-500 text-sm">{t('medications.medicationForm.requiredField')}</p>}
            </div>

            <div
              className={`mb-2 space-y-1 ${
                isCustomMedication && !isPRN ? 'md:col-span-8' : isPRN ? 'md:col-span-1' : 'md:col-span-6'
              }`}
            >
              <Label htmlFor="time" className="text-sm block mb-1">
                {t('medications.medicationForm.times')} <span className="text-primary">*</span>
              </Label>
              <Controller
                name="time"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimeSelector
                    onChange={handleSelectedTimesChange}
                    medicationTimes={residentMedicationTime}
                    selectedTimes={field.value}
                    value={field.onChange}
                    typeName={watch('typeName')}
                    className="text-sm"
                  />
                )}
              />
              {errors.time && <p className="text-red-500 text-sm">{errors.time.message}</p>}
            </div>

            {!isPRN && (
              <div className="mb-2 space-y-1 md:col-span-2">
                <Label htmlFor="custom-time-switch" className="text-sm block">
                  {t('medications.medicationForm.specialDay')}
                </Label>
                <div className="flex items-center h-8">
                  <Switch id="custom-time-switch" checked={isSpecialDay} onCheckedChange={handleSpecialDaySwitch} />
                </div>
              </div>
            )}
          </div>

          {isSpecialDay && !isPRN && (
            <Controller
              name="special_days"
              control={control}
              render={({ field }) => (
                <TypeOptions
                  isNewMedication={true}
                  selectStartDate={cycleStartDate}
                  selectEndDate={cycleEndDate}
                  type={selectedType}
                  onUpdateFormData={(updatedData) => {
                    field.onChange(updatedData);
                    setSpecialDays(updatedData);
                  }}
                  special_days={specialDays}
                />
              )}
            />
          )}
          <div className="space-y-2">
            <div className="mb-2 space-y-1">
              <Label htmlFor="instruction" className="text-sm block mb-1">
                {t('medications.medicationForm.instructions')}
              </Label>
              <Controller
                name="instruction"
                control={control}
                render={({ field }) => (
                  <Textarea
                    rows={2}
                    className="resize-none text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0 placeholder:text-gray-300"
                    style={{ height: 'auto', minHeight: 'unset', maxHeight: 'unset' }}
                    placeholder={t('medications.medicationForm.enterInstruction')}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mb-2 space-y-1">
              <Label htmlFor="special" className="text-sm block mb-1">
                {t('medications.medicationForm.specialInstructions')}
              </Label>
              <Controller
                name="special_instructions"
                control={control}
                render={({ field }) => (
                  <Textarea
                    rows={2}
                    className="resize-none text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0 placeholder:text-gray-300"
                    style={{ height: 'auto', minHeight: 'unset', maxHeight: 'unset' }}
                    placeholder={t('medications.medicationForm.enterSpecial')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-2">
          <Button type="submit" onClick={() => setRedirectOnSave(false)} disabled={isLoading}>
            {isLoading
              ? `${t('medications.medicationForm.saveMedication')}...`
              : t('medications.medicationForm.saveMedication')}
          </Button>

          {params.r && params.r !== 'all-residents' && (
            <Button type="submit" onClick={() => setRedirectOnSave(true)} disabled={isLoading}>
              {isLoading
                ? `${t('medications.medicationForm.addInformation')}...`
                : t('medications.medicationForm.addInformation')}
            </Button>
          )}
        </div>
      </form>
      {showConfirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          onConfirm={handleConfirm}
          onClose={handleCancel}
          message={t('medications.confirmationMessage')}
        />
      )}
    </>
  );
};

export default CreateMedication;
