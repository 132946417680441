import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReportByResidentService from "../../infrastructure/services/ReportByResidentService";

interface IState { 
    registersvitals: Array<any>;
    registers: Array<any>;
    isLoading: boolean;
    categories: Array<{ label: string; value: string | number }>; // Formato esperado para el MultiSelect
    categoriesvitals: Array<{ label: string; value: string | number }>;
}
  
const initialState: IState = {
    registersvitals: [],
    registers: [],
    isLoading: false,
    categories: [],
    categoriesvitals: [],
};

export const getCombinedReport = createAsyncThunk(
  'reportbyresident/getCombinedReport',
  async ({
      residentId,
      locationId,
      startDate,
      endDate,
      trackers,
      categoriesNotes,
      categoriesVitals,
  }: {
      residentId: string;
      locationId: string;
      startDate: string;
      endDate: string;
      trackers: string[];
      categoriesNotes?: string[]; //filtro opcional
      categoriesVitals?: string[]; //filtro opcional
  }) => {
      try {
          const response = await ReportByResidentService.getCombinedReport({
              //Filtros pasados al service
              residentId,
              locationId,
              startDate,
              endDate,
              trackers,
              categoriesNotes,
              categoriesVitals, 
          });
          return response.payload;
      } catch (error) {
          throw error;
      }
  }
);

// Acción para exportar el reporte de PDF
export const exportVitalsReport = createAsyncThunk(
  'reportbyresident/exportVitalsReport',
  async ({ residentId, locationId, startDate, endDate, trackers, categoriesNotes, categoriesVitals }: { residentId: string; locationId: string; startDate: string; endDate: string; trackers: string[], categoriesNotes: string[], categoriesVitals: string[] }) => {
    try {
      const response = await ReportByResidentService.exportReport({ residentId, locationId, startDate, endDate, trackers, categoriesNotes, categoriesVitals });

      // Crear un blob desde la respuesta
      const blob = new Blob([response], { type: 'application/pdf' });

      // Crear URL a partir del blob
      const url = window.URL.createObjectURL(blob);

      // Abrir el PDF en una nueva pestaña
      const newTab = window.open();
      if (newTab) {
        newTab.location.href = url; // Establece la ubicación del PDF
      } else {
        console.error('El navegador bloqueó la ventana emergente.');
      }

      return response;
    } catch (error) {
      console.error('Error al exportar el PDF:', error);
      throw error;
    }
  }
);

// AsyncThunk para obtener las categorías de notas
export const fetchNotesCategories = createAsyncThunk(
  'reportbyresident/fetchNotesCategories',
  async () => {
      try {
          const response = await ReportByResidentService.getNotesCategories();
          return response; // Se asume que el servicio devuelve un array con objetos { label, value }
      } catch (error) {
          throw error;
      }
  }
);

export const fetchVitalsCategories = createAsyncThunk(
  'reportbyresident/fetchVitalsCategories',
  async () => {
      try {
          const response = await ReportByResidentService.getVitalsCategories();
          return response;
      } catch (error) {
          throw error;
      }
  }
);

export const ReportByResidentSlice = createSlice({
    name: 'reportbyresident',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getCombinedReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCombinedReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registers = action.payload; // Combina los trackers
      })
      .addCase(getCombinedReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(exportVitalsReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportVitalsReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(exportVitalsReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchNotesCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotesCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload; // Asigna las categorías obtenidas
      })
      .addCase(fetchNotesCategories.rejected, (state) => {
          state.isLoading = false;
      })
      .addCase(fetchVitalsCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVitalsCategories.fulfilled, (state, action) => {
          state.isLoading = false;
          state.categoriesvitals = action.payload;
      })
      .addCase(fetchVitalsCategories.rejected, (state) => {
          state.isLoading = false;
      });
    }
});

export default ReportByResidentSlice.reducer;
