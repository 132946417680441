import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SheetFields from "../infrastructure/services/SheetFields";

interface IPagination {
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  hasMorePages: boolean
}

 export interface IDataSheetField {
  emergencySheet: { [key: string]: any[] };
  faceSheet: { [key: string]: any[] };
}
export interface ISheetField {
  result: string;
  data: IDataSheetField;
}

export interface ISheetFieldState {
  sheetFields: ISheetField;
  summaryData: {
    items: [],
    pagination: IPagination
  },
  categories: [],
  isLoading: boolean;
  error: string | null;
  message: string;
}

const initialState: ISheetFieldState = {
  sheetFields: {
    result: '',
    data: {
      emergencySheet: {
        "Religious Preference": []
      },
      faceSheet: {
        "Facility": []
      }
    },
  },
  summaryData: {
    items: [],
    pagination: {
      total: 0,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      hasMorePages: false
    }
  },
  categories: [],
  message: '',
  isLoading: false,
  error: null
};


export const getFields = createAsyncThunk('sheets/getFields',
  async () => {
    const response = await SheetFields.getFields();
    return response;
  }
);

export const updateEnabledFields = createAsyncThunk('sheets/updateFields',
  async (formData: any) => {
    const response = await SheetFields.updateEnabledFields(formData);
    return response;
  }
);
export const saveOrUpdateResidentFields = createAsyncThunk('sheets/saveOrUpdateResidentFields',
  async (formData: any) => {
    const response = await SheetFields.saveOrUpdateResidentFields(formData);
    return response;
  }
);

export const getSummary = createAsyncThunk('sheets/getSummary',
  async (formData: any) => {
    const response = await SheetFields.getSummary(formData);
    return response;
  }
);

export const getCategories = createAsyncThunk('sheets/getCategories',
  async () => {
     const response = await SheetFields.getCategories();
     return response;
   }
 );
export const deleteResidentField = createAsyncThunk('sheets/deleteResidentField',
  async (id: string) => {
     const response = await SheetFields.deleteResidentField(id);
     return response;
   }
 );

const SheetFieldsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    sheetFieldsLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
      addCase(getFields.fulfilled, (state, action) => {
        state.sheetFields = action.payload;
        state.isLoading = false;
      })
    .addCase(getSummary.fulfilled, (state, action) => {
      state.summaryData = action.payload;
      state.isLoading = false;
    })
    .addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.isLoading = false;
    })
    .addCase(deleteResidentField.fulfilled, (state) => {
      state.isLoading = false;
    });
  }
});

export const { sheetFieldsLoading } = SheetFieldsSlice.actions;
export default SheetFieldsSlice.reducer;