import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';

interface InfoCardProps {
  additionalClass?: string;
  headerTitle?: string;
  headerIconLeft?:  React.ComponentType<React.SVGProps<SVGSVGElement>>;
  headerIconRight?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  numberOfAlerts?: number;
  handleEdit?: () => void;
  children?: React.ReactNode;
}

export const InfoCard = ({
  additionalClass,
  headerTitle,
  headerIconLeft: HeaderIconLeft,
  headerIconRight: HeaderIconRight,
  numberOfAlerts = 0,
  handleEdit,
  children
 }: InfoCardProps) => {
  return (
    <div className={additionalClass}>
      <Card className={`flex flex-col px-4 py-3.5 w-full border-t-4 border-t-primary/80
        dark:bg-zinc-950 col-span-4 h-full`}>
        <CardHeader className='flex flex-row p-0 items-center gap-2 justify-start'>
          <div className='flex flex-row items-center gap-2'>
            {HeaderIconLeft && <HeaderIconLeft className="w-6 h-6 flex-shrink-0 text-primary" />}
            <CardTitle className='text-primary text-base font-semibold leading-5'>
              {headerTitle}
            </CardTitle>
            {
              numberOfAlerts > 0 && (
                <span className="w-8 h-6 flex-shrink-0 rounded-md border border-red-500
                bg-red-100 text-red-500 flex items-center justify-center font-bold">
                  {numberOfAlerts}
                </span>
              )
            }
          </div>
          {
            HeaderIconRight && (
            <HeaderIconRight className="ml-auto h-4 w-4 hover:cursor-pointer"
              onClick={handleEdit}
              />)
          }
        </CardHeader>
        <Separator className="w-full bg-primary my-3.5"/>
        <CardContent className='p-0 flex-1'>
          {children}
        </CardContent>
      </Card>
    </div>
  );
};
