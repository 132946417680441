import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { SaveCategoryPayload } from '../../../../Staff/DocumentCategories/infrastructure/interfaces/DocumentsInterface';

const LocationDocumentsCategoryService = {

    //PaginatedCategories
    getCategory: async (search: string, per_page: number, page: number) => {
        try {
            const response = await axios.get('locations/documents/categories', {
                params: { search, per_page, page },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching categories: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching categories: ' + error.message);
        }
    },

     //Unpaginated categories
     getCategories: async () => {
        try {
            const response = await axios.get('locations/documents/get-categories');
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching categories: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching categories: ' + error.message);
        }
    },

    saveCategories: async (formData: SaveCategoryPayload) => {
        try {
            const response = await axios.post(`locations/documents/save-category`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating categories: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error creating categories: ' + error.message);
        }
    },

    updateCategories: async (formData: SaveCategoryPayload) => {
        try {
            const response = await axios.post(`locations/documents/update-category/${formData.id}`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error updating categories: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error updating categories: ' + error.message);
        }
    },

    deleteCategory: async (id: string) => {
        try {
            const response = await axios.delete(`locations/documents/delete-category/${id}`);
            return response;
        } catch (error: any) {
            throw new Error('Error deleting categories: ' + error.message);
        }

    },
}

export default LocationDocumentsCategoryService