import { useEffect, useState, useCallback } from 'react';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { getTrackerBedroomQr } from '../slices/TrackerBedroomQrSlice';
import { Card } from '@/common/presentation/components/ui/card';
import { TrackerBedroomQrSummary } from '../components/BedroomQr/TrackerBedroomQrSummary';
import { getBedroomQrCategories } from '@/modules/residents/presentation/slices/residentBedroomQrSlice';
import * as staffSliceActions from '@/modules/staff/presentation/slices/staffSlice';

interface BedroomCategory {
    id: string;
    name: string;
    availability: string;
}

interface TrackerData {
    id: string;
    date: string;
    detail: string;
    bedroom_id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

interface ITrackerBedroomQR {
    id: string;
    tracker: string;
    location_id: string;
    resident_id: string;
    bedroom_category: BedroomCategory[];
    created_at: string;
    updated_at: string;
    data: TrackerData;
}

interface Bedroom {
    id: string;
    name: string;
}



export function TrackerBedroomQr() {

    const { t } = useTranslation();
    const { params, setParam } = useRouteParams();
    const { locationSelectedId } = useLocation();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const dispatch = useDispatch<AppDispatch>();
    const [dataBedroomQrReport, setDataBedroomQrReport] = useState<ITrackerBedroomQR[]>([])
    const [dataOriginal, setDataOriginal] = useState<ITrackerBedroomQR[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bedroomId, setBedroomId] = useState<string>('');
    const [dataBedroom, setDataBedroomCategories] = useState<Bedroom[]>([])
    const [selectedStaff, setSelectedStaff] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');

    const onResidentSelect = (resident: string) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (locationSelectedId) {
            dispatch(staffSliceActions.getStaffByLocation(locationSelectedId === 'all-locations' ? undefined : locationSelectedId));
        } else {
            setParam('l', 'all-locations');
        }
    }, [locationSelectedId]);

    useEffect(() => {
        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (!params.r) {
            setParam('r', 'all-residents');
        }
    }, [params.r, setParam]);

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load

    const dataTrackerBedroomQrReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true);

        const response = await dispatch(getTrackerBedroomQr({
            search: searchTerm,
            location_id: locationSelectedId,
            resident_id: params.r,
            bedroom_id: bedroomId,
            user_id: selectedStaff,
            per_page: perPage,
            page
        }));


        const { trackerBedroomQR }: {
            trackerBedroomQR: { data: ITrackerBedroomQR[], current_page: number, last_page: number, total: number }
        } = response.payload;

        const data = trackerBedroomQR.data
        if (resetData) {
            setDataBedroomQrReport(data);
            setDataOriginal(data);
        } else {
            setDataBedroomQrReport(prevData => [...prevData, ...data]);
            setDataOriginal(prevData => [...prevData, ...data]);
        }

        if (trackerBedroomQR.current_page >= trackerBedroomQR.last_page ||
            trackerBedroomQR.total < data.length) {
            setHasMoreRows(false);
        } else {
            setHasMoreRows(true);
        }

        setIsLoading(false)
    }

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const handleClearFilter = () => {
        setSearchTerm('');
    };

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataTrackerBedroomQrReport(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataTrackerBedroomQrReport]);


    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {

        // Initializes the state with complete data only if dataOriginal is empty
        if (!isInitialized && dataBedroomQrReport.length > 0) {
            setDataOriginal(dataBedroomQrReport); // Saves a copy of all the data initially
            setIsInitialized(true); // Marks initialization as complete
        }

        // Applies filtering when the original data is already loaded
        if (dataBedroomQrReport.length > 0 || dataBedroomQrReport.length > 0) {
            let updatedData = dataBedroomQrReport.length > 0 ? dataOriginal : dataBedroomQrReport;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: ITrackerBedroomQR) => item.location_id === locationSelectedId);

                }
            } else {
                updatedData = updatedData.filter((item: ITrackerBedroomQR) => {
                    if (locationSelectedId === "all-locations") {
                        return item.data.resident.id === params.r;
                    }

                    return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                });
            }

            // Only updates if dataReport needs to be changed
            if (JSON.stringify(updatedData) !== JSON.stringify(dataBedroomQrReport)) {
                setDataBedroomQrReport(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginal, dataBedroomQrReport]);

    useEffect(() => {
        setDataBedroomQrReport([]);
        setDataOriginal([]);
        setCurrentPage(1);
        setHasMoreRows(true);
        dataTrackerBedroomQrReport(1, rowsPerPage, true);
    }, [locationSelectedId, params.r, bedroomId, selectedStaff, searchTerm])

    const dataBedroomCategories = async () => {
        const response = await dispatch(getBedroomQrCategories());
        if (response.payload) {

            try {
                setDataBedroomCategories(response.payload);
            } catch (error: any) {

                console.log(error);
            }
        }
    }

    useEffect(() => {
        dataBedroomCategories()
    }, [])


    const handleFieldChange = (id: string) => {
        setBedroomId(id);
    };

    const handleStaffChange = (id: string) => {
        setSelectedStaff(id);
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />

            <div>
                <ResidentSelector
                    onSelect={onResidentSelect}
                    locationId={location || undefined}
                    residentId={params.r}
                    showAllResidents={true}
                />
            </div>

            <div>

                <Card className="border-t-4 border-t-primary/80 p-5 mt-8">

                    <TrackerBedroomQrSummary
                        dataBedroomQrReport={dataBedroomQrReport}
                        isLoading={isLoading}
                        dataTrackerBedroomQrReport={dataTrackerBedroomQrReport}
                        rowsPerPage={rowsPerPage}
                        dataBedroom={dataBedroom}
                        bedroomId={bedroomId}
                        selectedStaff={selectedStaff}
                        handleFieldChange={handleFieldChange}
                        handleStaffChange={handleStaffChange}
                        loadMore={loadMore}
                        hasMore={hasMoreRows}
                        resident={params.r}
                        onFilterChange={handleSearchChange}
                        filterValue={searchTerm}
                        onClear={handleClearFilter}
                        setCurrentPage={setCurrentPage}
                    />

                </Card>
            </div>
        </div>
    )
}