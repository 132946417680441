import { create } from 'zustand';

type RequestRestrictByDeviceDialogState = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  setMessage: (messageParam: string) => void;
  message: string;
  justClose?: boolean;
  setJustClose: (justCloseParam: boolean) => void;
  onSubmit: () => void;
  setOnSubmit: (submitFunc: () => void) => void;
  submitLoading: boolean;
  setSubmitLoading: (submitLoadingParam: boolean) => void;
  requestSent: boolean;
  setRequestSent: (requestSentParam: boolean) => void;
  requestDeviceInfo: { deviceName: string; deviceDescription: string };
  setRequestDeviceInfo: (requestDeviceInfoParam: { deviceName: string; deviceDescription: string }) => void;
};

export const useRequestRestrictByDeviceDialog = create<RequestRestrictByDeviceDialogState>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  message: '',
  setMessage: (messageParam: string) => set({ message: messageParam }),
  justClose: false,
  setJustClose: (justCloseParam: boolean) => set({justClose: justCloseParam}),
  onSubmit: () => {},
  setOnSubmit: (submitFunc: () => void) => set({ onSubmit: submitFunc }),
  submitLoading: false,
  setSubmitLoading: (submitLoadingParam: boolean) => set({submitLoading: submitLoadingParam}),
  requestSent: false,
  setRequestSent: (requestSentParam: boolean) => set({requestSent: requestSentParam}),
  requestDeviceInfo: { deviceName: '', deviceDescription: '' },
  setRequestDeviceInfo: (requestDeviceInfoParam: { deviceName: string; deviceDescription: string }) => set({requestDeviceInfo: requestDeviceInfoParam})
}));
