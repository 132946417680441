import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BedroomQrCategory from "../../infrastructure/services/ResidentBedroomQr";

interface IBedroomQrForm {
    residentId: string;
    bedrooms: Array<{
        bedroomId: string;
        status: 'active' | 'inactive';
    }>;
}


export const getBedroomQrCategories = createAsyncThunk('residentBedroomQr/getAllBedroomQrCategory',
    async () => {
        const response = await BedroomQrCategory.getBedroomQrCategory();
        return response;
    }
);

export const getBedroomQrCategoriesByResident = createAsyncThunk('residentBedroomQr/getCategoriesByResident',
    async (residentId: string) => {
        const response = await BedroomQrCategory.getBedroomQrCategoryByResident(residentId);
        return response;
    }
);

export const saveBedroomQr = createAsyncThunk("residentBedroomQr/saveCategoriesByResident", async (body: IBedroomQrForm) => {
    const response = await BedroomQrCategory.saveBedroomQrCategoryByResident(body);
    return response;
});


interface BedroomQrState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    bedroomQrReport: any[];
    saveBedroomQr?: {
        message: string;
        data: any[];
    };
}

const initialState: BedroomQrState = {
    status: 'idle',
    error: null,
    bedroomQrReport: []
};


const residentBedroomQr = createSlice({
    name: 'residentBedroomQr',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getBedroomQrCategories.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getBedroomQrCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.bedroomQrReport = action.payload;
        });

        builder.addCase(getBedroomQrCategories.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(getBedroomQrCategoriesByResident.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getBedroomQrCategoriesByResident.fulfilled, (state, action) => {
            state.status = 'idle';
            state.bedroomQrReport = action.payload;
        });

        builder.addCase(getBedroomQrCategoriesByResident.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(saveBedroomQr.fulfilled, (state, action) => {
            state.status = 'idle';
            state.bedroomQrReport = action.payload;
        });

        builder.addCase(saveBedroomQr.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

    }
});

export default residentBedroomQr.reducer;