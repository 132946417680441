import { Label } from '@/components/ui/label';
import { useOutletContext } from 'react-router-dom';
import CoursesList from '../components/CoursesList';

interface OutletContext {
  module: string | null;
}

const TrainingPage = () => {
  const { module } = useOutletContext<OutletContext>();

  console.log(module);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-center">
        <div className="flex flex-col justify-center items-center pt-6 gap-3">
          <Label className="text-xl text-center dark:text-zinc-300">Welcome to your</Label>
          <Label className="text-2xl text-primary text-center">RCFE Orientation Training</Label>
          <p className="text-justify w-3/5 items-center text-sm dark:text-zinc-300">
            This training group contains 20 courses of training. At the end of the course, you will need to complete a
            test to ensure knowledge on the topic. A minimum score of 70% must be obtained to receive your certificate
            of completion. If you receive a score less than 70% you may repeat the test. The site administrator will
            automatically receive your certificate when each course is completed
          </p>
        </div>
      </div>
      <div className="px-7">
        <CoursesList />
      </div>
    </div>
  );
};

export default TrainingPage;
