import { create } from 'zustand';
import ResidentCarriersFinancialInformationService from '@/modules/residents/infrastructure/services/ResidentCarriersFinancialInformationService';

export interface CarriersResponse {
  message: string;
  data: Carrier[];
}

export interface Carrier {
  id: string;
  resident_id: string;
  carrier: string | null;
  carrier_number: string | null;
  type: string | null;
  status: string;
  created_by: string | null;
  updated_by: string | null;
  deleted_by: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

type CarriersAndFinancialInformationState = {
  carriers: Carrier[] | null;
  financial: Record<string, unknown> | null;

  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getCarriers: (resident_id: string) => Promise<any>;
  createCarrier: (data: any) => Promise<any>;
  updateCarrier: (data: any) => Promise<any>;
  deleteCarrier: (data: any) => Promise<any>;

  getFinancial: (resident_id: string) => Promise<any>;
  upsertFinancial: (data: any) => Promise<any>;
  deleteFinancial: (data: any) => Promise<any>;
};

export const useCarriersAndFinancialInformationStore = create<CarriersAndFinancialInformationState>((set) => ({
  carriers: [],
  financial: {},
  status: 'idle',
  error: null,

  getCarriers: async (resident_id: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.getCarriers(resident_id);
      set({ status: 'idle', carriers: response.data?.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createCarrier: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.createCarrier(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  updateCarrier: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.updateCarrier(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteCarrier: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.deleteCarrier(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  getFinancial: async (resident_id: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.getFinancial(resident_id);
      set({ status: 'idle', financial: response.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  upsertFinancial: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.upsertFinancial(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteFinancial: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.deleteFinancial(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
