import { Switch } from '@/common/presentation/components/ui/switch';
import { Label } from '@/components/ui/label';
import { AppDispatch } from '@/store/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchPharmacyAuthenticated, updatePharmacyInfo } from '../../slices/PharmacySlice';
import { PharmacyI } from '@/modules/pharmacy/domain/pharmacy.domian';
import { Loader2 } from 'lucide-react';

const EmailNotifications = () => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const [pharmacyEmail, setPharmacyEmail] = useState<string>('--');
  const [pharmacy, setPharmacy] = useState<PharmacyI | null>(null);

  useEffect(() => {
    getPharmacy();
  }, []);

  const getPharmacy = async () => {
    try {
      const response = await dispatch(fetchPharmacyAuthenticated());
      const pharmacyInfo: PharmacyI = response.payload.data;
      setPharmacyEmail(pharmacyInfo.email);
      setPharmacy(pharmacyInfo);
      setEnabled(pharmacyInfo.notifications == 'active');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheck = useCallback(async () => {
    if (!pharmacy) return;
    setIsLoading(true);
    setEnabled(!enabled);
    await dispatch(updatePharmacyInfo({ id: pharmacy.id, data: { notifications: !enabled ? 'active' : 'inactive' } }));
    setIsLoading(false);  
}, [enabled, pharmacy]);

  return (
<div className="w-full grid grid-cols-1 md:grid-cols-1 gap-4 border rounded">
  <div className="flex flex-row items-center justify-between rounded-lg p-3">
    <div className="space-y-0.5 pr-2">
      <Label className="text-base text-primary font-semibold">
        {t('settings.emailNotificationForNote')}
      </Label>
      <p className="text-sm">
        {t('settings.emailNotificationForNoteDescription', { email: pharmacyEmail })}
      </p>
    </div>
    <div className="flex items-center gap-2">
      {isLoading && <Loader2 className="h-4 w-4 animate-spin" />}
      <Switch disabled={isLoading} checked={enabled} onCheckedChange={handleCheck} />
    </div>
  </div>
</div>
  );
};

export default EmailNotifications;
