import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useTranslation } from 'react-i18next';
import { format } from "date-fns";
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { Pencil, Trash, Calendar as CalendarIcon } from 'lucide-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { updateBedroomQr, deleteTrackerBedroomQr } from '../../slices/TrackerBedroomQrSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { SelectFieldBedroomQr } from './SelectFieldBedroomQr';
import { SelectStaffBedroomQr } from './SelectStaffBedroomQr';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { FiltersTable } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/FiltersTable';


interface BedroomCategory {
    id: string;
    name: string;
    availability: string;
}

interface TrackerData {
    id: string;
    date: string;
    detail: string;
    bedroom_id: string;
}

interface ITrackerBedroomQR {
    id: string;
    tracker: string;
    location_id: string;
    bedroom_category: BedroomCategory[];
    created_at: string;
    updated_at: string;
    data: TrackerData;
}

interface TrackerBedroomQrSummaryProps {
    dataBedroomQrReport: ITrackerBedroomQR[];
    isLoading: boolean;
    dataTrackerBedroomQrReport: (page: number, rowsPerPage: number, resetData: boolean) => void;
    loadMore: () => void;
    hasMore: boolean;
    resident: string;
    rowsPerPage: number;
    dataBedroom: Bedroom[];
    bedroomId: string;
    selectedStaff: string;
    filterValue: string;
    handleFieldChange: (id: string) => void;
    handleStaffChange: (id: string) => void;
    onFilterChange: (value: string) => void;
    onClear: () => void;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

interface Bedroom {
    id: string;
    name: string;
}

export function TrackerBedroomQrSummary({ dataBedroomQrReport, isLoading, dataTrackerBedroomQrReport,
    loadMore, hasMore, resident, rowsPerPage, dataBedroom, bedroomId, selectedStaff, onFilterChange, filterValue,
    onClear, handleFieldChange, handleStaffChange, setCurrentPage }: TrackerBedroomQrSummaryProps) {

    const { t } = useTranslation();
    const { formatDate } = useDateHelpers();
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };
    const [selectedRow, setSelectedRow] = useState<ITrackerBedroomQR | null>(null);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();
    const parsedDate = selectedRow?.data.date ? new Date(selectedRow.data.date) : new Date();
    const formattedDate = formatDate(parsedDate.toISOString());
    const [selectedBedroomId, setSelectedBedroomId] = useState<string | undefined>('')
    const auth = useSelector((state: RootState) => state.auth.user);

    const renderActions = (row: ITrackerBedroomQR) => (
        ['Super Administrator', 'Executive'].includes(auth?.roles[0]?.name) ? (
            <div className="flex space-x-2">
                <span className="cursor-pointer" onClick={() => handleOpenModal(row, 'edit')}>
                    <Pencil className="mr-2 h-4 w-4" />
                </span>
                <span className="cursor-pointer" onClick={() => handleOpenModal(row, 'delete')}>
                    <Trash className="mr-2 h-4 w-4" />
                </span>
            </div>
        ) : null
    );

    const handleOpenModal = (rowData: ITrackerBedroomQR, actionType: 'edit' | 'delete') => {
        setSelectedBedroomId(rowData.data.bedroom_id)
        setSelectedRow(rowData)
        setShowEdit(true)
        setAction(actionType)
    }

    const handleDelete = async () => {
        try {
            await dispatch(deleteTrackerBedroomQr(selectedRow!.id))
            handleCloseModal()
            dataTrackerBedroomQrReport(1, rowsPerPage, true)
        } catch (error) {
            console.log(error)
        }
    }


    const handleCloseModal = () => {
        setShowEdit(false)
    }

    const columnsreport = [
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        { key: 'date', labelTranslationKey: t('trackers.Date/Time'), value: 'date', sortable: true },
        { key: 'field', labelTranslationKey: t('trackers.Field'), value: 'field', sortable: true },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), sortable: true },
        { key: 'created_by', labelTranslationKey: t('trackers.Staff'), sortable: true },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions

        },
    ];

    const handleDateChange = (date: Date) => {
        setSelectedRow((prevState: ITrackerBedroomQR | null) => {
            if (prevState) {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        date: date.toISOString()
                    }
                };
            }
            return prevState;
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSelectedRow((prevState: ITrackerBedroomQR | null) => {
            if (prevState) {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        [name]: value,
                    }
                };
            }
            return prevState;
        });
    };

    const handleBedroomChange = (value: string) => {
        setSelectedBedroomId(value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!selectedRow) {
            alert("Error: no se ha seleccionado ningún elemento.");
            return;
        }

        try {

            const { id } = selectedRow;
            const { date, detail } = selectedRow?.data;

            const updateData = {
                id,
                date,
                detail,
                bedroom_id: selectedBedroomId
            };

            await dispatch(updateBedroomQr(updateData));
            setCurrentPage(1)
            dataTrackerBedroomQrReport(1, rowsPerPage, true)
            handleCloseModal();

        } catch (error) {
            console.error('Error actualizando el tracker bedroomQr:', error);

        }
    };


    return (
        <div>
            <TableCustomVirtuoso
                data={dataBedroomQrReport}
                columns={columnsreport}
                renderCellContent={(index, column, data) => {
                    const row = data[index];
                    switch (column.key) {
                        case 'resident_name':
                            return `${capitalizeWords(row.data.resident.first_name)} ${capitalizeWords(row.data.resident.last_name)}`;

                        case 'date':

                            return (
                                row.data.date ?
                                    <>

                                        {formatDate(row.data.date, false)}
                                        <br />
                                        {formatDate(row.data.date, true, true)}
                                    </>
                                    : ''
                            );

                        case 'field':

                            return row.bedroom_category[0].name ? capitalizeWords(row.bedroom_category[0].name) : '';

                        case 'detail':
                            return row.data.detail ? capitalizeWords(row.data.detail) : '';

                        case 'created_by':
                            return `${capitalizeWords(row.data.created_by.first_name)} ${capitalizeWords(row.data.created_by.last_name)}`;

                        default:
                            return row[column.key];
                    }
                }}
                additionalButtons={<></>}
                isLoading={isLoading}
                filters={
                    <div className="flex items-center justify-between gap-4">
                        <FiltersTable
                            onFilterChange={onFilterChange}
                            filterValue={filterValue}
                            onClear={onClear}
                        />
                        <SelectFieldBedroomQr
                            bedroomId={bedroomId}
                            handleFieldChange={handleFieldChange}
                            dataBedroom={dataBedroom}
                        />

                        <SelectStaffBedroomQr
                            selectedStaff={selectedStaff}
                            handleStaffChange={handleStaffChange}
                        />
                    </div>
                }
                loadMore={loadMore}
                hasMore={hasMore}
                key={resident}
                showSearchInput={false}
            />

            <CustomDialog
                newDialog={true}
                isOpen={showEdit}
                title={action === 'edit' ? t('notes.edit') : t('trackers.Delete')}
                onClose={handleCloseModal}
            >
                <div>
                    {action === 'edit' ? (
                        <form onSubmit={handleSubmit}>

                            <div className="grid gap-4">
                                <Label htmlFor="tracker_bedroom_date" className="control-label mb-3">
                                    Field
                                </Label>
                                <div className="input-group">
                                    <Select value={selectedBedroomId} onValueChange={handleBedroomChange}>
                                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                            <SelectValue>
                                                {dataBedroom.find((bedroom) => bedroom.id === selectedBedroomId)?.name || 'Select Bedroom'}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            {dataBedroom.map((bedroom) => (
                                                <SelectItem key={bedroom.id} value={bedroom.id}>
                                                    {bedroom.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                            <div className="grid gap-4">
                                <Label htmlFor="tracker_sleep_date" className="control-label mb-3 mt-4">
                                    {t('trackers.Date/Time')}
                                </Label>
                                <div className="input-group">
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {formattedDate}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0">
                                            <CustomCalendar
                                                onChange={handleDateChange}
                                                selectedDate={parsedDate}
                                                MonthAndYearPicker={true}
                                                timePicker={true}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>

                            <div className="grid gap-4">
                                <Label htmlFor="detail" className="control-label mb-3 mt-4">
                                    {t('trackers.Detail')}
                                </Label>
                                <div className="input-group">
                                    <Input
                                        className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                        id="detail"
                                        name="detail"
                                        type="text"
                                        value={selectedRow?.data.detail}
                                        onChange={handleChange}

                                    />
                                </div>
                            </div>

                            <div className="w-full flex justify-end mt-4">
                                <Button type="submit"
                                    className="bg-primary text-white px-4 py-2 rounded"
                                >
                                    {t('trackers.Save')}
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <div>
                            <div className='flex justify-center'>
                                <h2>{t('trackers.sureDelete')}</h2>
                            </div>

                            <div className="w-full flex justify-end mt-5">
                                <Button onClick={handleDelete} className="bg-red-600 text-white px-4 py-2 rounded">
                                    {t('trackers.Delete')}
                                </Button>
                                <Button onClick={handleCloseModal} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                                    {t('calendar.cancel')}
                                </Button>
                            </div>
                        </div>

                    )}
                </div>
            </CustomDialog>
        </div>
    )


}