import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EllipsisVertical } from 'lucide-react';
import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { getResidentNavigation } from '../../application/services/getResidentNavigation';

interface Props {
  id: string;
}

export const TableActions = ({ id }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const residentNavigationTabs = getResidentNavigation(t)

  const handleView = ({ e, id, tab }: { e: React.MouseEvent<HTMLDivElement, MouseEvent>; id: string; tab: string }) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/residents/update?r=${id}&t=${tab}`);
  };

  return (
    <>
      <div className="flex gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <EllipsisVertical className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {residentNavigationTabs.map((item, index) => (
              <DropdownMenuItem key={index} onClick={(e) => handleView({ e, id, tab: item.value })}>
                {item.icon}
                <span>{item.title}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};
