import { Label } from '@/components/ui/label';
import CoursesFilter from './CoursesFilter';
import { coursesDummy } from '../../domain/constants/training';
import CourseCard from './CourseCard';
import { useState } from 'react';
import { courseStatus } from '../../domain/training.domain';

const CoursesList: React.FC = () => {
  const filteredCourses = coursesDummy;
  const [currentFilter, setCurrentFilter] = useState<courseStatus>('all')

  return (
    <div className="container mx-auto px-4 py-6 space-y-6">
      <div className="flex flex-col items-start gap-3">
        <Label className="text-2xl font-bold dark:text-zinc-300">Courses ({filteredCourses.length})</Label>
        <CoursesFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
      </div>

      <div className="rounded-lg border p-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-5 gap-4">
          {filteredCourses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoursesList;
