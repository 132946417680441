import { RootState } from '@/store/store';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchGetClientSubscriptions, updateSubscription } from '../../presentation/slices/SubscriptionSlice';
import { removeMessage, showMessage } from '@/common/presentation/slices/bannerSlice';
import { ClientStatusType } from '../../domain/makerPlace.domain';
import { format } from 'date-fns';
import { setMenuAvailability } from '@/common/presentation/slices/menuSlice';
import { websocket } from '@/utils/helpers/websocket.helper';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';

interface IsSubscribedI {
  is_subscribed: boolean;
  modules: string[];
}

interface InitialStateI {
  isSubscribed: (service: string) => IsSubscribedI;
}

interface SubscriptionProviderProps {
  children: ReactNode;
}

interface RoleI {
  id: string;
  name: string;
}

const initialState: InitialStateI = {
  isSubscribed: () => ({ is_subscribed: false, modules: [] })
};

const SubscriptionContext = createContext(initialState);

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientInfo } = useConfigurationsProvider();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const subscriptions = useSelector((state: RootState) => state.subscription.subscriptions);
  const subscriptionsLoading = useSelector((state: RootState) => state.subscription.subscriptions_loading);
  const location = useLocation();
  const isFirstRender = useRef(true);

  const getSubscriptions = useCallback(async () => {
    await dispatch<any>(fetchGetClientSubscriptions());
  }, [dispatch]);

  const isUserExecutive = useMemo(() => {
    return userInfo?.roles.find((role: RoleI) => ['Executive', 'Super Administrator'].includes(role.name));
  }, [isAuthenticated, userInfo]);

  const showBannerMessage = useCallback(
    (serviceName: string, formattedDate: string, subscriptionId: string, serviceKey: string) => {
      let messageBody = {
        label: 'subscription.paymentSubscripionFailed',
        variables: { serviceName: serviceName, date: formattedDate }
      };
      let redirect = `/payment/subscription/${subscriptionId}`;
      let buttonText = { label: 'subscription.goToPay', variables: {} };

      if (!isUserExecutive) {
        messageBody = {
          label: 'subscription.paymentSubscripionFailedStaff',
          variables: {
            serviceName: serviceName,
            date: formattedDate
          }
        };
        redirect = '';
        buttonText = { label: '', variables: {} };
      }

      dispatch(
        showMessage({
          keyMessage: serviceKey,
          category: 'pendingSubscriptionPayment',
          title: { label: 'subscription.pendingSubscriptionPayment', variables: {} },
          message: messageBody,
          type: 'warning',
          redirect,
          buttonText,
          excludedRoutes: ['/payment/subscription']
        })
      );
    },
    [userInfo, isUserExecutive]
  );

  const checkSubscriptions = useCallback(() => {
    const path = location.pathname;
    if (clientInfo.status === ClientStatusType.informationPending && !/^\/sign-up(\/.*)?$/.test(path)) {
      navigate('/sign-up', { replace: true });
      return;
    }

    if (!Object.keys(subscriptions).includes('essential_plan')) {
      dispatch(
        showMessage({
          title: { label: 'Your account does not have an Essential subscription', variables: {} },
          message: { label: 'Please contact support for further assistance', variables: {} },
          type: 'warning'
        })
      );
    }

    Object.keys(subscriptions).forEach((key) => {
      const subscription = subscriptions[key];
      if (['essential_plan', 'training_plan'].includes(key)) {
        if (subscription.status === 'payment_pending') {
          const paymentFailDate = subscription.payments_pending[0].created_at;
          const formattedDate = format(new Date(paymentFailDate), 'yyyy-MM-dd');
          const serviceName = subscription.service.name;
          const subscriptionId = subscription.id;
          showBannerMessage(serviceName, formattedDate, subscriptionId, key);
        } else if (key === 'essential_plan' && subscription.status === 'past_due') {
          if (!isUserExecutive || isUserExecutive.name === 'Executive') {
            dispatch(setMenuAvailability(false));
            const redirectTo = !isUserExecutive ? '/service-suspended' : `/payment/subscription/${subscription.id}`;
            navigate(redirectTo, { replace: true });
            return;
          }
        } else {
          dispatch(setMenuAvailability(true));
          dispatch(
            removeMessage({
              category: 'pendingSubscriptionPayment',
              keyMessage: key
            })
          );
        }
      }
    });
  }, [subscriptions, dispatch, isUserExecutive, isAuthenticated]);

  const handleSocketMessage = useCallback(
    (data: any) => {
      dispatch(updateSubscription(data.message));
    },
    [subscriptions, userInfo, isAuthenticated]
  );

  useEffect(() => {
    if (!isAuthenticated || Object.keys(clientInfo).length === 0) return;
    if (isFirstRender.current) {
      getSubscriptions();
      websocket.subscribe(`subscription-channel-${clientInfo.id}`, handleSocketMessage);
      isFirstRender.current = false;
    }
  }, [isAuthenticated, clientInfo]);

  useEffect(() => {
    if (!isAuthenticated || !clientInfo || subscriptionsLoading) return;
    checkSubscriptions();
  }, [isAuthenticated, subscriptions, clientInfo, subscriptionsLoading]);

  const isSubscribed = (serviceKey: string) => {
    const isSubscribed = serviceKey in subscriptions && subscriptions[serviceKey].status === 'active';
    return {
      is_subscribed: isSubscribed,
      modules: isSubscribed ? subscriptions[serviceKey].plans.map((plan) => plan.service_module.key) : []
    };
  };

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscribed
      }}
      {...props}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used inside of SubscriptionProvider');
  }
  return context;
};
