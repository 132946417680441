import RenderActionsColumn from './renderActionsColumn';

const columnsReportMedications = (residentId) => [
  {
    id: 'month',
    key: 'month',
    labelTranslationKey: 'medications.reportCentralizedMedications.reportDate',
    type: 'medication',
    value: 'month',
    visible: true,
    static: true,
    sortable: true
  },
  {
    id: 'resident_name',
    key: 'resident_name',
    labelTranslationKey: 'medications.reportCentralizedMedications.resident',
    type: 'resident_name',
    value: 'resident_name',
    visible: true,
    static: true,
    sortable: true
  },
  {
    key: 'pdf',
    labelTranslationKey: 'Pdf',
    visible: true,
    sortable: true,
    editable: true,
    type: 'action',
    render: (row) => <RenderActionsColumn residentId={row.resident_id} month={row.month} />
  }
];

export default columnsReportMedications;
