export const frequencyOptions: { value: string; label: string }[] = [
  {
    value: 'once',
    label: 'customAlerts.frequencyOptions.once'
  },
  {
    value: 'daily',
    label: 'customAlerts.frequencyOptions.daily'
  },
  {
    value: 'weekly',
    label: 'customAlerts.frequencyOptions.weekly'
  },
  {
    value: 'monthly',
    label: 'customAlerts.frequencyOptions.monthly'
  },
  {
    value: 'yearly',
    label: 'customAlerts.frequencyOptions.yearly'
  }
];