import PlanOfCareService from '@/modules/residents/infrastructure/services/PlanOfCareService';
import { create } from 'zustand';
import { FormField } from '@/modules/residents/domain/models/FormField';

type PlanOfCareState = {
  planOfCare: FormField | null;
  planOfCareHistory: any[];
  formFields: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getPlanOfCareHistory: (residentId: string) => Promise<any>;
  savePlanOfCare: (data: any, pdf: boolean) => Promise<any>;
  deletePlanOfCare: (id: string, type: string) => Promise<any>;
  generateHistoryPdf: (id: string) => Promise<any>;
  addNewFieldForm: (data: any) => Promise<any>;
  getFormFields: (formId: string) => Promise<any>;
};

export const usePlanOfCareStore = create<PlanOfCareState>((set) => ({
  planOfCare: null,
  planOfCareHistory: [],
  formFields: [],
  status: 'idle',
  error: null,

  getPlanOfCareHistory: async (residentId: string) => {
    set({ status: 'loading' });
    try {
      const response = await PlanOfCareService.getPlanOfCareHistory(residentId);
      set({ status: 'idle', planOfCareHistory: response.data?.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  savePlanOfCare: async (data: any, pdf: boolean) => {
    set({ status: 'loading' });
    try {
      const response = await PlanOfCareService.savePlanOfCare(data, pdf);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deletePlanOfCare: async (id, type) => {
    set({ status: 'loading' });
    try {
      let response;
      if (type === 'document') {
        response = await PlanOfCareService.deleteDocument(id);
      } else {
        response = await PlanOfCareService.deleteForm(id);
      }
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  generateHistoryPdf: async (id: string) => {
    set({ status: 'loading' });
    try {
      const response = await PlanOfCareService.generateHistoryPdf(id);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  addNewFieldForm: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await PlanOfCareService.addNewFieldForm(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error adding new field';
        set({ status: 'failed', error: errorMessage });
        throw new Error(errorMessage);
      } else if (error.request) {
        set({ status: 'failed', error: error.request });
        throw new Error('Error adding new field: ' + error.request);
      } else {
        set({ status: 'failed', error: error.message });
        throw new Error(error.message);
      }
    }
  },

  getFormFields: async (formId: string) => {
    set({ status: 'loading' });
    try {
      const response = await PlanOfCareService.getFormFields(formId);
      set({ status: 'idle', formFields: response.data.fields });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
