import { Button } from '@/components/ui/button';
import { markAsDoneAlert } from '@/modules/alerts/presentation/slices/AlertSlice';
import { AppDispatch } from '@/store/store';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface HiddenAlertsI {
  [key: string]: string[];
}

interface IProps {
  alertId: string;
  relatedId: string;
  hiddenAlerts: HiddenAlertsI;
  setHiddenAlerts: (hiddenAlerts: HiddenAlertsI) => void;
}

const ButtonMarkAsDone: React.FC<IProps> = ({ alertId, relatedId, hiddenAlerts, setHiddenAlerts }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);

  const markAlert = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        markAsDoneAlert({
          alertId: alertId,
          relatedId: relatedId
        })
      ).unwrap();      
      setHiddenAlerts({
        ...hiddenAlerts,
        [alertId]: Array.isArray(hiddenAlerts[alertId]) ? [...hiddenAlerts[alertId], relatedId] : [relatedId]
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Button className="h-6" onClick={markAlert} disabled={loading}>
      {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : t('customAlerts.done')}
    </Button>
  );
};

export default ButtonMarkAsDone;
