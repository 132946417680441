import { Dialog } from '@/common/presentation/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useRequestRestrictByDeviceDialog } from '@/modules/security/infraestructure/hooks/useRestrictByDeviceDialog';
import { Loader2, MonitorSmartphone } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import RequestDeviceForm from './RequestDeviceForm';
import { useState } from 'react';

const RestrictByDeviceRequestDialog = () => {
  const { isOpen, onClose, message, justClose, onSubmit, submitLoading, requestDeviceInfo } =
    useRequestRestrictByDeviceDialog();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    deviceName: '',
    deviceDescription: ''
  });

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  const validateForm = () => {
    const newErrors = {
      deviceName: '',
      deviceDescription: ''
    };

    // Validate device name
    if (!requestDeviceInfo.deviceName) {
      newErrors.deviceName = t('security.deviceNameRequired');
    } else if (requestDeviceInfo.deviceName.length < 3) {
      newErrors.deviceName = t('security.deviceNameMinLength');
    }

    // Validate device description
    if (!requestDeviceInfo.deviceDescription) {
      newErrors.deviceDescription = t('security.descriptionRequired');
    } else if (requestDeviceInfo.deviceDescription.length < 5) {
      newErrors.deviceDescription = t('security.descriptionMinLength');
    }

    setErrors(newErrors);

    return !newErrors.deviceName && !newErrors.deviceDescription;
  };

  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black/50 bg-opacity-10 z-[999999999]" />}
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="z-[9999999999] w-[95%] max-w-md">
          <DialogHeader>
            <DialogTitle>
              <div className="flex flex-row gap-2 items-center text-left">
                <MonitorSmartphone className="text-primary mr-2" size={20} />
                <span className="text-left">{t('security.restrictByDevice')}</span>
              </div>
              <hr className="border-primary w-full my-3" />
            </DialogTitle>
            <DialogDescription className="text-left"></DialogDescription>
            <>
              <div className="text-left">{message}</div>
              {!justClose && <RequestDeviceForm errors={errors} setErrors={setErrors} />}
            </>
          </DialogHeader>
          <DialogFooter className="flex flex-col sm:flex-row sm:justify-end gap-2">
            {justClose ? (
              <Button onClick={onClose} className="w-full sm:w-auto">
                {t('security.close')}
              </Button>
            ) : (
              <>
                <Button variant="outline" onClick={onClose} className="w-full sm:w-auto">
                  {t('security.cancel')}
                </Button>
                <Button onClick={handleSubmit} disabled={submitLoading} className="w-full sm:w-auto">
                  {submitLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  {t('security.requestRegistration')}
                </Button>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RestrictByDeviceRequestDialog;
