import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { Button } from '@/common/presentation/components/ui/button';
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Input } from '@/common/presentation/components/ui/input';
import { useTranslation } from 'react-i18next';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';

import { getTrackerElopement, saveTrackerElopement } from "../slices/TrackerElopementSlice";
import { toast } from '@/common/presentation/components/ui/use-toast';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Card } from '@/common/presentation/components/ui/card';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { CircleFadingPlus } from "lucide-react";
import * as staffSliceActions from '@/modules/staff/presentation/slices/staffSlice';
import { ElopementSummary } from '../components/Elopement/ElopementSummary';
import { useDateHelpers } from '@/utils/helpers/dates.helper';

interface RadioOption {
    label: string;
    value: string;
}

interface TrackerI {

    data: TrackerDataI;
    resident_id: string;
    resident_name: string;
}

interface TrackerDataI {
    date: string;
    id_tracker: string;
    reported_by: string;
    mental_status: string;
    wandering: 'yes' | 'no' | '';
    psychotripic_drugs: 'yes' | 'no' | '';
    quantity_drugs: number | null;
    change_state: string;
    notes: string;
    details: string
    created_by: {
        id: string;
        first_name: string;
        last_name: string;
    };
    id: string;
    location_id: string;
    created_at: string;

}

interface FormData {
    date: string;
    reported_by: string;
    mental_status: string;
    wandering: 'yes' | 'no' | '';
    psychotripic_drugs: 'yes' | 'no' | '';
    quantity_drugs: number | null;
    change_state: string;
    notes: string;
    details: string
}


export default function TrackerElopement() {

    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const formattedDate = selectedDate ? selectedDate.toISOString() : '';
    const { formatDate } = useDateHelpers();
    const { locationSelectedId } = useLocation();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [dataReport, setDatareport] = useState<TrackerI[]>([]);
    const [selectedOption, setSelectedOption] = useState<string>(params.e || 'form');
    const onSelectOption = (e: string) => {
        setParam('e', e);
        setSelectedOption(e);
    };
    const { staff } = useSelector((state: RootState) => state.staff.allStaff);
    const [isSubmitForm, setIsSubmitForm] = useState<boolean>(false);
    const residents = useSelector((state: any) => {
        const residentState = state.residents?.resident?.resident; // Validar si existe `resident`
        return residentState ? residentState.mental_condition : null;
    });
    const authUserId = useSelector((state: RootState) => state.auth.user?.id);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        if (locationSelectedId) {
            dispatch(staffSliceActions.getStaffByLocation(locationSelectedId === 'all-locations' ? undefined : locationSelectedId));
        } else {
            setParam('l', 'all-locations');
        }
    }, [locationSelectedId]);


    const yesNoOptions: RadioOption[] = [
        { label: t('trackers.trackerFall.yesOption'), value: 'yes' },
        { label: t('trackers.trackerFall.noOption'), value: 'no' }
    ];

    const quantities = [1, 2, 3, 4, 5, 6, 7];

    const [selectedWandering, setSelectedWandering] = useState<string | null>(null);
    const [selectedDrugs, setSelectedDrugs] = useState<string | null>(null);
    const [selectedState, setSelectedState] = useState<string | null>(null);
    const [quantityDrugs, setQuantityDrugs] = useState<number | null>(null);

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (residents) {
            const mentalStatus = residents.dementia_type || '';

            setFormData(prevFormData => ({
                ...prevFormData,
                mental_status: mentalStatus
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                mental_status: ''
            }));
        }
    }, [residents, params.r]);

    const dispatch = useDispatch<AppDispatch>();


    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (!params.r) {
            setParam('r', 'all-residents');
        }
    }, [params.r, setParam]);


    const [isLoading, setIsLoading] = useState(false);
    const [dataOriginal, setDataOriginal] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load

    const dataElopementReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true);
        const response = await dispatch(getTrackerElopement({
            search: searchTerm,
            location_id: locationSelectedId,
            resident_id: params.r,
            per_page: perPage,
            page

        }));

        if (response.payload) {
            try {
                const { trackerElopements }: {
                    trackerElopements: { data: TrackerI[], current_page: number, last_page: number, total: number }
                } = response.payload;

                const data = trackerElopements.data;
                if (resetData) {
                    setDatareport(data);
                    setDataOriginal(data);
                } else {
                    setDatareport(prevData => [...prevData, ...data]);
                    setDataOriginal(prevData => [...prevData, ...data]);
                }

                if (trackerElopements.current_page >= trackerElopements.last_page ||
                    trackerElopements.total < data.length) {
                    setHasMoreRows(false);
                } else {
                    setHasMoreRows(true);
                }

            } catch (error: any) {
                console.log(error);
            }
        }
        setIsLoading(false);
    }

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const handleClearFilter = () => {
        setSearchTerm('');
    };

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataElopementReport(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataElopementReport]);

    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {

        // Initializes the state with complete data only if dataOriginal is empty
        if (!isInitialized && dataReport.length > 0) {
            setDataOriginal(dataReport); // Saves a copy of all the data initially
            setIsInitialized(true); // Marks initialization as complete
        }

        // Applies filtering when the original data is already loaded
        if (dataOriginal.length > 0 || dataReport.length > 0) {
            let updatedData = dataOriginal.length > 0 ? dataOriginal : dataReport;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: any) => {
                        return item.data.some((data: any) => data.location === locationSelectedId);
                    });

                }
            } else {
                updatedData = updatedData.filter((item: any) => {
                    if (locationSelectedId === "all-locations") {
                        return item.resident_id === params.r;
                    }

                    return item.resident_id === params.r && item.data.some((data: any) => data.location === locationSelectedId);
                });
            }

            // Only updates if dataReport needs to be changed
            if (JSON.stringify(updatedData) !== JSON.stringify(dataReport)) {
                setDatareport(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginal, dataReport]);

    useEffect(() => {
        setDatareport([]);
        setDataOriginal([]);
        setCurrentPage(1);
        setHasMoreRows(true);
        dataElopementReport(1, rowsPerPage, true);
    }, [locationSelectedId, params.r, selectedOption, searchTerm]);



    const [formData, setFormData] = useState<FormData>({
        date: selectedDate.toISOString(),
        reported_by: '',
        mental_status: '',
        wandering: '',
        psychotripic_drugs: '',
        quantity_drugs: null,
        change_state: '',
        notes: '',
        details: ''
    });

    const handleStaffChange = (staffId: string) => {
        setFormData(prevState => ({
            ...prevState,
            reported_by: staffId
        }));
    };

    useEffect(() => {
        const matchingStaff = staff.find((s) => s.id === authUserId);
        if (matchingStaff) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                reported_by: matchingStaff.id
            }));
        }
    }, [staff, authUserId]);

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setFormData(prevState => ({
            ...prevState,
            date: date.toISOString()
        }));
    };

    const handleWanderingChange = (value: 'yes' | 'no' | '') => {
        setSelectedWandering((prevValue) => {
            const newValue = prevValue === value ? '' : value;
            setFormData((prevData) => ({
                ...prevData,
                wandering: newValue,
            }));
            return newValue;
        });
    };

    const handleDrugsChange = (value: 'yes' | 'no' | '') => {
        setSelectedDrugs((prevValue) => {
            const newValue = prevValue === value ? '' : value;
            if (newValue !== 'yes') {
                setQuantityDrugs(null);
            }

            setFormData((prevData) => ({
                ...prevData,
                psychotripic_drugs: newValue,
                quantity_drugs: newValue !== 'yes' ? null : prevData.quantity_drugs
            }));

            return newValue;
        });
    };

    const handleQuantityChange = (value: number) => {
        setQuantityDrugs(value);
        setFormData((prevData) => ({
            ...prevData,
            quantity_drugs: value
        }));
    };

    const handleStateChange = (value: 'yes' | 'no' | '') => {
        setSelectedState((prevValue) => {
            const newValue = prevValue === value ? '' : value;
            setFormData((prevData) => ({
                ...prevData,
                change_state: newValue,
            }));

            return newValue;
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const json = {
            date: formData.date,
            reported_by: formData.reported_by,
            mental_status: formData.mental_status,
            wandering: formData.wandering,
            psychotripic_drugs: formData.psychotripic_drugs,
            quantity_drugs: formData.quantity_drugs,
            change_state: formData.change_state,
            notes: formData.notes,
            details: formData.details,
            resident_id: params.r,
            location_id: locationSelectedId
        };
        if (params.r === 'all-residents' || !params.r) {
            toast({
                description: t('trackers.trackerincontinence.selectResident'),
            });
            return
        }


        if (!formData.reported_by) {
            toast({
                description: t('trackers.trackerFall.reportedBy'),
            });
            return
        }

        try {
            setIsSubmitForm(true);
            const response = await dispatch(saveTrackerElopement([json]));
            if (response.payload) {
                toast({
                    description: t('trackers.trackerFall.saveOk'),
                    className: 'bg-green-500 text-white'
                });
                setFormData({
                    date: selectedDate.toISOString(),
                    reported_by: authUserId,
                    mental_status: formData.mental_status,
                    wandering: '',
                    psychotripic_drugs: '',
                    quantity_drugs: null,
                    change_state: '',
                    notes: '',
                    details: ''
                });
                setSelectedDrugs('');
                setSelectedWandering('');
                setSelectedState('');
                dataElopementReport(1, rowsPerPage, true);
                setIsSubmitForm(false);
            } else {
                toast({
                    description: t('trackers.trackerFall.error'),
                    variant: 'destructive'
                });
                setIsSubmitForm(false);
            }

        } catch (error) {
            toast({
                description: t('trackers.trackerFall.error'),
                variant: 'destructive'
            });
            setIsSubmitForm(false);
        }
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl text-primary">{t('trackers.trackerElopement.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerElopement.description')}</div>
                        </div>
                        <Tabs defaultValue={selectedOption} onValueChange={(e) => { onSelectOption(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('cleaning.create.area.newButton')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>

                    </div>
                )
            }

            <div>
                <ResidentSelector
                    onSelect={onResidentSelect}
                    locationId={location || undefined}
                    residentId={params.r}
                    showAllResidents={selectedOption === 'report'}
                />
            </div>

            {selectedOption === 'form' && (
                < Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-wrap">
                            <div className="flex flex-col w-full md:w-1/3 p-2">
                                <Label htmlFor="tracker_date" className="control-label mb-3">
                                    {t('trackers.Date/Time')}
                                </Label>
                                <div className="input-group">
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {formatDate(formattedDate)}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0">
                                            <CustomCalendar
                                                onChange={handleDateChange}
                                                selectedDate={selectedDate}
                                                MonthAndYearPicker={true}
                                                timePicker={true}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            <div className="flex flex-col w-full md:w-1/3 p-2">
                                <Label htmlFor="tracker_data" className="control-label mb-3">
                                    {t('trackers.trackerFall.reported_by')}
                                </Label>
                                <div className="input-group">

                                    <Select value={formData.reported_by} onValueChange={handleStaffChange}>
                                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                            <SelectValue>
                                                {formData.reported_by
                                                    ? staff.find(s => s.id === formData.reported_by)?.first_name + ' ' + staff.find(s => s.id === formData.reported_by)?.last_name
                                                    : 'Select staff'}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            {staff.map((member) => (
                                                <SelectItem key={member.id} value={member.id}>
                                                    {member.first_name} {member.last_name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                            <div className="flex flex-col w-full md:w-1/3 p-2">
                                <Label htmlFor="tracker_data" className="control-label mb-3">
                                    {t('trackers.trackerElopement.mentalStatus')}
                                </Label>
                                <div className="input-group">
                                    <div className="input-group">
                                        <Input
                                            className="w-full justify-start font-normal bg-gray-200"
                                            id="mental_status"
                                            name="mental_status"
                                            type="text"
                                            readOnly
                                            value={formData.mental_status || ''}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full md:w-1/3 p-2 mt-2">
                                <RadioGroup className="flex flex-col gap-4 w-full">
                                    <Label htmlFor="tracker_data" className="control-label">
                                        {t('trackers.trackerElopement.historyWandering')}
                                    </Label>

                                    <div className="flex flex-wrap gap-4 mt-2">

                                        {yesNoOptions.map((option) => (
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem
                                                    value={option.value}
                                                    checked={selectedWandering === option.value}
                                                    onClick={() => handleWanderingChange(option.value as 'yes' | 'no' | '')}
                                                    className="focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                                />
                                                <Label key={option.value} className="flex items-center gap-2 cursor-pointer"><span>{option.label}</span></Label>
                                            </div>

                                        ))}

                                    </div>
                                </RadioGroup>
                            </div>

                            <div className="flex flex-col w-full md:w-1/3 p-2 mt-2">

                                <RadioGroup className="flex flex-col gap-4 w-full">
                                    <Label htmlFor="tracker_data" className="control-label">
                                        {t('trackers.trackerElopement.drugs')}
                                    </Label>

                                    <div className="flex flex-wrap gap-4 mt-2">

                                        {yesNoOptions.map((option) => (
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem
                                                    value={option.value}
                                                    checked={selectedDrugs === option.value}
                                                    onClick={() => handleDrugsChange(option.value as 'yes' | 'no' | '')}
                                                    className="focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                                />
                                                <Label key={option.value} className="flex items-center gap-2 cursor-pointer">{option.label}</Label>
                                            </div>

                                        ))}

                                        {selectedDrugs === 'yes' && (
                                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                                                <Label htmlFor="quantity" className="control-label whitespace-nowrap">
                                                    {t('medications.columnsResidentMedications.quantity')}
                                                </Label>
                                                <Select value={quantityDrugs !== null ? quantityDrugs.toString() : ''} onValueChange={(value) => handleQuantityChange(Number(value))}>
                                                    <SelectTrigger id="quantity_drugs" className="w-full sm:w-auto bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                                        <SelectValue>
                                                            {quantityDrugs !== null ? quantityDrugs.toString() : 'Choose...'}
                                                        </SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {quantities.map((q) => (
                                                            <SelectItem key={q} value={q.toString()}>
                                                                {q}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        )}

                                    </div>
                                </RadioGroup>

                            </div>

                            <div className="flex flex-col w-full md:w-1/3 p-2">

                                <RadioGroup className="flex flex-col gap-4 w-full">
                                    <Label htmlFor="tracker_data" className="control-label">
                                        {t('trackers.trackerElopement.changeState')}
                                    </Label>

                                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">

                                        {yesNoOptions.map((option) => (
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem
                                                    value={option.value}
                                                    checked={selectedState === option.value}
                                                    onClick={() => handleStateChange(option.value as 'yes' | 'no' | '')}
                                                    className="focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                                />
                                                <Label key={option.value} className="flex items-center gap-2 cursor-pointer"><span>{option.label}</span></Label>
                                            </div>

                                        ))}

                                    </div>
                                </RadioGroup>

                            </div>

                            <div className="flex flex-col w-full md:w-2/3 p-2">
                                <Label htmlFor="notes" className="control-label mb-3">
                                    {t('trackers.trackerElopement.notes')}
                                </Label>
                                <div className="input-group">
                                    <Input
                                        className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                        id="notes"
                                        name="notes"
                                        type="text"
                                        value={formData.notes}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col w-full md:w-1/3 p-2">
                                <Label htmlFor="details" className="control-label mb-3">
                                    {t('trackers.trackerElopement.details')}
                                </Label>
                                <div className="input-group">
                                    <Input
                                        className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                        id="details"
                                        name="details"
                                        type="text"
                                        value={formData.details}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex justify-center mt-4">
                                <Button type="submit"
                                    className="bg-primary text-white px-4 py-2 rounded"
                                    disabled={isSubmitForm}>
                                    {t('trackers.Save')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </Card>
            )}

            {
                selectedOption === 'report' && (
                    <ElopementSummary
                        dataReport={dataReport}
                        isLoading={isLoading}
                        loadMore={loadMore}
                        hasMore={hasMoreRows}
                        resident={params.r}
                        onFilterChange={handleSearchChange}
                        filterValue={searchTerm}
                        onClear={handleClearFilter}
                    />
                )
            }
        </div >
    )

}