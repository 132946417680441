import { useState, useEffect } from 'react';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { getBedroomQrCategories, saveBedroomQr, getBedroomQrCategoriesByResident } from '@/modules/residents/presentation/slices/residentBedroomQrSlice';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Button } from '@/common/presentation/components/ui/button';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { useTranslation } from 'react-i18next';

interface BedroomQrPropsI {
    isOpen: boolean;
    onClose: () => void;
}

interface Bedroom {
    id: string;
    name: string;
}

interface BedroomStatus {
    bedroomId: string;
    status: 'active' | 'inactive';
}

interface Payload {
    residentId: string;
    bedrooms: BedroomStatus[];
}


export function BedroomQr({ isOpen, onClose }: BedroomQrPropsI) {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [dataBedroom, setDataBedroomCategories] = useState<Bedroom[]>([])
    const residentId = useSelector((state: RootState) => state.residents.resident.resident.id);
    const residentName = useSelector((state: RootState) => {
        const resident = state.residents.resident.resident;
        return `${resident.first_name} ${resident.last_name}`;
    });
    const [bedroomStatus, setBedroomStatus] = useState<{ [key: string]: boolean }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const auth = useSelector((state: RootState) => state.auth.user);

    const handleSwitchChange = (bedroomId: string, isActive: boolean) => {
        setBedroomStatus(prev => ({
            ...prev,
            [bedroomId]: isActive,
        }));
    };


    const handleUpdateClick = () => {
        window.open(`/tracker/update-bedroom-qr/?residentId=${residentId}&residentName=${residentName}`, '_blank')?.focus();
    };


    const handleQrCodeClick = async () => {
        window.open(`/qr-code/?residentId=${residentId}&residentName=${residentName}`, '_blank')?.focus();
    };

    const dataBedroomCategoriesByResident = async () => {
        const response = await dispatch(getBedroomQrCategoriesByResident(residentId));
        if (response.payload) {

            try {
                const { bedrooms } = response.payload;

                const initialStatus = bedrooms.reduce((statusMap: { [key: string]: boolean }, bedroom: { bedroomId: string; status: string }) => {
                    statusMap[bedroom.bedroomId] = bedroom.status === 'active';
                    return statusMap;
                }, {});


                setBedroomStatus(initialStatus);

            } catch (error: any) {
                console.log(error);
            }

        }
    }

    const dataBedroomCategories = async () => {
        setIsLoading(true);
        const response = await dispatch(getBedroomQrCategories());
        if (response.payload) {

            try {
                setDataBedroomCategories(response.payload);
                setIsLoading(false);
            } catch (error: any) {

                console.log(error);
            }
        }
    }

    const handleSubmit = async () => {
        setIsSubmit(true);
        const payload: Payload = {
            residentId,
            bedrooms: dataBedroom.map(bedroom => ({
                bedroomId: bedroom.id,
                status: bedroomStatus[bedroom.id] ? 'active' : 'inactive',
            })),
        };

        const response = await dispatch(saveBedroomQr(payload));
        if (response.payload) {
            onClose();
            setIsSubmit(false);
        } else {
            setIsSubmit(false);
            console.log('Error al crear');
        }
    };


    useEffect(() => {
        if (!isOpen) {
            return;
        }
        dataBedroomCategories()
        dataBedroomCategoriesByResident()
    }, [isOpen])


    return (

        <Dialog open={isOpen} onOpenChange={onClose} key={'bedroom'}>
            <DialogContent className="max-w-[92%] min-h-[80vh] flex flex-col gap-6 md:max-w-[45%] rounded-lg p-6 border-t-4 border-t-primary/80">

                <DialogTitle> <p className="text-primary">{residentName} - {t('trackers.trackerBedroom.bedroomQr')}</p></DialogTitle>
                <Separator className="bg-primary" />

                <p>{t('trackers.trackerBedroom.selectBedroom')}</p>

                <div className="p-4 bg-zinc-100 rounded-md overflow-scroll max-h-80">

                    {isLoading ? (
                        <div className="flex justify-center">
                            <p>{t('contacts.loading')}...</p>
                        </div>
                    ) : (
                        dataBedroom.sort((a, b) => a.name.localeCompare(b.name))
                        .map((bedroom) => (
                            <div key={bedroom.id} className="flex items-center justify-between h-12 bg-white mb-1 px-6">

                                <p className="font-bold">{bedroom.name}</p>
                                <Switch
                                    checked={!!bedroomStatus[bedroom.id]}
                                    onCheckedChange={(isActive) => handleSwitchChange(bedroom.id, isActive)}
                                />

                            </div>
                        ))
                    )}

                </div>


                {!isLoading ? (
                    <div>
                        <div className="flex flex-wrap justify-between items-center gap-4">
                            <div className="w-[48%]">
                                <Button onClick={handleQrCodeClick} className="w-full">
                                    {t('trackers.trackerBedroom.bedroomQrCode')}
                                </Button>
                            </div>
                            {['Super Administrator', 'Executive'].includes(auth?.roles[0].name) && (
                                <div className="w-[48%]">
                                    <Button onClick={handleUpdateClick} className="w-full">
                                        {t('trackers.trackerBedroom.updateBedroomQrCode')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                ) : ''}

                <Separator className="bg-primary mb-4" />

                {!isLoading ? (
                    <div className='flex justify-end gap-4'>
                        <Button onClick={onClose} variant="outline" className="w-[140px]">
                            {t('trackers.Close')}
                        </Button>
                        <Button onClick={handleSubmit} disabled={isSubmit} className="w-[140px]">
                            {t('trackers.Save')}
                        </Button>
                    </div>
                ) : ''}



            </DialogContent>

        </Dialog >

    )

}