import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut, X, RotateCcw } from 'lucide-react';

interface Image {
  name: string;
  image: string;
  uploaded_at: string;
}

interface ImageViewerProps {
  images: Image[];
  initialIndex?: number;
  onClose?: () => void;
  maxZoom?: number;
  minZoom?: number;
}

export default function ImageViewer({
  images,
  initialIndex = 0,
  onClose,
  maxZoom = 3,
  minZoom = 0.5
}: ImageViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
    resetTransforms();
  };

  const previousImage = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
    resetTransforms();
  };

  const zoomIn = () => {
    setZoom((prev) => Math.min(prev + 0.25, maxZoom));
  };

  const zoomOut = () => {
    setZoom((prev) => Math.max(prev - 0.25, minZoom));
  };

  const rotate = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const resetTransforms = () => {
    setZoom(1);
    setRotation(0);
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
    resetTransforms();
  };

  if (!images || images.length === 0) {
    return <div className="flex items-center justify-center h-full bg-gray-900 text-white">No images available</div>;
  }

  return (
    <div className="flex flex-col w-full h-full bg-gray-900">
      <div className="flex justify-between items-center p-4 bg-gray-800">
        <div className="text-white">
          {currentIndex + 1} / {images.length}
        </div>
        <div className="flex gap-2">
          <button onClick={zoomOut} className="p-2 rounded hover:bg-gray-700 text-white">
            <ZoomOut className="w-5 h-5" />
          </button>
          <button onClick={zoomIn} className="p-2 rounded hover:bg-gray-700 text-white">
            <ZoomIn className="w-5 h-5" />
          </button>
          <button onClick={rotate} className="p-2 rounded hover:bg-gray-700 text-white">
            <RotateCcw className="w-5 h-5" />
          </button>
          <button onClick={resetTransforms} className="p-2 rounded hover:bg-gray-700 text-white">
            Reset
          </button>
          {onClose && (
            <button onClick={onClose} className="p-2 rounded hover:bg-gray-700 text-white">
              <X className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>

      <div className="relative flex-1 flex items-center justify-center overflow-hidden">
        {images.length > 1 && (
          <>
            <button
              onClick={previousImage}
              className="absolute left-4 p-2 rounded-full bg-black/50 text-white hover:bg-black/70"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>

            <button
              onClick={nextImage}
              className="absolute right-4 p-2 rounded-full bg-black/50 text-white hover:bg-black/70"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </>
        )}

        <div
          className="w-full h-full flex items-center justify-center"
          style={{
            transform: `scale(${zoom}) rotate(${rotation}deg)`,
            transition: 'transform 0.3s ease'
          }}
        >
          <img
            src={images[currentIndex].image}
            alt={`Image ${currentIndex + 1}`}
            className="max-h-full max-w-full object-contain"
          />
        </div>
      </div>

      {images.length > 1 && (
        <div className="p-4 bg-gray-800">
          <div className="flex justify-center gap-2 overflow-x-auto">
            {images.map((image, index) => (
              <button
                key={image.name}
                onClick={() => handleThumbnailClick(index)}
                className={`relative w-16 h-16 flex-shrink-0 ${currentIndex === index ? 'ring-2 ring-blue-500' : ''}`}
              >
                <img src={image.image} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-cover rounded" />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
