import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
import { RootState } from '@/store/store';
import { Eye, Trash } from 'lucide-react';
import { useSelector } from 'react-redux';

interface Props {
  document_id: string;
  form_id: string;
  url: string;
  data: Record<string, unknown>;
}

export const PlanOfCareHistoryActions = ({ document_id, form_id, url, data }: Props) => {
  const { deletePlanOfCare, status: formsStatus, getPlanOfCareHistory } = usePlanOfCareStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const handleDelete = async () => {
    try {
      const response = await deletePlanOfCare(
        data !== null ? form_id : document_id,
        data !== null ? 'form' : 'document'
      );

      toast({
        description: 'Form deleted successfully',
        variant: 'default'
      });

      if (response) {
        await getPlanOfCareHistory(resident?.id);
      }
    } catch (error) {
      toast({
        description: 'Error deleting form',
        variant: 'destructive'
      });
    }
  };

  const handleView = async () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="flex gap-2">
      <Button size={'icon'} variant={'outline'} onClick={handleView} disabled={formsStatus === 'loading'}>
        <Eye className="w-4 h-4" />
      </Button>
      <Button size={'icon'} variant={'outline'} onClick={handleDelete} disabled={formsStatus === 'loading'}>
        <Trash className="w-4 h-4" />
      </Button>
    </div>
  );
};
