import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import QuickTrackerInputsProps from "../interfaces/QuickTrackerInputsPropsInterface";
import QuickTrackerService from "../services/QuickTrackerService";

const QuickTrackerInputSelect = ({
    item,
    resident,
    type,
    exist,
    existOnList,
    onselectvalue,
    volumes,
    save
}: QuickTrackerInputsProps) => {
    return (
        <div className="relative" style={{ width: "15rem", maxWidth: "15rem" }}>
            <Select 
            key={item} 
            onValueChange={
                (value) => {
                    onselectvalue(
                        value, 
                        resident, 
                        type, 
                        exist,
                        exist ? item.id : undefined,
                        exist ? item.id_data : undefined
                    );

                    if(QuickTrackerService.existOnVolumes(volumes, type)) {
                        const volume = volumes[type.name];
                        const volumeOptions = Object.keys(volume);

                        volumeOptions.forEach(
                            (volumeOption) => {
                                if(volumeOption === value) {
                                    if(volume[volumeOption]) volume[volumeOption](resident, save);
                                }
                            }
                        );
                    }
                }
            }>
                <SelectTrigger className="px-2 py-1 bg-gray-100 rounded-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0" style={{ width: "100%" }}>
                    {
                        item && !existOnList ? (
                            <SelectValue placeholder={item.action}>
                                {item.action}
                            </SelectValue>
                        ) : (
                            <SelectValue></SelectValue>
                        )
                    }
                </SelectTrigger>

                <SelectContent>
                    {
                        type.options && type.options ? (
                            type.options.map(
                                (value) => (
                                    <SelectItem value={value}>{value}</SelectItem>
                                )
                            )
                        ): null
                    }
                </SelectContent>
            </Select>
        </div>
    );
};

export default QuickTrackerInputSelect;
