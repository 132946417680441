import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useCarriersAndFinancialInformationStore } from '@/modules/residents/domain/stores/carriers-financial-information/use-carrier-financial-information';
import { RootState } from '@/store/store';

const formSchema = z.object({
  financial_source: z.string().optional(),
  source_payeer: z.string().optional(),
  uci_number: z.string().optional(),
  social_worker: z.string().optional(),
  source_payee_phone: z.string().optional()
});
type FormValues = z.infer<typeof formSchema>;

export const FinancialInformationForm = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const { getFinancial, upsertFinancial, deleteFinancial, status } = useCarriersAndFinancialInformationStore();

  const [hasFinancialInfo, setHasFinancialInfo] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      financial_source: '',
      source_payeer: '',
      uci_number: '',
      social_worker: '',
      source_payee_phone: ''
    }
  });

  useEffect(() => {
    if (resident) {
      const fetchFinancial = async () => {
        try {
          const { data } = await getFinancial(resident.id);

          if (data) {
            form.reset({
              financial_source: data.financial_source || '',
              source_payeer: data.source_payeer || '',
              uci_number: data.uci_number || '',
              social_worker: data.social_worker || '',
              source_payee_phone: data.source_payee_phone || ''
            });
            setHasFinancialInfo(true);
          } else {
            form.reset({
              financial_source: '',
              source_payeer: '',
              uci_number: '',
              social_worker: '',
              source_payee_phone: ''
            });
            setHasFinancialInfo(false);
          }
        } catch (error) {
          toast({
            description: 'Error fetching financial information',
            variant: 'destructive'
          });
        }
      };

      fetchFinancial();
    } else {
      form.reset({
        financial_source: '',
        source_payeer: '',
        uci_number: '',
        social_worker: '',
        source_payee_phone: ''
      });
      setHasFinancialInfo(false);
    }
  }, [resident, getFinancial]);

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      resident_id: resident?.id,
      ...values
    };

    try {
      await upsertFinancial(payload);
      setHasFinancialInfo(true);
      toast({
        description: 'Financial Information saved successfully'
      });
    } catch (error) {
      toast({
        description: 'Failed to save Financial Information',
        variant: 'destructive'
      });
    }
  };

  const handleClear = useCallback(async () => {
    if (resident) {
      try {
        await deleteFinancial({ resident_id: resident.id });
        form.reset({
          financial_source: '',
          source_payeer: '',
          uci_number: '',
          social_worker: '',
          source_payee_phone: ''
        });
        setHasFinancialInfo(false);
        toast({
          description: 'Financial Information deleted successfully'
        });
      } catch (error) {
        toast({
          description: 'Failed to delete Financial Information',
          variant: 'destructive'
        });
      }
    }
  }, [resident, deleteFinancial, form]);

  return (
    <div>
      <CustomAccordion
        titleComponent={<h1 className="text-xl font-bold">Financial Information</h1>}
        openOption={true}
        defaultOpen={true}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="financial_source"
                render={({ field }) => (
                  <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel>Financial Source</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="source_payeer"
                render={({ field }) => (
                  <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel>Source Payee</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="uci_number"
                render={({ field }) => (
                  <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel>UCI#</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="social_worker"
                render={({ field }) => (
                  <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel>Social Worker</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="source_payee_phone"
                render={({ field }) => (
                  <FormItem className="col-span-2 md:col-span-2">
                    <FormLabel>Source Payee Phone</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="col-span-2 space-y-4">
              <Separator />
              <div className="flex items-center gap-2">
                {form.formState.isDirty && (
                  <Button className="flex gap-2" variant="default" type="submit" disabled={status === 'loading'}>
                    {status === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                    Save
                  </Button>
                )}
                <Button
                  className="flex gap-2"
                  variant="outline"
                  type="button"
                  onClick={handleClear}
                  disabled={status === 'loading' || !hasFinancialInfo}
                >
                  {status === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                  Clear Information
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </CustomAccordion>
    </div>
  );
};
