import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ResidentMedicalInformationService = {
  updateHealthInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/general-information`, { ...data });
    return response;
  },
  uppsertDiagnosisInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/diagnosis`, { ...data });
    return response;
  },
  deleteDiagnosisInformation: async (data: any) => {
    const response = await axios.delete(`/residents/medical-information/diagnosis/${data.id}`);
    return response;
  },
  uppsertAllergyInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/allergy`, { ...data });
    return response;
  },
  deleteAllergyInformation: async (data: any) => {
    const response = await axios.delete(`/residents/medical-information/allergy/${data.id}`);
    return response;
  },
  uppsertExamOrProcedureInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/exam-or-procedure`, { ...data });
    return response;
  },
  deleteExamOrProcedureInformation: async (data: any) => {
    const response = await axios.delete(`/residents/medical-information/exam-or-procedure/${data.id}`);
    return response;
  },
  uppsertMentalConditionInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/mental-condition`, {
      ...data
    });
    return response;
  },
  uppsertTubercolosisInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/tuberculosis`, { ...data });
    return response;
  },
  createOrUpdateActionsInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/actions/${data.resident_id}`, { ...data });
    return response;
  },

  searchCode: async (code: string) => {
    const response = await axios.get(`/residents/medical-information/search-code/${code}`);
    return response;
  },

  createDocumentCategory: async (data: any) => {
    const response = await axios.post(`/residents/documents/category/${data.category_id}`, { ...data });
    return response;
  }
};

export default ResidentMedicalInformationService;
